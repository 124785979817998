import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";

export default function retrievalReducer(state = initialState.retrievals, action) {

    /**
     retrievals: {
        unpicked: [],
        unsent: [],
        completed: []
        }
     */
    
    switch (action.type) {
        case(types.GOT_UNPICKED_RETRIEVAL_ORDERS): {
            let newState = {...state};
            const orders = action.orders;
            orders.forEach( o =>  {
                fixDates(o);
            });
            newState.unpicked = orders;
            return newState;
        }
        case(types.GOT_UNSENT_RETRIEVAL_ORDERS): {
            let newState = {...state};
            const orders = action.orders;
            orders.forEach( o =>  {
                fixDates(o);
            });
            newState.unsent = action.orders;
            return newState;
        }
        case(types.GOT_COMPLETED_RETRIEVAL_ORDERS): {
            let newState = {...state};
            const orders = action.orders;
            orders.forEach( o =>  {
                fixDates(o);
            });
            newState.completed = action.orders;
            return newState;
        }
        case(types.MARKED_RO_AS_SENT): {
            let updatedOrder = action.order;
            
            // moving from unsent to sent we assume 
            const unsent = state.unsent?.filter(item => item.id !== updatedOrder.id)??[]; // copy and remove from unsentOrders
            fixDates(updatedOrder);
            const completed = [...state.completed,updatedOrder]; //Object.assign([],state.completed, updatedOrder); // copy / append

            return {
                unpicked: state.unpicked,   // unchanged
                unsent: unsent,             // order removed
                completed: completed        // order added
            };
        }
        default:
            return(state)
    }
    
    function fixDates(order) {
        order.dateDueBack = LIUtils.dateFromJsonUtcDateStr(order.dateDueBack);
        order.dateStarted = LIUtils.dateFromJsonUtcDateStr(order.dateStarted);
        order.datePickCompleted = LIUtils.dateFromJsonUtcDateStr(order.datePickCompleted);
        order.dateSent = LIUtils.dateFromJsonUtcDateStr(order.dateSent);
    }
}

