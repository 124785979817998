import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function serverInfoReducer(state = initialState.serverInfo, action) {

    /**
     initialized: <bool>,
     name: <str>,
     version: <str>
     */
    
    switch (action.type) {
        case(types.GOT_SERVER_INFO): { 
            return {
                initialized: true,
                name: action.name,
                version: action.version,                        // webservice version                      
                requiredDbVersion: action.requiredDbVersion,    // added by pott - 231117
                dbVersion: action.dbVersion,                    // added by pott - 231117
                demoMode: action.demoMode,                      // added by pott - 220905
                localTimeUtc: action.localTimeUtc,              // added by pott - 221117
            };
        }
        
        default:
            return(state)
    }
}

