import React, {useContext, useEffect, useState} from 'react';
import {Prompt, Redirect, useParams} from "react-router-dom";
import Header from "../Header";
import BackButton from "../../GUI_COMMON/COMPONENTS/BackButton";
import TextInput from "../../GUI_COMMON/COMPONENTS/TextInput";
import LIButton from "../../GUI_COMMON/COMPONENTS/LIButton";
import Popup from "../../GUI_COMMON/COMPONENTS/Popup";
import ArchiverClientApi from "../../API/archiverClientApi";
import ErrorPopup from "../../GUI_COMMON/COMPONENTS/ErrorPopup";
import TextArea from "../../GUI_COMMON/COMPONENTS/TextArea";
import ArchiverGuiUtils from "../../SUPPORT/ArchiverGuiUtils";
import CheckInput from "../CheckInput";
import {gotLocations} from "../../ACTIONS/clientActions";
import {StoreContext} from "../../store";

const DepartmentsAdminPage = () => {

    const [,dispatch] = useContext(StoreContext);
    const {locationId} = useParams();
    
    const [curDeptDetails, setCurDeptDetails] = useState(getDefaultDepartmentDetails());
    const [parentLocationDetails, setParentLocationDetails] = useState({});
    const [fullDeptList, setFullDeptList] = useState([]);
    
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const [redirectTo, setRedirectTo] = useState();
    
    useEffect(() => {
        console.debug("refreshing depts list");
        getDeptList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    function getDefaultDepartmentDetails():{} {
        return(
            {
                id: null,
                name: "",
                notes: "",
                parentLocationId: locationId?Number(locationId):null,
                isEnabled: true,
            }
        );
    }
    
    function getDeptList() {
        setIsLoading(true);
        ArchiverClientApi.getLocationGivenId(locationId, true) 
        .then( (locationDetails) => {
            setParentLocationDetails({id:locationDetails.id,name:locationDetails.name});
            setFullDeptList(locationDetails.departments??[]);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsLoading(false);
        });
    }
    
    function handleCancelClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        confirmClearDirty();
    }
    
    function confirmClearDirty() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetDeptDetailsShown();
                });
        }
        else {
            resetDeptDetailsShown(); // just clear what's there
        }
    }
    
    function handleDeleteDepartmentClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        Popup.showConfirm("Delete Department?",`<p>This will remove '${curDeptDetails.name}' completely from the location '${parentLocationDetails.name}'.</p>`+
                                "<p>Any users in that department will remain in the system "+
                                " but will not be assignable in Retrieval Orders.</p>"+
                                "<p>Are you sure you want to do this?</p>","Yes, Delete it",null, 
            () => {
               doDeptRemove(curDeptDetails.name,curDeptDetails.id);
            });
    }
   
    function doDeptRemove(deptName, deptId) {
        setIsRemoving(true);
        ArchiverClientApi.sendDepartmentRemove(deptId)
        .then(() => {
            Popup.showInfo(`Department '${deptName}' removed from location '${parentLocationDetails.name}'`);
            removeFromDeptList(deptId);
            resetDeptDetailsShown();
        })
        .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally(() => {
            setIsRemoving(false);
        });
    }
    
    function handleSaveClicked(e,action) {
        e.preventDefault();
        e.stopPropagation();
        
        if(!checkForValid()) {
            return;
        }
       
        setIsSaving(true);
        ArchiverClientApi.sendDepartmentUpdate(curDeptDetails)
        .then( (savedDeptDetails) => {
            Popup.showInfo(`Location '${curDeptDetails.name}' Saved`);
            setIsDirty(false);
            if(action==="ADD") {
                addToFullDeptList(savedDeptDetails);
                resetDeptDetailsShown();
            }
            else if(action==="UPDATE") {
                updateExistingDept(savedDeptDetails);
            }
            // clear out the stored locations so ROs and stuff get the new list when they are re-visited
            dispatch(gotLocations([]));
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
           setIsSaving(false); 
        });
    }
    
    function checkForValid():boolean {
        let isValid=true;
        let notValidMsg="";
        if(!document.getElementsByName("name")[0].checkValidity()) {
            isValid=false;
            notValidMsg="Name needs to be non-blank";
        }
       
        if(!isValid) {
            ErrorPopup.showError("Validation Problem",notValidMsg);
            return false;
        }
        return true;

    }
    
    function addToFullDeptList(deptDetails) {
        const curDeptList = [...fullDeptList];
        curDeptList.push(deptDetails);
        curDeptList.sort( (a,b) => {
            if(a.fullName < b.fullName) return -1;
            if(a.fullName > b.fullName) return 1;
            return 0;
        });
        setFullDeptList(curDeptList);
    }

    function removeFromDeptList(userId) {
        const curList = fullDeptList.filter( item => item.id !== userId);
        setFullDeptList(curList);
    }
    
    function updateExistingDept(deptDetails) {
        const curList = [...fullDeptList];
        let thisUserIndex = curList.findIndex(item => item.id===deptDetails.id);
        curList[thisUserIndex]=deptDetails;
        setFullDeptList(curList);
    }
    
    function onChange(e) {
        const thing = e.target.name;
        const editInfo = {...curDeptDetails};
        if(e.target.type==="checkbox") { // this is a checkbox
            editInfo[thing] = e.target.checked;
        }
        else {
            let value = e.target.value;
            if(thing==="dueBackDays") {  // number
                value = Number(value);
            }
            editInfo[thing] = value;
        }
        setCurDeptDetails(editInfo);
        setIsDirty(true);
    }
    
    function showDeptsList(listContents) {

        if(isLoading) {
            return <div className="pr-4 w-30 li-border-right-light">...loading...</div>;
        }
        
        let resultList = listContents?.map((item,index) => {
                return <li key={index} className={"list-group-item li-pointer "+(item.isEnabled===false?"li-fg-muted":"")}
                    onClick={() => handleListItemClicked(item)}>{item.name}</li>;
            }
        );
        if(!resultList || resultList.length===0) {
            resultList = "no departments found";
        }
        let locationExtra=null;
        if(parentLocationDetails && parentLocationDetails.id) {
            locationExtra = "in "+parentLocationDetails.name;
        }

        return (
            <div className="pr-4 w-30 li-border-right-light">
                <h4>Departments {locationExtra}</h4>
                <div className="li-scrollable-at-500 ">
                    <ul className="list-group-flush li-list-group-w-hover pl-0">
                        {resultList}
                    </ul>
                    <div className="li-border-line-light"/>
                </div>
                <LIButton small primary label="" bgClass={isRemoving?"m-3":"fa fa-trash m-3"} disabled={curDeptDetails?.id===null}
                          onClick={handleDeleteDepartmentClicked} showBusy={isRemoving}/>
            </div>
        );
    }
    
    function handleListItemClicked(item) {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetDeptDetailsShown();
                    doRealListItemClicked(item);
                });
        }
        else {
            doRealListItemClicked(item);
        }
    }
    
    function doRealListItemClicked(item) {
        const dept = fullDeptList.filter(i => i===item);
        if(dept.length===0) {
            resetDeptDetailsShown();
        }
        else {
            setCurDeptDetails(dept[0]);
        }
    }
    
    function resetDeptDetailsShown() {
        setCurDeptDetails(getDefaultDepartmentDetails());
        setIsDirty(false);
    }
    
    function handleBackButtonClicked() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    setRedirectTo(`/admin/locations/${locationId}`);
                });
        }
        else {
            setRedirectTo(`/admin/locations/${locationId}`);
        }
    }
    
    if (redirectTo) {
        return <Redirect to={redirectTo}/>;
    }
    
    return (
        <div className="container">
            <Prompt
                when={isDirty}
                message={ () => "You have unsaved changes.  Really leave?"}
            />
            <Header hideAdmin/>
            <div className="li-header-row">
                <BackButton onClick={handleBackButtonClicked}/>
                <h1 className="ml-4 d-inline-block align-middle">Department Administration</h1>
            </div>
            
            <div className="row mt-4">
                {/* LEFT SIDE LIST */}
                {showDeptsList(fullDeptList)}

                {/* RIGHT SIDE FORM */}
                <div className="pl-4 w-60">
                    <div className="li-fg-slightly-muted li-font-small text-right">[Id:{curDeptDetails?.id??"-"}]</div>
                    <div className="text-right">
                        {/* right side, row #0 */}
                        <CheckInput label="Enabled" name="isEnabled" checked={curDeptDetails.isEnabled} onChanged={(e)=>onChange(e)}/>
                    </div>
                    <div className="row p-0 m-0 justify-content-between">
                        {/* right side, row #1 */}
                        <TextInput name="name" label="Name" onChange={(e)=>onChange(e)}
                                   placeholder="" textLeft
                                   value={curDeptDetails?.name??""}
                                   required/>
                    </div>
                    { /* right side, row #2 */}
                    <TextArea name="notes" label="Notes" value={curDeptDetails?.notes??""} readonly={false} 
                              onChange={(e)=>onChange(e)} rows={3}/>
                    
                    {/* BUTTON ROW */}
                    <div className="text-center my-5">
                        <LIButton name="cancel" label={isDirty?"Cancel":"Clear"} bgClass="mr-4" primary onClick={handleCancelClicked}/>
                        <LIButton name="save" label={curDeptDetails?.id===null?"ADD":"UPDATE"} secondary disabled={!isDirty || isSaving} 
                                  onClick={(e)=>handleSaveClicked(e,curDeptDetails?.id===null?"ADD":"UPDATE")} showBusy={isSaving}/>
                    </div>
                </div>
            </div>

            
        </div>
    );
}

DepartmentsAdminPage.propTypes = {
   
};

export default DepartmentsAdminPage;