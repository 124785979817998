import React, {useContext, useEffect, useState} from 'react';
import {Redirect} from 'react-router';

import ArchiverClientApi from "../API/archiverClientApi";
import {useCookies} from "react-cookie";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import Footer from "./Footer";

import microscopeImage from '../GUI_COMMON/IMAGES/microscope-trans-gray2-192.png'
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import {StoreContext} from "../store";
import {setClientInfo} from "../ACTIONS/clientActions";
import LoginFormWithKeyboard from "../GUI_COMMON/COMPONENTS/LoginFormWithKeyboard";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import {checkAndReportWwwAppUpdate} from "../SUPPORT/update";

const LoginPage = () => {

    const [cookies, setCookies,] = useCookies();
    const [, dispatch] = useContext(StoreContext);
    const [redirectToDashboard, setRedirectToDashboard] = useState();
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        
        console.debug("loginPage(): starting up...");
        if(LIUtils.isAuthenticatedHook(cookies)) {
            return <Redirect to="/dashboard"/>
        }

        checkAndReportWwwAppUpdate(false);
        
        
    }, []);   // eslint-disable-line react-hooks/exhaustive-deps
 
    function onSubmit(authCode) {
        const form = document.getElementById("loginForm");
        if (form.checkValidity() === false) {
            return;
        }

        // STEP 1 - login to the WebService 
        setIsLoading(true);
        ArchiverClientApi.sendSignInRequest(authCode)
        .then((authInfo) => {
            if (authInfo.isAuthenticated === false) {
                setIsLoading(false);
                ErrorPopup.showError("SignIn Problem", "Not authenticated");
                return;
            }

            try {
                LIUtils.saveAuthInfoToCookieHook(authInfo,setCookies);
                dispatch(setClientInfo(authInfo.userId, authInfo.fullName, authInfo.level));
                setRedirectToDashboard(true);
                setIsLoading(false);
            } catch (ex) {
                setIsLoading(false);
                throw ex;      // rethrow
            }
        })
        .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsLoading(false);
        });
    }
    
    if(redirectToDashboard) {
        return <Redirect to="/dashboard"/>;
    }
    
    LIUtils.setBackgroundColor('--li-login-bg-color');
    
    return (
        <div className="container">
            <div className="li-centered-all">
                <div className="li-bg-card li-card li-header-row">
                    <div className="mb-1 text-center li-page-header-bg">
                        <img className="mb-3 mt-2 mr-3" src={microscopeImage} width="70px" alt="microscope"/>
                        <span className="li-font-small align-top position-absolute mt-2">&nbsp;Slide System</span>
                        <span className="li-font-huge align-middle font-weight-bold">Archiver</span>
                    </div>
                    <LoginFormWithKeyboard onSubmit={onSubmit} isBusy={isLoading}/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

LoginPage.propTypes = {};

LoginPage.defaultProps = {};

export default LoginPage;