import ArchiverClientApi from "../API/archiverClientApi";
import {gotProfilesList} from "../ACTIONS/clientActions";
import ArchiverGuiUtils from "./ArchiverGuiUtils";

/**
 * get all profiles from the server and setup the global store with them.
 */
export function setupProfiles(dispatch) {
    ArchiverClientApi.getProfiles().then(({profiles}) => {
        dispatch(gotProfilesList(profiles)); // []
    })
    .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError));
}