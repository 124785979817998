
import * as types from "../ACTIONS/actionTypes";
import initialState from "./initialState";

import ajaxStatusReducer from "./ajaxStatusReducer";
import adminReducer from "./adminReducer";
import clientReducer from "./clientReducer";
import caseReducer from "./caseReducer";
import sampleReducer from "./sampleReducer";
import statsReducer from "./statsReducer";
import profilesReducer from "./profilesReducer";
import retrievalReducer from "./retrievalReducer";
import pickSheetReducer from "./pickSheetReducer";
import locationReducer from "./locationReducer";
import contactReducer from "./contactReducer";
import chaseListReducer from "./chaseListReducer";
import watchListReducer from "./watchListReducer.cs";
import serverInfoReducer from "./serverInfoReducer";
import notificationReducer from "./notificationReducer";
import searchReducer from "./searchReducer";

// this combines the state from each of the reducers into our initial state
// NOTE: we remap the reducers to their better known names in in the larger
// state object here.
function appReducer(state = initialState, action) {
    return {
        ajaxCallsInProgress: ajaxStatusReducer(state.ajaxCallsInProgress,action),
        adminInfo: adminReducer(state.adminInfo, action),
        clientInfo: clientReducer(state.clientInfo, action),
        case: caseReducer(state.case, action),
        sample: sampleReducer(state.sample, action),
        stats: statsReducer(state.stats, action),
        profiles: profilesReducer(state.profiles, action),
        retrievals: retrievalReducer(state.retrievals,action),
        picksheet: pickSheetReducer(state.picksheet,action),
        locations: locationReducer(state.locations,action),
        contacts: contactReducer(state.contacts, action),
        chaseList: chaseListReducer(state.chaseList, action),
        watchList: watchListReducer(state.watchList, action),
        serverInfo: serverInfoReducer(state.serverInfo, action),
        notifications: notificationReducer(state.notifications, action),
        searchResults: searchReducer(state.searchResults, action),
    };
}

const rootReducer = (state, action) => {
    switch(action.type) {

        case types.CLEAR_STATE_ON_LOGOUT: {
            return (initialState); // null out the store on logout.
        }
        default:
            return appReducer(state, action); // on to the rest since we didn't match a logout action
    }
};

export default rootReducer;