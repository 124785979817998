import React, {useEffect, useState} from "react";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import PropTypes from "prop-types";
import GuiUtils from "../GUI_COMMON/SUPPORT/GuiUtils";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const SamplesList = ({name, samples, sampleType, onSampleClicked, onRepairClicked, onSampleCheckChanged, onOrderClicked, 
                         showCheckBox, showDelete, onDeleteClicked, readOnly, allowCheckIfOut, isLoading, thisOrderId, showImages}) => {

    const [filteredSamples, setFilteredSamples] = useState([]);

    useEffect(() => {
        const sList = samples?.filter(item => item.sampleType === sampleType)??[]; 
        setFilteredSamples(sList);
    }, [samples]); // eslint-disable-line react-hooks/exhaustive-deps
    
    function markManuallyAddedSample(sampleItem) {
        return ArchiverGuiUtils.ShowSampleIsManuallyEdited(sampleItem);
    }

    function sampleIsOut(item):boolean {
        return(!item.magBarcode || item.magBarcode === "");
    }
    
    function dumpRepairToSelect(itemId) {
        
        if(readOnly) { // no click to repair
            return (
                <span className="align-self-baseline">
                    <div className="mx-2 badge li-fg-white li-bg-danger">attention</div>
                </span>
            );
        }
        
        return (
            <span className="align-self-baseline">
                <div className="mx-2 badge li-fg-white li-bg-danger li-pointer"
                     onClick={() => onRepairClicked(itemId)}>
                        attention
                        <i className="fa fa-cog ml-2"/>
                </div>
                <i className="fa fa-arrow-left mr-2"/>
                <span className="li-font-small"> repair to select</span>
            </span>
        );
    }
    
    function handleDeleteClicked(item) {
        if(onDeleteClicked) {
            onDeleteClicked(item.id);
        }
    }
    
    function handleMouseOverImage(e,sampleItem, sampleImageItemId) {
        console.debug(`over image at ${e.clientX},${e.clientY}`);
        const magnifier = document.getElementById("imageMagnifier");
        magnifier.style.top=(e.pageY-100)+"px";
        magnifier.style.left=(e.pageX+50)+"px";

        const magnifierImg = document.getElementById("magnifierImg");
        if(sampleItem.imageData) {
            magnifierImg.src="data:image/png;base64,"+sampleItem.imageData;
        }
        else {
            magnifierImg.src="";  // bug fix - 231129 -pott
        }
        
        const magnifierBarcode = document.getElementById("magnifierBarcode");
        magnifierBarcode.innerText=LIUtils.SanitizeBarcodeToLength(sampleItem.barcode);
        
        magnifier?.classList.remove("hide");
        magnifier?.classList.add("show");
        
        const imageDiv = document.getElementById(sampleImageItemId);
        if(imageDiv) {
            imageDiv.style.borderStyle="inset";
            imageDiv.style.borderWidth="5px";
            imageDiv.style.borderColor="var(--li-main-color)";
        }
    }
    
    function handleMouseOverImageOut(sampleImageItemId) {
        console.debug("over image out");
        const magnifier = document.getElementById("imageMagnifier")
        magnifier?.classList.remove("show");
        magnifier?.classList.add("hide");
        const magnifierImg = document.getElementById("magnifierImg");
        magnifierImg.src="";  // bug fix - 231129 -pott

        const imageDiv = document.getElementById(sampleImageItemId);
        if(imageDiv) {
            imageDiv.style.borderStyle="none";
        }
    }
    
    function dumpImageMagnifier() {
        
        return(
            <div id="imageMagnifier" className="li-magnifier">
                <div id="magnifierBarcode" className="text-center w-100"/>
                <img id="magnifierImg" alt="missing sample pic"/>
            </div>
        );
    }
    
    function dumpSampleRow(item) {
        const sampleStr = GuiUtils.BuildSampleDetailsStr(item.barcode, item.caseNumber,item.sampleNumber);
        let needsAttention=!LIUtils.SampleHasCriticalInfo(item.caseNumber,item.sampleNumber,item.profileId);

        const sampleImageItemId = "sampleImage" + item.id.toString();
        
        return (
            <div key={item.id} className="row m-0 p-0 px-3">
                {showCheckBox &&
                <input type="checkbox" className="m-2 mr-3" name={name} data-parent-id={item.id} aria-label="Checkbox"
                       disabled={readOnly || (!allowCheckIfOut && sampleIsOut(item)) || needsAttention}
                       onChange={(e) => onSampleCheckChanged(item,e.target.checked)}/>
                }
                <div className="align-self-center text-nowrap">
                    {showDelete && !readOnly &&
                        <span className="m-2 mr-3 li-pointer" data-toggle="tooltip" title="Delete this sample"
                              onClick={() => handleDeleteClicked(item)}>
                                <i className="fa fa-lg fa-times-circle li-fg-danger"/></span>
                    }
                    <div className="d-inline-block">
                        <div className="p-0 small mr-2 d-inline-block" 
                             onMouseOver={(e)=>handleMouseOverImage(e,item, sampleImageItemId)}
                                onMouseOut={() => handleMouseOverImageOut(sampleImageItemId)}>
                            {LIUtils.getImgFromImageData(item.imageData,null,50,sampleImageItemId)}
                        </div>
                        <span className="li-pointer btn-link" onClick={() => onSampleClicked(item.id)}>{LIUtils.SanitizeBarcodeToLength(sampleStr)}</span>
                    </div>
                    {ArchiverGuiUtils.ShowSampleIsInOutOrPendingOrPicked(item,onOrderClicked,true,thisOrderId)}
                    {markManuallyAddedSample(item)}
                    {needsAttention && dumpRepairToSelect(item.id)}
                </div>
            </div>
        );
    }

    if (isLoading) {
        return <LoadingSpinner medium center/>;
    }
    if (!filteredSamples || filteredSamples.length === 0) {
        let sampleTypeStr="slides";
        if(sampleType===2) {
            sampleTypeStr="blocks";
        }
        return <div className="ml-5">no {sampleTypeStr} found</div>;
    }
    
    return (
        <div className="row row-cols-lg-2 m-0 p-0">
            {filteredSamples.map((item) => dumpSampleRow(item))}
            {dumpImageMagnifier()}
        </div>
    );
}

SamplesList.defaultProps = {
    thisOrderId: undefined
}

SamplesList.propTypes = {
    name: PropTypes.string.isRequired,
    onSampleClicked: PropTypes.func,
    onSampleCheckChanged: PropTypes.func,
    onOrderClicked: PropTypes.func,
    samples: PropTypes.array,
    sampleType: PropTypes.number.isRequired,
    showCheckBox: PropTypes.bool,
    isLoading: PropTypes.bool,
    showDelete: PropTypes.bool,
    onDeleteClicked: PropTypes.func,
    readOnly: PropTypes.bool,
    allowCheckIfOut: PropTypes.bool,
    onRepairClicked: PropTypes.func,
    thisOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showImages: PropTypes.bool,
}

export default SamplesList;