import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import Header from "../Header";
import BackButton from "../../GUI_COMMON/COMPONENTS/BackButton";
import TextInput from "../../GUI_COMMON/COMPONENTS/TextInput";
import LIButton from "../../GUI_COMMON/COMPONENTS/LIButton";
import Popup from "../../GUI_COMMON/COMPONENTS/Popup";
import ArchiverClientApi from "../../API/archiverClientApi";
import LoadingSpinner from "../../GUI_COMMON/COMPONENTS/LoadingSpinner";
import ArchiverGuiUtils from "../../SUPPORT/ArchiverGuiUtils";

const MagRegisterAdminPage = () => {

    const [fullDeviceList, setFullDeviceList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDeviceId, setSelectedDeviceId] = useState(-1);
    const [magBarcode, setMagBarcode] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const history = useHistory();
    
    useEffect(() => {
        console.debug("refreshing device list");
        doGetDeviceList();
    }, []);
    
    function doGetDeviceList() {
        setIsLoading(true);
        ArchiverClientApi.getDevices() 
        .then( (devices) => {
            const magDevices = devices.filter(item => item.deviceType===1 || item.deviceType===2 || item.deviceType===3);
            setFullDeviceList(magDevices);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsLoading(false);
        });
    }

    function dumpDeviceList() {
        if(isLoading) {
            return <LoadingSpinner medium/>;
        }
        return(
            <>
                <label htmlFor="deviceId" className="li-form-label mr-3">The device to register against</label>
                <select onChange={onChange} id="deviceId" name="deviceId" value={selectedDeviceId??-1}
                        className="li-form-field no-width align-self-end li-border-dark" required={true}>
                    <option value={-1}>(select a device)</option>
                    <option value={0}>No Device (register only)</option>
                    {dumpDeviceOptionsList()}
                </select>
                {/*<small className="form-text text-muted">* determine</small>*/}
            </>
        );
    }

    function dumpDeviceOptionsList() {
        return fullDeviceList.map(item => <option key={item.id} value={item.id}>{item.fullName}</option>);
    }

    function onChange(e) {
        const thing = e.target.name;

        let value = e.target.value;
        if (thing === "deviceId") {  // number
            setSelectedDeviceId(Number(value));
        }
        else if(thing==="magBarcode") {
            setMagBarcode(value);
        }
    }
    
    function isValid() {
        if(!magBarcodeLooksGood(magBarcode)) {
            return false;
        }
        return selectedDeviceId !== -1; 
    }
    
    function magBarcodeLooksGood(magBC) {
        if(!magBC || magBC==="") {
            return false;
        }
        const re = new RegExp('^[0-9][0-9][a-zA-Z]*.[0-9]{4}.[0-9]$');
        if(!re.test(magBC)) { // not complete
            console.log("bad mag barcode "+magBC);
            return false;
        }
        return true; // good
    }

    function handleRegisterClicked(e) {
        e.preventDefault();
        e.stopPropagation();

        setIsSaving(true);
        ArchiverClientApi.sendRegisterMagToDevice(magBarcode, selectedDeviceId)
        .then((magInfo) => {
            Popup.showInfo(`Mag '${magBarcode}' registered to '${magInfo.parentDeviceName??"no specific device"}'.`);
        })
        .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally(() => {
            setIsSaving(false);
        });
    }
    
    function handleBackClicked() {
        history.goBack();
    }
    
    return (
        <div className="container">
            <Header hideAdmin/>
            <div className="li-header-row">
                <BackButton onClick={handleBackClicked}/>
                <h1 className="ml-4 d-inline-block align-middle">Mag Register</h1>
            </div>
            <p>Use this page to register new magazines into the system.  You don't ordinarily need to do this yourself -- when you print labels, the system automatically registers
                the magazine to the device based on the box number that is currently being worked on.  </p>
            <p>If you find that a magazine has been skipped or is otherwise missing, you can use this page to force a magazine to be registered to a particular machine, so that
                labels can then be printed for it.</p>
            <ul>
                <li>It is likely that any newly registered magazine will become the "NEXT" magazine required for insertion into the parent device.  If you don't want this, choose the 'No Device' option
                    from the list below.</li>
                <li><strong className="li-fg-danger">NOTE: do NOT register magazines that are for the future!</strong>  Only magazines that have been skipped or are for PAST years should be registered
                using this page.</li>
            </ul>
            <div className="row justify-content-center">
                <TextInput onChange={onChange} label="Magazine Barcode" name="magBarcode" widthPels={400} oneRow placeholder="##<neumonic>.####.#"
                helpText="* this may be a new magazine or one that is already registered to another device"/>
            </div>
            <div className="row justify-content-center mt-3">
                {dumpDeviceList()}
            </div>
            
            <div className="text-center mt-5">
            <LIButton bgClass="" primary onClick={handleRegisterClicked} label="Register" showBusy={isSaving} disabled={!isValid()}/>
            </div>
        </div>
    );
}

MagRegisterAdminPage.propTypes = {
   
};

export default MagRegisterAdminPage;