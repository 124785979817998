import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function locationReducer(state = initialState.locations, action) {

    /**
     locations: [
            
     ]
     */
    
    switch (action.type) {
        case types.GOT_LOCATIONS_LIST:
            const newState = action.locations;
            return(newState);
        default:
            return(state)
    }
}

