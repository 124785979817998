import React from 'react';
import * as PropTypes from 'prop-types';

const ButtonWithBusy = ({id, isBusy, label, busyLabel, labelAwesome, isDisabled, addOnStyling, onClick}) => {
    
    let classInfo = "li-button ";
    if(addOnStyling && addOnStyling!=='') {
        classInfo+= " "+addOnStyling;
    }
    else {
        classInfo+= " li-primary";
    }
    
    function showLabelOrAwesome() {
        if(label!=='') {
            return(label);
        }
        if(labelAwesome!=='') {
            const classInfo = `fa ${labelAwesome}`;
            return(<i className={classInfo}/>);
        }
        
        // default
        return('no label');
    }
    
    function showBusyLabelAndSpinner() {
        if(busyLabel && busyLabel!=='') {
            return <span>{busyLabel}&nbsp;<i className="fa fa-spinner fa-pulse"/></span>;
        }
        return <i className="fa fa-spinner fa-pulse"/>;
    }
    
    return (
        <>
            <button type="button" id={id} className={classInfo} disabled={isDisabled} onClick={onClick}>
                { !isBusy && showLabelOrAwesome()}
                { isBusy && showBusyLabelAndSpinner()}
            </button>
        </>
    );
};

ButtonWithBusy.defaultProps = {
    label: '',
    labelAwesome: '',  
    isBusy: false,
};

ButtonWithBusy.propTypes = {
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isBusy: PropTypes.bool,
    label: PropTypes.string,
    busyLabel: PropTypes.string,
    labelAwesome: PropTypes.string,
    isDisabled: PropTypes.bool,
    addOnStyling: PropTypes.string,
};

export default ButtonWithBusy;