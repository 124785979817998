import React, {useContext, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import ArchiverClientApi from '../API/archiverClientApi';
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import {useCookies} from "react-cookie";
import {clearStoreOnLogout} from "../ACTIONS/clientActions";
import {StoreContext} from "../store";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import LIStorage from "../SUPPORT/LIStorage";

const SignoutPage = () => {

    const [signOutComplete, setSignoutComplete] = useState(false);
    
    useEffect(() => {
        doSignOut();  
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    const [,dispatch] = useContext(StoreContext);
    //const location = useLocation();
    const [cookies,,removeCookie] = useCookies();

    //const params = new URLSearchParams(location.search);
    //const expired = params.get('expired');
    
    function doSignOut(fromError:boolean=false) {
        
        if(!fromError) {
            ErrorPopup.hide(); // we can hide the error popup
        }
        Popup.hide();
        
        ArchiverClientApi.sendSignOutRequest()
        .then(() => {
            console.log("signed out at server...");
        })
        .catch((error) => { // ignore and signout anyway
            console.error(`ERROR: failed to signout (${error})`);
            
        })
        .finally( () => {
            completeSignoutCleanup();
        });
    }

    function completeSignoutCleanup() {
        
        dispatch(clearStoreOnLogout());
        
        LIStorage.clearAll();
        LIUtils.doCookieSignoutHook(cookies,removeCookie);
        setSignoutComplete(true);
    }

    if (signOutComplete) {
        return (
            <Redirect to="/signin"/>
        );
    }
    else return null;
};

SignoutPage.propTypes = { };

export default SignoutPage;
