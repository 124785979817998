import React from "react";
import * as PropTypes from "prop-types";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";

const MagCard = ({info, onClick}) => {
    
    function handleOnClick() {
        if(onClick) {
            onClick();
        }
    }
    
    function dumpSection(section:Object) {
        if (section) {
            return <span className="li-font-small">{section.numSamples} {section.isFull ? "(marked full)" : ""}</span>;
        }
        return null;
    }
    
    function getLastModifiedDate() {
        if(info?.dateModified===null) {
            return "never";
        }
        
        return LIUtils.shortDateTimeFromJsonUtcDateStr(info.dateModified);
    }
    
    function dumpSampleType(sType) {
        
        let sTypeStr="?";
        let extraClass = "badge-muted";
        if(isSlideType(sType)) {
            sTypeStr="S";
            extraClass = "badge-slide";
        }
        if(isBlockType(sType)) {
            sTypeStr="B";
            extraClass = "badge-block";
        }
        
        return (
            <span className={"badge li-font-large font-weight-bold p-1 px-2 rounded mr-2 "+extraClass}>
                {sTypeStr}
            </span>
        );
    }
    
    function isSlideType(sType) {
        return sType && sType===1;
    }
    function isBlockType(sType) {
        return sType && sType===2;
    }
    
    function dumpLast2Sections() {
        return (
            <>
                <div><strong>Section C: </strong>{dumpSection(info.sectionC)}</div>
                <div><strong>Section D: </strong>{dumpSection(info.sectionD)}</div>
            </>);
    }
    
    if(info===null) { // spacer / empty mag card holder
        return <div className="card li-sample-card clear"/>;
    }
    
    return(
        <div className="card li-sample-card" onClick={handleOnClick}>
            <div className="card-body">
                <div className="row justify-content-between p-0 m-0">
                    <div>
                        <div className="mb-1">
                            {dumpSampleType(info.holdsSampleType)}
                            <span className="card-title li-font-large font-weight-bold">{info.barcode}</span>
                        </div>
                        <div className="li-font-small"><strong>Device:</strong> {info.parentDeviceName??"-"}</div>
                        <div className="mb-3 li-font-tiny li-fg-slightly-muted"><strong>Profile:</strong> {info.parentProfileId}</div>
                        {info.isCurrent===true && <span className="li-font-small badge badge-success">Is Current</span>}
                        {info.isNext===true && <span className="li-font-small badge badge-warning">Is Next</span>}
                    </div>
                    <div className="text-right">
                        <div>
                            <div><strong>Section A: </strong>{dumpSection(info.sectionA)}</div>
                            <div><strong>Section B: </strong>{dumpSection(info.sectionB)}</div>
                            {isSlideType(info.holdsSampleType) && dumpLast2Sections()}
                        </div>
                        <div className="li-font-tiny mt-3 li-bottom-right"><strong>Last Used:</strong> {getLastModifiedDate()}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

MagCard.propTypes =  {
    info: PropTypes.object,
    onClick: PropTypes.func,
}

export default MagCard;