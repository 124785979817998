// this is a long running webworker "main"
// for handling the interaction with the webservice when refreshing 
// a potentially large number of NA samples
//
// Written By: Peter Ott
// Date Started: 231023

// This hack allows bundling of the refreshAllNa WebWorker JS file into the 
// main app bundle (more secure and doesn't now need to be served from the main public '/' directory
// so it's worth the pain here.
//
// This technique borrowed from:
// https://www.ifourtechnolab.com/blog/how-to-use-web-workers-in-reactjs-a-step-by-step-guide
//
// 231024 - pott
//
const refreshNAWorker = () => {
    let gToken = undefined;
    let gBaseUrl = undefined;

    onmessage = function (e) {
        console.log(`Worker: got a message from master ${JSON.stringify(e.data)}...`);

        if (e.data["token"]) {
            gToken = e.data["token"];
            console.debug(`token set to ${gToken}`);
        }
        if (e.data["baseUrl"]) {
            gBaseUrl = e.data["baseUrl"];
            console.debug(`baseUrl set to ${gBaseUrl}`);
        }
        if (e.data["action"] === "start") {
            console.debug("firing up");
            fireUp()
        }
    }

    function fireUp() {

        const bearerToken = gToken;
        const webSocket = new WebSocket(gBaseUrl + "/ws-api/sample/refresh_na/all"); // ** NOTE: the ws-api here ... websocket redirection for nginx

        webSocket.onopen = (event) => {
            const msg = {
                action: "start", token: bearerToken
            }

            // send auth header
            webSocket.send(JSON.stringify(msg));
        }

        webSocket.onmessage = (event) => {
            const msg = JSON.parse(event.data);

            console.debug("WS Message: " + msg.action);

            if (msg.action === "starting") {
                console.debug("START noted");
            } else if (msg.action === "progress") {
                var updateResult = {
                    isProgress: true, sampleId: msg.sampleId, percent: msg.percent
                }
                postMessage(updateResult);
            } else if (msg.action === "done") {
                var finalResult = {
                    isError: false, isDone: true, numProcessed: msg.processed, numRemoved: msg.removed
                }
                console.debug(`DONE -- processed: ${finalResult.numProcessed}, removed: ${finalResult.numRemoved}`);
                webSocket.close();

                postMessage(finalResult); // json
            } else if (msg.action === "error") {
                var errorResult = {
                    isError: true, errorReason: msg.errorReason
                }
                console.debug(`ERROR -- reason: ${errorResult.errorReason}`);
                webSocket.close();

                postMessage(errorResult); // json
            } else {
                console.debug(`unknown message action '${msg.action}'`);
            }
        }

        webSocket.onerror = (event) => {

            console.debug("WS Error: " + JSON.stringify(event));
            const errorResult = {
                isError: true, errorReason: "websocket error (see console)"
            }
            console.debug(`ERROR -- reason: ${errorResult.errorReason}`);
            webSocket.close();

            postMessage(errorResult); // json
        }
    }
}

let code = refreshNAWorker.toString()
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"))
const blob = new Blob([code], { type: 'application/javascript' }) 
const workerScript = URL.createObjectURL(blob)
module.exports = workerScript;

