let liToken = ""; // backing field for the liToken Property

class CommonClientApi {
    
    //#region AUTH
   
    static get liToken() {
        return liToken;
    }

    static set liToken(value) {
        console.log("liToken (real) set to " + value);
        liToken = value;
    }

    /** put the header into the response,
     *  liToken must already have been setup somewhere from a previous auth request */
    static buildHeader() {
        return "Authorise: bearer " + CommonClientApi.liToken;
    }

    /** setup the header fields for communications with the webservice.
     *  The cookies are used as the source of truth during a refresh.
     * @param liToken
     */
    static setupHeader(liToken) {
        CommonClientApi.liToken = liToken;
    }

    /* send login request to the server */
    static sendSignInRequest(code) {

        // Description: request a login token from the server
        //
        // POST /api/auth/token
        //
        // Body: {
        //    code:  <code>
        // }
        //
        // Returns: {
        //      "tokenInfo": {
        //          token: sdlkfjsdlfkjsdEREsdfsdDfsdflkjl23kjlkjsdflksjdflksjdflksjdflskdjfsldkjflskdjf
        //          expiration: 1553710066  (unix seconds)
        //      },
        //     userId: <long or 0>
        //     level: 'PICKER,SEARCHER,etc'
        //     fullName: <string>
        // }
        //

        console.log(
            `sendSigninRequest(): sending request using code '${code}'...`
        );

        return new Promise((resolve, reject) => {
            const body = {
                code: code
            };
            this.sendRequestAsJson("/api/auth/token", "POST", body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
               
                const tokenInfo = Object.assign({}, response.tokenInfo);
                let isAuthenticated = false;
                if (tokenInfo.token !== "") {
                    // we got a token back -- we are logged in
                    isAuthenticated = true;
                }
                resolve({isAuthenticated, tokenInfo, userId: response.userId, fullName: response.fullName, level: response.level});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    /* send signout request to the server */
    static sendSignOutRequest() {
        // Description: sign this user out of the system
        //
        // GET /api/auth/signout
        //
        // Body: { }
        //
        // Response:
        // {
        //    msg: user 'xxx' is signed out,
        //    isAuthenticated: false
        // }
        //

        console.log("sendSignoutRequest(): sending signoutRequest");

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson("/api/auth/signout", "GET")
            .then((response) => {
                CommonClientApi.liToken = undefined;
                resolve(response);
            })
            .catch((e) => {
                reject("could not log you out [" + e + "]")
            });
        });
    }
    
    //#endregion AUTH

    //#region INFO

    static getServerInfo() {
        // Description: request some basic info back from the webservice 
        //
        // GET /api/info
        //
        // Body: {}
        //
        // Returns: {
        //     version: <str>,
        //     name: <str>,
        //     demoMode: <bool>,
        //     dbVersion: <str>,
        //     requiredDbVersion: <str>
        // }
        //

        console.log(
            `getServerInfo(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson("/api/info", "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({name:response.name,version:response.version,demoMode:response.demoMode, 
                    dbVersion:response.dbVersion, requiredDbVersion:response.requiredDbVersion, localTimeUtc:response.localTimeUtc});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    //#endregion INFO
    
    static getSlideImage(imageName, imageType) {
        // Description: request an image from the webservice
        //
        // GET /api/slideImage/firmware/<imageName> or 
        // GET /api/slideImage/db/<imageName>
        //
        // Body: { }
        //
        // Response:
        // {
        //    <image data>
        // }
        //

        console.log(`getSlideImage(): sending for ${imageName} of type ${imageType}`);
        
        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/slideimage/${imageType}/${imageName}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response);
            })
            .catch((e) => reject("could get image.  Details: " + e + "]"));
        });
    }
    
    static uploadImageAsSampleImage(imageData) {
        // Description: send raw file image data up to the server
        //
        // POST /api/general/image
        //
        // Body: {
        //    imageData:  <code>
        // }
        //
        // Returns: {
        //     id: <#>
        // }
        //

        console.log(
            `uploadImageAsSampleImage(): sending upload request...`
        );

        return new Promise((resolve, reject) => {
            const body = {
                imageData: imageData
            };
            this.sendRequestAsJson("/api/general/image", "POST", body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.id);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static sendRequestForTextFile(url) {
        // Description: request a text file from the webservice
        //
        // GET /url
        //
        // Body: { }
        //
        // Response:
        // {
        //    <text file data>
        // }
        //

        const now = Date.now();
        console.log(`sendRequestForTextFile(): sending for ${url}`);

        return fetch(url+"?"+now, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "same-origin", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "omit", // include, *same-origin, omit (JS credentials)
            headers: {
                "Content-Type": "text/html",
            }, redirect: "error", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
        })
        .then( (response) => {
            if (response.headers.has("Content-Type")) {
                if (response.headers.get("Content-Type").startsWith("text/html")) {
                    return response.text();
                }
            }
            return response;
        })
        .catch((e) => {
            this.handleServerInternalError(e)
        });
    }
    
    /** send some data to the server using GET, PUT, POST or any REST action.
     * The body is sent as a JSON string.
     * NOTE: if GET is specified, the body will not be included in the message as per
     * REST requirements.
     *
     * @param url: url to send the request to (plus any query string)
     * @param method: one of GET, POST, PUT, DELETE (default is GET)
     * @param body: body object to send (empty by default)
     * @returns {Promise<any | never>}
     */
    static sendRequestAsJson(url = "", method = "GET", body = {}) {
        let requestInit = {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: "same-origin", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "omit", // include, *same-origin, omit (JS credentials)
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "error", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
        };
        
        const bearerToken = CommonClientApi.liToken;

        if (bearerToken && bearerToken !== "undefined") {
            // add tokens if we have them.
            requestInit.headers["Authorization"] = "Bearer " + bearerToken;
        }

        if (method !== "GET") {
            // GET requests cannot have a body
            requestInit["body"] = JSON.stringify(body);
        }

        return fetch(url, requestInit)
        .then((response) => this.handleServerResponse(response))
        .catch((e) => { 
            this.handleServerInternalError(e)
        });
    }

    /** post a file to the server
     *
     * @param url: the url to post to
     * @param fieldName (the name of the field in the form with the fileInfoObject in it)
     * @param fileInfoObject (the field from the form that has the selected file in it)
     * @param fileName
     */
    static postFileAsFormData(url = "", fieldName, fileInfoObject, fileName) {

        let formData = new FormData();
        formData.append(fieldName, fileInfoObject, fileName);

        let requestInit = {
            method: "POST",
            mode: "same-origin", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "omit", // include, *same-origin, omit (credentials sent if same origin only)
            headers: new Headers({
                Authorization: "Bearer " + CommonClientApi.liToken,
                //'Content-Type': 'multipart/form-data'
            }),
            redirect: "error", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: formData,
        };

        return fetch(url, requestInit)
        .then((response) => this.handleServerResponse(response))
        .catch((e) => this.handleServerInternalError(e));
    }

    /** returns a promise that handles a server's response (json, or text with errors
     *
     * @param response
     * @returns {*|void|Promise<any>}
     */
    static handleServerResponse(response) {
        if (response.headers.has("Content-Type")) {
            if (response.headers.get("Content-Type").startsWith("application/json")) {
                // convert to json
                const payload = response.json();
                if (payload.isError) {
                    return payload.isError;
                }
                return payload;
            }
            else if(response.headers.get("Content-Type").startsWith("image/")) { // image data
                return( // read the blob fully and then return the URL
                    response.blob().then( theBlob => URL.createObjectURL(theBlob))
                );  // return the image as a url
            }
            else if(response.headers.get("Content-Type").startsWith("application/octet-stream")) { // general bin data
                const disposition=response.headers.get("Content-Disposition");
                let fileNameHint = '';
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/, matches = filenameRegex.exec(disposition)
                    if (matches != null && matches[1]) fileNameHint = matches[1].replace(/['"]/g, '')
                }
                return( // read the blob fully and then return the URL
                    response.blob().then( data => {
                        // The actual download
                        const blob = new Blob([data], {type: "application/octet-stream"});
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fileNameHint;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                );  // return force the browser to download the blob
            }
        }

        if (response.status >= 300 && response.status < 500) { // covers multiple choices
            return this.buildErrorReturn(response.status, response.statusText);
        }
        
        if (response.ok === false) {
            return response
            .text()
            .then((text) => {
                if (text.indexOf("Could not proxy") !== -1) {
                    // service is gone
                    text =
                        "<p>We apologize, but theServer appears to not be responding.</p><p>Please try your request again shortly.</p>";
                }
                return this.buildErrorReturn(response.status,text === "" ? "Server Response: " + response.statusText : text);
            })
            .catch((e) => this.handleServerInternalError(e));
        }
    }
    
    static buildErrorReturn(statusCode:number, errorStr:string) {
        return {
            isError: true,
            statusCode: statusCode,
            error: errorStr
        };
    }

    /** handle internal server errors
     *
     * @param error
     * @returns {{isError: boolean, error: *, statusCode: number}}
     */
    static handleServerInternalError(error) {
        return this.buildErrorReturn(500,error);
    }

    /**
     * check the given response for a blank response or just some error that's slipped through
     * and return a good error response.
     * @param response
     * @param reject
     * @returns {boolean}
     */
    static checkForNoResponseOrErrorResponse(response,reject:function) {
        if (!response) {
            reject(this.buildErrorReturn(400, "No Specific Error provided - is the Archiver Service running?"));
            return true; // error
        } 
        if(response.isError) {
            reject(this.buildErrorReturn(response.statusCode ?? 400, response.error ?? "No Specific Error provided - is the Archiver Service running?"));
            return true;
        }
        return false; // all ok!
    }


    /*#endregion*/
}

export default CommonClientApi;
