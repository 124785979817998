import React, {useContext, useEffect, useState} from "react"
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import {StoreContext} from "../store";
import Header from "./Header";
import Footer from "./Footer";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import ArchiverClientApi from "../API/archiverClientApi";
import {Redirect, useParams} from "react-router-dom";
import {clearAllCaseInfo, clearCaseHistory, gotCaseBasicInfo, gotCaseHistory} from "../ACTIONS/clientActions";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import {useCookies} from "react-cookie";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import ReactDOMServer from "react-dom/server";

const CaseDetailsPage = () => {

    const [globalState,dispatch] = useContext(StoreContext);
    const [isSearchingHistory,setIsSearchingHistory] = useState(false);
    const [isSearchingBasic,setIsSearchingBasic] = useState(false);
    const [redirectTo, setRedirectTo] = useState("");

    const { caseNum } = useParams();
    const [caseId, setCaseId] = useState(-1);
    const [cookies] = useCookies();

    useEffect(() => {
        console.debug("ShowCaseDetailsPage starting up...");

        window.scrollTo(0,0);
        LIUtils.setBackgroundColor('--li-main-bg-color');
        getCaseBasicInfo(caseNum);
    }, [caseNum]);  // eslint-disable-line react-hooks/exhaustive-deps

    function getCaseBasicInfo(caseNum) {
        // if(globalState.case.id === caseId) { // no need to refresh
        //     return;
        // }
        setIsSearchingBasic(true);
        dispatch(clearAllCaseInfo());
        ArchiverClientApi.getCaseBaseInfo(caseNum)
        .then( (caseInfo) => {
            setCaseId(caseInfo.id); // trap this for later
            dispatch(gotCaseBasicInfo(caseInfo.id,caseInfo));  // case id comes from here
            getCaseHistory(caseInfo.id); // now grab the history
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsSearchingBasic(false));
    }

    function getCaseHistory(caseId) {

        setIsSearchingHistory(true);
        dispatch(clearCaseHistory());
        ArchiverClientApi.getCaseHistory(caseId)
        .then( (caseHistory) => {
            dispatch(gotCaseHistory(caseId,caseHistory));
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsSearchingHistory(false));
    }

    function handleShowInventory() {
        setRedirectTo("/case_inventory/"+caseId+"/"+caseNum);
    }

    function showBasicInfo() {
        if(isSearchingBasic) {
            return <LoadingSpinner medium center/>;
        }
        return(
            <div className="row p-0 m-0 mt-3">
                <div className="col-12 col-sm-6">
                    <div className="li-font-medium">
                        <div><strong>Case Started:</strong> {LIUtils.shortDateFromJsonUtcDateStr(globalState.case.dateStarted)}</div>
                        <div><strong>Slides:</strong> {globalState.case.numSlides} / <strong>Out:</strong> {globalState.case.numSlidesOut}</div>
                        <div><strong>Blocks:</strong> {globalState.case.numBlocks} / <strong>Out:</strong> {globalState.case.numBlocksOut}</div>
                    </div>
                </div>
                <div className="mt-3 col-6 offset-3 col-sm-6 offset-sm-0">
                    <button className="li-button li-primary w-100" onClick={handleShowInventory}>Show Inventory</button>
                </div>
            </div>
        );
    }

    function handleSampleClicked(sampleId) {
        setRedirectTo(`/sample_details/${sampleId}`);
    }

    function historyLinkClickHandler(note: Element, sampleId: number) {
        if(!note) return;
        ArchiverClientApi.sendNotifySampleLocationViewed(sampleId,"Case Details"); // fire and forget
        Popup.showInfo(ReactDOMServer.renderToString(note), true);
    }

    function showHistoryTable() {
        if(!globalState.case) return;

        const caseHistory = globalState.case.history;

        if (globalState.case.id===-1 || caseHistory.length === 0) {
            return <div className="card">{isSearchingHistory?<LoadingSpinner center medium/>:"No history found"}</div>;
        }

        const resultList = caseHistory.map((item, index) => {
            
                const historyNote = LIUtils.translateHistoryNote(item.note, LIUtils.UserCanSeeSampleLocation(cookies), (note)=>historyLinkClickHandler(note,item.sampleId));
                
                return <tr className="text-left" key={item.id}>
                    <td>{LIUtils.shortDateTimeFromJsonUtcDateStr(item.date)}</td>
                    <td className="text-center"><span className="btn-link li-pointer"
                                                      onClick={() => handleSampleClicked(item.sampleId)}>{item.sampleNumber}</span>
                    </td>
                    <td width="250px">{item.actionVerb}</td>
                    <td>{item.actor}</td>
                    <td className="text-wrap">
                        {historyNote}
                    </td>
                </tr>
            }
        );

        return (
            <div className="li-scrollable-at-500">
                <table className="table table-striped">
                    <thead>
                    <tr className="text-left"><th>Date</th><th width="60px">Sample</th><th className="50px text-wrap">Action</th><th>Who</th><th>Note</th></tr>
                    </thead>
                    <tbody>
                    {resultList}
                    </tbody>
                </table>
            </div>
        );
    }

    if(redirectTo) {
        return (
          <Redirect push to={redirectTo}/>
        );
    }

    return (
        <div className="container">
            <Header/>
            <div className="row mt-3 li-header-row justify-content-between">
                <div>
                    <BackButton/>
                    <span className="li-font-huge mt-2 mb-2">
                        Case: {caseNum}
                    </span>
                </div>
                <div className="li-font-small text-right my-auto ml-auto mr-3">[id: {globalState.case.id}]</div>
            </div>
            
            {showBasicInfo()}
            <div className="mb-5">
                <div className="li-font-huge">History</div>
                <div className="li-search-results-table">
                    {showHistoryTable()}
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default CaseDetailsPage;
