import React, {useEffect, useState} from 'react';
import {Prompt, Redirect, useHistory, useParams} from "react-router-dom";
import Header from "../Header";
import BackButton from "../../GUI_COMMON/COMPONENTS/BackButton";
import TextInput from "../../GUI_COMMON/COMPONENTS/TextInput";
import LIButton from "../../GUI_COMMON/COMPONENTS/LIButton";
import Popup from "../../GUI_COMMON/COMPONENTS/Popup";
import ArchiverClientApi from "../../API/archiverClientApi";
import ErrorPopup from "../../GUI_COMMON/COMPONENTS/ErrorPopup";
import CheckInput from "../CheckInput";
import DigitInput from "../../GUI_COMMON/COMPONENTS/DigitInput";
import ArchiverGuiUtils from "../../SUPPORT/ArchiverGuiUtils";

const DevicesAdminPage = () => {

    /**
     * these are hardcoded into the archiver -- don't change
     * @type {[{deviceType: number, name: string},{deviceType: number, name: string},{deviceType: number, name: string}]}
     */
    const DEVICE_TYPES = [
        {deviceType:1,name:"Slide Track"},
        {deviceType:2,name:"Block Track"}, 
        {deviceType:3,name:"Slide Scan"},
        {deviceType:4,name:"Processor"}
    ];
    
    const {deviceId} = useParams();
    const history = useHistory();
    
    const [curDeviceDetails, setCurDeviceDetails] = useState(getDefaultDeviceDetails());
    const [fullDeviceList, setFullDeviceList] = useState([]);
    
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const [redirectToPage, setRedirectToPage] = useState();
    
    useEffect(() => {
        console.debug("refreshing devices list");
        getDeviceList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        if(fullDeviceList && fullDeviceList.length>0 && !isDirty) {
            doRealListItemClicked(deviceId?Number(deviceId):null);  // preselect if the url has soemthign in it.
        }
    },[fullDeviceList]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    function getDefaultDeviceDetails():{} {
        return(
            {
                id: null,
                name: "",
                ipAddress:"",
                deviceType: -1,
                accessCode: "",
                enabled: true,
                profiles: null,
            }
        );
    }
    
    function getDeviceList() {
        setIsLoading(true);
        ArchiverClientApi.getDevices() 
        .then( (devices) => {
            setFullDeviceList(devices);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsLoading(false);
        });
    }
    
    function handleCancelClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        confirmClearDirty();
    }
    
    function confirmClearDirty() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetDeviceDetailsShown();
                });
        }
        else {
            resetDeviceDetailsShown(); // just clear what's there
        }
    }
    
    function handleDeleteDeviceClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        Popup.showConfirm("Delete Device?",`<p>This will remove '${curDeviceDetails.fullName}' completely the system.</p>`+
                                "<p>Are you sure you want to do this?</p>","Yes, Delete it",null, 
            () => {
               doDeviceRemove(curDeviceDetails.fullName,curDeviceDetails.id);
            });
    }
   
    function doDeviceRemove(deviceName, deviceId) {
        setIsRemoving(true);
        ArchiverClientApi.sendDeviceRemove(deviceId)
        .then(() => {
            Popup.showInfo(`Device '${deviceName}' removed`);
            removeFromDeviceList(deviceId);
            resetDeviceDetailsShown();
        })
        .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally(() => {
            setIsRemoving(false);
        });
    }
    
    function handleSaveClicked(e,action) {
        e.preventDefault();
        e.stopPropagation();
        
        if(!checkForValid()) {
            return;
        }
       
        setIsSaving(true);
        ArchiverClientApi.sendDeviceUpdate(curDeviceDetails)
        .then( (savedDeviceDetails) => {
            Popup.showInfo(`Device '${curDeviceDetails.fullName}' Saved`);
            if(action==="ADD") {
                addToFullDeviceList(savedDeviceDetails);
                resetDeviceDetailsShown();
            }
            else if(action==="UPDATE") {
                updateExistingDevice(savedDeviceDetails);
                setIsDirty(false);
            }
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
           setIsSaving(false); 
        });
    }
    
    function checkForValid():boolean {
        let isValid=true;
        let notValidMsg="";
        const typeSelect = document.getElementById("deviceType");
        if(!document.getElementsByName("fullName")[0].checkValidity()) {
            isValid=false;
            notValidMsg="Name needs to be non-blank";
        }
        else if(typeSelect.value===-1 || typeSelect.value==="-1") {  // stupid here
            isValid=false;
            notValidMsg="Device type needs to be selected";
        }
        else if(!document.getElementsByName("accessCode")[0].checkValidity()) {
            isValid=false;
            notValidMsg="Access Code needs to be non-blank and made up of 6 digits";
        }
       
        if(!isValid) {
            ErrorPopup.showError("Validation Problem",notValidMsg);
            return false;
        }
        return true;

    }
    
    function addToFullDeviceList(deviceDetails) {
        const curDeviceList = [...fullDeviceList];
        curDeviceList.push(deviceDetails);
        curDeviceList.sort( (a,b) => {
            if(a.fullName < b.fullName) return -1;
            if(a.fullName > b.fullName) return 1;
            return 0;
        });
        setFullDeviceList(curDeviceList);
    }

    function removeFromDeviceList(deviceId) {
        const curList = fullDeviceList.filter( item => item.id !== deviceId);
        setFullDeviceList(curList);
    }
    
    function updateExistingDevice(details) {
        const curList = [...fullDeviceList];
        let thisDeviceIndex = curList.findIndex(item => item.id===details.id);
        curList[thisDeviceIndex]=details;
        setFullDeviceList(curList);
    }
    
    function onChange(e) {
        const thing = e.target.name;
        const editInfo = {...curDeviceDetails};
        if(e.target.type==="checkbox") { // this is a checkbox
            editInfo[thing] = e.target.checked;
        }
        else {
            let value = e.target.value;
            // the device type selector has it's own names for fields - we remap
            if(thing==="deviceType") { // value here is the id selected
                value = Number(value);
                if(value===editInfo.type) { // no change
                    return;
                }
                editInfo.deviceType=value;
            }
            else {
                editInfo[thing] = value;
            }
        }
        setCurDeviceDetails(editInfo);
        setIsDirty(true);
    }
    
    function showDeviceList(listContents) {

        if(isLoading) {
            return <div className="pr-4 w-30 li-border-right-light">...loading...</div>;
        }
        
        let resultList = listContents?.map((item,index) => {
                return <li key={index} className={"list-group-item li-pointer "}
                    onClick={() => handleListItemClicked(item)}>{item.fullName}</li>;
            }
        );
        if(!resultList || resultList.length===0) {
            resultList = "no devices found";
        }
        return (
            <div className="pr-4 w-30 li-border-right-light">
                <h4>Devices</h4>
                <div className="li-scrollable-at-500 ">
                    <ul className="list-group-flush li-list-group-w-hover pl-0">
                        {resultList}
                    </ul>
                    <div className="li-border-line-light"/>
                </div>
                <LIButton small primary label="" bgClass={isRemoving?"m-3":"fa fa-trash m-3"} disabled={curDeviceDetails?.id===null}
                          onClick={handleDeleteDeviceClicked} showBusy={isRemoving}/>
            </div>
        );
    }
    
    function handleListItemClicked(item) {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetDeviceDetailsShown();
                    doRealListItemClicked(item.id);
                });
        }
        else {
            doRealListItemClicked(item.id);
        }
    }
    
    function doRealListItemClicked(itemId) {
        const device = fullDeviceList.filter(i => i.id===itemId);
        if(device.length===0) {
            resetDeviceDetailsShown();
        }
        else {
            setCurDeviceDetails(device[0]);
            updateUrlLink(`/admin/devices/${device[0].id}`);
        }
    }

    function updateUrlLink(toThis) {
        history.replace(toThis);
    }
    
    function resetDeviceDetailsShown() {
        setCurDeviceDetails(getDefaultDeviceDetails());
        setIsDirty(false);
        updateUrlLink("/admin/devices");
    }
    
    function dumpDeviceTypeOptionsList() {
        return DEVICE_TYPES.map(item => <option key={item.deviceType} value={item.deviceType}>{item.name}</option>);
    }

    function handleSetProfilesClicked() {
        const deviceId = curDeviceDetails.id;
        setRedirectToPage("/admin/set_profiles/"+deviceId);
    }

    function getProfilesLabel() {
        const numProfiles = curDeviceDetails?.profiles?.length??0;
        return(
            `Set Profiles (${numProfiles})`
        );
    }

    function handleBackClicked() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    setRedirectToPage("/adminPage");
                });
        }
        else {
            history.goBack();
        }
    }

    if (redirectToPage) {
        return <Redirect to={redirectToPage}/>;
    }
    
    return (
        <div className="container">
            <Prompt
                when={isDirty}
                message={ () => "You have unsaved changes.  Really leave?"}
            />
            <Header hideAdmin/>
            <div className="li-header-row">
                <BackButton onClick={handleBackClicked}/>
                <h1 className="ml-4 d-inline-block align-middle">Device Administration</h1>
            </div>
            
            <div className="row">
                {/* LEFT SIDE LIST */}
                {showDeviceList(fullDeviceList)}

                {/* RIGHT SIDE FORM */}
                <div className="pl-4 w-60">
                    <div className="li-fg-slightly-muted li-font-small text-right">[Id:{curDeviceDetails?.id??"-"}]</div>
                    <div className="row p-0 m-0 justify-content-between">
                        {/* right side, row #1 */}
                        <TextInput name="fullName" label="Name" onChange={(e)=>onChange(e)}
                                   placeholder="" textLeft
                                   value={curDeviceDetails?.fullName??""}
                                   required/>
                        <CheckInput label="Enabled" name="enabled" checked={curDeviceDetails.enabled} onChanged={(e)=>onChange(e)}/>
                    </div>
                    {/* right side, row #2 */}
                    <label htmlFor="deviceType" className="li-form-label mr-3">Device Type</label>
                    <select onChange={onChange} id="deviceType" name="deviceType" value={curDeviceDetails.deviceType??-1} 
                            className="li-form-field no-width align-self-end li-border-dark" required={true}>
                        <option value={-1}>(select a type)</option>
                        {dumpDeviceTypeOptionsList()}
                    </select>
                    {/* right side, row #3 */}
                    <TextInput name="ipAddress" label="IP Address" onChange={(e)=>onChange(e)} placeholder=""  
                               value={curDeviceDetails?.ipAddress??""} oneRow/>
                    { /* right side, row #4 */}
                    <DigitInput name="accessCode" label="Access Code" value={curDeviceDetails?.accessCode??""} oneRow
                              onChange={(e)=>onChange(e)} required minLength={6} maxLength={6}/>
                    {/* right side, row #4 */}
                    <div className="my-5 text-center">
                        <LIButton name="profiles" label={getProfilesLabel()} bgClass="w-75" secondary
                                  disabled={curDeviceDetails?.id===null}
                                  onClick={handleSetProfilesClicked}/>
                    </div>
                    
                    {/* BUTTON ROW */}
                    <div className="text-center my-5">
                        <LIButton name="cancel" label={isDirty?"Cancel":"Clear"} bgClass="mr-4" primary onClick={handleCancelClicked}/>
                        <LIButton name="save" label={curDeviceDetails?.id===null?"ADD":"UPDATE"} secondary disabled={!isDirty || isSaving} 
                                  onClick={(e)=>handleSaveClicked(e,curDeviceDetails?.id===null?"ADD":"UPDATE")} showBusy={isSaving}/>
                    </div>
                </div>
            </div>

            
        </div>
    );
}

DevicesAdminPage.propTypes = {
   
};

export default DevicesAdminPage;