import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function statsReducer(state = initialState.stats, action) {

    /**
     numNeedsAttention: -1,
     */
    
    switch (action.type) {
        case(types.GOT_STAT_UPDATE): { 
            const name = action.name;
            const value = action.value;
            let newState = {...state};
            newState[name] = value; // update
            return newState;
        }
        case(types.GOT_NUM_NEEDS_ATTENTION): {
            const name ="numNeedsAttention";
            const value = action.numNeedsAttention;
            let newState = {...state};
            newState[name] = value; // update as stat
            return newState;
        }
        case(types.GOT_NUM_NEEDS_CHASING): {
            const name ="numNeedsChasing";
            const value = action.numNeedsChasing;
            let newState = {...state};
            newState[name] = value; // update as stat
            return newState;
        }
        case(types.GOT_CHASELIST): {
            const name ="numNeedsChasing";
            const value = action.totalItems;
            let newState = {...state};
            newState[name] = value; // update as stat
            return newState;
        }
        case(types.GOT_NUM_WATCHES): {
            const name ="numWatching";
            const value = action.numWatches;
            let newState = {...state};
            newState[name] = value; // update as stat
            return newState;
        }
        case types.GOT_WATCHLIST: {
            const name="numWatching";
            const value = action.totalItems;
            let newState = {...state};
            newState[name] = value; // update as stat
            return (newState);
        }
        case(types.GOT_NUM_RETRIEVALS): {
            const name ="numRetrievals";
            const value = action.numRetrievals;
            let newState = {...state};
            newState[name] = value; // update as stat
            return newState;
        }
        case(types.GOT_NUM_EVENTS): {
            const name ="numEvents";
            const value = action.numEvents;
            let newState = {...state};
            newState[name] = value; // update as stat
            return newState;
        }
        
        default:
            return(state)
    }
}

