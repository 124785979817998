
class LIStorage {
    
    static getRetrievalSampleList():Array {
        const rInfo = LIStorage.getRetrievalInfo();
        return rInfo?.samples??[]; // grab the samples out of the general info (easier)
    }
    
    static setRetrievalSampleList(samples:Array) {
        const rInfo = LIStorage.getRetrievalInfo();
        rInfo.samples = samples;
        LIStorage.setRetrievalInfo(rInfo); // updated
    }

    static setRetrievalIsDirtyFlag(isDirty:boolean) {
        const rInfo = LIStorage.getRetrievalInfo();
        rInfo.saved=!isDirty;       // ### reversed
        LIStorage.setRetrievalInfo(rInfo); // updated
    }
    
    static clearRetrievalSampleList() {
        LIStorage.setRetrievalSampleList({});
    }
    
    static addSamplesToRetrievalList(samples:Array) {
        let addedOne=false;
        const curList = LIStorage.getRetrievalSampleList();
        for(let i=0;i<samples.length;i++) { // check for duplicates
            if(!curList.find(item => item.id===samples[i].id)) { // add
                curList.push(samples[i]);
                addedOne=true;
            }
        }
        LIStorage.setRetrievalSampleList(curList);
        if(addedOne) {
            LIStorage.setRetrievalIsDirtyFlag(true);  
        }
    }
    
    static getRetrievalInfo():Object {
        let rInfo = localStorage.getItem('retrievalInfo');
        if(!rInfo || rInfo==="") return {};
        return JSON.parse(rInfo);
    }

    static setRetrievalInfo(rInfo:Object) {
        console.debug("saving ROInfo...");
        localStorage.setItem('retrievalInfo', JSON.stringify(rInfo));
    }

    static clearRetrievalInfo(initialValue={}) {
        console.debug("clearing RO...");
        LIStorage.setRetrievalInfo(initialValue);
    }

    static clearAll() {
        localStorage.clear();
    }
}

export default LIStorage;