import React, {useContext, useEffect, useState} from "react";
import Header from "./Header";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import {Redirect, useLocation} from "react-router-dom";
import { useHistory } from "react-router";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import ArchiverClientApi from "../API/archiverClientApi";
import Footer from "./Footer";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import {gotSampleBaseInfo, saveSearchResults} from "../ACTIONS/clientActions";
import {StoreContext} from "../store";
import SampleCard from "./SampleCard";
import ReloadButton from "../GUI_COMMON/COMPONENTS/ReloadButton";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import {useCookies} from "react-cookie";
import BackNextWidget from "../GUI_COMMON/COMPONENTS/BackNextWidget";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import LIButton from "../GUI_COMMON/COMPONENTS/LIButton";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";

const ATTENTION_PAGE_SIZE=30;

const AttentionPage = () => {
    
    const [globalState, dispatch] = useContext(StoreContext);
    
    const [isSearching,setIsSearching] = useState(false);
    const [samplesList, setSamplesList] = useState([]);
    const [totalNASamples, setTotalNASamples] = useState(0);
    const [redirectTo, setRedirectTo] = useState();
    const [isIniting, setIsIniting] = useState(true);

    const history = useHistory();
    const [cookies,] = useCookies();
    
    const location = useLocation();
    const [pageSize,] = useState(ATTENTION_PAGE_SIZE);
    
    const params = new URLSearchParams(location.search);
    const [startIndex, setStartIndex]=useState(Number(params.get('si')??0));
    const [orderBy,setOrderBy] = useState(params.get('ob')??"timedesc");
    const [sampleType, setSampleType] = useState(params.get('t')??"");
    
    const [reRunNASamplesLabel, setReRunNASamplesLabel ] = useState("Rerun Evaluation");
    const [canRefreshNASamples, setCanRefreshNASamples] = useState(true);
    
    useEffect(() => {

        console.debug("GetNeedsAttention starting up...");
        window.scrollTo(0,0);
        LIUtils.setBackgroundColor('--li-main-bg-color');
        
        setIsIniting(false);
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if(!isIniting) {
            const haveStartIndex = params.get('si'); // will be empty if coming from dashboard (ie. refresh!)
            if (haveStartIndex && searchResultsHaveNotChanged()) {
                setSamplesList(globalState.searchResults.searchResults);
                setTotalNASamples(globalState.searchResults.totalNumResults);
            } else { // go to the API
                handleBackNext(startIndex, pageSize);
            }
        }
    },[pageSize, orderBy, sampleType, isIniting]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    function searchResultsHaveNotChanged() {
        const searchTypeKey = "na_search";
        const gSearchResults = globalState.searchResults;

        return gSearchResults.searchType === searchTypeKey &&
            gSearchResults.startIndex === startIndex &&
            gSearchResults.numToReturn === pageSize &&
            gSearchResults.searchResults !== undefined &&
            gSearchResults.searchTerm === orderBy+"-"+sampleType; // reuse here of term for orderBy! -pott
    }
    
    function handleBackNext(si, numToReturn) {

        console.debug(`*** handling backnext (${si}:${numToReturn}) ***`);
        setStartIndex(si);
        replaceBrowserHistory(si,numToReturn,orderBy, sampleType);
        setIsSearching(true);
        setSamplesList([]);
        ArchiverClientApi.getNeedsAttentionList(si,numToReturn,orderBy,sampleType)
        .then( ({total,samples}) => {
            setTotalNASamples(total);
            setSamplesList(samples);

            dispatch(saveSearchResults("na_search",samples,si, numToReturn, orderBy, total)); // save for possible later use
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsSearching(false));
    }
    
    function handleSampleClicked(sampleItem) {

        replaceBrowserHistory(startIndex,ATTENTION_PAGE_SIZE,orderBy, sampleType)
        if(LIUtils.UserHasLevel(cookies,"FIXER") || LIUtils.UserHasLevel(cookies,"EDITOR")) {
            dispatch(gotSampleBaseInfo(sampleItem));
            setRedirectTo(`/attention_edit/${sampleItem.id}`);
        }
        else {
            Popup.show("","<p>You are not a SAMPLE FIXER or EDITOR type user.</p>" +
                "Talk to your Archiver Administrator if you wish to change this.");
        }
    }
    
    function replaceBrowserHistory(startIndex, pageSize, orderBy, sampleType) {
        history.replace(`/attention?si=${startIndex}&nr=${pageSize}&ob=${orderBy}&t=${sampleType}`);
    }
    
    function showResultsTable() {

        if (!samplesList || samplesList.length === 0) {
            return <div className="li-card text-center li-font-medium my-5">
                * <b>You're DONE!</b> There are no samples in Needs Attention *
            </div>;
        }
        if (samplesList.length % 2 !== 0) { // need an extra one to keep the last left sample to the left (hack)
            samplesList.push(null); // final one
        }

        const naList = samplesList.map((item, index) => <SampleCard key={index} sampleItem={item}
                                                                    displayDate={item?.addedToNeedsAttention}
                                                                    showPosition needsAttention showStatus={false}
                                                                    onClick={() => handleSampleClicked(item)}/>);
        return (<>
                <BackNextWidget disableNext={samplesList.length < pageSize} pageSize={pageSize} startIndex={startIndex}
                                onClick={handleBackNext}/>
                <div className="row justify-content-center">
                    {naList}
                </div>
                <BackNextWidget disableNext={samplesList.length < pageSize} pageSize={pageSize} startIndex={startIndex}
                                onClick={handleBackNext}/>

            </>);
    }
    
    function setSortOrder(newOrder:string) {
        setStartIndex(0); // reset
        setOrderBy(newOrder);
    }
    
    function dumpOrderFilter() {
        return(
            <div className="mt-2">
                <span className="li-fg-muted">Sort Order: </span>&nbsp;
                <span className={orderBy==="timedesc"?"font-weight-bold":"btn-link li-pointer"} onClick={()=>setSortOrder("timedesc")}>newest first</span> |&nbsp; 
                <span className={orderBy==="timeasc"?"font-weight-bold":"btn-link li-pointer"} onClick={()=>setSortOrder("timeasc")}>oldest first</span>
            </div>
        );
    }
    
    function showReRunNASamplesIfRequired() {
        return LIUtils.UserHasLevel(cookies,"SERVICE");
    }
    
    function rerunSamples() {
        
        setCanRefreshNASamples(false);
        ArchiverClientApi.refreshNASamples((progressValue:Number, isDone:boolean=false, isError:boolean=false, finalResults:Object=null)=> {
            
            let updatedLabel = "Rerun Evaluation";
            if(!isDone && !isError) {
                console.debug("na refresh progress: "+progressValue);
                updatedLabel = `Rerunning ... ${progressValue}%`;
            }
            setReRunNASamplesLabel(updatedLabel);
            
            if(isDone===true) {
                setCanRefreshNASamples(true);
                handleBackNext(0,pageSize); // refresh the NA list
                Popup.show("Attention Refresh Completed",`<b>Samples Processed:</b> ${finalResults.numProcessed}<br/><b>Samples Removed from NA:</b> ${finalResults.numRemoved}`);
            }
            if(isError===true) {
                setCanRefreshNASamples(true);
                ErrorPopup.showError("Rerun Error",`<p>Failed to run attention refresh.</p><p>Reason: ***<b>${finalResults.errorReason}</b>***</p>`);
            }
        });
    }
    
    function dumpTypeFilter() {
        return (
            <div className="m-0 p-0">
                <input type="radio" className="m-2 medium align-self-center" name="li-filter-type" aria-label="Filter Type"
                       checked={sampleType===""} onChange={(e)=>handleRadioChanged(e)}
                       onClick={(e)=>handleRadioClicked(e)} value=""/>
                <label className="li-form-label m-0 medium mr-3" htmlFor="li-filter-type">all</label>

                <input type="radio" className="m-2 medium align-self-center" name="li-filter-type" aria-label="Filter Type"
                       checked={sampleType==="slide"} onChange={(e)=>handleRadioChanged(e)}
                       onClick={(e)=>handleRadioClicked(e)} value="slide"/>
                <label className="li-form-label m-0 medium mr-3" htmlFor="li-filter-type">slides</label>

                <input type="radio" className="m-2 medium align-self-center" name="li-filter-type" aria-label="Filter Type"
                       checked={sampleType==="block"} onChange={(e)=>handleRadioChanged(e)}
                       onClick={(e)=>handleRadioClicked(e)} value="block"/>
                <label className="li-form-label m-0 medium mr-5" htmlFor="li-filter-type">blocks</label>
            </div>
        );
    }

    function handleRadioChanged(e) {
        e.stopPropagation();
        setSampleType(e.target.value);
        setStartIndex(0);  // reset
    }
    function handleRadioClicked(e) {
        e.stopPropagation(e);
    }
    
    if(redirectTo) {
        return (
            <Redirect push to={redirectTo}/>
        );
    }

    return (
        <div className="container">
            <Header/>
            <div className="row mt-3 li-header-row justify-content-between">
                <div>
                    <BackButton onClick={() => setRedirectTo('/dashboard')}/>
                    <h1 className="d-inline-block align-middle">Needs Attention</h1>
                </div>
                <div>
                    <ReloadButton/>
                </div>
            </div>
            <div className="row mt-3 justify-content-between">
                <div>
                    <div className="li-font-medium d-inline-block mr-4"><strong>Total Samples:</strong> {totalNASamples}</div>
                    {showReRunNASamplesIfRequired() && <LIButton primary small label={reRunNASamplesLabel} onClick={rerunSamples} disabled={!canRefreshNASamples}/>}
                </div>
                <div>
                    {dumpTypeFilter()}
                    {dumpOrderFilter()}
                </div>
            </div>

            {canRefreshNASamples &&
                <div className="">
                    {isSearching && <LoadingSpinner medium center/>}
                    {!isSearching && showResultsTable() }
                </div>
            }
            {!canRefreshNASamples &&
                <div className="text-center">
                    <LoadingSpinner medium center/>
                    <p className="li-font-medium mt-4">...Re-running Needs Attention Samples - please wait...</p>
                </div>
            }
            
            <Footer/>
        </div>
    );
}

export default AttentionPage;