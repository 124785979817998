import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';

import './GUI_COMMON/STYLES/App.css';
import './STYLES/archiver.css';

import '@fortawesome/fontawesome-free/css/all.min.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import {BrowserRouter as Router} from 'react-router-dom';

import StoreProvider from "./store";

// FOR DEBUGGING!
import { debugContextDevtool } from 'react-context-devtool';

ReactDOM.render(
  <React.StrictMode>
      <StoreProvider>
          <Router basename="/app">
                <App/>
          </Router>
      </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// Attach root container -- FOR DEBUGGING!
debugContextDevtool(document.getElementById('root'), {debugContext:true, debugReducer:true, disable:false, disableAutoMode:false});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
