import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function caseReducer(state = initialState.case, action) {

    /**
     id: -1,
     number: "",
     numSlides: 0,
     numBlocks: 0,
     numSlidesOut: 0,
     numBlocksOut: 0,
     dateStarted: undefined,
     dateModified: undefined,
     history: [],
     */
    
    switch (action.type) {
        case(types.CLEAR_CASE_BASE_INFO): {  // clear all case info actually
            return {...initialState.case};            
        }
        case(types.SET_CASE_BASIC_INFO): {
            //const caseId = action.caseId;
            let newState = {...action.caseItem};
            newState.history = [];
            return newState;
        }
        case(types.CLEAR_CASE_HISTORY): {
            const newState = {...state};
            newState.id = -1;
            newState.history = [];
            return newState;
        }
        case(types.GOT_CASE_HISTORY): {
            const newState = {...state};
            newState.id= action.caseId;
            newState.history=action.caseHistory;
            return newState;
        }
        default:
            return(state)
    }
}

