import React from 'react'

import {VERSION} from '../SUPPORT/version'
import liLogoImage from "../GUI_COMMON/IMAGES/labimprovements-trans.png";
import HelpLink from "../GUI_COMMON/COMPONENTS/HelpLink";

const Footer = () => {
    
    return (
        <div className="mb-2">
            <div className="li-border-line w-100 mt-3 mb-2"/>
            <div className="row justify-content-between w-100 li-font-tiny m-0 p-0">
                <img src={liLogoImage} width="150px" height="52px" alt="lab improvements"/>
                <div className="align-self-center">
                    <span className="mr-2">Archiver App: {VERSION}</span>
                    <HelpLink link="index.html"/>
                </div>
            </div>    
        </div>
    );
}

export default Footer;