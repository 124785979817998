import React, {useEffect, useState} from 'react';
import ArchiverClientApi from "../API/archiverClientApi";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import LIButton from "../GUI_COMMON/COMPONENTS/LIButton";
import DigitInput from "../GUI_COMMON/COMPONENTS/DigitInput";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

const ConsumablesForm = ({onSubmitClicked, isSaving}) => {
    
    const [shipmentValues, setShipmentValues] = useState({});
    const [valueChanged, setValueChanged] = useState({});
    const [goodNess, setGoodNess] = useState({});
    const [oneValueHasChanged, setOneValueHasChanged] = useState({});
    const [redirectTo, setRedirectTo] = useState();

    useEffect( () => {
        initializeFields();
    },[]);
    
    function initializeFields() {
        setShipmentValues({
            slideMags:0,
            blockMags:0,
            boxes:0
        });
        setValueChanged({
            slideMags:false,
            blockMags:false,
            boxes:false
        });
        setGoodNess({
            slideMags: "true",
            blockMags: "true",
            boxes: "true"
        });
        setOneValueHasChanged(false);
    }
    
    function handleInventoryChanged(changeType:string, e) {
        const rawStr:string = filterBadChars(e.target.value,shipmentValues[changeType]);

        const curShipmentValues = {...shipmentValues};
        if(curShipmentValues[changeType]!==rawStr) {
            curShipmentValues[changeType] = rawStr;
            valueChanged[changeType] = true;
        }
        setShipmentValues(curShipmentValues);
        
        const curGoodNess = {...goodNess};
        curGoodNess[changeType] = isInventoryValueGood(rawStr)?"good":"bad";
        setGoodNess(curGoodNess);
        
        setOneValueHasChanged(true);
    }
    
    function isInventoryValueGood(rawValue:string):boolean {
        if(!rawValue || rawValue==="") {
            return false;
        }
        const numValue = Number(rawValue);
        return numValue>=0;
    }
    
    function filterBadChars(value:string, curString:string) {
        // check for a bad characer
        const re=new RegExp('[^0-9]');
        if(re.test(value)) { // bad character -- ignore
            console.log("ignoring bad sample # character");
            return curString;  // no change
        }
        return value;  // return new value
    }
    
    function formIsValid() {
        const slidesAreGood = valueChanged.slideMags? isInventoryValueGood(shipmentValues.slideMags):true;
        const blocksAreGood = valueChanged.blockMags? isInventoryValueGood(shipmentValues.blockMags):true;
        const boxesAreGood = valueChanged.boxes? isInventoryValueGood(shipmentValues.boxes):true;
       return oneValueHasChanged && slidesAreGood && blocksAreGood && boxesAreGood;
    }
    
    function handleReset() {
        initializeFields();
    }
    
    function showConsumablesHistory() {
        ArchiverClientApi.getReportDetailsGivenName("consumables history")
        .then( ({basicInfo}) => {
            setRedirectTo("/run_report/"+Number(basicInfo.id));
        })
        .catch( responseError => {
            let error=responseError?.error??"";
            if(responseError.statusCode===404) {
                error = "A report with the name 'consumables history' was not found.<p class='mt-3'>Please create or rename a "+
                    "report that you wish to run whenever you press the Inventory History button.</p>";
            }
            ErrorPopup.showError("Problem",error)
        });
    }
    
    function handleSubmit(e) {
        if(onSubmitClicked) {
            onSubmitClicked(e,shipmentValues);
            handleReset();
        }
    }

    if(redirectTo) {
        return <Redirect push to={redirectTo}/>
    }
    
    return (
        <form id="consumablesAdjustForm" className="li-font-medium">
            <div className="card border-0">
                <h1>Receive Shipment</h1>
                <div className="my-3">
                <p>Use the fields below to <span className="li-bg-warn li-fg-white py-1 px-1 li-border-round-slightly font-weight-bold">add inventory</span> when receiving more stock.
                If your inventory numbers are currently incorrect and need to be <span className="font-weight-bold">adjusted</span>, use the <i className="px-2 fa fa-pen"/> buttons above to change 
                    the total inventory for each consumable independantly.</p> 
                </div>
                <div className="row justify-content-around li-consumable-digits">
                    <table>
                        <tbody>
                            <tr>
                                <td className="" width="200px" height="60px">
                                    <div className="mt-0">Slide Magazines</div>
                                </td>
                                <td>
                                    <DigitInput name="slideMags" label="" maxLength={5} onChange={(e) =>handleInventoryChanged("slideMags",e)}
                                               goodNess={goodNess.slideMags??"true"}
                                               placeholder="#"
                                               value={shipmentValues?.slideMags??"-"}
                                               required oneRow/>
                                </td>
                            </tr>
                            <tr>
                                <td width="200px" height="60px">
                                    <div className="mt-0">Block Magazines</div>
                                </td>
                                <td>
                                    <DigitInput name="blockMags" label="" maxLength={5} onChange={(e) =>handleInventoryChanged("blockMags",e)}
                                                goodNess={goodNess?.blockMags??"true"}
                                                placeholder="#"
                                                value={shipmentValues?.blockMags??"-"}
                                                required oneRow/>
                                </td>
                            </tr>
                            <tr>
                                <td width="200px" height="60px">
                                    <div className="mt-0">Boxes</div>
                                </td>
                                <td>
                                    <DigitInput name="boxes" label="" maxLength={5} onChange={(e) =>handleInventoryChanged("boxes",e)}
                                                goodNess={goodNess?.boxes??"true"}
                                                placeholder="#"
                                                value={shipmentValues?.boxes??"-"}
                                                required oneRow/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <LIButton label="Inventory History" onClick={showConsumablesHistory}/>
                </div>
            </div>
            
            <div className="row justify-content-center">
                <LIButton bgClass="mx-5 my-5" large primary onClick={handleSubmit} disabled={!formIsValid()}
                          label="Add" showBusy={isSaving}/>
                <LIButton bgClass="mx-5 my-5" large secondary onClick={handleReset} label="Clear" disabled={!oneValueHasChanged}/>
            </div>
        </form>
    );
};

ConsumablesForm.propTypes = {
    onSubmitClicked: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
};

export default ConsumablesForm;