import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function contactReducer(state = initialState.contacts, action) {

    /**
     contacts: [
            
     ]
     */
    
    switch (action.type) {
        case types.GOT_CONTACTS_FOR_DEPARTMENT:
            const newState = action.contacts;
            return(newState);
        default:
            return(state)
    }
}

