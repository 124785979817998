import React from "react";
import * as PropTypes from "prop-types";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";

const CaseCard = ({caseId,caseNumber, dateStarted, dateModified, numSlides, 
                      numBlocks, numSlidesOut, numBlocksOut, onClick}) => {
    function handleOnClick() {
        if (onClick) {
            onClick();
        }
    }
    
    function dumpNumOut(numOut) {
        if(numOut===0) {
            return;
        }
        return <span className="badge badge-warning">Out:{numOut}</span>
    }

    return (
        <div className="card li-case-card" onClick={handleOnClick}>
            <div className="card-body">
                <h4 className="card-title font-weight-bold text-left">{caseNumber ?? "-"}</h4>
                <h5 className="mb-0 p-0 text-left li-fg-dark">Slides: {numSlides}  {dumpNumOut(numSlidesOut)}</h5>
                <h5 className="mb-3 p-0 text-left li-fg-blue">Blocks: {numBlocks}  {dumpNumOut(numBlocksOut)}</h5>

                <div className="li-font-tiny li-fg-muted">
                    <strong>First Archived:</strong> {LIUtils.shortDateFromJsonUtcDateStr(dateStarted)}<br/>
                    <strong>Last Modified:</strong> {LIUtils.shortDateFromJsonUtcDateStr(dateModified)}
                </div>

            </div>
        </div>
    );
}

CaseCard.propTypes =  {
    caseId: PropTypes.number,
    caseNumber: PropTypes.string,
    dateStarted: PropTypes.string,
    dateModified: PropTypes.string,
    numSlides: PropTypes.number,
    numBlocks: PropTypes.number,
    numSlidesOut: PropTypes.number,
    numBlocksOut: PropTypes.number,
    onClick: PropTypes.func,
}

export default CaseCard;