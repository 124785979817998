import React from 'react';
import * as PropTypes from "prop-types";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import ButtonWithBusy from "../GUI_COMMON/COMPONENTS/ButtonWithBusy";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import {useCookies} from "react-cookie";

const RetrievalOrderUnsentCardRow = ({orderNumber,dateStarted,requestedBy,destinedForContact,
                                        destinedForLocation,onOrderClick,onMarkCompleteClick,onShowSamplesClick,
                                         isBusyMarking}) => {
    
    const [cookies,] = useCookies();
    
    function dumpDestination() {
        const c = destinedForContact?.fullName??"";
        const l = destinedForLocation?.name??"";
        let sep="";
        if(l && c) {
            sep="/";
        }
        return(
            <div>{l}{sep}{c}</div>    
        );
    }
    
    function onMarkComplete(e) {
        
        if(LIUtils.UserHasLevel(cookies, "SENDER")) {
            if (onMarkCompleteClick) {
                onMarkCompleteClick(e);
            }
        }
        else {
            Popup.show("","<p>You are not a SENDER type user.</p>" +
                "Talk to your Archiver Administrator if you wish to change this.");
        }
    }
    
    function handleOrderClicked(e) {
        e.stopPropagation();
        if(onOrderClick) {
            onOrderClick();
        }
    }
    
    return (
            <tr>
                <td>
                    <span className="btn-link li-pointer" 
                          onClick={(e)=>handleOrderClicked(e)}>{LIUtils.prettyOrderNumber(orderNumber)}</span>
                    <span className="btn-link li-pointer ml-4" 
                          onClick={(e)=>onShowSamplesClick(e)}>
                                <i className="fa fa-list" data-toggle="tooltip" title="list samples"/></span>
                </td>
                <td>{LIUtils.dateTimeToPretty(dateStarted)}</td>
                <td>{requestedBy.fullName}</td>           
                <td>{dumpDestination()}</td>
                <td><ButtonWithBusy id="markSentBTN" addOnStyling="li-button-height-auto" isBusy={isBusyMarking} onClick={(e) => onMarkComplete(e)} label="Mark Sent"/></td>
            </tr>
    );
}

RetrievalOrderUnsentCardRow.propTypes = {
    orderNumber: PropTypes.number.isRequired,
    requestedBy:PropTypes.object,
    destinedForContact:PropTypes.object,
    destinedForLocation:PropTypes.object,
    onOrderClick: PropTypes.func,
    dateStarted: PropTypes.object,
    onMarkCompleteClick: PropTypes.func,
    onShowSamplesClick: PropTypes.func,
    isBusyMarking: PropTypes.bool,
}

export default RetrievalOrderUnsentCardRow;