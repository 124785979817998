import DashboardButton from "../GUI_COMMON/COMPONENTS/DashboardButton";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import {useCookies} from "react-cookie";

const DashboardAdminButton = (props) => {
    
    const [cookies,] = useCookies();
    
    if(!LIUtils.UserHasLevel(cookies,"ADMIN")) {
        return null;
    }
    return(
        <DashboardButton special={true} {...props} />
    );
}

DashboardAdminButton.propTypes = {
}

export default DashboardAdminButton;
        
        
