import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function searchReducer(state = initialState.searchResults, action) {
    
    switch (action.type) {
        case types.SAVE_SEARCH_RESULTS:
            const newState = {
                searchType: action.searchType,
                searchResults: action.searchResults,
                startIndex: action.startIndex,
                numToReturn: action.numToReturn,
                searchTerm: action.searchTerm,
                totalNumResults: action.totalNumResults
            };
            return(newState);
        case types.REMOVE_NA_SAMPLE_FROM_SEARCH_RESULTS:
            if(state.searchType === "na_search") { // needs attention results exist ... prune
                const sampleIdNum=Number(action.sampleId);
                const numBefore = state.searchResults.length;
                const updatedSearchResults = state.searchResults.filter( item => item && item.id!==sampleIdNum); // copied
                const newState = Object.assign({},state);
                if(numBefore>updatedSearchResults.length) {
                    newState.totalNumResults-=1;
                }
                newState.searchResults = updatedSearchResults;
                return newState;  // updated state
            }
            return state;
        default:
            return(state)
    }
}

