import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import TextInput from "../../GUI_COMMON/COMPONENTS/TextInput";
import LIButton from "../../GUI_COMMON/COMPONENTS/LIButton";

const ValidationTester = ({showFor, regexList, sampleValue, caseIndexes=0, sampleIndexes=0, width="400px", onSetSampleValue, onClose}) => {

    const [caseResult, setCaseResult] = useState();
    const [sampleResult, setSampleResult] = useState();
    const [mainPassFailResult, setMainPassFailResult] = useState();
    
    useEffect( () => {
        if(showFor) {
            handleClearResults();       // always start fresh
        }
    },[showFor]);
    
    function shouldShow():boolean {
        return showFor;
    }

    function handleOnClose() {
        if (onClose) {
            onClose();
        }
    }
    
    function handleSetSampleValue(newValue) {
        if(onSetSampleValue) {
            onSetSampleValue(showFor, newValue);
        }
    }
    
    function doMainRegexTestWith(thisValue) {
        const testRegex = regexList[showFor];

        const found = thisValue.match(testRegex);
        if (!found) {
            setMainPassFailResult(false);
            setCaseResult("(failed)");
            setSampleResult("(failed)");
            return;
        }
        setMainPassFailResult(found?.length > 0 ?? false);
        
        setCaseResult("");
        let indexArray = breakdownBasicIndexes(caseIndexes);
        if(indexArray.length>0) {
            setCaseResult(indexArray.reduce((previousValue, currentValue) => {
                if(previousValue && previousValue!=="") { // prepend separator
                    return (previousValue + "-" + found[currentValue]); // append
                }
                return (found[currentValue]); // set initial
            },""));
        }

        setSampleResult("");
        indexArray = breakdownBasicIndexes(sampleIndexes);
        if(indexArray.length>0) {
            setSampleResult(indexArray.reduce((previousValue, currentValue) => {
                if (found.length > currentValue) {
                    if(previousValue && previousValue!=="") { // prepend separator
                        return (previousValue + "-" + found[currentValue]); // append
                    }
                    return (found[currentValue]); // set initial
                }
                return previousValue;   // no change
            },""));
        }

    }

    function doSimpleRegexTestWith(thisValue) {
        const testRegex = regexList[showFor];

        const found = thisValue.match(testRegex);
        if (!found) {
            setCaseResult(false);
            setSampleResult(false);
            return;
        }
        setCaseResult(found?.length > 0 ?? false);
        setSampleResult(found?.length > 0 ?? false);
    }

    function breakdownBasicIndexes(basicIndexValue:number):[] {
        /** this taken almost directly from FWCommon (python conversion) */
        
        if (basicIndexValue <= 0) {      // no match desired
            return [];
        }
        if (basicIndexValue < 10) {      // normal single value index (pre 220814) ie (0) thru (9)
            return [basicIndexValue];
        }
        if (basicIndexValue < 100) {        // two digit appending indexes => ie '(1)(0)' thru '(9)(9)'
            const first = Math.floor(basicIndexValue / 10);
            const second = basicIndexValue - first * 10;
            return [first, second];
        }
        if (basicIndexValue < 1000) {        // three digit appending indexes => ie '(1)(0)(0)' thru '(9)(9)(9)'
            const first = Math.floor(basicIndexValue / 100)
            const second = Math.floor((basicIndexValue - first * 100) / 10)
            const third = basicIndexValue - first * 100 - second * 10
            return [first, second, third]
        }
       
        // get here if too many indexes
        alert("too many indexes specified (test not possible)");
        return([]);
    }
    
    function handleClearResults() {
        setMainPassFailResult();
        setCaseResult();
        setSampleResult();
    }
    
    function dumpTFValueWithNull(value) {
        if(value===undefined || value===null) {
            return("-");
        }
        return value ? "true":"false";
    }
    
    function dumpIOForTest() {
        if (!showFor) {
            return null;
        }
        if (showFor === "main") {
            return (<>
                <div className="row m-0 p-0">
                    <TextInput focus label="Barcode" maxLength={40} minLength={0} widthPels={350} name="valTestInput" value={sampleValue[showFor]} onChange={(e) => handleSetSampleValue(e.target.value)}/>
                    <LIButton bgClass="li-bg-primary align-self-end ml-2" label="Run" small onClick={() => doMainRegexTestWith(sampleValue[showFor])}/>
                </div>
                <div className="li-border-line-light my-3"/>
                <h3 className="d-inline-block">Results <LIButton label="clear" primary onClick={handleClearResults} small bgClass="ml-5"/></h3>
                <table className="w-100 mt-3">
                    <tbody>
                    <tr>
                        <td className="li-fg-muted" style={{width: "150px"}}>Validation Match?</td>
                        <td className={"font-weight-bold "+(mainPassFailResult ? "li-fg-success" : "li-fg-danger")}>{dumpTFValueWithNull(mainPassFailResult)}</td>
                    </tr>
                    <tr>
                        <td className="li-fg-muted" style={{width: "150px"}}>Case Result</td>
                        <td className="font-weight-bold">{caseResult}</td>
                    </tr>
                    <tr>
                        <td className="li-fg-muted" style={{width: "150px"}}>Sample Result</td>
                        <td className="font-weight-bold">{sampleResult}</td>
                    </tr>
                    </tbody>
                </table>

            </>);
        }
        if (showFor === "case") {
            return (<>
                <div className="row m-0 p-0">
                    <TextInput focus label="Case #" maxLength={40} minLength={0} widthPels={350} name="valTestInput" value={sampleValue[showFor]} onChange={(e) => handleSetSampleValue(e.target.value)}/>
                    <LIButton bgClass="li-bg-primary align-self-end ml-2" label="Run" small onClick={() => doSimpleRegexTestWith(sampleValue[showFor])}/>
                </div>
                <div className="li-border-line-light my-3"/>
                <h3 className="d-inline-block">Results <LIButton label="clear" primary onClick={handleClearResults} small bgClass="ml-5"/></h3>
                <table className="w-100 mt-3">
                    <tbody>
                    <tr>
                        <td className="li-fg-muted" style={{width: "150px"}}>Validation Match?</td>
                        <td className={"font-weight-bold "+(caseResult ? "li-fg-success" : "li-fg-danger")}>{dumpTFValueWithNull(caseResult)}</td>
                    </tr>
                    </tbody>
                </table>

            </>);
        }
        if (showFor === "sample") {
            return (<>
                <div className="row m-0 p-0">
                    <TextInput focus label="Sample #" maxLength={40} minLength={0} widthPels={350} name="valTestInput" value={sampleValue[showFor]} onChange={(e) => handleSetSampleValue(e.target.value)}/>
                    <LIButton bgClass="li-bg-primary align-self-end ml-2" label="Run" small onClick={() => doSimpleRegexTestWith(sampleValue[showFor])}/>
                </div>
                <div className="li-border-line-light my-3"/>
                <h3 className="d-inline-block">Results <LIButton label="clear" primary onClick={handleClearResults} small bgClass="ml-5"/></h3>
                <table className="w-100 mt-3">
                    <tbody>
                    <tr>
                        <td className="li-fg-muted" style={{width: "150px"}}>Validation Match?</td>
                        <td className={"font-weight-bold "+(sampleResult ? "li-fg-success" : "li-fg-danger")}>{dumpTFValueWithNull(sampleResult)}</td>
                    </tr>
                    </tbody>
                </table>

            </>);
        }
    }
    
    function showCurrentSetup() {
        if(showFor==="main") {
            return(
                <div>
                    <div><span className="font-weight-bold">Validation: </span>{regexList[showFor]}</div>
                    <div><span className="font-weight-bold">caseIndex: </span>{caseIndexes}</div>
                    <div><span className="font-weight-bold">sampleIndex: </span>{sampleIndexes}</div>
                </div>
            );
        }

        return(
            <div>
                <div><span className="font-weight-bold">Validation: </span>{regexList[showFor]}</div>
            </div>
        );
    }
    
    function dumpHeader() {
        let heading="Main Barcode Tester";
        if(showFor==="case") {
            heading = "Case Tester";
        }
        else if(showFor==="sample") {
            heading = "Sample Tester";
        }
        return(
            <div className="row m-0 p-0 justify-content-between">
                <h2>{heading}</h2><i className="btn text-right li-fg-primary fa fa-window-close fa-lg" onClick={handleOnClose}/>
            </div>
        );
    }
    
    return (

        <div className={"li-validation overlay "+(shouldShow()?"overlay-on":"")+ " li-bg-dark"}>
            <div className="li-validation popup" style={{width:width}}>
                {dumpHeader()}
                {showCurrentSetup()}
                {dumpIOForTest()}
            </div>
        </div>
    );

}

ValidationTester.propTypes = {
    showFor: PropTypes.string,    // can be null
    regexList: PropTypes.object,
    sampleValue: PropTypes.object.isRequired,
    caseIndexes: PropTypes.number,
    sampleIndexes: PropTypes.number,
    width: PropTypes.string,
    onSetSampleValue: PropTypes.func.isRequired,
    onClose: PropTypes.func,
};

export default ValidationTester;