import React from "react";
import * as PropType from "prop-types";
import LIButton from "./LIButton";

const AuthButton = ({user, signOutClicked}) => {

    return (
        <span className="">
            <span className="li-font-small mr-2">{user??"unknown person"}</span>
            <LIButton primary bgClass="li-header-button" onClick={signOutClicked} tooltip="sign out" faIcon="fas fa-sign-out-alt"/>
        </span>
    );
}

AuthButton.propTypes = {
    user: PropType.string,
    signOutClicked: PropType.func,
}

export default AuthButton;