import React from "react";
import PropTypes from "prop-types";
import LIButton from "./LIButton";

const AdminButton = ({adminClicked}) => {
    return <LIButton secondary faIcon="fas fa-cog" tooltip="admin system" bgClass="li-header-button" onClick={adminClicked}/>
}

AdminButton.propTypes =  {
    adminClicked: PropTypes.func.isRequired,
}

export default AdminButton;