import {useCookies} from "react-cookie";

// stolen from https://reacttraining.com/react-router/web/example/auth-workflow
import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import LIUtils from "../SUPPORT/LIUtils";
import ErrorPopup from "./ErrorPopup";

const ProtectedRoute = ({ level, children, ...rest}) => {

    const [cookies,,] = useCookies();
    return (
        <Route {...rest} render={({location}) => {
            if (LIUtils.isAuthenticatedHook(cookies)) {
                if (!level) { // no specific level require
                    return (children);
                } else if (level && LIUtils.UserHasLevel(cookies, level)) { // has the required level - ok to proceed
                    return (children);
                } else { // required level not found
                    if (location.path !== "/signin") {
                        ErrorPopup.showError("Improper Level", `You need user level ${level} to proceed`, () => {
                            // get here if signin is required

                        });
                        return (<Redirect to={{
                            pathname: '/signin',
                            state: {from: location}
                        }}/>);
                    }
                }
            }
            // get here if signin is required
            return (<Redirect to={{
                pathname: '/signin',
                state: {from: location}
            }}/>);

        }}/>);
}

export default ProtectedRoute;