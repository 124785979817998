import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function sampleReducer(state = initialState.sample, action) {

    /**
     id: -1,
     number: "",
     manuallyAdded: false,
     imageData: undefined,
     dateStarted: undefined,
     dateModified: undefined,
     history: [],
     */
    
    switch (action.type) {
        case(types.CLEAR_SAMPLE_BASE_INFO): {  // clear all sample info actually
            return {...initialState.sample};            
        }
        case(types.SET_SAMPLE_BASIC_INFO): {
            // const barcode = action.barcode;
            //const caseNum = action.caseNum;
            //const sampleNum = action.sampleNum;
            //const sampleItem = action.sampleItem;
            let newState = {...action.sampleItem};
            newState.history = [];
            return newState;
        }
        case(types.CLEAR_SAMPLE_HISTORY): {
            const newState = {...state};
            newState.id = -1;
            newState.history = [];
            return newState;
        }
        case(types.GOT_SAMPLE_HISTORY): {
            const newState = Object.assign({},state);
            newState.id=action.sampleId; // safety
            newState.history=action.sampleHistory;
            return newState;
        }
        default:
            return(state)
    }
}

