import $ from 'jquery';
import React, {useEffect} from 'react';
import * as PropTypes from 'prop-types';

const Popup = ({name, useFade}) => {
    
    useEffect(() => {
        $(document).off('shown.bs.modal', '#liModalPopup');
        $(document).on('shown.bs.modal', '#liModalPopup', function (e) {
            $("#liCloseBTN").focus();
        });
        $(document).off('hidden.bs.modal','#liModalPopup');
        return (() => { // unmount
            console.debug(">>> unmounting popup");
            $('#liModalPopup').modal('hide');
            $('.modal-backdrop').remove();
            $("#liConfirmInput").off("keyup",null,null,Popup.gotTextChanged);
        });
    }, [])
    
    return (
        <div className={"modal "+(useFade===true?"fade":"")} tabIndex="-1" id="liModalPopup" role="dialog" aria-labelledby="liPopup"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content li-modal-content">
                    <div className="modal-header li-modal-header" id="liPopupHeader">
                        <h5 className="modal-title" id="liPopupTitle">The Title Is: {name}</h5>
                        <button id="liPopupClose" type="button" className="close" data-dismiss="modal" aria-label="close" onClick={(e) => Popup.hide(e)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body" id="liPopupBody">
                        This is the body of the popup.
                    </div>
                    <div className="modal-footer border-0 w-100">
                        <button className="li-button li-primary" id="liCloseBTN" onClick={(e) => Popup.hide(e)}>Close</button>
                        <button className="li-button li-secondary" id="liOkBTN" onClick={(e) => Popup._ok(e)}>Ok</button>
                        <button className="li-button li-secondary" id="liExtraBTN" onClick={(e) => Popup._extraClicked(e)}>Extra</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

Popup.propTypes = {
    name: PropTypes.string.isRequired
};

Popup.defaultProps = {
    name: "no name"
};

export default Popup;

Popup.gPopupOnClose=undefined;
Popup.gPopupOnOk=undefined;
Popup.gPopupOnExtra=undefined;

Popup._showTitleIfNeeded = (title, color) => {
    $('#liModalPopup .modal-header').css({'background': color});
    const titleBar = $('#liPopupHeader');
    if (!title || title === "") {
        titleBar.hide();
    } else {
        titleBar.show();
        $('#liModalPopup #liPopupTitle').html(title);
    }
}

Popup.focus = () => {
    $("#liCloseBTN").focus();
}

Popup._noTitle = () => {
    const titleBar = $('#liPopupHeader');
    titleBar.hide();
}

Popup._showCloseBtn = (showFlag) => {
    if(showFlag) {
        $("#liCloseBTN").show();
    }
    else {
        $("#liCloseBTN").hide();
    }
}

Popup._showBody = (message, allowOutOfBandClose:boolean=false, centered:boolean=false) => {
    const el = $('#liModalPopup');
    
    $("#liOkBTN").hide();
    $("#liExtraBTN").hide();
    const theBody = $('#liPopupBody');
    theBody.css('text-align', centered?"center":"left");
    theBody.html(message);

    if(allowOutOfBandClose) {
        console.debug(">>> OOB allowed ");
        el.modal({
            backdrop: true,         // backdrop, but CAN be removed with outside click
            keyboard: false
        });
    }
    else {
        console.debug(">>> NOT OOB ");
        el.modal();
        el.off("click");   // we don't want clicks handled by backdrop
    }
}

/**
 * show a popup with a simple title, message and a single close button.
 * 
 * @param title
 * @param message
 * @param onClose
 */
Popup.show = (title, message, onClose) => {
    Popup._clearActions();
    
    Popup._showCloseBtn(true);
    Popup._showTitleIfNeeded(title, '#e0e0e0');
    Popup._showBody(message);
    Popup.gPopupOnClose = onClose;
}

Popup.gotTextChanged = (e) => {
    if(e.target.value!=="") {
        console.debug("value is "+$("#liConfirmInput").val());
        $("#liOkBTN").prop('disabled',false);
    }
    else {
        $("#liOkBTN").prop('disabled',true);
    }
}

/** Show a prompt text area and let the user type some stuff in that's returned
 *  when they push OK.
 *
 * @param title
 * @param defaultText
 * @param okBtnText
 * @param onClose
 * @param onOk
 * @param rows - number of rows in the text area (default=3)
 * @param placeholder - the text to show as a placeholder in the prompt (default='type your message')
 */
Popup.showPrompt = (title, defaultText, okBtnText, onClose, onOk, rows = 3, placeholder="type your message") =>{
    Popup._clearActions();
    
    let textInput = `<textarea class='form-control' rows=${rows} autofocus id='liConfirmInput' placeholder='${placeholder}'>`;
    textInput += defaultText + "</textarea>";
    Popup.show(title, textInput, onClose); // same.

    const textInputWidget = $("#liConfirmInput");
    const okBTN = $("#liOkBTN");
    if (okBtnText && okBtnText !== "") {
        okBTN.text(okBtnText);
        if(textInputWidget) {
            okBTN.prop('disabled', true);
        }
        okBTN.show();
    }
    Popup.gPopupOnOk = onOk;
    
    textInputWidget.on("keyup",null,null,Popup.gotTextChanged); // added by pott - 230905
}

/**
 * show this popup as a confirmation with 2 or 3 buttons for choices and what to
 * do for each button choice.
 * 
 * @param title
 * @param msg
 * @param okBtnText
 * @param onClose
 * @param onOk
 * @param extraBtnText
 * @param onExtra
 */
Popup.showConfirm = (title, msg, okBtnText, onClose, onOk, extraBtnText, onExtra) =>{
    Popup._clearActions();
    
    Popup.show(title, msg, onClose); // same.
    const okBTN = $("#liOkBTN");
    okBTN.prop('disabled', false);
    if (okBtnText && okBtnText !== "") {
        okBTN.text(okBtnText);
        okBTN.show();
    }
    const extraBTN = $("#liExtraBTN");
    if (extraBtnText && extraBtnText !== "") {
        extraBTN.text(extraBtnText);
        extraBTN.show();
    }
    Popup.gPopupOnOk=onOk;
    Popup.gPopupOnExtra=onExtra;
}

/**
 * show this popup with a red banner - as an error
 * 
 * @param title
 * @param message
 * @param onClose
 */
Popup.showError = (title, message, onClose)=> {
    Popup._clearActions();
    
    Popup._showCloseBtn(true);
    Popup._showTitleIfNeeded(title, 'indianred');
    Popup._showBody(message);
    Popup.gPopupOnClose = onClose;
}

/**
 * show the popup with no buttons, just an OutOfBounds close available
 * (minimal decoration)
 * @param message - the message to show
 * @param centered - if true, the text within the popup is centered
 */
Popup.showInfo = (message, centered:boolean=false) => {
    Popup._clearActions();
    
    Popup._noTitle();
    Popup._showCloseBtn(false);
    Popup._showBody(message, true, centered);
}

/**
 * force a hide of the current popup (this still runs any onClose method
 * that was hooked up during the show())
 * @param event
 */
Popup.hide = (event) => { // SAME AS CLOSE
    event?.preventDefault();
    event?.stopPropagation();
    const $modal = $('#liModalPopup');
    $modal.off('hidden.bs.modal');
    $modal.on('hidden.bs.modal', function (e) {
        const content = $("#liConfirmInput").val();
        console.debug("running onClose...");
        if (Popup.gPopupOnClose) {
            Popup.gPopupOnClose(content);
        }
        else {
            console.debug("no onClose...ignored");
        }
    })
    $modal.modal('hide');
}

Popup._ok = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    const $modal = $('#liModalPopup');
    $modal.off('hidden.bs.modal');
    $modal.on('hidden.bs.modal', function (e) {
        const content = $("#liConfirmInput").val();
        console.debug("running onOk...");
        if (Popup.gPopupOnOk) {
            Popup.gPopupOnOk(content);
        }
        else {
            console.debug("no onOk...ignored");
        }
    })
    $modal.modal('hide');
}

Popup._extraClicked = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    const $modal = $('#liModalPopup');
    $modal.off('hidden.bs.modal');
    $modal.on('hidden.bs.modal', function (e) {
        const content = $("#liConfirmInput").val();
        console.debug("running onExtra...");
        if (Popup.gPopupOnExtra) {
            Popup.gPopupOnExtra(content);
        }
        else {
            console.debug("no onExtra...ignored");
        }
    });
    $modal.modal('hide');
}

Popup._clearActions = () => {
    Popup.gPopupOnClose=undefined;
    Popup.gPopupOnOk=undefined;
    Popup.gPopupOnExtra=undefined;
}


