import {useLocation} from "react-router-dom";
import React, {useState} from "react";
import PropTypes from "prop-types";

const PAGE_SIZE=20;

const BackNextWidget = ({disableNext, pageSize=PAGE_SIZE, show=true, startIndex=0, onClick}) => {
    
    const location = useLocation();
    
    const params = new URLSearchParams(location.search);
    const [numToReturn] = useState(Number(params.get('nr')??pageSize));
    
    function getPrevBunch() {
        const nextStartIndex = startIndex-numToReturn<0?0:startIndex-numToReturn;
        handleOnClick(nextStartIndex, numToReturn);
    }
    function getNextBunch() {
        const nextStartIndex = startIndex+numToReturn;
        handleOnClick(nextStartIndex, numToReturn);
    }
    function showNextButton() {
        let isDisabled = false;
        if (disableNext) { // assume NOT another page
            isDisabled = true;
        }
        return (
            <button className="li-button li-primary li-prev-button" disabled={isDisabled} onClick={getNextBunch}>&gt;&gt;</button>
        );
    }
    function showPrevButton() {
        let isDisabled = false;
        if (startIndex < numToReturn) {
            isDisabled = true;
        }
        return (
            <button className="li-button li-primary li-next-button mr-2" disabled={isDisabled} onClick={getPrevBunch}>&lt;&lt;</button>
        );
    }

    function handleOnClick(startIndex,numToReturn) {
        if(onClick) {
            onClick(startIndex,numToReturn);
        }
    }
    
    return(
        <div className={(show?"":"d-none")+" text-center"}>
            {showPrevButton()} {startIndex}-{startIndex+pageSize}
            {showNextButton()}
        </div>
    );
    
}

BackNextWidget.propTypes = {
    disableNext: PropTypes.bool,
    pageSize: PropTypes.number,
    show: PropTypes.bool,
    startIndex: PropTypes.number,
    onClick: PropTypes.func,
}

export default BackNextWidget;
export {PAGE_SIZE};