import React, {useContext, useEffect, useState} from "react";
import Header from "./Header";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import {Redirect} from "react-router-dom";
import {StoreContext} from "../store";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import {gotSamplePickSheet} from "../ACTIONS/clientActions";
import ArchiverClientApi from "../API/archiverClientApi";
import Footer from "./Footer";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import {useHistory} from "react-router";
import LIUtils, {SAMPLE_TYPE_BLOCK, SAMPLE_TYPE_SLIDE} from "../GUI_COMMON/SUPPORT/LIUtils";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const PickSheetPage = () => {
    
    const SORT_ORDER_MAG_LOCATION:Number = 0;
    const SORT_ORDER_ORDER_NUMBER:Number = 1;
    const SORT_ORDER_CASE_NUMBER:Number = 2;

    const [globalState, dispatch] = useContext(StoreContext);
    const [isLoading, setIsLoading] = useState(false);
    const [redirectTo, setRedirectTo] = useState();
    const [checkedItems, setCheckedItems] = useState({});
    const [samplesByMag, setSamplesByMag] = useState([]);
    const [samplesByOrderId, setSamplesByOrderId] = useState([]);
    const [samplesByCaseNumber, setSamplesByCaseNumber] = useState([]);
    const [aSampleIsSelected, setASampleIsSelected] = useState(false);
    const [pickSheetDate, setPickSheetDate] = useState(new Date());
    const [orderBy, setOrderBy] = useState(SORT_ORDER_MAG_LOCATION);
    const [sampleCounts, setSampleCounts] = useState([0,0]);
    
    const history = useHistory();
    
    useEffect( () => {
        if (history.location.search) { // get the orders list 
            let orderStr = history.location.search;
            let orderList=[];
            if(orderStr && orderStr.length >0) {
                orderStr = decodeURI(orderStr);
                if(orderStr.startsWith('?'))
                    orderStr = orderStr.slice(1); // kill '?'
                if(orderStr.endsWith(','))
                    orderStr = orderStr.slice(0,-1);
                const re = /[?,]/;
                orderList = orderStr.split(re);
                orderList = orderList.map(item => Number(item));  // we want id numbers, not strings
            }
            setIsLoading(true);
            ArchiverClientApi.getSamplePickSheet(orderList)
            .then(({orders,samples}) => {
                dispatch(gotSamplePickSheet(orders,samples));
            })
            .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
            .finally( () => setIsLoading(false));
        }
    }, [history.location.search]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        if(globalState.picksheet.samples) {
            const sampleList = globalState.picksheet.samples;
            setSamplesByMag(sampleList);  // samples comes ordered by mag by default from the server
            if(orderBy===SORT_ORDER_ORDER_NUMBER) {
                const samplesByOrderId = sortSamplesByOrderId(sampleList);  // order by order id too now.
                setSamplesByOrderId(samplesByOrderId);
            }
            else if(orderBy===SORT_ORDER_CASE_NUMBER) {
                const samplesByCaseNumber = sortSamplesByCaseNumber(sampleList); // order by case number too now
                setSamplesByCaseNumber(samplesByCaseNumber);
            }
            
            setSampleCounts([countSlideSamples(sampleList),countBlockSamples(sampleList)]);
        }
        setPickSheetDate(new Date());
    }, [globalState.picksheet.samples, orderBy]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        setASampleIsSelected(haveCheckedNonDisabledSamples(checkedItems));
    },[checkedItems]);
    
    //#region SORTERS

    function sortSamplesByOrderId(samples) {
        samples.sort( (a,b) => {
            if(a.parentOrderId < b.parentOrderId) return -1;
            if(a.parentOrderId > b.parentOrderId) return 1;
            return 0;
        });

        return samples;
    }

    function sortSamplesByCaseNumber(samples) {
        samples.sort( (a,b) => {
            if(a.caseNumber < b.caseNumber) return -1;
            if(a.caseNumber > b.caseNumber) return 1;
            return 0;
        });

        return samples;
    }
    
    //#endregion SORTERS
    
    //#region HANDLERS

    function handleCBChanged(e,item) {
        const isChecked = e.target.checked;
        checkOrUncheckSample(item, isChecked)
    }

    function checkOrUncheckSample(item, shouldCheck:boolean) {
        const curCheckedItems = {...checkedItems};
        if(shouldCheck) {
            curCheckedItems[item.id]=item;
        }
        else {
            delete(curCheckedItems[item.id]);
        }
        setCheckedItems(curCheckedItems); // update

        setASampleIsSelected(haveCheckedNonDisabledSamples(curCheckedItems));
    }

    function handleSelectAllCBChanged(e) {
        const isChecked = e.target.checked;
        let curCheckedItems = {...checkedItems};

        if(isChecked) { // add all to checked list
            if(samplesByMag && samplesByMag.length>0) {
                samplesByMag.forEach(item => {
                    if(!sampleIsMarkedAsOutOrDeleted(item)) {
                        curCheckedItems[item.id] = item;
                    }
                });
            }
        }
        else { // remove all from checked list
            curCheckedItems={};
        }
        setCheckedItems(curCheckedItems); // update

        setASampleIsSelected(haveCheckedNonDisabledSamples(curCheckedItems));
    }

    function handlePrintPage() {
        window.print();
    }

    function handleMarkSamplesOut() {
        const ids = Object.keys(checkedItems).map( (key) => {
            const theSample = checkedItems[key];
            return {sampleId: Number(key), orderId: Number(theSample.parentOrderId)};  // returning an object per item
        });

        Popup.showConfirm("Remove Samples?",
            "<p>Are you sure you want to remove the selected samples from their mags?</p>"+
            "<p>*this may take some time if picking many samples</p>","Yes, Remove!",
            null,() => doRemoveSamplesAtServer(ids));
    }
    
    //#endregion HANDLERS

    //#region OUTPUT_FUNCTIONS
    
    function dumpOrderNumberAndDest(orderNumber, sendTo) {
        return <div className="text-right">
                <span className="li-font-small d-block ml-2">
                    <i>ORDER:</i> <strong>{LIUtils.prettyOrderNumber(orderNumber)}</strong><br/>
                <span className="badge li-bg-light ml-2">{sendTo??"-"}</span>
                </span>
        </div>;
    }
    
    function dumpSampleAsOut(item,index, showOrder:boolean=true, showLocation:boolean=true, caseIsRepeated:boolean=false) {
        return (
            <tr key={index}>
                <td>
                    <input type="checkbox" className="m-2 large align-self-center li-sample-cb" name="retrieval-cb"
                           aria-label="Checkbox" disabled={true}/>
                </td>
                <td>
                    <span style={{textDecoration:"line-through"}}>
                        {buildPickSheetSampleDetailsStr(item.barcode, item.caseNumber, item.sampleNumber, caseIsRepeated)}
                    </span>
                </td>
                <td/>
                <td className="text-right">
                    {item.datePickCompleted!==null &&
                        <span className="badge li-fg-white li-bg-picked align-self-center ml-2 mr-3">PICKED</span> }
                    {item.datePickCompleted===null &&
                        <span className="badge li-fg-white li-bg-danger align-self-center ml-2 mr-3">DIFFERENT ORDER</span> }
                    <span className="badge badge-warning align-self-center btn-link li-pointer mr-3"
                          onClick={() => ArchiverGuiUtils.GetSampleOutInfo(item)}>* OUT
                    </span>
                </td>
                
                <td>
                    {showOrder && dumpOrderNumberAndDest(item.parentOrderId,showLocation?item.sentToBrief:"")}
                </td>
            </tr>
        );
    }

    function buildPickSheetSampleDetailsStr(barcode:string, caseNumber:string, sampleNumber:string, caseIsRepeated:boolean=false) {
        if(caseNumber && caseNumber!=="") {
            return( 
                <div className="row m-0 p-0">
                    <div className="col">{caseIsRepeated===false ? caseNumber : ""}</div>
                    <div className="col">
                        <span className="font-italic font-weight-normal">{(LIUtils.existsAndNotEmpty(sampleNumber) ? sampleNumber : "")}</span>
                    </div>
                </div>
            );
        }
        if (!barcode || barcode === "") {
            return barcode;
        }
        return("unknown");
    }
    
    function dumpInterMagSpacer(key) {
        return(
            <tr key={key}>
                <td colSpan="5" className="text-center li-font-tiny">--- new mag ---</td>
            </tr>
        );
    }
    
    function dumpSampleAsIn(item,index,showOrder:boolean=true, showMag:boolean=false, showLocation:boolean=true, caseIsRepeated:boolean=false) {
        return(
            <tr key={index}>
                <td>
                    <input type="checkbox" className="m-2 large align-self-center li-sample-cb" name={"retrieval-cb-"+item.id}
                            aria-label="Checkbox" onChange={(e) => handleCBChanged(e,item)}
                            checked={checkedItems[item.id]??false}/>
                </td>
                <td>
                    <strong>{buildPickSheetSampleDetailsStr(item.barcode,item.caseNumber, item.sampleNumber,caseIsRepeated)}</strong>
                </td>
                <td>
                    {showMag && <span className="">
                            <i className="fa fa-caret-right mx-2 li-fg-primary"/>
                        {buildMagString(item.magBarcode)}
                        </span>
                    }
                </td>
                <td>
                    <span className=""><i className="fa fa-caret-right mx-2 li-fg-success"/>Section&nbsp;
                        <strong>{LIUtils.convertMagSectionIndexToAlpha(item.magSection)}</strong>
                    </span> / <strong>{item.positionAbsolute}</strong>
                </td>
                <td>
                    {showOrder && dumpOrderNumberAndDest(item.parentOrderId,showLocation?item.sentToBrief:"")}
                </td>
            </tr>
        );
    }
    
    function buildMagString(magBarcode,numSlides=null,numBlocks=null) {
        if(magBarcode===null) {
            return(<strong>Mag: none</strong>);
        }
        let slideNumbers="";
        let blockNumbers="";
        if(numSlides!==null && numSlides>0) {
            slideNumbers = <><span className="text-muted ml-5">Slides:</span> {numSlides}</>;
        } 
        if(numBlocks!==null && numBlocks>0) {
            blockNumbers=<><span className="text-muted ml-2">Blocks:</span> {numBlocks}</>;
        }
        const [year,box,position]=magBarcode.split(".");
        return <span className="li-bg-light p-1 rounded">
                <strong className="">Year</strong>: {year}
                <strong className="ml-2">Box:</strong> {box}
                <strong className="ml-2">Magazine:</strong> {position}
            {slideNumbers}{blockNumbers}
            </span>;
    } 
    
    function buildMagLocationString(locationName) {
        let locStr = locationName;
        if(locationName===null || locationName==="-") {
            locStr = "(default location)";
        }
        return  <td colSpan="5">
                    <h3>
                        <i className="fa fa-map-marked-alt li-fg-success mr-2"/>
                        <span className="font-weight-normal">Stored at: </span>
                        <span className="li-fg-dark li-font-medium">{locStr}</span>
                    </h3>
                </td>;
    }

    function buildOrderHeaderString(orderNumber,destination,numSlides=0,numBlocks=0) {
        let orderStr = LIUtils.prettyOrderNumber(orderNumber);
        let requestedBy = "-"
        if(orderNumber===null || orderNumber==="-") {
            orderStr = "-";
        }
        else { // get out requested by
            requestedBy = getOrderRequestedBy(orderNumber,globalState.picksheet.orders);
        }
        var numSlidesInfo = "";
        if(numSlides && numSlides>0) {
            numSlidesInfo = <><span className="text-muted">Slides:</span> {numSlides}</>
        }
        var numBlocksInfo = "";
        if(numBlocks && numBlocks>0) {
            numBlocksInfo = <><span className="text-muted ml-2">Blocks:</span> {numBlocks}</>
        }
        
        return (
            <td colSpan="4">
                <h3 className="d-flex justify-content-between">
                    <div>
                        <i className="fa fa-file-alt li-fg-success mr-2"/>
                        <span className="font-weight-normal">Order #: </span>{orderStr}
                        <div className="li-font-small text-right">Requested By: {requestedBy}</div>
                    </div>
                    <div>
                        {numSlidesInfo}
                        {numBlocksInfo}
                        
                    </div>
                    {dumpDestinationHeaderString(destination)}
                </h3>
               
            </td>
        );
    }

    function buildCaseHeaderString(caseNumber,numSlides=0,numBlocks=0) {
        let caseStr = String(caseNumber);
        if(caseNumber===null || caseNumber==="-") {
            caseStr = "-";
        }
        var numSlidesInfo = "";
        if(numSlides && numSlides>0) {
            numSlidesInfo = <><span className="text-muted">Slides:</span> {numSlides}</>
        }
        var numBlocksInfo = "";
        if(numBlocks && numBlocks>0) {
            numBlocksInfo = <><span className="text-muted ml-2">Blocks:</span> {numBlocks}</>
        }
        
        return (
            <td colSpan="4">
                <h3 className="d-flex justify-content-between">
                    <div>
                        <i className="fa fa-folder li-fg-success mr-2"/>
                        <span className="font-weight-normal">Case #: </span>{caseStr}
                    </div>
                    <div>
                        {numSlidesInfo}
                        {numBlocksInfo}
                    </div>
                </h3>

            </td>
        );
    }
    
    function dumpDestinationHeaderString(sendTo) {
        return (
            <span className="badge align-self-center li-bg-light p-3">{sendTo??"-"}</span>
        );
    }

    function showSamplesOrderedByLocationAndMag() {

        if(!isOrderingByLocation()) { // don't show any of this.
            return;
        }

        if(!samplesByMag || samplesByMag.length===0) {
            return <tr>
                <td colSpan="5">
                    <i className="fa fa-exclamation-circle li-fg-danger mr-2"/>No samples found to pick.
                </td>
            </tr>;
        }

        const retVal=[];
        let curMagBarcode = undefined;
        let curMagLocation = undefined;
        samplesByMag.forEach( (sample,index) => {
            if(curMagLocation!==sample.magLocationName) { // dump out the location change
                curMagLocation=sample.magLocationName;
                retVal.push(<tr className="mt-3 border-bottom" key={"LOC"+curMagBarcode}>
                    {buildMagLocationString(curMagLocation)}</tr>);
                curMagBarcode=undefined;
            }
            if(curMagBarcode!==sample.magBarcode) { // dump out the mag change
                curMagBarcode = sample.magBarcode;
                // this is a bit of a hack and slow -- but allows us to include the counts in the header
                retVal.push(constructOrderSamplesForMagBarcode(curMagBarcode));
            }
        });

        return retVal;
    }

    function constructOrderSamplesForMagBarcode(curMagBarcode) {
        const retVal=[];
        const slidesForMag = samplesByMag?.filter( item => item.magBarcode===curMagBarcode && item.sampleType===SAMPLE_TYPE_SLIDE)??[];
        const blocksForMag = samplesByMag?.filter( item => item.magBarcode===curMagBarcode && item.sampleType===SAMPLE_TYPE_BLOCK)??[];

        // header
        retVal.push(<tr className="mt-3 ml-5 li-bg-light" key={"M" + curMagBarcode}
                        style={{borderTop: "4px solid black"}}>
                    <td/>
                    <td colSpan="4">{buildMagString(curMagBarcode,slidesForMag.length,blocksForMag.length)}</td>
                    </tr>);


        let lastCaseNum=undefined;
        slidesForMag.forEach( (sample, index) => {
            let caseIsRepeated=true;
            if(lastCaseNum!==sample.caseNumber) {
                lastCaseNum=sample.caseNumber;
                caseIsRepeated=false;
            }
            if(sampleIsMarkedAsOutOrDeleted(sample)) {
                retVal.push(dumpSampleAsOut(sample,index,true, true, caseIsRepeated));
            }
            else {
                retVal.push(dumpSampleAsIn(sample,index,true,false, true, caseIsRepeated));
            }
        });

        lastCaseNum=undefined;
        blocksForMag.forEach( (sample, index) => {
            let caseIsRepeated=true;
            if(lastCaseNum!==sample.caseNumber) {
                lastCaseNum=sample.caseNumber;
                caseIsRepeated=false;
            }
            if(sampleIsMarkedAsOutOrDeleted(sample)) {
                retVal.push(dumpSampleAsOut(sample,index,true, true, caseIsRepeated));
            }
            else {
                retVal.push(dumpSampleAsIn(sample,index,true,false, true, caseIsRepeated));
            }
        });

        return retVal;
    }

    function showSamplesOrderedByOrderNumber() {
        if(!isOrderingByOrderNumber()) { // don't show any of this.
            return;
        }

        if(!samplesByOrderId || samplesByOrderId.length===0) {
            return <tr>
                <td colSpan="5">
                    <i className="fa fa-exclamation-circle li-fg-danger mr-2"/>No samples found to pick.
                </td>
            </tr>;
        }

        let retVal=[];
        let curOrderId = undefined;
        samplesByOrderId.forEach( (sample,index) => {
            if(curOrderId!==sample.parentOrderId) { // changing the current order
                curOrderId = sample.parentOrderId;
                // this is a bit of a hack and slow -- but allows us to include the counts in the header
                retVal.push(constructOrderSamplesForOrderId(curOrderId, sample.sentToBrief));
            }
        });

        return retVal;
    }
    
    function constructOrderSamplesForOrderId(curOrderId, locationShort) {
        const retVal=[];
        const slidesForOrder = samplesByOrderId?.filter( item => item.parentOrderId===curOrderId && item.sampleType===SAMPLE_TYPE_SLIDE)??[];
        const blocksForOrder = samplesByOrderId?.filter( item => item.parentOrderId===curOrderId && item.sampleType===SAMPLE_TYPE_BLOCK)??[];

        // header
        retVal.push(<tr className=" li-border-dark-top" key={"DEST"+curOrderId}>
            {buildOrderHeaderString(curOrderId,locationShort,slidesForOrder.length,blocksForOrder.length)}
        </tr>);
        
        // slides section
        let magBarcode=undefined
        let lastCaseNum=undefined;
        slidesForOrder.forEach( (sample, index) => {
            let caseIsRepeated=true;
            if(lastCaseNum!==sample.caseNumber) {
                lastCaseNum=sample.caseNumber;
                caseIsRepeated=false;
            }
            if(magBarcode!==sample.magBarcode) {
                if(magBarcode!==undefined) { // changing mags and not first one -- space
                    retVal.push(dumpInterMagSpacer("spacer"+index));
                }
                magBarcode=sample.magBarcode;
            }
            if(sampleIsMarkedAsOutOrDeleted(sample)) {
                retVal.push(dumpSampleAsOut(sample,index,false, true, caseIsRepeated));
            }
            else {
                retVal.push(dumpSampleAsIn(sample,index,false,true,true, caseIsRepeated));
            }
        });

        // blocks section
        magBarcode=undefined
        lastCaseNum=undefined;
        blocksForOrder.forEach( (sample, index) => {
            let caseIsRepeated=true;
            if(lastCaseNum!==sample.caseNumber) {
                lastCaseNum=sample.caseNumber;
                caseIsRepeated=false;
            }
            if(magBarcode!==sample.magBarcode) {
                if(magBarcode!==undefined) { // changing mags and not first one -- space
                    retVal.push(dumpInterMagSpacer("spacer"+index));
                }
                magBarcode=sample.magBarcode;
            }
            if(sampleIsMarkedAsOutOrDeleted(sample)) {
                retVal.push(dumpSampleAsOut(sample,index,false, true, caseIsRepeated));
            }
            else {
                retVal.push(dumpSampleAsIn(sample,index,false,true, true, caseIsRepeated));
            }
        });
        
        return retVal;
    }

    function showSamplesOrderedByCaseNumber() {
        if(!isOrderingByCaseNumber()) { // don't show any of this.
            return;
        }

        if(!samplesByCaseNumber || samplesByCaseNumber.length===0) {
            return <tr>
                <td colSpan="5">
                    <i className="fa fa-exclamation-circle li-fg-danger mr-2"/>No samples found to pick.
                </td>
            </tr>;
        }

        let retVal=[];
        let curCaseNumber = undefined;
        samplesByCaseNumber.forEach( (sample,index) => {
            if(curCaseNumber!==sample.caseNumber) { // changing the current caseNumber
                curCaseNumber = sample.caseNumber;
                // this is a bit of a hack and slow -- but allows us to include the counts in the header
                retVal.push(constructOrderSamplesForCaseNumber(curCaseNumber));
            }
        });

        return retVal;
    }

    function constructOrderSamplesForCaseNumber(curCaseNumber) {
        const retVal=[];
        const slidesForOrder = samplesByOrderId?.filter( item => item.caseNumber===curCaseNumber && item.sampleType===SAMPLE_TYPE_SLIDE)??[];
        const blocksForOrder = samplesByOrderId?.filter( item => item.caseNumber===curCaseNumber && item.sampleType===SAMPLE_TYPE_BLOCK)??[];

        // header
        retVal.push(<tr className=" li-border-dark-top" key={"DEST"+curCaseNumber}>
            {buildCaseHeaderString(curCaseNumber,slidesForOrder.length,blocksForOrder.length)}
        </tr>);

        // slides section
        let magBarcode=undefined
        let lastCaseNum=undefined;
        slidesForOrder.forEach( (sample, index) => {
            let caseIsRepeated=true;
            if(lastCaseNum!==sample.caseNumber) {
                lastCaseNum=sample.caseNumber;
                caseIsRepeated=false;
            }
            if(magBarcode!==sample.magBarcode) {
                if(magBarcode!==undefined) { // changing mags and not first one -- space
                    retVal.push(dumpInterMagSpacer("spacer"+index));
                }
                magBarcode=sample.magBarcode;
            }
            if(sampleIsMarkedAsOutOrDeleted(sample)) {
                retVal.push(dumpSampleAsOut(sample,index,true,true,caseIsRepeated));
            }
            else {
                retVal.push(dumpSampleAsIn(sample,index,true,true,true, caseIsRepeated));
            }
        });

        // blocks section
        magBarcode=undefined
        lastCaseNum=undefined;
        blocksForOrder.forEach( (sample, index) => {
            let caseIsRepeated=true;
            if(lastCaseNum!==sample.caseNumber) {
                lastCaseNum=sample.caseNumber;
                caseIsRepeated=false;
            }
            if(magBarcode!==sample.magBarcode) {
                if(magBarcode!==undefined) { // changing mags and not first one -- space
                    retVal.push(dumpInterMagSpacer("spacer"+index));
                }
                magBarcode=sample.magBarcode;
            }
            if(sampleIsMarkedAsOutOrDeleted(sample)) {
                retVal.push(dumpSampleAsOut(sample,index,true,true, caseIsRepeated));
            }
            else {
                retVal.push(dumpSampleAsIn(sample,index,true,true,true, caseIsRepeated));
            }
        });

        return retVal;
    }

    function dumpTotalSampleCounts() {
        return (
            <div className="p-0 m-0 medium text-right">
                <div className="p-0 m-0">Total Slides: <strong>{sampleCounts[0]}</strong></div>
                <div className="p-0 m-0">Total Blocks: <strong>{sampleCounts[1]}</strong></div>
            </div>
        );
    }

    function dumpSelectAll() {
        return(
            <div>
                <input type="checkbox" className="mt-4 ml-2 large align-self-center li-sample-cb" name={"retrieval-cb-all"}
                       aria-label="Checkbox" onChange={(e) => handleSelectAllCBChanged(e)}
                />
                <span className="ml-3">Select All Samples</span>
            </div>

        );
    }
    
    //#endregion OUTPUT_FUNCTIONS
    
    //#region HELPERS
    
    function getOrderRequestedBy(orderNumber, orders):string {
        const order = orders.find( item => item.id===orderNumber);
        if(order) {
            return order?.requester?.fullName??"-";
        }
        return "-";
    }
    
    function markSamplesAsRemoved(sampleIds:[]) {
        sampleIds.forEach(removedSampleId => {
            const s = samplesByMag.find(sample => sample.id === removedSampleId);
            if (s) {
                s.magBarcode = "";  // fake it's out
                s.datePickCompleted = Date.now();       // fake it - just something not null
            }
        });
        setCheckedItems({});

        let notPicked = samplesByMag.filter(s => (s.magBarcode && s.magBarcode !== "") || s.datePickCompleted === null);
        if (notPicked.length===0) {
            // we are part of the Popup() so we need to use the ErrorPopup here -- hack.
            ErrorPopup.showInfo("", "Pick Sheet is Complete", () => {
                setRedirectTo("/retrievals");
            });
        }
    }

    function markSamplesAsAlreadyOut(sampleIds:[]) {
        sampleIds.forEach(alreadyOutSampleId => {
            const s = samplesByMag.find(sample => sample.id === alreadyOutSampleId);
            if (s) {
                s.magBarcode = "";          // fake it's out
                s.datePickCompleted = null;
            }
        });
        setCheckedItems({});
    }
        
    function doRemoveSamplesAtServer(ids) {
        setIsLoading(true);
        ArchiverClientApi.sendPickSamplesRequest(ids)
        .then( ({samplesRemoved, samplesAlreadyOut}) => {
            markSamplesAsRemoved(samplesRemoved);
            markSamplesAsAlreadyOut(samplesAlreadyOut);
            if(samplesAlreadyOut && samplesAlreadyOut.length>0) {
                ErrorPopup.showError("Samples Already Out", 
                        `<p>There were ${samplesAlreadyOut.length} samples already marked as OUT and could not be picked.</p>`+
                    "<p>These samples remain unchecked in the pick sheet.</p>"
                );
            }
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsLoading(false);
        });
    }
    
    function isOrderingByLocation() {
        return orderBy===SORT_ORDER_MAG_LOCATION;
    }

    function isOrderingByOrderNumber() {
        return orderBy===SORT_ORDER_ORDER_NUMBER;
    }

    function isOrderingByCaseNumber() {
        return orderBy===SORT_ORDER_CASE_NUMBER;
    }

    function haveCheckedNonDisabledSamples(checkedItemsList) {
        const ids = Object.keys(checkedItemsList);
        const nonDisabled = ids?.filter(i => {
            const v = document.getElementsByName("retrieval-cb-" + i);
            if(!v || v.length===0) return true;  // true == keep
            const disabled = v[0].disabled;
            return !disabled;  // keep

        })??[];
        return nonDisabled.length>0;
    }

    function sampleIsMarkedAsOutOrDeleted(item) {
        if(!item || !item.magBarcode || item.magBarcode==="") {
            return true; // out
        }
        return !!item.isDeleted;
    }

    function countSlideSamples(samples) {
        if(!samples){
            return 0;
        }
        return samples.reduce( (prevVal, sampleItem) => {
            if (sampleItem.sampleType === SAMPLE_TYPE_SLIDE) { // SLIDE TYPE
                return prevVal + 1;
            }
            return prevVal;
        },0);
    }

    function countBlockSamples(samples) {
        if(!samples){
            return 0;
        }
        return samples.reduce( (prevVal, sampleItem) => {
            if (sampleItem.sampleType === SAMPLE_TYPE_BLOCK) { // BLOCK TYPE
                return prevVal + 1;
            }
            return prevVal;
        },0);
    }
    
    //#endregion HELPERS
    
    if(redirectTo) {
        return (
            <Redirect push to={redirectTo}/>
        );
    }
    
    return(
        <div className="container li-font-medium">
            <Header/>
            <div className="li-header-row my-4">
                <BackButton/>
                <h1 className="ml-2 d-inline-block align-middle">Sample Pick Sheet</h1>
            </div>
            
            <div className="row m-0 p-0 justify-content-between">
                <div>
                    <h3>Date: {LIUtils.dateToShortDateTimeString(pickSheetDate)}</h3>
                    <p className="li-font-small m-0 p-0"><strong>Generated By:</strong> {globalState.clientInfo.fullName}</p>
                </div>
                <div className="li-font-med-small">
                    <span>Sort by</span>
                    <span className={"btn-link li-pointer ml-1 "+(isOrderingByLocation()?"font-weight-bold":"")} onClick={() => setOrderBy(SORT_ORDER_MAG_LOCATION)}>Mag Location</span> |
                    <span className={"btn-link li-pointer ml-1 "+(isOrderingByOrderNumber()?"font-weight-bold":"")} onClick={() => setOrderBy(SORT_ORDER_ORDER_NUMBER)}>Order #</span> |
                    <span className={"btn-link li-pointer ml-1 "+(isOrderingByCaseNumber()?"font-weight-bold":"")} onClick={() => setOrderBy(SORT_ORDER_CASE_NUMBER)}>Case #</span>
                </div>
            </div>
            
            <div className="row m-0 p-0 justify-content-between">
                {dumpSelectAll()}
                {dumpTotalSampleCounts()}
            </div>
            {/* SHOW SAMPLES ORDERED BY... */}
            {isLoading && <LoadingSpinner medium center/>}
            <table className="table li-picksheet-table li-font-med-small my-3 w-100">
                <tbody>
                    {!isLoading && showSamplesOrderedByLocationAndMag()}
                    {!isLoading && showSamplesOrderedByOrderNumber()}
                    {!isLoading && showSamplesOrderedByCaseNumber()}
                </tbody>
            </table>

            {/* MARK ACTION BUTTONS */}
            <div className="row justify-content-around">
                <button className="li-button li-primary li-font-medium px-4" disabled={!aSampleIsSelected} 
                        onClick={handleMarkSamplesOut}>
                    Mark Samples Picked
                </button>
                <button className="li-button li-primary li-font-medium px-5" onClick={handlePrintPage}>
                    Print
                </button>
            </div>
            
            <Footer/>
        </div>
    );
}

export default PickSheetPage;