
import React, {useEffect} from "react";
import LoginPage from "./COMPONENTS/LoginPage";
import {Route, Switch} from "react-router";
import SignoutPage from "./COMPONENTS/SignOutPage";
import DashboardPage from "./COMPONENTS/DashboardPage";
import AdminPage from "./COMPONENTS/AdminPage";
import SearchCasesPage from "./COMPONENTS/SearchCasesPage";
import SearchSamplesPage from "./COMPONENTS/SearchSamplesPage";
import CaseDetailsPage from "./COMPONENTS/CaseDetailsPage";
import SampleDetailsPage from "./COMPONENTS/SampleDetailsPage";
import CaseInventoryPage from "./COMPONENTS/CaseInventoryPage";
import RetrievalsPage from "./COMPONENTS/RetrievalsPage";
import ChaseListPage from "./COMPONENTS/ChaseListPage";
import AttentionPage from "./COMPONENTS/AttentionPage";
import ReportsPage from "./COMPONENTS/ReportsPage";
import SampleEditPage from "./COMPONENTS/SampleEditPage";
import PickSheetPage from "./COMPONENTS/PickSheetPage";
import AddUpdateROPage from "./COMPONENTS/AddUpdateROPage";
import WatchListPage from "./COMPONENTS/WatchListPage";
import PrintLabelsPage from "./COMPONENTS/PrintLabelsPage";
import SearchMagsPage from "./COMPONENTS/SearchMagsPage";
import MagDetailsPage from "./COMPONENTS/MagDetailsPage";
import RetrievalSamplesPage from "./COMPONENTS/RetrievalSamplesPage";
import RunReportPage from "./COMPONENTS/RunReportPage";

// COMMON GUI STUFF
import ProtectedRoute from "./GUI_COMMON/COMPONENTS/ProtectedRoute";
import Popup from "./GUI_COMMON/COMPONENTS/Popup";
import ErrorPopup from "./GUI_COMMON/COMPONENTS/ErrorPopup";
import UserAdminPage from "./COMPONENTS/ADMIN/UserAdminPage";
import LocationsAdminPage from "./COMPONENTS/ADMIN/LocationsAdminPage";
import DepartmentsAdminPage from "./COMPONENTS/ADMIN/DepartmentsAdminPage";
import DevicesAdminPage from "./COMPONENTS/ADMIN/DevicesAdminPage";
import SetDeviceProfilesAdminPage from "./COMPONENTS/ADMIN/SetDeviceProfilesAdminPage";
import ProfileAdminPage from "./COMPONENTS/ADMIN/ProfileAdminPage";
import ProfileIRAdminPage from "./COMPONENTS/ADMIN/ProfileIRAdminPage";
import ProfilePRAdminPage from "./COMPONENTS/ADMIN/ProfilePRAdminPage";
import ReportAdminPage from "./COMPONENTS/ADMIN/ReportAdminPage";
import MagRegisterAdminPage from "./COMPONENTS/ADMIN/MagRegisterAdminPage";
import SearchBarcodesPage from "./COMPONENTS/SearchBarcodesPage";
import NotificationsPage from "./COMPONENTS/NotificationsPage";
import ConsumablesPage from "./COMPONENTS/ConsumablesPage";
import NotifyTimer from "./COMPONENTS/NotifyTimer";

const App = () => {
    
    useEffect( () => {
        console.debug("app (main) starting up...");
        return () => {
            console.debug("app (main) shutting down...");
        }
    },[])
    
    return (
        <>
            <Switch>
                <Route path="/signin" push><LoginPage/></Route>
                <Route path="/signout"><SignoutPage/></Route>

                <ProtectedRoute path="/dashboard"><DashboardPage/></ProtectedRoute>
                <ProtectedRoute path="/adminPage"><AdminPage/></ProtectedRoute>
                
                <ProtectedRoute path="/case_search/:searchTerm"><SearchCasesPage/></ProtectedRoute>
                <ProtectedRoute path="/case_search"><SearchCasesPage/></ProtectedRoute>
                <ProtectedRoute path="/sample_search/:searchTerm"><SearchSamplesPage/></ProtectedRoute>
                <ProtectedRoute path="/sample_search"><SearchSamplesPage/></ProtectedRoute>
                <ProtectedRoute path="/mag_search/:searchTerm"><SearchMagsPage/></ProtectedRoute>
                <ProtectedRoute path="/mag_search"><SearchMagsPage/></ProtectedRoute>
                <ProtectedRoute path="/barcode_search/:searchTerm"><SearchBarcodesPage/></ProtectedRoute>
                <ProtectedRoute path="/barcode_search"><SearchBarcodesPage/></ProtectedRoute>
                
                <ProtectedRoute path="/mag_details/:magBC"><MagDetailsPage/></ProtectedRoute>
                <ProtectedRoute path="/case_details/:caseNum"><CaseDetailsPage/></ProtectedRoute>
                <ProtectedRoute path="/case_inventory/:caseId/:caseNum"><CaseInventoryPage/></ProtectedRoute>
                <ProtectedRoute path="/sample_details/:sampleId"><SampleDetailsPage/></ProtectedRoute>
                <ProtectedRoute path="/retrievals" exact><RetrievalsPage/></ProtectedRoute>
                <ProtectedRoute path="/retrievals/new"><AddUpdateROPage/></ProtectedRoute>
                <ProtectedRoute path="/retrievals/details/:orderId"><AddUpdateROPage/></ProtectedRoute>
                <ProtectedRoute path="/retrievals/samples/:orderId"><RetrievalSamplesPage/></ProtectedRoute>
                <ProtectedRoute path="/pick_sheet"><PickSheetPage/></ProtectedRoute>
                <ProtectedRoute path="/chase"><ChaseListPage/></ProtectedRoute>
                <ProtectedRoute path="/attention"><AttentionPage/></ProtectedRoute>
                <ProtectedRoute path="/attention_edit/:sampleId"><SampleEditPage/></ProtectedRoute>
                <ProtectedRoute path="/reports"><ReportsPage/></ProtectedRoute>
                <ProtectedRoute path="/watch"><WatchListPage/></ProtectedRoute>
                <ProtectedRoute path="/print"><PrintLabelsPage/></ProtectedRoute>
                <ProtectedRoute path="/run_report/:reportId"><RunReportPage/></ProtectedRoute>
                <ProtectedRoute path="/notifications"><NotificationsPage/></ProtectedRoute>
                <ProtectedRoute path="/consumables" level="CONSUMABLE_ADMIN"><ConsumablesPage/></ProtectedRoute>

                {/* ADMIN PAGES */}
                <ProtectedRoute path="/admin/users"><UserAdminPage/></ProtectedRoute>
                <ProtectedRoute exact path="/admin/locations"><LocationsAdminPage/></ProtectedRoute>
                <ProtectedRoute path="/admin/locations/:locationId"><LocationsAdminPage/></ProtectedRoute>
                <ProtectedRoute path="/admin/depts/:locationId"><DepartmentsAdminPage/></ProtectedRoute>
                <ProtectedRoute exact path="/admin/devices"><DevicesAdminPage/></ProtectedRoute>
                <ProtectedRoute path="/admin/devices/:deviceId"><DevicesAdminPage/></ProtectedRoute>
                <ProtectedRoute path="/admin/set_profiles/:deviceId"><SetDeviceProfilesAdminPage/></ProtectedRoute>
                <ProtectedRoute exact path="/admin/profiles"><ProfileAdminPage/></ProtectedRoute>
                <ProtectedRoute path="/admin/profiles/:profileId"><ProfileAdminPage/></ProtectedRoute>
                <ProtectedRoute exact path="/admin/irs/:profileId"><ProfileIRAdminPage/></ProtectedRoute>
                <ProtectedRoute path="/admin/irs/:profileId/:irId"><ProfileIRAdminPage/></ProtectedRoute>
                <ProtectedRoute path="/admin/prs/:profileId/:irId"><ProfilePRAdminPage/></ProtectedRoute>
                <ProtectedRoute exact path="/admin/reports"><ReportAdminPage/></ProtectedRoute>
                <ProtectedRoute path="/admin/reports/:reportId"><ReportAdminPage/></ProtectedRoute>
                <ProtectedRoute exact path="/admin/mag_register"><MagRegisterAdminPage/></ProtectedRoute>
            
                <Route><LoginPage/></Route>
            </Switch>

            <Popup useFade/>
            <ErrorPopup/>
            <NotifyTimer/>
        </>
    );
}

export default App;
