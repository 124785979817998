import React, {useContext, useEffect, useState} from 'react';
import {Prompt, Redirect, useHistory, useParams} from "react-router-dom";
import Header from "../Header";
import BackButton from "../../GUI_COMMON/COMPONENTS/BackButton";
import TextInput from "../../GUI_COMMON/COMPONENTS/TextInput";
import LIButton from "../../GUI_COMMON/COMPONENTS/LIButton";
import Popup from "../../GUI_COMMON/COMPONENTS/Popup";
import ArchiverClientApi from "../../API/archiverClientApi";
import ErrorPopup from "../../GUI_COMMON/COMPONENTS/ErrorPopup";
import DigitInput from "../../GUI_COMMON/COMPONENTS/DigitInput";
import {StoreContext} from "../../store";
import {gotRemoveIRFromProfile, gotUpdateProfile} from "../../ACTIONS/clientActions";
import ArchiverGuiUtils from "../../SUPPORT/ArchiverGuiUtils";

const ProfileIRAdminPage = () => {

    /**
     * these are hardcoded into the archiver -- don't change
     * @type {[{deviceType: number, name: string},{deviceType: number, name: string},{deviceType: number, name: string}]}
     */
    const DECODE_METHODS = [
        {decodeMethod:1,name:"DataMatrix Barcode"},
        {decodeMethod:2,name:"Linear Barcode"},
        /*{decodeMethod:3,name:"OCR"},
        {decodeMethod:4,name:"Color Swatch"},*/
        {decodeMethod:5,name:"PDF417 Barcode"},
        {decodeMethod:6,name:"QR Code"},
    ];
    
    const [globalState, dispatch] = useContext(StoreContext);
    
    const [curIRDetails, setCurIRDetails] = useState(getDefaultIRDetails());
    const [fullIRsList, setFullIRsList] = useState([]);
    const [someProfileDetails, setSomeProfileDetails] = useState({});
    
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, ] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const [redirectToPage, setRedirectToPage] = useState();
    
    const history = useHistory();
    const {profileId,irId} = useParams();           // set in the url
    
    useEffect(() => {
        console.debug("refreshing profiles list");
        if (globalState.profiles && globalState.profiles.initialized) {
            setSomeOfOurProfileDetails(Number(profileId),globalState.profiles.items);
            const irsList = getIRsListForProfile(Number(profileId),globalState.profiles.items);
            setFullIRsList(irsList);
            setIsDirty(false);
        }
    }, [globalState.profiles]);   // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        if(fullIRsList && fullIRsList.length>0) {
            let preselectId = irId?Number(irId):null;
            if(fullIRsList.length===1) { // always preselect the only one in a list if only 1
                preselectId = fullIRsList[0].id;
            }
            doRealListItemClicked(preselectId);  // preselect if the url has soemthign in it.
        }
    },[fullIRsList]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    function getDefaultIRDetails():{} {
        return(
            {
                id: null,
                name: "",
                hint: null,
                decodeMethod: -1,
                finalResize: null,
                postProcessing: null,
                
                cropTop: 0,             // crop breakdowns
                cropLeft: 0,
                cropBottom: 600,
                cropRight: 800,
            }
        );
    }
    
    function getIRsListForProfile(profileId, systemProfilesList:[]):[] {
        const ourProfile = systemProfilesList.find(item => item.id===profileId);
        if(!ourProfile) {
            return([]);
        }
        const irsList = ourProfile.inspectionRegions;
        return(irsList);
    }
    
    function setSomeOfOurProfileDetails(profileId, systemProfilesList:[]) {
        const ourProfile = systemProfilesList.find(item => item.id===profileId);
        if(!ourProfile) {
            return([]);
        }
        setSomeProfileDetails({id: ourProfile.id, name: ourProfile.name});
    }
    
    function handleCancelClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        confirmClearDirty();
    }
    
    function confirmClearDirty() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetIRDetailsShown();
                });
        }
        else {
            resetIRDetailsShown(); // just clear what's there
        }
    }
    
    function handleDeleteIRClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        Popup.showConfirm("Delete Region?",`<p>This will remove inspection region '${curIRDetails.name}' from the system.</p>`+
                                "<p>Are you sure you want to do this?</p>","Yes, Delete it",null,
            () => {
               doIRRemove(curIRDetails.name,curIRDetails.id, profileId);
            });
    }
   
    function doIRRemove(irName, irId, profileId) {
        setIsRemoving(true);
        ArchiverClientApi.sendInspectionRegionRemove(profileId, irId)
        .then(() => {
            Popup.showInfo(`Inspection Region '${irName}' removed`);
            // removeFromFullIRList(irId);
            dispatch(gotRemoveIRFromProfile(profileId,irId));
            resetIRDetailsShown();
        })
        .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally(() => {
            setIsRemoving(false);
        });
    }
    
    function handleSaveClicked(e,action) {
        e.preventDefault();
        e.stopPropagation();
        
        if(!checkForValid()) {
            return;
        }
       
        setIsSaving(true);
        ArchiverClientApi.sendInspectionRegionUpdate(profileId, fixValues())
        .then( (savedProfileInfo) => {
            Popup.showInfo(`Region '${curIRDetails.name}' Saved`);
            setIsDirty(false);
            if(action==="ADD") {
                // addToFullIRList(savedProfileInfo);
                dispatch(gotUpdateProfile(savedProfileInfo));
                resetIRDetailsShown();
            }
            else if(action==="UPDATE") {
                //updateExistingIR(savedProfileInfo, curIRDetails.id);
                dispatch(gotUpdateProfile(savedProfileInfo));
            }
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
           setIsSaving(false); 
        });
    }
    
    function checkForValid():boolean {
        let isValid=true;
        let notValidMsg="";
        
        const cropMessage = isCropRectValid();
        if(!curIRDetails || curIRDetails.name==="") {
            isValid=false;
            notValidMsg="Name needs to be provided";
        }
        else if(curIRDetails.decodeMethod===-1 || curIRDetails.decodeMethod==="-1") {
            isValid=false;
            notValidMsg="You need to choose a decode method";
        }
        else if(cropMessage!=="") {
            isValid=false;
            notValidMsg=cropMessage;
        }
        else if(!isFinalResizeValid()) {
            isValid=false;
            notValidMsg="Final Resize needs to be 'width,height' non-zero or completely blank";
        }
       
        if(!isValid) {
            ErrorPopup.showError("Validation Problem",notValidMsg);
            return false;
        }
        return true;

    }
    
    function isFinalResizeValid():boolean {
        const finalResize = curIRDetails.finalResize;
        if(!finalResize || finalResize==="" || finalResize===null) {
            return true; // ok
        }
        const parts = finalResize.split(',');
        if(parts.length!==2) {
            return false; // missing parts
        }
        const width = Number(parts[0]);
        const height = Number(parts[1]);
        if(height<0 || isNaN(height) || width<0 || isNaN(width)) {
            return false;  // 0, negative or empty
        }
        
        return true; // ok
    }
    
    function isCropRectValid() {
        
        const top = Number(curIRDetails.cropTop);
        const left = Number(curIRDetails.cropLeft);
        const bottom = Number(curIRDetails.cropBottom);
        const right = Number(curIRDetails.cropRight);
        
        if(curIRDetails.cropTop==="" || top<0) {
            return("Cropping area TOP value needs to be non-blank and positive");
        }
        if(curIRDetails.cropLeft==="" || left<0) {
            return("Cropping area LEFT value needs to be non-blank and positive");
        }
        if(curIRDetails.cropBottom==="" || bottom<0 || bottom<top) {
            return("Cropping area BOTTOM value needs to be non-blank, positive, and bigger than TOP value");
        }
        if(curIRDetails.cropRight==="" || right<0 || right<left) {
            return("Cropping area RIGHT value needs to be non-blank,positive, and bigger than LEFT value");
        }
        return ""; // all good
    }

    function fixValues():{} {
        const editInfo = {...curIRDetails};
        
        // individual editted values need to be brought together for the API
        editInfo.cropTopLeft = `${curIRDetails.cropLeft},${curIRDetails.cropTop}`;
        editInfo.cropBottomRight = `${curIRDetails.cropRight},${curIRDetails.cropBottom}`;

        editInfo.decodeMethod=Number(editInfo.decodeMethod); // number version
        
        if(editInfo.finalResize==="" || editInfo.finalResize==="0,0") {
            editInfo.finalResize=null; // really empty
        }
        
        return editInfo;
    }
    
    // function addToFullIRList(profileDetails) {
    //     // we ACTUALLY just replace the entire list since that's easier and
    //     // the profileDetails contains all of our inspections regions.
    //     const curList = profileDetails.inspectionRegions;
    //     curList.sort( (a,b) => {
    //         if(a.name < b.name) return -1;
    //         if(a.name > b.name) return 1;
    //         return 0;
    //     });
    //     setFullIRsList(curList);
    // }

    // function removeFromFullIRList(userId) {
    //     const curList = fullIRsList.filter( item => item.id !== userId);
    //     setFullIRsList(curList);
    // }
    
    // function updateExistingIR(profileDetails, thisIRId) {
    //     // entire profile comes in here.
    //     const curList = [...fullIRsList];
    //     const thisIRLocalIndex = curList.findIndex(item => item.id===thisIRId);
    //     curList[thisIRLocalIndex]=profileDetails.inspectionRegions.find(item => item.id === thisIRId);
    //     setFullIRsList(curList);
    // }
    //
    function onChange(e) {
        const thing = e.target.name;
        const editInfo = {...curIRDetails};
        if(e.target.type==="checkbox") { // this is a checkbox
            editInfo[thing] = e.target.checked;
        }
        else {
            let value = e.target.value;
            
            // Digit only boxes
            if(thing==="holdsSampleType") {  // number
                value = Number(value);
            }
            else if(thing==="destinationMagProfileId") {  // number
                value = Number(value);
            }
            
            // selection drop downs
            if(thing==="type") {
                value = Number(value);
            }
            editInfo[thing] = value;
        }
        setCurIRDetails(editInfo);
        setIsDirty(true);
    }
    
    function showIRsList(listContents:[]) {

        if(isLoading) {
            return <div className="pr-4 w-30 li-border-right-light">...loading...</div>;
        }
        
        let resultList = listContents?.map((item,index) => {
                return <li key={index} className={"list-group-item li-pointer"}
                    onClick={() => handleListItemClicked(item)}>
                    {item.name}
                </li>;
            }
        );
        if(!resultList || resultList.length===0) {
            resultList = "no regions found";
        }

        return (
            <div className="pr-4 w-30 li-border-right-light">
                <h4>Inspection Regions</h4>
                <div className="li-scrollable-at-500 ">
                    <ul className="list-group-flush li-list-group-w-hover pl-0">
                        {resultList}
                    </ul>
                    <div className="li-border-line-light"/>
                </div>
                <LIButton key="deleteIR" small primary label="" bgClass={isRemoving?"m-3":"fa fa-trash m-3"} disabled={curIRDetails?.id===null}
                          onClick={handleDeleteIRClicked} showBusy={isRemoving}/>
            </div>
        );
    }

    function dumpDecodeMethodOptionsList() {
        return DECODE_METHODS.map(item => <option key={item.decodeMethod} value={item.decodeMethod}>{item.name}</option>);
    }
    
    function handleListItemClicked(item) {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetIRDetailsShown();
                    doRealListItemClicked(item?.id);
                });
        }
        else {
            doRealListItemClicked(item?.id);
        }
    }
    
    function doRealListItemClicked(itemId) {
        const ir = fullIRsList.find(i => i.id===itemId);
        if(!ir) {
            resetIRDetailsShown();
            return;
        }
       
        const afterIR = setupIRIndividualCrops(ir);        // update
        setCurIRDetails(afterIR);
        
        updateUrlLink(`/admin/irs/${profileId}/${ir.id}`);
    }
    
    function setupIRIndividualCrops(ir) { 
        // this is used to break cropTopLeft and cropBottomRight
        // into individual parts to make editting easier and more reliable
        let parts = ir.cropTopLeft.split(',');
        ir.cropLeft = parts[0];
        ir.cropTop = parts[1];
        parts = ir.cropBottomRight.split(',');
        ir.cropRight = parts[0];
        ir.cropBottom = parts[1];
        
        return ir;
    }
    
    function updateUrlLink(toThis) {
        history.replace(toThis);
    }
    
    function resetIRDetailsShown() {
        setCurIRDetails(getDefaultIRDetails());
        setIsDirty(false);
        updateUrlLink(`/admin/irs/${profileId}`); // blank for this profile
    }
    
    function handleEditPRClicked() {
        const parentIRId = curIRDetails.id;
        setRedirectToPage(`/admin/prs/${profileId}/${parentIRId}`);
    }
    
    function handleBackClicked() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    setRedirectToPage(`/admin/profiles/${profileId}`);
                });
        }
        else {
            setRedirectToPage(`/admin/profiles/${profileId}`);
        }
    }

    if (redirectToPage) {
        return <Redirect to={redirectToPage}/>;
    }
    
    return (
        <div className="container">
            <Prompt
                when={isDirty}
                message={ () => "You have unsaved changes.  Really leave?"}
            />
            <Header hideAdmin/>
            <div className="li-header-row">
                <div>
                    <BackButton onClick={handleBackClicked}/>
                    <h1 className="ml-4 d-inline-block align-middle">Inspection Region Administration</h1>
                </div>
            </div>

            <div className="row m-0 p-0 justify-content-between">
                <div className="li-font-medium font-weight-bold">
                Parent Profile: 
                <span className="li-fg-primary ml-2">{someProfileDetails.name}</span>
                </div>
                <span className=" li-fg-slightly-muted li-font-small text-right">[Id:{someProfileDetails.id??"-"}]</span>
            </div>
            <div className="li-border-line-light my-4"/>
            
            <div className="row m-0 p-0">
                {/* LEFT SIDE USER LIST */}
                {showIRsList(fullIRsList)}

                {/* RIGHT SIDE FORM */}
                <div className="pl-4 w-60">
                    <div className="li-fg-slightly-muted li-font-small text-right">[Id:{curIRDetails?.id??"-"}]</div>
                    <div className="row p-0 m-0 justify-content-between">
                        {/* right side, row #1 */}
                        <TextInput name="name" label="Name" onChange={(e)=>onChange(e)}
                                   placeholder="" textLeft
                                   value={curIRDetails?.name??""}
                                   widthPels="370"
                                   required/>
                    </div>
                    { /* right side, row #2 */}

                    <label htmlFor="type" className="li-form-label mr-3">Decode Method</label>
                    <select onChange={onChange} id="decodeMethod" name="decodeMethod" value={curIRDetails.decodeMethod??-1}
                            className="li-form-field no-width align-self-end li-border-dark" required={true}>
                        <option value={-1}>(select a method)</option>
                        {dumpDecodeMethodOptionsList()}
                    </select>
                    {/* right side, row #3 */}
                    <TextInput name="hint" label="Processor Hint" value={curIRDetails?.hint??""} readonly={false}
                              onChange={(e)=>onChange(e)} oneRow
                                helpText="* the value here depends on the options available in the selected decode method"/>

                    {/* right side, row #4 */}
                    <div className="li-form-label border-top pt-3">Cropping Rectangle</div>
                    <small className="form-text text-muted">* these define the rectangle coordinates used for
                                        the inspection region, based on the dimensions of the full camera Image.</small>
                    <div className="row m-0 p-0 justify-content-between">
                        <div className="col-3">
                            <DigitInput name="cropLeft" label="Left" onChange={(e)=>onChange(e)}
                                        placeholder="" maxLength={4} minLength={4}
                                        value={curIRDetails?.cropLeft??0}/>
                            </div>
                        <div className="col-3">
                            <DigitInput name="cropTop" label="Top" onChange={(e)=>onChange(e)}
                                        placeholder="" maxLength={4} minLength={4} 
                                        value={curIRDetails?.cropTop??0}/>
                        </div>
                            <div className="col-3">
                                <DigitInput name="cropRight" label="Right" onChange={(e)=>onChange(e)}
                                            placeholder="" maxLength={4} minLength={4}
                                            value={curIRDetails?.cropRight??600}/>
                            </div>
                        <div className="col-3">
                            <DigitInput name="cropBottom" label="Bottom" onChange={(e)=>onChange(e)}
                                        placeholder="" maxLength={4} minLength={4} 
                                        value={curIRDetails?.cropBottom??800}/>
                        </div>
                    </div>

                    {/* right side, row #5 */}
                    <div className="mt-2">
                        <TextInput name="finalResize" label="Final Resize" onChange={(e)=>onChange(e)}
                                    placeholder="width,height" maxLength={9} minLength={3} widthPels={350}
                                    value={curIRDetails?.finalResize??""} oneRow/>
                        <small className="form-text text-muted">* optional width,height used in resizing the region to a given size before
                            passing off to camera decode (may help in decode effort required)</small>
                    </div>

                    {/* right side, row #6 */}
                    <div className="my-5 text-center">
                        <LIButton name="processingRules" label="Edit Processing Rule" bgClass="w-75" secondary 
                                  disabled={curIRDetails?.id===null || !curIRDetails.postProcessing || isDirty}
                                  onClick={handleEditPRClicked}/>
                    </div>
                    
                    {/* BUTTON ROW */}
                    <div className="text-center my-5">
                        <LIButton name="cancel" label={isDirty?"Cancel":"Clear"} bgClass="mr-4" primary onClick={handleCancelClicked}/>
                        <LIButton name="save" label={curIRDetails?.id===null?"ADD":"UPDATE"} secondary disabled={!isDirty || isSaving} 
                                  onClick={(e)=>handleSaveClicked(e,curIRDetails?.id===null?"ADD":"UPDATE")} showBusy={isSaving}/>
                    </div>
                </div>
            </div>

            
        </div>
    );
}

ProfileIRAdminPage.propTypes = {
   
};

export default ProfileIRAdminPage;