import React, {useEffect, useRef, useState} from "react";
import Header from "./Header";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import ArchiverClientApi from "../API/archiverClientApi";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import {useCookies} from "react-cookie";
import {Redirect} from "react-router-dom";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import DashboardButton from "../GUI_COMMON/COMPONENTS/DashboardButton";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import Footer from "./Footer";
import TextInput from "../GUI_COMMON/COMPONENTS/TextInput";
import LIButton from "../GUI_COMMON/COMPONENTS/LIButton";

const ReportsPage = () => {
    
    const [initialized, setInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [reportsList, setReportsList] = useState([]);
    const [redirectToPush, setRedirectToPush] = useState();
    
    const [cookies] = useCookies();
    const textFilterRef = useRef(null);
    const [filteredReportsList, setFilteredReportsList] = useState(null);
    
    useEffect( () => {
        if(!initialized) {
            if (!LIUtils.UserHasLevel(cookies, "REPORTER")) {
                Popup.show("", "<p>You are not a REPORTER type user.</p>" +
                    "Talk to your Archiver Administrator if you wish to change this.", () => {
                    setRedirectToPush("/dashboard");
                });
                return;
            }
            
            setIsLoading(true);
            ArchiverClientApi.getReportsList()
            .then( ({reports}) => {
                setInitialized(true);
                setReportsList(reports);
            })
            .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
            .finally( () => {
                setIsLoading(false);
            });
        }
    },[]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    function runReport(reportId) {
       setRedirectToPush(`/run_report/${reportId}`);
    }
    
    function dumpReportsList() {
        if(isLoading) {
            return <LoadingSpinner medium center/>;
        }
        
        if(!LIUtils.existsAndNotEmpty(reportsList)) {
            return <div className="text-center">No Reports Found</div>;
        }

        const showingReportsList = filteredReportsList??reportsList;
        if(showingReportsList.length===0) {
            return <div className="text-left my-4 font-italic font-weight-bold">No Reports Found</div>;
        }
        const resultList=showingReportsList.map( (item,index) => {
            const iconName = (item.iconName===""?"fa-chart-bar":item.iconName);
            return(<div className="col-sm-6 offset-sm-0 col-md-4" key={index}>
                        <DashboardButton subTitle={item.name} icon={"fa-2x "+iconName} onClick={(e)=>runReport(item.id)} title=""/>
                    </div>);
        });
        
        return(
            <div className="li-dash-body-bg row p-0">
                {resultList}
            </div>
        );
    }

    function handleClearFilterClicked() {
        if(textFilterRef.current) {
            textFilterRef.current.value = "";
        }
        setFilteredReportsList(null);
    }

    function dumpDataFilter() {
        return (
                <div className="input-group">
                    <TextInput refName={textFilterRef} name="textFilter" label="&nbsp;" placeholder="report filter" oneRow textLeft
                               maxLength={10} onChange={handleDataFilterChanged}/>
                    <div className="input-group-append">
                        <LIButton bgClass="mt-3 ml-1" faIcon="fa fa-times-circle" small primary onClick={handleClearFilterClicked}/>
                    </div>
                </div>
        );
    }

    function handleDataFilterChanged() {
        if(textFilterRef.current) {
            const filterText = textFilterRef.current.value;
            if(filterText==="") {
                setFilteredReportsList(null);
            }
            else {
                const filtered = reportsList.filter( row => {
                        return row["name"].toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
                    
                });
                setFilteredReportsList(filtered);
            }
        }
    }

    if(redirectToPush) {
        return <Redirect push to={redirectToPush}/>;
    }
    
    return(
        <div className="container">
            <Header/>
            <div className="li-header-row my-4">
                <div className="row m-0 p-0 justify-content-between">
                    <div>
                        <BackButton/>
                        <h1 className="d-inline-block align-middle">Reports</h1>
                    </div>
                    <div className="mr-4">
                        {dumpDataFilter()}
                    </div>
                </div>
            </div>
            
            {dumpReportsList()}
            <Footer/>
        </div>
    );
}

export default ReportsPage;