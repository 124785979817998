
import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function clientReducer(state = initialState.clientInfo, action) {

    switch (action.type) {
        case types.SET_CLIENT_INFO:
            return {
                userId: Number(action.userId),
                fullName: action.fullName,
                level: action.level
            };
        default:
            return(state)
    }
}

