import * as types from "../ACTIONS/actionTypes";
import initialState from "./initialState";

export default function profilesReducer(state = initialState.profiles, action) {

    switch (action.type) {

        case types.GOT_PROFILES_LIST:
            return {
                initialized: true,
                items: action.profiles
            };
        case types.ADD_NEW_PROFILE: {
            const updatedProfiles = [...state.items, action.profile];
            return {
                initialized: true,
                items: updatedProfiles
            };
        }
        case types.REMOVE_PROFILE: {
            const updatedProfiles = state.items.filter(p => p.id !== action.profileId);
            return {
                initialized: true,
                items: updatedProfiles
            };
        }
        case types.UPDATE_PROFILE: {
            const updatedProfiles = state.items.filter(p => p.id !== action.profile.id);
            updatedProfiles.push(action.profile); // read add.
            return {
                initialized: true,
                items: updatedProfiles
            };
        }
        case types.REMOVE_IR_FROM_PROFILE: {
            const profileId = Number(action.profileId);
            const parentProfile = state.items.find(p => p.id===profileId);
            // kill the removed IR from the list
            parentProfile.inspectionRegions = parentProfile.inspectionRegions.filter(ir => ir.id !== action.irId);
            const updatedProfiles = state.items.filter(p => p.id !== profileId);
            updatedProfiles.push(parentProfile);
            
            return {
                initialized: true,
                items: updatedProfiles
            };
        }
            
        default:
            return state;
    }
}
    