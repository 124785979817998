import PropTypes from "prop-types";


const HeaderBadge = ({title, danger, light, primary, icon, medium}) => {

    let extraClass="";
    if(danger) {
        extraClass="li-bg-danger";
    }
    if(light) {
        extraClass="li-bg-light";
    }
    if(primary) {
        extraClass="li-bg-primary li-fg-white";
    }

    return(
        <div className={"row py-1 px-3 rounded m-3 "+extraClass}>
            {icon && <i className={"fa fa-2x mt-1 mr-2 "+icon}/>}
            {!medium && <h2 className="align-self-center">{title}</h2>}
            {medium && <h4 className="mt-1">{title}</h4>}
        </div>
    );
}

HeaderBadge.propTypes = {
    title: PropTypes.string.isRequired,
    danger: PropTypes.bool,
    light: PropTypes.bool,
    primary: PropTypes.bool,
    icon: PropTypes.string,
    medium: PropTypes.bool,
}

export default HeaderBadge;