
//import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function adminReducer(state = initialState.adminInfo, action) {

    switch (action.type) {
        default:
            return(state)
    }
}

