import AdminButton from "../GUI_COMMON/COMPONENTS/AdminButton";
import AuthButton from "../GUI_COMMON/COMPONENTS/AuthButton";
import React, {useContext, useEffect, useState} from "react";
import {StoreContext} from "../store";
import {useHistory} from "react-router";
import * as PropTypes from "prop-types";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import {useCookies} from "react-cookie";
import {Redirect, useLocation} from "react-router-dom";
import {gotServerInfo, setClientInfo} from "../ACTIONS/clientActions";
import {setupProfiles} from "../SUPPORT/startup";
import CommonClientApi from "../GUI_COMMON/API/commonClientApi";
import NotificationButton from "./NotificationButton";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import {checkAndReportWwwAppUpdate} from "../SUPPORT/update";

const Header = ({hideAdmin}) => {
    
    const [globalState, dispatch] = useContext(StoreContext);
    const [redirectTo, setRedirectTo] = useState();
    const [redirectWithPushTo, setRedirectWithPushTo] = useState();
    const location = useLocation();
    const history = useHistory();
    const [cookies,] = useCookies();
    
    useEffect(() => {
        console.debug("header starting up...");
        if (globalState?.serverInfo.initialized === false) {
            CommonClientApi.getServerInfo()
            .then(({name, version, demoMode, requiredDbVersion, dbVersion, localTimeUtc}) => {
                dispatch(gotServerInfo(name, version, demoMode, requiredDbVersion, dbVersion, localTimeUtc));
            })
            .catch(() => { 
                console.error("failed to get server info");
            });
            checkAndReportWwwAppUpdate();
        }
    }, [globalState.serverInfo.initialized]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // ensure the token is available after refreshes.
        if (!globalState.clientInfo || globalState.clientInfo.userId === -1) {

            // HERE WE RE-REQUEST EVERYTHING NEEDED FROM THE SERVER IN THE EVENT OF A USER REFRESH
            CommonClientApi.setupHeader(cookies.liToken);
            dispatch(setClientInfo(cookies.liId, cookies.liFullName, cookies.liType));
        }

    }, [globalState.clientInfo]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (LIUtils.isAuthenticatedHook(cookies) && globalState.profiles.initialized !== true) {
            setupProfiles(dispatch);
        }
    }, [globalState.profiles.initialized]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    /**
     * get all profiles from the server and setup the global store with them.
     */
    
    function handleSignoutClicked() {
        history.push("/signout");
    }

    function handleAdminClicked() {
        history.push("/adminPage");
    }

    function redirectToDashboard() {
        if (!location.pathname.startsWith("/dashboard")) {
            setRedirectTo("/dashboard");
        }
    }

    function redirectToNotifications() {
        if (!location.pathname.startsWith("/notifications")) {
            setRedirectWithPushTo("/notifications");
        }
    }

    function showToDashboardButtonIfRequired() {
        if (location.pathname.indexOf("dashboard") === -1) { // show back
            return (<i className="mr-2 li-fg-white fa fa-angle-double-left align-baseline"/>);
        }
        return null;
    }

    function showAdminButtonIfRequired() {
        if (hideAdmin) {
            return;
        }
        if (!LIUtils.UserHasLevel(cookies, "SERVICE") && !LIUtils.UserHasLevel(cookies, "ADMIN")) {
            return;
        }

        return (<AdminButton adminClicked={handleAdminClicked}/>);
    }

    function showNotificationIconIfRequired() {
        if (globalState.notifications.numNotifs >0) {
            return <div className="mr-3">
                <NotificationButton numUnread={globalState.notifications.numUnread} numNotifs={globalState.notifications.numNotifs} onClick={redirectToNotifications}/>
            </div>;
        }
    }

    if (redirectTo) {
        return <Redirect to={redirectTo}/>;
    }

    if (redirectWithPushTo) {
        return <Redirect push to={redirectWithPushTo}/>;
    }

    if (LIUtils.isAuthenticatedHook(cookies) === false) {
        Popup.show("Session Expired","<p>Your session has expired.</p><p>Please dismiss this dialog to login again.</p>",
            () => history.push("/signout")
        );
    }

    return (<>
            <div className="row justify-content-between mt-2 li-page-header-bg">
                <div className="col li-font-large d-inline font-weight-bold p-0 m-0 ml-2">
                    <div className="li-pointer d-inline-block" onClick={redirectToDashboard}>
                        {showToDashboardButtonIfRequired()}
                        <div className="d-inline">{globalState.serverInfo.name}</div>
                    </div>
                </div>
                <div className="col align-self-center">
                    <div className="row m-0 p-0 justify-content-between">
                        <div className="col">
                            {showAdminButtonIfRequired()}
                        </div>
                        {showNotificationIconIfRequired()}
                        <AuthButton user={globalState.clientInfo?.fullName} signOutClicked={handleSignoutClicked}/>
                    </div>
                </div>
            </div>
            <div className="mt-1 li-border-line-light w-100 mb-2"/>
        </>);
}

Header.propTypes = {
    hideAdmin: PropTypes.bool
}

export default Header;