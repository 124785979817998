import React, {useContext, useEffect, useState} from "react";
import Header from "./Header";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import {StoreContext} from "../store";
import ArchiverClientApi from "../API/archiverClientApi";
import {Redirect} from "react-router-dom";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import Footer from "./Footer";
import {gotWatchList, setWatchItemChecked} from "../ACTIONS/clientActions";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import WatchListCardRow from "./WatchListCardRow";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const WatchListPage = () => {

    const [globalState, dispatch] = useContext(StoreContext);
    const [isLoading, setIsLoading] = useState(false);
    const [redirectTo, setRedirectTo] = useState();
    const [watchList,setWatchList] = useState([]);

    useEffect(() => {
        console.debug("WatchListPage starting up...");
        if(!globalState.watchList.watchListRaw || globalState.watchList.watchListRaw.length===0) { // get it
            setIsLoading(true);
            ArchiverClientApi.getWatchList(0, 20)
            .then(({totalItems,watchItems}) => {
                dispatch(gotWatchList(watchItems,totalItems));
            })
            .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
            .finally( () => setIsLoading(false));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        if(globalState.watchList) {
            setWatchList(globalState.watchList.watchListRaw);
        }
    }, [globalState.watchList]);
    
    function handleGotoSample(sampleId) {
        setRedirectTo(`/sample_details/${sampleId}`);
    }
    
    function showResultsList() {
        if (!watchList || watchList.length === 0) {
            return <div className="card text-center">none</div>;
        }

        const resultRows = watchList.map((item, index) => {
                return <WatchListCardRow key={item.sampleId} watchItem={item} isChecked={item.isChecked} 
                                         onCheckChanged={(isChecked) => handleSampleCheckChanged(item,isChecked)} onSampleClick={() => handleGotoSample(item.sampleId)}/>
            }
        );

        return (
            <div id="watchList" className="">
                {resultRows}
            </div>
        );
    }
    
    function handleSampleCheckChanged(watchItem,isChecked) {
        dispatch(setWatchItemChecked(watchItem,isChecked));
    }

    function numSamplesChecked():boolean {
        let numChecked = 0;
        watchList.forEach( item => {if(item.isChecked) numChecked++ });
        return numChecked;
    }
    
    if(redirectTo) {
        return (
            <Redirect push to={redirectTo}/>
        );
    }
    
    function handleRemoveSelected(e) {
        e.preventDefault();
        e.stopPropagation();

        const checkedList = watchList?.filter(item => item.isChecked)??[];
        const idsOnly = checkedList.map(item => item.sampleId);

        setIsLoading(true);
        ArchiverClientApi.sendRemoveWatchesRequest(idsOnly)
        .then(({totalInList, removedIdList}) => {
            removeWatchSamples(removedIdList,totalInList);
            Popup.show("", `Removed ${removedIdList?.length} samples.`);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsLoading(false));
    }
    
    function removeWatchSamples(sampleIdList,totalItems) {
        const afterList = watchList?.filter(item => !sampleIdList.includes(item.sampleId))??[];
        dispatch(gotWatchList(afterList,totalItems));
    }
    
    function dumpWatchList() {
        let numChecked = numSamplesChecked();
        return(
            <>
                <div className="row li-header-row m-0 p-0 justify-content-between">
                    <div>
                        <BackButton/>
                        <h1 className="d-inline-block align-middle">Watch List</h1>
                    </div>
                </div>
                <div className="text-right my-1">
                    Samples Selected: {numChecked} / {(globalState.watchList.totalSamples)}</div>
                <div className="">
                    {isLoading && <LoadingSpinner medium center/>}
                    {!isLoading && showResultsList()}
                </div>

                <div className="mt-3 my-5">
                    <button className="li-button li-primary" onClick={handleRemoveSelected} disabled={numChecked===0}>Delete Selected Watches</button>
                </div>
            </>
        );
    }

    return(
        <div className="container">
            <Header/>
            {dumpWatchList()}
            <Footer/>
        </div>
    );
}

export default WatchListPage;