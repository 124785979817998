import React, {useState} from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

const SearchButtonList = ({activeChoice}) => {

    const [redirectTo, setRedirectTo] = useState();
    
    if(redirectTo) {
        return (
            <Redirect push to={redirectTo}/>
        );
    }
    
    function showClickableOrReadonly(label, destination) {
        if(activeChoice && label===activeChoice) {
            return (
                <button className="li-button li-bg-dark li-fg-white mr-2" disabled><i className="fa fa-search mr-2"/>{label}</button>
            );
        }
        return(
            <button className="li-button li-primary li-pointer mr-2"
                    onClick={() => setRedirectTo(destination)}><i className="fa fa-search mr-2"/>{label}</button>
        );
    }
    
    return(
        <div className="my-4 mr-2">
            {showClickableOrReadonly("samples","/sample_search")}
            {showClickableOrReadonly("cases","/case_search")}
            {showClickableOrReadonly("magazines","/mag_search")}
            {showClickableOrReadonly("barcodes","/barcode_search")}
        </div>
    );
}

SearchButtonList.propTypes = {
    activeChoice: PropTypes.string,
}

export default SearchButtonList;