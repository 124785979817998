export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const ERRORED_AJAX_CALL = 'ERRORED_AJAX_CALL';

export const CLEAR_STATE_ON_LOGOUT = "CLEAR_STATE_ON_LOGOUT";

export const SET_CLIENT_INFO = "SET_CLIENT_INFO";
export const GOT_STAT_UPDATE = "GOT_STAT_UPDATE";

export const GOT_CASE_HISTORY="GOT_CASE_HISTORY";
export const CLEAR_CASE_HISTORY="CLEAR_CASE_HISTORY";
export const SET_CASE_BASIC_INFO="SET_CASE_BASIC_INFO";
export const CLEAR_CASE_BASE_INFO="CLEAR_CASE_BASE_INFO";

export const GOT_SAMPLE_HISTORY="GOT_SAMPLE_HISTORY";
export const CLEAR_SAMPLE_HISTORY="CLEAR_SAMPLE_HISTORY";
export const SET_SAMPLE_BASIC_INFO="SET_SAMPLE_BASIC_INFO";
export const CLEAR_SAMPLE_BASE_INFO="CLEAR_SAMPLE_BASE_INFO";
export const GOT_NUM_NEEDS_ATTENTION="GOT_NUM_NEEDS_ATTENTION";
export const GOT_NUM_NEEDS_CHASING="GOT_NUM_NEEDS_CHASING";

export const GOT_PROFILES_LIST = "GOT_PROFILES_LIST";
export const ADD_NEW_PROFILE = "ADD_NEW_PROFILE";
export const REMOVE_PROFILE = "REMOVE_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const REMOVE_IR_FROM_PROFILE = "REMOVE_IR_FROM_PROFILE";

export const GOT_UNPICKED_RETRIEVAL_ORDERS="GOT_UNPICKED_RETRIEVAL_ORDERS";
export const GOT_COMPLETED_RETRIEVAL_ORDERS="GOT_COMPLETED_RETRIEVAL_ORDERS";
export const GOT_UNSENT_RETRIEVAL_ORDERS="GOT_UNSENT_RETRIEVAL_ORDERS";
export const GOT_SAMPLE_PICK_SHEET="GOT_SAMPLE_PICK_SHEET";

export const GOT_LOCATIONS_LIST="GOT_LOCATIONS_LIST";

export const GOT_CONTACTS_FOR_DEPARTMENT="GOT_CONTACTS_FOR_DEPARTMENT";

export const GOT_CHASELIST="GOT_CHASELIST";
export const SET_CHASE_ITEM_CHECKED="SET_CHASE_ITEM_CHECKED";

export const GOT_WATCHLIST="GOT_WATCHLIST";
export const SET_WATCH_ITEM_CHECKED="SET_WATCH_ITEM_CHECKED";
export const CLEAR_WATCH_LIST="CLEAR_WATCH_LIST";

export const GOT_NUM_WATCHES="GOT_NUM_WATCHES";

export const GOT_NUM_RETRIEVALS="GOT_NUM_RETRIEVALS";
export const MARKED_RO_AS_SENT="MARKED_RO_AS_SENT";

export const GOT_NUM_EVENTS="GOT_NUM_EVENTS";

export const GOT_SERVER_INFO="GOT_SERVER_INFO";

export const GOT_NOTIFICATIONS = "GOT_NOTIFICAITONS";
export const GOT_NUM_NOTIFICATIONS = "GOT_NUM_NEW_NOTIFICATIONS";
export const GOT_NOTIFICATION_STATUS_UPDATE = "GOT_NOTIFICATION_STATUS_UPDATE";
export const GOT_NOTIFICATION_DELETE = "GOT_NOTIFICATION_DELETE";

export const SAVE_SEARCH_RESULTS = "SAVE_SEARCH_RESULTS";
export const REMOVE_NA_SAMPLE_FROM_SEARCH_RESULTS = "REMOVE_NA_SAMPLE_FROM_SEARCH_RESULTS";


