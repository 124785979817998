import React, {useContext, useEffect, useRef, useState} from 'react';
import {Prompt, Redirect, useHistory, useParams} from "react-router-dom";
import Header from "../Header";
import BackButton from "../../GUI_COMMON/COMPONENTS/BackButton";
import TextInput from "../../GUI_COMMON/COMPONENTS/TextInput";
import LIButton from "../../GUI_COMMON/COMPONENTS/LIButton";
import Popup from "../../GUI_COMMON/COMPONENTS/Popup";
import ErrorPopup from "../../GUI_COMMON/COMPONENTS/ErrorPopup";
import CheckInput from "../CheckInput";
import DigitInput from "../../GUI_COMMON/COMPONENTS/DigitInput";
import TextArea from "../../GUI_COMMON/COMPONENTS/TextArea";
import {StoreContext} from "../../store";
import LIUtils, {SAMPLE_TYPE_BLOCK, SAMPLE_TYPE_SLIDE} from "../../GUI_COMMON/SUPPORT/LIUtils";
import CommonClientApi from "../../GUI_COMMON/API/commonClientApi";
import ArchiverClientApi from "../../API/archiverClientApi";
import {gotAddNewProfile, gotRemoveProfile, gotUpdateProfile} from "../../ACTIONS/clientActions";
import ArchiverGuiUtils from "../../SUPPORT/ArchiverGuiUtils";

const ProfileAdminPage = () => {

    /**
     * these are hardcoded into the archiver -- don't change
     */
    const PROFILE_TYPE_MAG=0;
    const PROFILE_TYPE_SAMPLE=1;
    const PROFILE_TYPES = [
        {type:PROFILE_TYPE_MAG,name:"MAG"},
        {type:PROFILE_TYPE_SAMPLE,name:"SAMPLE"},
    ];
    const SAMPLE_TYPES = [
        {type:SAMPLE_TYPE_SLIDE,name:"SLIDE"},
        {type:SAMPLE_TYPE_BLOCK,name:"BLOCK"}
    ];
    const MAX_IMAGE_SIZE_BYTES = 65535;

    const [globalState, dispatch] = useContext(StoreContext);
    
    const [curProfileDetails, setCurProfileDetails] = useState(getDefaultProfileDetails());
    const [fullProfilesList, setFullProfilesList] = useState([]);
    
    const [isDirty, setIsDirty] = useState(false);
    const [imageChangedTo, setImageChangedTo] = useState({dataBytes:null, newId:null});
    const [isLoading, ] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const [redirectToPage, setRedirectToPage] = useState();
    
    const history = useHistory();
    const fileOpenRef = useRef(null);
    const {profileId} = useParams();           // set in the url
    
    useEffect( () => {
        // add our change listener for the file uploader here
        const fileRefHolder = fileOpenRef.current;
        fileRefHolder.removeEventListener("change",uploadImageFileSelected);
        fileRefHolder.addEventListener("change", uploadImageFileSelected, false);
        return( () => { // unmount
            if(fileRefHolder) {
                fileRefHolder.removeEventListener("change", uploadImageFileSelected);
            }
        });
    },[fileOpenRef]);
    
    useEffect(() => {
        console.debug("refreshing profiles list");
        if(globalState.profiles && globalState.profiles.initialized) {
            setFullProfilesList(globalState.profiles.items);
            setIsDirty(false);
        }
    }, [globalState.profiles]); 
    
    useEffect( () => {
        if(fullProfilesList && fullProfilesList.length>0) {
            doRealListItemClicked(profileId?Number(profileId):null);  // preselect if the url has soemthign in it.
        }
    },[fullProfilesList]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => { // our image has been changed (for current profile)
        if(imageChangedTo?.newId>0) {
            onChangeSampleImage(imageChangedTo);
        }
    },[imageChangedTo]); // eslint-disable-line react-hooks/exhaustive-deps
    
    function getDefaultProfileDetails():{} {
        return(
            {
                id: null,
                name: "",
                comment: "",
                enabled: true,
                holdsSampleType: null,
                destinationMagProfileId: null,
                barcodesAreUnique: false,
                isCaseMappedExternal: false,
                isSampleNumMappedLater: false,
                inspectionRegions: [],
                sampleImage: {dataBytes:"",isEmpty:true, numBytes:0, save:true}, // fake
                type: -1,
            }
        );
    }
    
    function handleCancelClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        confirmClearDirty();
    }
    
    function confirmClearDirty() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetProfileDetailsShown();
                });
        }
        else {
            resetProfileDetailsShown(); // just clear what's there
        }
    }
    
    function handleDeleteProfileClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        Popup.showConfirm("Delete Profile?",`<p>This will remove '${curProfileDetails.name}' completely from the system,`+
                                " including any inspection regions and post processing rules associated with it.</p>"+
                                "<p>Any devices using the profile will continue to use the profile until they are restarted.</p>"+
                                "<p>Are you sure you want to do this?</p>","Yes, Delete it",null, 
            () => {
               doProfileRemove(curProfileDetails.name,curProfileDetails.id);
            });
    }
   
    function doProfileRemove(profileName, profileId) {
        setIsRemoving(true);
        ArchiverClientApi.sendProfileRemove(profileId)
        .then(() => {
            Popup.showInfo(`Profile '${profileName}' removed`);
            dispatch(gotRemoveProfile(profileId));
            resetProfileDetailsShown();
        })
        .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally(() => {
            setIsRemoving(false);
        });
    }
    
    function handleSaveClicked(e,action) {
        e.preventDefault();
        e.stopPropagation();
        
        if(!checkForValid()) {
            return;
        }
        
        setIsSaving(true);
        ArchiverClientApi.sendProfileUpdate(fixValues(), imageChangedTo.newId)
        .then( (savedProfileInfo) => {
            Popup.showInfo(`Profile '${curProfileDetails.name}' Saved`);
            setIsDirty(false);
            setImageChangedTo({dataBytes:null, newId:null});  // clear
            if(action==="ADD") {
                dispatch(gotAddNewProfile(savedProfileInfo));
            }
            else if(action==="UPDATE") {
                dispatch(gotUpdateProfile(savedProfileInfo));
            }
            
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
           setIsSaving(false); 
        });
    }
    
    function checkForValid():boolean {
        let isValid=true;
        let notValidMsg="";
        if(curProfileDetails.name==="") {
            isValid=false;
            notValidMsg="Name needs to be non-blank";
        }
        if(curProfileDetails.type==="" || curProfileDetails.type===-1) {
            isValid=false;
            notValidMsg="Profile Type needs to be selected";
        }
        
        if(!isValid) {
            ErrorPopup.showError("Validation Problem",notValidMsg);
            return false;
        }
        return true;

    }
    
    function fixValues():{} {
        const editInfo = {...curProfileDetails};
        
        if(editInfo.destinationMagProfileId<0) {
            editInfo.destinationMagProfileId=null; // none
        }
        
        return editInfo;
    }
    
    function onChange(e) {
        const thing = e.target.name;
        const editInfo = {...curProfileDetails};
        if(e.target.type==="checkbox") { // this is a checkbox
            editInfo[thing] = e.target.checked;
        }
        else {
            let value = e.target.value;
            
            // Digit only boxes
            if(thing==="holdsSampleType") {  // number
                value = Number(value);
            }
            else if(thing==="destinationMagProfileId") {  // number
                value = Number(value);
                if(value===0) { // BUGFIX: AR-27 - pott 230906
                    value="";
                }
            }
            
            // selection drop downs
            if(thing==="type") {
                value = Number(value);
            }
            editInfo[thing] = value;
        }
        setCurProfileDetails(editInfo);
        setIsDirty(true);
    }
    
    function onChangeSampleImage(imageChangedToInfo) {
        const editInfo = {...curProfileDetails};
        editInfo.sampleImage= {
            dataBytes: imageChangedToInfo.dataBytes,
            isEmpty: false,
            numBytes: imageChangedToInfo.dataBytes.length
        };
        setCurProfileDetails(editInfo);
        
        // reset the filename so we can do it again  *** IMPORTANT IF SAME FILE IS SELECTED AGAIN
        fileOpenRef.current.value="";
        
        setIsDirty(true);
    }
    
    function showProfilesList(listContents:[]) {

        if(isLoading) {
            return <div className="pr-4 w-30 li-border-right-light">...loading...</div>;
        }
        
        listContents.sort( (a,b) => {
           if(a.type<b.type) return -1;
           if(a.type>b.type) return 1;
           return 0;
        });
        
        let resultList = listContents?.map((item,index) => {
                let extraClass = item.enabled ? "" : " li-fg-muted";
                if(item.type===PROFILE_TYPE_MAG) {
                    extraClass += " li-fg-blue";
                }
                return <li key={index} className={"list-group-item li-pointer "+extraClass}
                    onClick={() => handleListItemClicked(item)}>
                    <span className="font-weight-bold mr-2">{item.type===PROFILE_TYPE_MAG?"[M]":null}</span>
                    {item.name}
                </li>;
            }
        );
        if(!resultList || resultList.length===0) {
            resultList = "no profiles found";
        }

        return (
            <div className="pr-4 w-30 li-border-right-light">
                <h4>Profiles</h4>
                <div className="li-scrollable-at-500 ">
                    <ul className="list-group-flush li-list-group-w-hover pl-0">
                        {resultList}
                    </ul>
                    <div className="li-border-line-light"/>
                </div>
                <LIButton small primary label="" bgClass={isRemoving?"m-3":"fa fa-trash m-3"} disabled={curProfileDetails?.id===null}
                          onClick={handleDeleteProfileClicked} showBusy={isRemoving}/>
            </div>
        );
    }

    function dumpProfileTypeOptionsList() {
        return PROFILE_TYPES.map(item => <option key={item.type} value={item.type}>{item.name}</option>);
    }

    function dumpSampleTypeOptionsList() {
        return SAMPLE_TYPES.map(item => <option key={item.type} value={item.type}>{item.name}</option>);
    }
    
    function handleListItemClicked(item) {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetProfileDetailsShown();
                    doRealListItemClicked(item?.id);
                });
        }
        else {
            doRealListItemClicked(item?.id);
        }
    }
    
    function doRealListItemClicked(itemId) {
        const profile = fullProfilesList.find(i => i.id===itemId);
        if(!profile) {
            resetProfileDetailsShown();
        }
        else {
            setCurProfileDetails(profile);
            updateUrlLink(`/admin/profiles/${profile.id}`);
        }
    }
    
    function updateUrlLink(toThis) {
        history.replace(toThis);
    }
    
    function resetProfileDetailsShown() {
        setCurProfileDetails(getDefaultProfileDetails());
        setImageChangedTo({dataBytes:null, newId:null}); // reset
        setIsDirty(false);
        updateUrlLink("/admin/profiles"); // blank
    }
    
    function handleEditIRsClicked() {
        const profileId = curProfileDetails.id;
        setRedirectToPage(`/admin/irs/${profileId}`);
    }
    
    function getIRLabel() {
        const numIRs = curProfileDetails?.inspectionRegions?.length??0;
        return(
            `Inspection Regions (${numIRs})`
        );
    }
    
    function handleBackClicked() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    setRedirectToPage("/adminPage");
                });
        }
        else {
            history.goBack();
        }
        
    }
    
    function dumpHoldsSampleType() {
        // APPLIES ONLY TO MAG TYPE PROFILES
        if(curProfileDetails.type!==PROFILE_TYPE_MAG) {
            return null;
        }
        return(
            <>
                <label htmlFor="type" className="li-form-label mr-3">Mag Holds Sample Type</label>
                <select onChange={onChange} id="holdsSampleType" name="holdsSampleType" value={curProfileDetails.holdsSampleType??-1}
                    className="li-form-field no-width align-self-end li-border-dark" required={true}>
                    <option value={-1}>(select a type)</option>
                    {dumpSampleTypeOptionsList()}
                </select>
                <small className="form-text text-muted">* determines what type of sample this magazine can accept</small>
            </>
        );
    }
    
    function dumpDestMagProfile() {
        // APPLIES ONLY TO SAMPLE TYPE PROFILES
        if(curProfileDetails.type!==PROFILE_TYPE_SAMPLE) {
            return null;
        }
        let destMagProfileValue = curProfileDetails?.destinationMagProfileId??"";
        if(destMagProfileValue===-1 || destMagProfileValue==="-1") {
            destMagProfileValue=""; // stay consistent with "" == none.
        }
        return(
            <DigitInput name="destinationMagProfileId" label="Destination Mag Profile" onChange={(e)=>onChange(e)}
                       placeholder="" textLeft
                       value={destMagProfileValue}
                       required oneRow minLength={1} maxLength={4} helpText="* leave empty for 'any mag'"/>
        );
    }
    
    function dumpSampleTypeOptions() {
        // APPLIES ONLY TO SAMPLE TYPE PROFILES
        if(curProfileDetails.type!==PROFILE_TYPE_SAMPLE) {
            return null;
        }
        
        return (
            <div className="card card-body li-bg-light">
                <div className="mt-3">
                    <CheckInput label="Barcodes Are Unique" name="barcodesAreUnique"
                                checked={curProfileDetails.barcodesAreUnique} onChanged={(e)=>onChange(e)} 
                                helpLink="admin/profile_admin.html#barcodes_are_unique"
                                helpText="* samples missing case or sample # will generate duplicates if barcodes are unique"/>
                </div>
                <div className="mt-3">
                    <CheckInput label="Case and Sample Are Mapped Externally" name="isCaseMappedExternal"
                                checked={curProfileDetails.isCaseMappedExternal} onChanged={(e)=>onChange(e)}
                                helpLink="admin/profile_admin.html#is_case_mapped_external"
                                helpText="* The external SlideMapper will need to be configured to watch for this profile too!"/>
                </div>
                <div className="mt-3">
                    <CheckInput label="Sample # can be missing until retrieval" name="isSampleNumMappedLater"
                                checked={curProfileDetails.isSampleNumMappedLater} onChanged={(e)=>onChange(e)}
                                helpLink="admin/profile_admin.html#is_samplenum_mapped_later"
                                helpText="* the sample # will need to be manually added prior to any retrievals"/>
                </div>
            </div>
        );
        
    }
    
    function handleUploadImage(e) {
        e.preventDefault();
        e.stopPropagation();
        
        fileOpenRef.current.click(); // open the file picker
    }
    function uploadImageFileSelected(e) {
       
        const files = e.target.files;
        if(files.length===0) {
            return;
        }
        const firstFile = files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
            const dataBytes = e.target.result;
            const readyState = e.target.readyState;
            console.debug("ready state is '"+readyState+"'");
            const numBytes = e.total;
            console.debug("file size='"+numBytes+"' bytes");
            if(numBytes > MAX_IMAGE_SIZE_BYTES) { // currently the limit of our db images column.
                ErrorPopup.showError("Image Too Large", `Image needs to be < ${MAX_IMAGE_SIZE_BYTES} bytes`)
                return; // bail.
            }

            // ### NOTE: this may not read all at once... issue?
            // ### Seems to be ok at the moment locally.
            const encodedBytes = btoa(dataBytes);
            CommonClientApi.uploadImageAsSampleImage(encodedBytes)
            .then( (newId:number) => {
                setImageChangedTo({dataBytes:encodedBytes, newId:newId}); // *** DONT DO MUCH HERE EXTERNAL
                Popup.show("Image Uploaded","<p>The new image has been uploaded to the Archiver</p>" +
                    "<p>*** You still need to SAVE your profile below ***</p>");
            })
            .catch( responseError => ErrorPopup.showError("Upload Problem", `Failed to upload image.  Details: ${responseError.error}.`));
        }
        reader.readAsBinaryString(firstFile);
        console.debug(`image file selected was ${firstFile.name}`);
    }
    function showSampleImageAndButton() {
        
        return(
            <div className="">
                <label htmlFor="sampleImage" className="li-form-label mr-3">Profile Image</label>
                <div className="row m-0 p-0 ml-5 mt-2">
                    {LIUtils.getImgFromImageData(curProfileDetails.sampleImage?.dataBytes,null,150)}
                    <LIButton bgClass="ml-5" medium secondary label={"Upload New Image"+(imageChangedTo?.newId>0?" *":"")}
                              onClick={handleUploadImage}/>
                </div>
            </div>
        );
    }
    
    if (redirectToPage) {
        return <Redirect to={redirectToPage}/>;
    }
    
    return (
        <div className="container">
            <Prompt
                when={isDirty}
                message={ () => "You have unsaved changes.  Really leave?"}
            />
            <Header hideAdmin/>
            <div className="li-header-row">
                <BackButton onClick={handleBackClicked}/>
                <h1 className="ml-4 d-inline-block align-middle">Profile Administration</h1>
            </div>
            
            <div className="row">
                {/* LEFT SIDE PICK LIST */}
                {showProfilesList(fullProfilesList)}

                {/* RIGHT SIDE FORM */}
                <div className="pl-4 w-60">
                    <div className="li-fg-slightly-muted li-font-small text-right">[Id:{curProfileDetails?.id??"-"}]</div>
                    <div className="row m-0 p-0 justify-content-end mt-2">
                        <CheckInput label="Enabled" name="enabled" checked={curProfileDetails.enabled} 
                                    onChanged={(e)=>onChange(e)}/>
                    </div>
                    <div className="row p-0 m-0 justify-content-between">
                    {/* right side, row #1 */}
                        <TextInput name="name" label="Name" onChange={(e)=>onChange(e)}
                                   placeholder="" textLeft maxLength={32}
                                   value={curProfileDetails?.name??""}
                                   required/>
                        <div className="align-self-end">
                        <label htmlFor="type" className="li-form-label mr-3">Profile Type</label>
                        <select onChange={onChange} id="type" name="type" value={curProfileDetails.type??-1}
                                className="li-form-field no-width align-self-end li-border-dark" required={true}>
                            <option value={-1}>(select a type)</option>
                            {dumpProfileTypeOptionsList()}
                        </select>
                        </div>
                    </div>
                    {/* right side, row #3 */}
                    <TextArea name="comment" label="Notes" value={curProfileDetails?.comment??""} readonly={false} 
                              onChange={(e)=>onChange(e)} rows={2} maxLength={60}/>

                    {/* right side, row #4 */}
                    {dumpHoldsSampleType()} 
                    {dumpDestMagProfile()}
                    {dumpSampleTypeOptions()}
                    {showSampleImageAndButton()}
                    
                    {/*#region INSPECTION_REGIONS */}
                    <div className="li-border-line-light my-4"/>
                    <div className="text-center">
                        <LIButton name="inspection_regions" label={getIRLabel()} bgClass="w-75" secondary 
                                  disabled={curProfileDetails?.id===null || isDirty}
                                  onClick={handleEditIRsClicked}/>
                    </div>
                    {/*endregion INSPECTION_REGIONS*/}
                    
                    {/* BUTTON ROW */}
                    <div className="text-center my-5">
                        <LIButton key="cancel" name="cancel" label={isDirty?"Cancel":"Clear"} bgClass="mr-4" primary onClick={handleCancelClicked}/>
                        <LIButton key="save" name="save" label={curProfileDetails?.id===null?"ADD":"UPDATE"} secondary disabled={!isDirty || isSaving} 
                                  onClick={(e)=>handleSaveClicked(e,curProfileDetails?.id===null?"ADD":"UPDATE")} showBusy={isSaving}/>
                    </div>
                </div>
                {/* our file uploader */}
                <input type="file" ref={fileOpenRef} id="uploadFileInput" name="uploadFileInput" className="d-none" accept="image/jpeg"/>
            </div>

            
        </div>
    );
}

ProfileAdminPage.propTypes = {
   
};

export default ProfileAdminPage;