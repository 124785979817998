import React from "react";
import * as PropTypes from "prop-types";
import LIUtils, {SAMPLE_TYPE_BLOCK} from "../GUI_COMMON/SUPPORT/LIUtils";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const SampleCard = ({sampleItem, displayDate, needsAttention, showPosition, onClick, showStatus=true}) => {
    
    function showCaseAsSubTitle() {
        
        return(  
            <>
                <div><strong>Case#:</strong> {sampleItem.caseNumber??"-"}</div>
                <div><strong>Sample#:</strong> {sampleItem.sampleNumber??"-"}</div>
            </>
        );
    }
    
    function handleOnClick() {
        if(onClick) {
            onClick();
        }
    }
    
    function sampleIsOut(){
        return(!sampleItem.magBarcode ||sampleItem.magBarcode === "");
    }

    function showSampleIsInOutOrPendingOrDup() {
        const spanInfo = ArchiverGuiUtils.ShowSampleIsInOutOrPendingOrPicked(sampleItem);
        const manuallyEdited=ArchiverGuiUtils.ShowSampleIsManuallyEdited(sampleItem);
        return (
                <div>
                    {manuallyEdited}{spanInfo}
                </div>
            );
    }
    
    function showPositionIfRequired() {
        if (showPosition) { 
            
            if(sampleIsOut()) {
                return null;
            }
                
            const sectionAsName = LIUtils.convertMagSectionIndexToAlpha(sampleItem.magSection);
            let sectionFgColor;
            switch(sectionAsName) {
                case "A":
                    sectionFgColor = "li-position-marker-section-a";
                    break;
                case "B":
                    sectionFgColor = "li-position-marker-section-b";
                    break;
                case "C":
                    sectionFgColor = "li-position-marker-section-c";
                    break;
                case "D":
                    sectionFgColor = "li-position-marker-section-d";
                    break;
                default:
                    sectionFgColor = "";
            }
            
            return  <div>
                        <div className={"d-inline badge badge-white li-font-tiny "+sectionFgColor}>{sectionAsName}#{sampleItem.positionAbsolute}</div>
                    </div>
        }
        return null;
    }
    
    function showDateIfRequired() {
        if(LIUtils.existsAndNotEmpty(displayDate)) {
            const d:Date = LIUtils.dateFromJsonUtcDateStr(displayDate);
            return  <>
                <div className={"d-inline li-font-tiny li-bottom-right"}>added {d.toLocaleString()}</div>
            </>
        }
    }
    
    function getBadgeColorGivenSampleType(sampleType) {
        if(sampleType===SAMPLE_TYPE_BLOCK) {
            return(
                "li-bg-blue li-fg-white"
            );
        }
        return(
            "li-bg-primary li-fg-white"
        );
    }
    
    let colorClass="";

    if(!sampleItem) {// just a spacer
        return <div className="card li-sample-card clear"/>;
    }

    // this follows here for a reason!
    if(needsAttention) {
        if(sampleItem.isDuplicate) { // overrides standard needs attention
            colorClass = " li-bg-duplicate";
        }
        else if(LIUtils.existsAndNotEmpty(sampleItem.barcode) && 
            !LIUtils.existsAndNotEmpty(sampleItem.profileId)) { // barcode present, but missing profile Id - special colour
            colorClass = " li-bg-missing-profile "; //li-flash" // removed 230613 -pott
        }
        else { // standard needs attention
            colorClass = " li-bg-danger";
        }
    }
    
    
    return(
        <div className="card li-sample-card" onClick={handleOnClick}>
            <div className={"card-body p-1 "+colorClass}>
                <div className="row justify-content-between p-0 m-0">
                    <div className="d-flex">
                        <div className="p-0 medium mr-2 li-border-dark align-self-center">{LIUtils.getImgFromImageData(sampleItem.imageData,null,100)}</div>
                        <div>
                            <div className="card-title font-weight-bold w-100 text-truncate">Barcode#: {LIUtils.SanitizeBarcodeToLength(sampleItem?.barcode,20)}</div>
                            <h6 className="card-subtitle">{showCaseAsSubTitle()}</h6>
                        </div>
                    </div>
                    <div className="text-right">
                        <div className={"text-center rounded px-1 "+getBadgeColorGivenSampleType(sampleItem.sampleType)}>{LIUtils.convertSampleTypeToStr(sampleItem.sampleType)}</div>
                        {showStatus && showSampleIsInOutOrPendingOrDup()}
                        {showPositionIfRequired()}
                        {showDateIfRequired()}
                    </div>
                </div>
            </div>
        </div>                                          
    );
}
            
SampleCard.propTypes = {
    showPosition: PropTypes.bool,
    needsAttention: PropTypes.bool,
    sampleItem: PropTypes.object,
    onClick: PropTypes.func,
    showStatus: PropTypes.bool,
    displayDate: PropTypes.string,
    
}

export default SampleCard;