import React, {useContext, useEffect, useState} from "react"
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import Header from "./Header";
import Footer from "./Footer";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import ArchiverClientApi from "../API/archiverClientApi";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import MagCard from "./MagCard";
import BackNextWidget from "../GUI_COMMON/COMPONENTS/BackNextWidget";
import SampleSearchInput from "./SampleSearchInput";
import SearchButtonList from "./SearchButtonList";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import {StoreContext} from "../store";
import {saveSearchResults} from "../ACTIONS/clientActions";

const DEFAULT_MAG_PAGE_SIZE=20;

const SearchMagsPage = () => {

    const [globalState, dispatch] = useContext(StoreContext);

    const [isSearching,setIsSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [redirectTo, setRedirectTo] = useState();
    
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [term,setTerm] = useState(params.get('s')??"");
    const [isIniting, setIsIniting] = useState(true);
    
    const [pageSize,] = useState(DEFAULT_MAG_PAGE_SIZE);
    const [startIndex, setStartIndex]=useState(Number(params.get('si')??0));
    const [sampleType, setSampleType] = useState(params.get('t')??"");
    
    useEffect(() => {
        // RECALL -- this runs AFTER all children mount!
        console.debug("SearchMagsPage starting up...");
        window.scrollTo(0,0);
        LIUtils.setBackgroundColor('--li-main-bg-color');

        setIsIniting(false);
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        if(!isIniting) {
            if(searchResultsHaveNotChanged()) {
                setSearchResults(globalState.searchResults.searchResults);
            }
            else { // go to the API
                handleBackNext(startIndex, pageSize);
            }
        }
    }, [term,sampleType, isIniting]); // eslint-disable-line react-hooks/exhaustive-deps

    function buildSearchTypeKey():string {
        return "mag_search_" + sampleType;
    }
    
    function searchResultsHaveNotChanged() {
        const searchTypeKey = buildSearchTypeKey();
        var gSearchResults = globalState.searchResults;

        return gSearchResults.searchType === searchTypeKey &&
            gSearchResults.startIndex === startIndex &&
            gSearchResults.numToReturn === pageSize &&
            gSearchResults.searchResults !== undefined &&
            gSearchResults.searchTerm === term;
    }
    
    function handleMagSearchClicked(newTerm) {
        if(newTerm===term) {
            console.debug("ignoring search request -- term didn't change");
            return;
        }
        setStartIndex(0);
        setTerm(newTerm);
    }

    function handleMagClicked(magItem) {
        replaceBrowserHistory(term,startIndex,DEFAULT_MAG_PAGE_SIZE,sampleType);
        setRedirectTo(`/mag_details/${magItem.barcode}`);
    }

    function handleBackNext(si, numToReturn) {

        console.debug(`*** handling backnext (${si}:${numToReturn}) ***`);
        setStartIndex(si);
        replaceBrowserHistory(term,si,numToReturn,sampleType);
        if(!term || term==="") {
            return null;
        }
        setIsSearching(true);
        setSearchResults([]);
        ArchiverClientApi.sendMagSearchRequest(term,sampleType,si, numToReturn)
        .then( (results) => {
            setSearchResults(results);
            dispatch(saveSearchResults(buildSearchTypeKey(),results,si, numToReturn, term)); // save for possible later use
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsSearching(false));
    }

    function replaceBrowserHistory(searchTerm,startIndex,numToReturn,sampleType) {
        history.replace(`/mag_search?s=${searchTerm}&t=${sampleType}&si=${startIndex}&nr=${numToReturn}`);
    }
    
    function handleRadioChanged(e) {
        e.stopPropagation();
        setSampleType(e.target.value);
        setStartIndex(0);  // reset
    }

    function handleRadioClicked(e) {
        e.stopPropagation(e);
    }
    
    function showResultsTable() {
        if(!term || term==="") {
            return <div className="text-center mt-5"/>;
        }
        if(isSearching) {
            return <div className="text-center mt-5">...searching...</div>;
        }
        if(!searchResults || searchResults.length===0) {
            return <div className="badge-pill mt-5 p-3 li-bg-card">no matches found</div>;
        }
        if(searchResults.length%2!==0) { // need an extra one to keep the last left sample to the left (hack)
            searchResults.push(null); // final one
        }

        const resultList = searchResults.map( (item, index) =>
            <MagCard key={index} info={item} onClick={() => handleMagClicked(item)}/>
        );

        return (
            <div className="row justify-content-center">
                {resultList}
            </div>
        );
    }

    function haveResults() {
        return !isSearching && ((searchResults && searchResults.length>0) || startIndex>0);
    }

    if(redirectTo) {
        return (
            <Redirect push to={redirectTo}/>
        );
    }
    
    return (
        <div className="container">
            <Header/>
            <div className="row mt-3 li-header-row justify-content-between">
                <div>
                    <BackButton onClick={() => setRedirectTo("/dashboard")}/><span className="li-font-huge">Search</span>
                </div>
                <SearchButtonList activeChoice="magazines"/>
            </div>

            <div className="position-relative mt-5">
                <div className="position-absolute" style={{right:"0em",top:"-3em"}}>
                    <input type="radio" className="m-2 medium align-self-center" name="li-filter-type" aria-label="Filter Type"
                           checked={sampleType===""} onChange={(e)=>handleRadioChanged(e)} 
                           onClick={(e)=>handleRadioClicked(e)} value=""/>
                    <label className="li-form-label medium mr-3" htmlFor="li-filter-type">all</label>

                    <input type="radio" className="m-2 medium align-self-center" name="li-filter-type" aria-label="Filter Type"
                           checked={sampleType==="slide"} onChange={(e)=>handleRadioChanged(e)}
                           onClick={(e)=>handleRadioClicked(e)} value="slide"/>
                    <label className="li-form-label medium mr-3" htmlFor="li-filter-type">slides</label>

                    <input type="radio" className="m-2 medium align-self-center" name="li-filter-type" aria-label="Filter Type"
                           checked={sampleType==="block"} onChange={(e)=>handleRadioChanged(e)}
                           onClick={(e)=>handleRadioClicked(e)} value="block"/>
                    <label className="li-form-label medium mr-3" htmlFor="li-filter-type">blocks</label>
                </div>
                <div className="py-3">
                    <SampleSearchInput name="magSearch" label="Magazine:" onSearchClicked={handleMagSearchClicked} busyFlag={isSearching} 
                                       initialValue={term} placeholder="" hasFocus/>
                </div>
            </div>

            <div className="mt-3 mb-4">
                <BackNextWidget disableNext={searchResults.length < pageSize} pageSize={pageSize} startIndex={startIndex} 
                                onClick={handleBackNext} show={haveResults()}/>
                <div className="li-search-results-table mt-2">
                    {showResultsTable()}
                </div>
            </div>
            
            <BackNextWidget disableNext={searchResults.length < pageSize} pageSize={pageSize} startIndex={startIndex} 
                            onClick={handleBackNext} show={haveResults()}/>
            
            <Footer/>
        </div>
    );
}

export default SearchMagsPage;