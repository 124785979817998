import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function watchListReducer(state = initialState.watchList, action) {

    /**
     * 
     watchList: {
        totalSamples: #,
        watchListRaw: []
     }
     
     */

    switch (action.type) {
        case types.GOT_WATCHLIST: {
            const newState = {
                totalSamples: action.totalItems,
                watchListRaw: action.watchItems,
            };
            return (newState);
        }
        case types.SET_WATCH_ITEM_CHECKED: {
            const watchItem = action.watchItem;
            const isChecked = action.isChecked;
            const newWatchListRaw = [...state.watchListRaw];
            const thing = newWatchListRaw.find(item => item===watchItem);
            if(thing) {
                thing.isChecked = isChecked; // augment.
            }
            return {
                watchListRaw: newWatchListRaw,
                totalSamples: state.totalSamples
            };
        }
        case types.CLEAR_WATCH_LIST:
            return {
                watchListRaw: [],
                totalSamples: 0
            };
        default:
            return(state)
    }
}

