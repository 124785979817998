import LIUtils from "./LIUtils";

class GuiUtils {
    
    /**
     * build a pretty location based on what's available in the location, department, and contact objects
     * 
     * @param location
     * @param department
     * @param contact
     * @returns {string}
     * @constructor
     */
    static BuildPrettyLocation(location:Object, department:Object, contact:Object):string {
        const c = contact?.fullName ?? "";
        const d = department?.name ?? "";
        const l = location?.name ?? "";
        let output = "";

        // ### this could be better
        if (l && d && c) { // location, contact, and department
            output = c + "@" + l + "/" + d;
        } else if (l && c) { // location and contact
            output = c + "@" + l;
        } else if (l) { // location only
            output = l;
        } else if (c) {  // contact only
            output = c;
        }
        
        return output;
    }

    /**
     * given sample basic info, build a string that includes at least the basic identification info, handling the
     * case were the barcode may be missing and/or the case/sample may be missing.
     * @param barcode
     * @param caseNumber
     * @param sampleNumber
     * @returns {string}
     * @constructor
     */
    static BuildSampleDetailsStr(barcode:string, caseNumber:string, sampleNumber:string) {
        if(caseNumber && caseNumber!=="") {
            return caseNumber + (LIUtils.existsAndNotEmpty(sampleNumber) ? ("-" + sampleNumber) : "");
        }
        if (!barcode || barcode === "") {
            return barcode;
        }
        return("unknown");
    }

    /**
     * given sample basic info, build a string that includes at least the basic identification info, that
     * concentrates on the case and sample numbers, not the barcode at all.
     * @param caseNumber
     * @param sampleNumber
     * @returns {string}
     * @constructor
     */
    static BuildCaseSampleDetailsStr(caseNumber:string, sampleNumber:string) {
        let caseSample="-";
        if(caseNumber && caseNumber!=="") {
            caseSample=caseNumber+(LIUtils.existsAndNotEmpty(sampleNumber)?("-"+sampleNumber):"");
        }
        return caseSample;
    }

    /**
     * return a simple string that describes why a sample may not have been found
     * @returns {string}
     * @constructor
     */
    static GetSampleNotFoundMessage():string {
        return "<p><strong>Your sample was not found.</strong><p/>" +
            "<p>This sample will not be available any longer if you have<br/> " +
            "just repaired a sample and it was found to be a duplicate.<p/>" +
            "<p class='font-weight-bold text-center'>The original sample will still be available.</p>" +
            "<p>Try searching for it again.</p>";
    }
}

export default GuiUtils;