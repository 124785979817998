import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function chaseListReducer(state = initialState.chaseList, action) {

    /**
     chaseList: [

     ]
     */

    switch (action.type) {
        case types.GOT_CHASELIST: {
            // we aren't using included totalItems (that's a stat)
            const newState = {
                chaseListRaw: action.chaseItems,
                chaseListByLocation: action.chaseListByLocation
            };
            return (newState);
        }
        case types.SET_CHASE_ITEM_CHECKED: {
            const chaseItem = action.chaseItem;
            const isChecked = action.isChecked;
            const newChaseListRaw = [...state.chaseListRaw];
            const thing = newChaseListRaw.find(item => item===chaseItem);
            if(thing) {
                thing.isChecked = isChecked; // augment.
            }
            return {
                chaseListRaw: newChaseListRaw,
                chaseListByLocation: state.chaseListByLocation
            };
        }
        default:
            return(state)
    }
}

