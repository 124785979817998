import React from 'react';
import * as PropTypes from "prop-types";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";

const RetrievalOrderCardRow = ({orderNumber,dateStarted,createdBy,requestedBy,destinedForContact,
                                        destinedForLocation,notes,showCheckBox,isChecked, onCheckChanged, onClick,
                                        allowDelete, onDeleteClicked}) => {
    function dumpDestination() {
        const c = destinedForContact?.fullName??"";
        const l = destinedForLocation?.name??"";
        let sep="";
        if(l && c) {
            sep="/";
        }
        return(
            <div>{l}{sep}{c}</div>    
        );
    }
    
    function handleCBChanged(e) {
        e.stopPropagation();
        if(onCheckChanged) {
            onCheckChanged(e.target.checked);
        }
    }
    
    function handleCBClicked(e) {
        e.stopPropagation(e);
    }
    
    function handleOnClick(e) {
        e.stopPropagation();
        if(onClick) {
            onClick();
        }
    }
    
    function handleDeleteClicked(e) {
        e.stopPropagation();
        if(onDeleteClicked) {
            onDeleteClicked();
        }
    }
    
    function showCheckboxIfRequired() {
        if(showCheckBox) {
            return <td>
                <input type="checkbox" className="m-2 mr-3 border-bottom" name="retrieval-cb" aria-label="Checkbox" checked={isChecked} 
                       onChange={(e)=>handleCBChanged(e)} onClick={(e)=>handleCBClicked(e)}/>
            </td>
        }
    }
    
    function showDeleteIfAllowed() {
        if(!allowDelete) {
            return null;
        }
        
        return(
            <span onClick={(e) => handleDeleteClicked(e)} data-toggle="tooltip" title="Delete this order">
                <i className="li-pointer li-fg-danger fa fa-times-circle fa-lg"/>
            </span>
        );
    }
    
    return (
            <tr>
                {showCheckboxIfRequired()}
                <td className="text-nowrap"><span className="btn-link li-pointer mr-2" onClick={(e)=>handleOnClick(e)}>
                    {LIUtils.prettyOrderNumber(orderNumber)}</span>
                    {showDeleteIfAllowed()}
                </td>
                <td>{LIUtils.dateTimeToPretty(dateStarted)}</td>
                <td>{requestedBy.fullName}</td>
                <td>{createdBy.fullName}</td>
                <td>{dumpDestination()}</td>
                <td>{notes}</td>
            </tr>
    );
}

RetrievalOrderCardRow.propTypes = {
    orderNumber: PropTypes.number.isRequired,
    createdBy:PropTypes.object,
    requestedBy:PropTypes.object,
    destinedForContact:PropTypes.object,
    destinedForLocation:PropTypes.object,
    onClick: PropTypes.func,
    onCheckChanged:PropTypes.func,
    dateStarted: PropTypes.object,
    showCheckBox: PropTypes.bool,
    isChecked: PropTypes.bool,
    notes: PropTypes.string,
    allowDelete: PropTypes.bool,
    onDeleteClicked: PropTypes.func,
}

export default RetrievalOrderCardRow;