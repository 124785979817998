import React, {useContext, useEffect, useState} from "react"
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import {Redirect} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import DashboardButton from "../GUI_COMMON/COMPONENTS/DashboardButton";
import DashboardAdminButton from "./DashboardAdminButton";
import {gotNumEvents, gotNumNeedsAttention, gotNumNeedsChasing, gotNumRetrievals, gotNumWatches} from "../ACTIONS/clientActions";
import {StoreContext} from "../store";
import ArchiverClientApi from "../API/archiverClientApi";
import {useCookies} from "react-cookie";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const Dashboard = () => {

    const [globalState, dispatch] = useContext(StoreContext);
    const [redirectTo, setRedirectTo] = useState();
    const [attentionValue, setAttentionValue] = useState();
    const [chaseValue, setChaseValue] = useState();
    const [watchValue, setWatchValue] = useState();
    const [retrievalValue, setRetrievalValue] = useState();
    const [eventsValue, setEventsValue] = useState();
    
    const [cookies,] = useCookies();
    
    useEffect(() => {
        // RECALL -- this runs AFTER all children mount!
        console.debug("Dashboard starting up...");
        window.scrollTo(0,0);
        LIUtils.setBackgroundColor('--li-main-bg-color');
        getInitialStats();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        let numAttention = "";
        if(globalState.stats?.numNeedsAttention!==null) {
            numAttention = `(${globalState.stats.numNeedsAttention<0?0:globalState.stats.numNeedsAttention})`;
        }
        setAttentionValue(numAttention);
        
        let numChase="";
        if(globalState.stats?.numNeedsChasing!==null) {
            numChase = `(${globalState.stats.numNeedsChasing<0?0:globalState.stats.numNeedsChasing})`;
        }
        setChaseValue(numChase);

        let numWatches="";
        if(globalState.stats?.numWatching!==null) {
            numWatches = `(${globalState.stats.numWatching<0?0:globalState.stats.numWatching})`;
        }
        setWatchValue(numWatches);

        let numRetrievals="";
        if(globalState.stats?.numRetrievals!==null) {
            numRetrievals = `(${globalState.stats.numRetrievals<0?0:globalState.stats.numRetrievals})`;
        }
        setRetrievalValue(numRetrievals);

        let numEvents="";
        if(globalState.stats?.numEvents!==null) {
            numEvents = `(${globalState.stats.numEvents<0?0:globalState.stats.numEvents})`;
        }
        setEventsValue(numEvents);
        
    },[globalState.stats]);
    
    function getInitialStats() {
        ArchiverClientApi.getDashboardCounts()
        .then( (response) => {
            dispatch(gotNumNeedsAttention(response.numNeedsAttentions));
            dispatch(gotNumNeedsChasing(response.numChases));
            dispatch(gotNumWatches(response.numWatches));
            dispatch(gotNumRetrievals(response.numRetrievals));
            dispatch(gotNumEvents(response.numEvents));
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError));
    }
    
    function showRetrievalsButton() {
        if(LIUtils.UserHasLevel(cookies,"RETRIEVER") || LIUtils.UserHasLevel(cookies,"PICKER") ||
            LIUtils.UserHasLevel(cookies,"SENDER")) {
            return (
                <div className="col-sm-6 offset-sm-0 col-md-4">
                    <DashboardButton title="Retrievals" subTitle={retrievalValue} icon="fa-2x fa-suitcase" onClick={() => setRedirectTo("/retrievals")}/>
                </div>
            );
        }
        return null;
    }
    
    function showSearchButton() {
        if(LIUtils.UserHasLevel(cookies,"SEARCHER")) {
            return (
            <div className="col col-sm-6 offset-sm-0 col-md-4">
                <DashboardButton title="Search" icon="fa-2x fa-search" onClick={() => setRedirectTo("/case_search")}/>
            </div>
            );
        }
        return null;
    }

    function showConsumablesButton() {
        if(LIUtils.UserHasLevel(cookies,"CONSUMABLE_ADMIN")) {
            return (
                <div className="col-sm-6 offset-sm-0 col-md-4">
                    <DashboardButton title="Consumables" icon="fa-2x fa-parachute-box" onClick={() => setRedirectTo("/consumables")}/>
                </div>
            );
        }
        return null;
    }

    /**
     * this is special -- because the report runner needs an id, we get the id for the 
     * "events" report first from the API and then run it.
     */
    function handleEventsClicked() {
        
        ArchiverClientApi.getReportDetailsGivenName("events")
        .then( ({basicInfo}) => {
            setRedirectTo("/run_report/"+Number(basicInfo.id));
        })
        .catch( responseError => {
            let error=responseError?.error??"";
            if(responseError.statusCode===404) {
                error = "A report with the name 'events' was not found.<p class='mt-3'>Please create or rename a "+
                    "report that you wish to run whenever you press the Events button.</p>";
            }
            ErrorPopup.showError("Problem",error)
        });
    }
    
    if(redirectTo) {
        return <Redirect push to={redirectTo}/>
    }
    
    return (
        <div className="container">
            <Header/>
            <div className="li-dash-body-bg row p-0">
                {showSearchButton()}
                {showRetrievalsButton()}
                {showConsumablesButton()}
                <div className="col-sm-6 offset-sm-0 col-md-4">
                    <DashboardButton title="Chase List" subTitle={chaseValue} icon="fa-2x fa-running" onClick={()=>setRedirectTo("/chase")}/>
                </div>
                <div className="col-sm-6 offset-sm-0 col-md-4">
                    <DashboardButton title="Attention" subTitle={attentionValue} icon="fa-2x fa-exclamation-circle" onClick={()=>setRedirectTo("/attention")}/>
                </div>
                <div className="col-sm-6 offset-sm-0 col-md-4">
                    <DashboardButton title="Watch List" subTitle={watchValue} icon="fa-2x fa-binoculars" onClick={()=>setRedirectTo("/watch")}/>
                </div>
                <div className="col-sm-6 offset-sm-0 col-md-4">
                    <DashboardButton title="Print Labels" icon="fa-2x fa-print" onClick={()=>setRedirectTo("/print")}/>
                </div>
            </div>
            <div className="li-dash-body-bg row p-0">
                <div className="col-sm-6 offset-sm-0 col-md-4">
                    <DashboardAdminButton title="Reports" icon="fa-2x fa-chart-line" level="admin" onClick={()=>setRedirectTo("/reports")}/>
                </div>
                <div className="col-sm-6 offset-sm-0 col-md-4">
                    <DashboardAdminButton title="Events" icon="fa-2x fa-calendar" subTitle={eventsValue} level="admin" onClick={handleEventsClicked}/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Dashboard;