import PropTypes from "prop-types";
import LIUtils from "../SUPPORT/LIUtils";

const DashboardButton = ({icon, onClick, title, subTitle, special, disabled}) => {
    
    function subTitleIfAny() {
        if(LIUtils.existsAndNotEmpty(subTitle)) {
            return(
                <div className="li-font-medium">{subTitle}</div>  
            );
        }
        return null;
    }
    
    function handleOnClick(e) {
        if(onClick && !disabled) {
            onClick(e);
        }
    }
    
    let bgClass = (special?"li-bg-admin":"li-primary");
    if(disabled) {
        bgClass = "li-bg-muted";
    }
    
    return(
        <div className={"li-dash-button text-center "+bgClass} onClick={handleOnClick}>
            <i className={"mb-3 fa "+icon} aria-hidden="true"/>
            <div>{title}{subTitleIfAny()}</div>
        </div>
    );
}

DashboardButton.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.oneOfType([PropTypes.string,PropTypes.object]),
    onClick: PropTypes.func,
    special: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default DashboardButton;
        
        
