import ErrorPopup from "../COMPONENTS/ErrorPopup";
import CommonClientApi from "../API/commonClientApi";
import React from "react";

/**
 * a type of sample that IS a slide
 * @type {number}
 */
export const SAMPLE_TYPE_SLIDE=1;

/**
 * a type of sample that IS a block
 * @type {number}
 */
export const SAMPLE_TYPE_BLOCK=2;


class LIUtils {
    /** return an object that has the entire passed in Date object broken down
     *  into it's basic parts.
     * @param date (Date Object)
     * @returns {{year: number, month: number, fullMonth: *, date: number, fullDate: *, day: number, hours: number, fullHours: *, minutes: number, fullMinutes: *}}
     */
    static getParsedDate(date) {
        return {
            year: date.getFullYear(),
            month: date.getMonth(),
            fullMonth:
                date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1, // One based
            date: date.getDate(),
            fullDate: date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
            day: date.getDay(),
            hours: date.getHours(),
            fullHours: date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
            minutes: date.getMinutes(),
            fullMinutes:
                date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
        };
    }

    static shortDateFromJsonUtcDateStr(dateString):string {
        if (!dateString || dateString === "") return "-";
        if (dateString[dateString.length-1]!=="Z") {
            dateString+="Z";  // we add the Z if it's not there to ensure this is treated as a SERVER UTC date.
        }
        const date = new Date(dateString);  
        return date.toLocaleDateString([], {year: "numeric", month: "short", day:"2-digit" });
    }

    static dateFromJsonUtcDateStr(dateString:string):Date {
        if (!dateString || dateString === "") return null;
        if (dateString instanceof Date) return dateString; // bugfix - 231114 -pott
        
        dateString = LIUtils.fixAMPMInDateString(dateString);
        if (!dateString.endsWith("Z")) {
            dateString+="Z";  // we add the Z if it's not there to ensure this is treated as a SERVER UTC date.
        }
        return(new Date(dateString));
    }
    
    static fixAMPMInDateString(dateString:string) {
        if(dateString.endsWith("AM")) {
            let [ds, ts, extra] = dateString.split(' ');
            ts = ts+(extra?extra:"");  // in case AM/PM come in with a space
            const baseTime = ts.substring(0,ts.length-2).trim();
            const [hh, mm, ss] = baseTime.split(':');

            dateString = `${ds} ${hh}:${mm}:${ss}`;
        }
        if(dateString.endsWith("PM")) {
            let [ds, ts, extra] = dateString.split(' ');
            ts = ts+(extra?extra:"");  // in case AM/PM come in with a space
            const baseTime = ts.substring(0,ts.length-2).trim();
            const [hh, mm, ss] = baseTime.split(':');

            let hhFixed = Number(hh)+12;
            dateString = `${ds} ${hhFixed}:${mm}:${ss}`;
        }
        return dateString;
    }

    static shortDateTimeFromJsonUtcDateStr(dateString) {
        if (!dateString || dateString === "") return "-";
        if (dateString[dateString.length-1]!=="Z") {
            dateString+="Z";  // we add the Z if it's not there to ensure this is treated as a SERVER UTC date.
        }
        const date = new Date(dateString);
        return date.toLocaleDateString([], {year: "numeric", month: "short", day:"2-digit" ,hour: "2-digit", minute: "2-digit"});
    }

    /** given a date object, format the time suitable for display
     *
     * @param date
     * @returns {string} or '' on error
     */
    static dateToShortTimeString(date) {
        if (!date || date === "") return "";
        return date.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"});
    }

    static dateToShortDateString(date) {
        if (!date || date === "") return "";
        return date.toLocaleDateString([], {year: "numeric", month: "short", day:"2-digit"});
    }

    static dateToShortDateTimeString(date:Date) {
        if (!date || date === "" || !date instanceof Date) return "";
        return date.toLocaleDateString([], {year: "numeric", month: "short", day:"2-digit", hour: "2-digit", minute: "2-digit"});
    }

    // /**
    //  * return a UTC date approx equal to the local date, good to the second.
    //  * 
    //  * ### this isn't right yet.
    //  * 
    //  * @param localDate
    //  * @returns {number}
    //  */
    // static localDateToUtcDate(localDate:Date) {
    //     return new Date(localDate);
    // }

    /** return a HH:MM based time string for the given unix timet
     *
     * @param timet
     * @returns {*}
     */
    static timetToShortTimeString(timet) {
        return LIUtils.dateToShortTimeString(new Date(timet * 1000));
    }

    /** given a unit timet (secs), build a date string for this (no time is returned).
     *
     * @param timet
     * @returns {string}
     */
    static timetToDateString(timet) {
        return new Date(timet * 1000).toDateString();
    }

    /** given a unit timet (epoch secs UTC), build a date and time string for this
     * in the local time.
     *
     * @param timet
     * @returns {string}
     */
    static timetToDateTimeString(timet) {
        if (timet === "") {
            return "never";
        }
        // JS dates are based on 1970 epoch.
        // C# dates are based on 0001 epoch.
        return new Date(timet * 1000).toLocaleString();
    }

    static dateTimeToPretty(date:Date):string {
        const now= Date.now();
        if(now-date < 12*3600*1000) { // less than 12 hours
            return LIUtils.dateToShortTimeString(date);
        }
        return LIUtils.dateToShortDateString(date);
    }

    /**
     * make a displayable order number from an ordinary order id.
     * 
     * @param orderNumber
     * @returns {string}
     */
    static prettyOrderNumber(orderNumber):string {
        if(!orderNumber || orderNumber===-1) {
            return "-";
        }
        
        return String(orderNumber).padStart(8,"0");
    }

    /** determine if the two Date objects are the same
     *
     * @param date1 - Date object
     * @param date2 - Date object
     * @param type - can be 'day', 'month', or 'year' for compare (default is 'day')
     * @returns { true / false}
     */
    static isSameDate(date1, date2, type) {
        if (!date1 || !date2) return false;
        let d1 = LIUtils.getParsedDate(date1),
            d2 = LIUtils.getParsedDate(date2),
            _type = type ? type : "day",
            conditions = {
                day:
                    d1.date === d2.date && d1.month === d2.month && d1.year === d2.year,
                month: d1.month === d2.month && d1.year === d2.year,
                year: d1.year === d2.year,
            };

        return conditions[_type];
    }

    /** perform a deep copy of entire specified object
     * NOTE: THIS DOESN't WORK FOR DATE OBJECTS
     * @param objToCopy
     */
    static deepCopyUsingStringify(objToCopy) {
        return JSON.parse(JSON.stringify(objToCopy));
    }
    
    /** compute a unix time_t for the given date, at the given hours and minutes
     *
     * @param date:  the base date (day, month, year, etc)
     * @param timeHH:  the HH number to set the hours time to for this date
     * @param timeMM:  the MM number to set the minutes time to for this date.
     * @returns number: unix time ticks (seconds)
     */
    static computeTimeT(date, timeHH, timeMM) {
        const jobDate = date;
        jobDate.setHours(timeHH);
        jobDate.setMinutes(timeMM);
        return Math.floor(jobDate.getTime() / 1000);
    }

    /** return the unix timet (secs) given a normal JS date object.
     * This ensures it is not a floating point.
     * This uses the given date as is -- likely to be local
     *
     * @param date
     * @returns {number}
     */
    static computeTimeTFromDate(date) {
        return Math.floor(date.getTime() / 1000);
    }

    static computeTimeTFromDateGivenLocal(year:number, month:number, day:number, hour:number=0, minute:number=0, 
                                          second:number=0, doEOD:boolean=false) {
        const localDT = new Date();
        localDT.setFullYear(year);
        localDT.setMonth(month); // starts at 0
        localDT.setDate(day); // starts at 1 with 0 being the last day of the previous month
        
        let ticks = Math.floor(localDT.setHours(hour,minute,second)/1000);
        
        //let ticks = Math.floor(Date(year,month,day,hour,minute,second) / 1000);
        if(doEOD) {
            ticks += 86399; // include entire day of ticks
        }
        return ticks;
    }
    
    /** check the given flag value against known false, "false", or undefined.
     *
     * @param flagValue
     * @returns {boolean}
     */
    static getValueAsBool(flagValue) {
        if (!flagValue || flagValue === "false" || flagValue === false) flagValue = false;
        else flagValue = true;

        return flagValue;
    }
    
    /**
     * check for authentication in cookies given the cookies
     * object from the new react hook.
     * @returns {boolean}
     * @param cookiesObj
     */
    static isAuthenticatedHook(cookiesObj) {
        const token = cookiesObj.liToken;
        const expires = cookiesObj.liExpires; // unix secs

        if (!token || token === "") {
            return false;
        }
        if (!expires || expires === "") {
            return false;
        }
        let expiresInt = Number.parseInt(expires);
        if (isNaN(expiresInt)) {
            return false;
        }
        const nowUxSecs = this.computeTimeTFromDate(new Date()); // value here is UTC unix msecs
        if (nowUxSecs <= expiresInt) {
            // good
            return true;
        }

        console.log("token has expired");
        return false;
    }

    static saveAuthInfoToCookieHook(authInfo, setCookie) {
        setCookie('liToken', authInfo.tokenInfo.token, {path: '/'});
        setCookie('liExpires', authInfo.tokenInfo.expiration, {path: '/'});  // unix seconds

        setCookie('liId', authInfo.userId, {path: '/'});
        setCookie('liFullName', authInfo.fullName, {path: '/'});
        setCookie('liType', authInfo.level, {path: '/'}); // USER LEVELS

        CommonClientApi.setupHeader(authInfo.tokenInfo.token); // make sure the header is setup for server coms.
    }
    
    /***
     * returns true if the user's Type cookie has the given level in it somewhere
     * @param cookiesObj
     * @param level
     * @returns {boolean}
     * @constructor
     */
    static UserHasLevel(cookiesObj, level:string) {
        const levelsRaw: string = cookiesObj["liType"];
        return LIUtils.UserHasLevelGivenList(levelsRaw, level);
    }

    /**
     * returns true if the list of ',' separated levels contains the given level
     * @param levelsList
     * @param level
     * @returns {boolean|*}
     * @constructor
     */
    static UserHasLevelGivenList(levelsList, level:string) {
        if(!levelsList || levelsList==="") {
            return false;
        }
        let splitBy=",";
        if(levelsList.indexOf(", ")!==-1) {
            splitBy=", ";  // strange " " appears between these cookies values at times... 211115 -pott
        }
        const levels = levelsList.split(splitBy);
        return levels.includes(level);      // ### can include '<something>{level}<something> ###
    }

    /**
     * returns true if the given notify flag string is in the list of notificaiton flags presented
     * @param flagsList
     * @param flag
     * @returns {boolean|*}
     * @constructor
     */
    static UserHasNotifyFlagGivenList(flagsList, flag:string) {
        if(!flagsList || flagsList==="") {
            return false;
        }
        let splitBy=",";
        if(flagsList.indexOf(", ")!==-1) {
            splitBy=", ";  // strange " " appears between these cookies values at times... 211115 -pott
        }
        const flags = flagsList.split(splitBy);
        return flags.includes(flag);      // ### can include '<something>{flag}<something> ###
    }
    
    /**
     * returns true if the user has the proper levels for seeing the location of a sample
     * (ie mag, section, and position
     * 
     * @param cookiesObj
     * @returns {boolean}
     * @constructor
     */
    static UserCanSeeSampleLocation(cookiesObj) {
        return LIUtils.UserHasLevel(cookiesObj,"ADMIN") || LIUtils.UserHasLevel(cookiesObj,"PICKER") || LIUtils.UserHasLevel(cookiesObj,"SERVICE");
    }
    
    /** clear out the cookies so another user can loggin later.
     *  NOTE: if the rememberFlag is set in the cookies, the username is maintained.
     * @param cookies
     * @param removeCookie
     */
    static doCookieSignoutHook(cookies,removeCookie) {
        // kill all cookies
        removeCookie("liExpires", {path: "/"});
        removeCookie("liToken", {path: "/"});
        removeCookie("liId", {path: "/"});
        const remember = cookies.rememberFlag;
        if (!remember || remember === "" || remember === "false") {
            // ### must be a better way.
            removeCookie("liFullName", {path: "/"});
            removeCookie("liType", {path: "/"});
        }
    }
    
    /** check the browser to see if the current window size
     * is less than that spec'd
     * @param thisWidth
     * @param window
     * @param document
     * @returns {boolean}
     * @constructor
     */
    static IsBrowserWidthLessThan(thisWidth, window, document) {
        const width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (width < thisWidth) {
            return true;
        }
        return false;
    }
    
    static setBackgroundColor(cssVar:string) {
        const bgValue = getComputedStyle(document.documentElement).getPropertyValue(cssVar);
        document.getElementsByTagName("body")[0].style.backgroundColor=bgValue;
    }
    
    static returnValueOrDash(thisValue):string {
        if(!thisValue || thisValue==="") {
            return("-");
        }
        return thisValue ;
    }
    
    static convertMagSectionIndexToAlpha(sectionNum:number):string {
        if(sectionNum===null || sectionNum==="-") {
            return "-";
        }
        if(sectionNum===0) { sectionNum="A"; }
        else if(sectionNum===1) { sectionNum="B";}
        else if(sectionNum===2) { sectionNum="C";}
        else if(sectionNum===3) { sectionNum="D";}
        else { sectionNum="?";}
        return sectionNum
    }
    
    static buildMagLocationAsStr(magBarcode:string, magSection:number, positionIndex:number) {
        return `${magBarcode}:${LIUtils.convertMagSectionIndexToAlpha(magSection)}:${positionIndex}`;
    }
    
    static convertSlotIndexToStr(slotIndex:number):string {
        let slotIndexStr="?";
        if(slotIndex >=0 ) {
            slotIndexStr = (slotIndex+1).toString();
        }
        return slotIndexStr;
    }

    static convertSlideIndexToStr(slideIndex:number):string {
        let slideIndexStr="?";
        if(slideIndex >=0 ) {
            slideIndexStr = (slideIndex+1).toString();
        }
        return slideIndexStr;
    }
    
    static showFirmwareConnectError() {
        ErrorPopup.showError("Failed To Connect",
            "Device is offline or restarting" +
            "<br/><small>(will retry in 10 seconds)</small>");
        setTimeout(() => window.location.reload(false), 10000);
    }
    
    static existsAndNotEmpty(thisStr) {
        if(thisStr instanceof Array) {
            if(thisStr.length===0) {
                return false; // empty
            }
        }
        return thisStr && thisStr !== "";
    }
    
    static findProfileGivenId(profileList, profileId):Object {
        if(profileList && profileId) {
            return profileList.find(p => p.id === profileId);
        }
        return undefined;
    }
    
    static findBarcodeProcessingRule(profile):Object {
        
        if(!profile) return null;
        
        // these are defined in InspectionRegion.py in firmware
        const DATA_MATRIX_DECODE = 1
        const LINEAR_DECODE = 2
        //const OCR_DECODE = 3
        //const COLOR_DECODE = 4
        const PDF417_DECODE = 5
        const QR_DECODE = 6
        
        const irList = profile["inspectionRegions"];
        if(irList) {
            const bcIR = irList.find( ir => ir["decodeMethod"] && 
                (ir["decodeMethod"]===DATA_MATRIX_DECODE || ir["decodeMethod"]===LINEAR_DECODE ||
                    ir["decodeMethod"]===PDF417_DECODE || ir["decodeMethod"]===QR_DECODE))
            if(bcIR) {
                return bcIR["postProcessing"];
            }
                
        }
        return null;
    }

    /**
     * return the profiles that have the given profile type.
     * Common types are "mag","slide","block" -- the int versions are defined in
     * the webservices.
     *      UNKNOWN=-1
     *      MAG=0
     *      SLIDE=1
     *      BLOCK=2
     *      CHARACTERISTIC=3
     *      
     * @param profileList
     * @param profileType
     */
    static findProfilesOfType(profileList, profileType:Number) {
        if(!profileList) return;
        let profileTypeInt = -1
        if (profileType === 1) profileTypeInt = 1;    // slides
        else if (profileType === 2) profileTypeInt = 2;   // blocks

        return profileList?.filter(item => item.type === profileTypeInt)??[];
    }
    
    static convertSampleTypeToStr(sampleType:number):string {
        if(!sampleType) {
            return "none";
        }
        if(sampleType===1) {
            return "SLIDE";
        }
        if(sampleType===2) {
            return "BLOCK";
        }
        return("OTHER");
    }

    /**
     * given a profile type, convert this into a meaningful string
     * @param profileType
     * @returns {string}
     */
    static convertProfileTypeToStr(profileType:number):string {
        if(profileType===0) {
            return "MAG";
        }
        if(profileType===1) {
            return "SAMPLE";
        }
        
        return("OTHER");
    }

    /***
     * returns true if all of the info that makes up a complete sample is present.
     * @param caseNumber
     * @param sampleNumber
     * @param profileId
     * @returns {boolean|*}
     * @constructor
     */
    static SampleHasCriticalInfo(caseNumber:string, sampleNumber:string, profileId:Number):boolean {
        let profileInfoIsPresent=false;
        // if(profileId===undefined || profileId===null) { // no profile
        //     profileInfoIsPresent=true;
        // }
        // else 
        if(profileId && profileId>0) { // there is a profile and it's ok.
            profileInfoIsPresent=true;
        }
        const caseAndSampleIsPresent = LIUtils.existsAndNotEmpty(caseNumber) && LIUtils.existsAndNotEmpty(sampleNumber)
        return profileInfoIsPresent && caseAndSampleIsPresent;
    }

    /**
     * returns a string containing the reason a sample may be considered needing attention
     * or "" if not
     * @param caseNumber
     * @param sampleNumber
     * @param profileId
     * @param isDuplicate
     * @returns {string}
     * @constructor
     */
    static GetMissingCriticalInfo(caseNumber:string, sampleNumber:string, profileId:Number, isDuplicate:boolean):string {
        if(isDuplicate) {
            return "*** this is a duplicate ***";
        }
        let profileInfoIsPresent=false;
        if(profileId && profileId>0) { // there is a profile and it's ok.
            profileInfoIsPresent=true;
        }
        const caseIsPresent = LIUtils.existsAndNotEmpty(caseNumber);
        const sampleIsPresent = LIUtils.existsAndNotEmpty(sampleNumber);
        
        let missing="";
        if(!profileInfoIsPresent) {
            missing+=(missing===""?"":", ")+"missing profile";
        }
        if(!caseIsPresent) {
            missing+=(missing===""?"":", ")+"missing case#";
        }
        if(!sampleIsPresent) {
            missing+=(missing===""?"":", ")+"missing sample#";
        }
        return missing;
    }

    /**
     * attempt to get and display an image given the raw image data
     * 
     * @param imageData
     * @param className
     * @param widthInPels
     * @param destId
     * @returns {JSX.Element}
     */
    static getImgFromImageData(imageData,className:string="", widthInPels:Number=null, destId:string=null) {

        let widthVerbage="100%";
        if(widthInPels!==null) {
            widthVerbage = widthInPels+"px";
        }
        if(!imageData) {
            let missingText = "No image available";
            if(widthInPels<100) {
                missingText = "";
            }
            return <div className="text-center mt-2" style={{width:widthVerbage}}>{missingText}</div>;
               
        }
        return( <img id={destId} src={"data:image/png;base64,"+imageData} className={className} width={widthVerbage} alt="mag,profile, or sample"/> );
    }

    /**
     * returns true if the client id to the FW currently appears to be good
     * @param clientId
     * @returns {boolean}
     */
    static hasGoodClientId(clientId):boolean {
        return !(clientId === '' || clientId === -1);
        
    }

    /**
     * returns true if the given mag barcode (YY. or YY<pneumonic>.)
     * matches the given mag barcode profile with pneumonic.
     * This would be used to see if we have a next mag for the given profile or not perhaps.
     * @param magBC
     * @param magProfile
     * @constructor
     */
    static MagBarcodeMatchesMagProfile(magBC:string, magProfile:string) {
        if(magProfile==="*") { // star mag profile matches all mags
            return true;
        }
        const index = magBC.indexOf(".")
        if(index===-1) { // bad barcode
            return false;
        }
        const magPrefix = magBC.substr(0,index);  // ## or ##<pneumonic
        let windex = 0
        let result = false;
        for(const c of magPrefix) {
            if(magProfile.length<=windex) {
                break;  // off the end
            }
            if(magProfile[windex]==="*" || magProfile[windex]===c) {
                result=true;  // good match so far
            }
            else {
                result=false;
                break;
            }
            windex++;            
        }
        if(result===true && magProfile.length > magPrefix.length) { // didn't match the entire mag profile string yet 
            if (magProfile[-1] === "*") { // match all further to the end
                result = true;
            } else { // no match
                result = false;
            }
        }
        
        return result; // no match.
    }

    /**
     * given an input value, try to determine a good / bad input class
     * to surround the input with
     *
     * @param value
     * @param isRequired
     * @param isReadOnly
     * @param needsToBeTrue
     * @returns {string}
     */
    static getValidationClassGivenValue(value, isRequired, isReadOnly, needsToBeTrue=true) {
        let inputClass = 'form-control li-form-field';
        if(isReadOnly) {
            inputClass += ' muted'; // readonly is always muted.
            return inputClass;
        }

        let markWith= "good"; // good by default 
        if(isRequired) {
            if (!value || value === "" || value === -1) markWith = "bad"; // empty is bad
        }
        else {
            inputClass += " border-dark";
            return inputClass;
        }

        if(needsToBeTrue===false) markWith = "bad";

        // update color
        if(markWith==="bad") inputClass += ' li-border-danger'; // mark bad -- **NOTE: don't change this without changed isValidationClassValid below too!
        else if(markWith==="good") inputClass += ' li-border-good' ; // mark good

        return inputClass;
    }

    /**
     * given a validation class string, look at it to determine if it signifies valid or not.
     * @param validationClass
     */
    static isValidationClassValid(validationClass:string):boolean {
        if(!validationClass || validationClass==="") {
            return false; // not valid
        }
        return !validationClass.includes("li-border-danger");
    }

    /**
     * check the given barcode string for a good format.
     * @param barcode
     * @returns {boolean}
     */
    static magBarcodeHasGoodFormat(barcode:string):boolean {
        const re=new RegExp('^[0-9][0-9][a-zA-Z]*.[0-9]{4}.[1-8]$');
        return re.test(barcode);

    }

    /**
     * given a sample or case history note, translate any links in it to useful, clickable,
     * links.
     * @param note
     * @param showSampleLocationLinks - if true, parse and return [link:mag:xxx] showing the location of the sample if
     *                                  these links are present.
     *                                  (default value == false)
     * @param linkClickedHandler - if defined, this function will be called when a link is clicked
     */
    static translateHistoryNote(note:string, showSampleLocationLinks:boolean=false, linkClickedHandler:function=undefined):Element {
        if(!note || note==="") {
            return "";
        }
        
        let count=0;
        let resultList=[];
        let linkIndex=note.indexOf('[link:');
        if(linkIndex===-1) { // no links in here -- just return the note
            return note;
        }
        let workingNote = note;
        while(linkIndex!==-1) { // link, format=[link:<type>:<detail>] (process any links that are here)
            resultList.push(LIUtils._processLinkNote(workingNote,count++, showSampleLocationLinks, linkClickedHandler));
            linkIndex=workingNote.indexOf('[link:',linkIndex+6);
            workingNote = workingNote.substring(linkIndex);
        }
        // convert to JSX
        return <>{resultList}</>;
    }

    static _processLinkNote(note:string, count:Number, showSampleLocationLinks:boolean=false, linkClickedHandler:function=undefined):Object {

        const re = /^(.*?)\[link:(.+?):(.+?)](.*)$/gm;  // multiline
        const m = re.exec(note);
        const prefix=m[1];
        const type = m[2];
        const linkDetail:string = m[3];
        let postFix= m[4];
        if(postFix.indexOf("[link:")!==-1) { // more links in here (post fix is up to the link)
            postFix = postFix.substring(0,postFix.indexOf("[link:"));
        }
        const lineSep = (postFix?<br />:null);
        let path="";
        if(type==="ro") {
            const prettyOrder = LIUtils.prettyOrderNumber(linkDetail);
            path=<a href={"/retrievals/details/"+linkDetail} title="click to see the RO">{prettyOrder}</a>;
        }
        else if(type==="mag") {
            if(showSampleLocationLinks) {
                const [magBC, magSection, magPosition] = linkDetail.split(":");
                const verbiageOnClick = <span><a href={"/mag_details/"+magBC}>Mag {magBC}</a>, Section {magSection}, Position: {magPosition}</span>;
                path = <span className="li-pointer btn-link" title="click to see mag location"
                             onClick={() => linkClickedHandler?linkClickedHandler(verbiageOnClick):null}><i className="fa fa-hand-point-right ml-1 mr-1"/>Mag Location
                       </span>;
            }
            else {
                path = <span className="li-pointer li-fg-primary" title="requires ADMIN level to show" onClick={() => alert("requires ADMIN level to show")}>
                                    &lt;hidden&gt;
                        </span>;
            }
        }
        return (<span key={count}>{prefix}{path}{lineSep}{postFix}</span>);
        
    }
    
    /**
     * search in the inbound string for unicode control chars and kill them
     * JSON converters can't use these and complain if they see them.
     * @param inString
     */
    static removeUnicodeControlChars(inString:string):string {

        if(LIUtils.existsAndNotEmpty()) {
            // stolen from: https://stackoverflow.com/questions/26741455/how-to-remove-control-characters-from-string
            return inString.replace(/[\u0000-\u001F\u007F-\u009F]/g, "");  // eslint-disable-line no-control-regex
        }
        return inString;
    }
    
    /**
     * try to keep the length of the given barcode to that specified, or add
     * truncate dots to it and return it.
     */
    static SanitizeBarcodeToLength(barcode:string, length:number=22):string {
        if(!LIUtils.existsAndNotEmpty(barcode)) {
            return "-";
        }
        if(barcode.length > length) {
            let fixed = barcode.substr(0,length-3);
            fixed += "...";
            return fixed;
        }
        return barcode;
    }

    /**
     * these need to match those comign from the webservice.
     * @param notifType
     * @returns {string}
     */
    static ConvertNotificationTypeToStr(notifType:number):string {

        /**
         *         NONE = 0,
         *         SAMPLE_WAS_RETURNED = 1,
         *         SAMPLE_OVER_DUE = 2,
         *         ADMIN = 4,
         *         SERVICE = 8,
         *         CONSUMABLES = 16,
         *         ALL = 256, // implies all of the ones above (can be bigger than 8bits)
         */

        if(!notifType) {
            return "none";
        }
        let retVal = "";
        if(notifType&1) retVal+="Sample Returned ";
        if(notifType&2) retVal+="Sample Overdue ";
        if(notifType&4) retVal+="Admin ";
        if(notifType&8) retVal+="Service ";
        if(notifType&16) retVal+="Consumables ";
        
        if(retVal==="") {
            retVal = "??";
        }
        return retVal;
    }

    /**
     * these need to match those comign from the webservice.
     * @param notifStatus
     * @returns {string}
     */
    static ConvertNotificationStatusToStr(notifStatus:number):string {

        /**
         *     NEW=1,                      // notification is new, not sent via email, and not read
         *     SENT_OK=2,                  // email notification sent
         *     SEND_FAILED_CONNECTION=4,   // can be retried
         *     SEND_FAILED_USER=8,         // can't be retried
         *     SEND_FAILED_UNKNOWN=16,      // don't know why it failed. (can't be retried).
         *     GUI_READ=32,
         */

        if(!notifStatus) {
            return "none";
        }
        switch(notifStatus) {
            case 1: return "Email New";
            case 2: return "Email sent OK";
            case 4: return "Send Failed: Bad Connection";
            case 8: return "Send Failed: Bad Username";
            case 16: return "Send Failed: Unknown reason";
            case 32: return "GUI New";
            case 64: return "GUI Read";  // not a "saved" state -- don't use
            default: return "??";
        }
    }
    
    static NotificationIsNew(status:number):boolean {
        // if((status & ~32 ) >0) {
        if((status & 32) > 0) {   
            return true;
        }
        return false;
    }
}

export default LIUtils;
