import React from "react";
import PropTypes from "prop-types";
import LIButton from "../GUI_COMMON/COMPONENTS/LIButton";

const NotificationButton = ({numUnread, numNotifs, onClick}) => {
    
    if(numNotifs===0) {
        return null;
    }
    
    function handleClicked(e) {
        if(onClick) {
            onClick();
        }
    }
    
    const flashClass = numUnread>20?"li-flash":null;
    
    return (
        <div className={"position-relative "+flashClass}>
            <LIButton faIcon="fa fa-envelope" bgClass="li-header-button li-notification-icon" tooltip="notifications" onClick={handleClicked}/>
        {/*<button className="li-header-button li-notification-icon px-4" onClick={handleClicked}><i className="fa fa-lg fa-envelope"/></button>*/}
            {/*fa-exclamation-triangle*/}
            {numUnread>0?<span className="li-num-unread-badge badge">{numUnread}</span>:null}
            </div>
    );
}

NotificationButton.propTypes =  { 
    numUnread: PropTypes.number.isRequired,
    numNotifs: PropTypes.number.isRequired,
    onClick: PropTypes.func,
}

export default NotificationButton;