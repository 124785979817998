import React, {useContext, useEffect, useState} from "react"
import LIUtils, {SAMPLE_TYPE_BLOCK, SAMPLE_TYPE_SLIDE} from "../GUI_COMMON/SUPPORT/LIUtils";
import ArchiverClientApi from "../API/archiverClientApi";
import {Redirect, useLocation, useParams} from "react-router-dom";
import SamplesList from "./SamplesList";
import Header from "./Header";
import Footer from "./Footer";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import LIStorage from "../SUPPORT/LIStorage";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import {useHistory} from "react-router";
import {clearWatchList} from "../ACTIONS/clientActions";
import {StoreContext} from "../store";
import {useCookies} from "react-cookie";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const CaseInventoryPage = () => {

    const [,dispatch] = useContext(StoreContext);
    const [isSearchingSlides,setIsSearchingSlides] = useState(false);
    const [isSearchingBlocks,setIsSearchingBlocks] = useState(false);
    const [slideInventory, setSlideInventory] = useState([]);
    const [blockInventory, setBlockInventory] = useState([]);
    const [sampleIsSelected, setSampleIsSelected] = useState(false);
    const [redirectWithPush, setRedirectWithPush] = useState();
    const [redirectToRetrievalOrders, setRedirectToRetrievalOrders] = useState();
    const [includeDeleted, setIncludeDeleted] = useState(false);

    const { caseId, caseNum } = useParams();
    
    const location = useLocation();
    const history = useHistory();
    const [cookies] = useCookies();
    const [comingFromRetrievalOrder] = useState(location.state?.roInProgress);
    const [comingFromRetrievalOrderId] = useState(location.state?.roOrderId);
    
    useEffect(() => {
        // RECALL -- this runs AFTER all children mount!
        console.debug("ShowCaseInventoryPage starting up...");
        window.scrollTo(0,0);
        LIUtils.setBackgroundColor('--li-main-bg-color');
        getCaseSlideInventory(caseId,includeDeleted);
        getCaseBlockInventory(caseId,includeDeleted);
        
        if(!comingFromRetrievalOrder) { // clear out the current order, to build a new one
            LIStorage.clearRetrievalInfo();
        }
    }, [caseId,includeDeleted]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    function getCaseSlideInventory(caseId,includeDeleted) {
        setIsSearchingSlides(true);
        ArchiverClientApi.getCaseSampleInventory(caseId,includeDeleted,SAMPLE_TYPE_SLIDE)
        .then( (samples) => {
            //const slideList = samples?.filter(item => item.sampleType === 1)??[];  // slides
            const sortedSlides = samples.sort((a,b) => a.sampleNumber?.localeCompare(b?.sampleNumber??""));
            setSlideInventory(sortedSlides);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsSearchingSlides(false);
        });
    }

    function getCaseBlockInventory(caseId,includeDeleted) {
        setIsSearchingBlocks(true);
        ArchiverClientApi.getCaseSampleInventory(caseId,includeDeleted,SAMPLE_TYPE_BLOCK)
        .then( (samples) => {
            //const blockList = samples?.filter(item => item.sampleType === 2)??[];  // blocks
            const sortedBlocks = samples.sort((a,b) => a.sampleNumber?.localeCompare(b?.sampleNumber??""));
            setBlockInventory(sortedBlocks);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsSearchingBlocks(false);
        });
    }
    
    function reportNumFound(numFound) {
        return (
            <div className="text-left text-muted">{numFound} total</div>
        );
    }
    
    function handleSampleClicked(sampleId) { 
        setRedirectWithPush(`/sample_details/${sampleId}`); 
    }
    
    function handleSampleRepairClicked(sampleId) {
        setRedirectWithPush(`/attention_edit/${sampleId}`); 
    }
    
    function handleSampleOrderClicked(orderId) {
        setRedirectWithPush(`/retrievals/details/${orderId}`);
    }
    
    function handleSlideCheckAll() {

        function setCBAndParentSlide(cbItem,isChecked) {
            cbItem.checked=isChecked;
            const parentSlide = slideInventory.find( sample => sample.id===Number(cbItem.dataset.parentId));
            parentSlide.checked=isChecked;  // set the parent too
        }

        const isChecked = document.getElementById("slideCB").checked;
        const slideCBs = document.getElementsByName("slide-cb");
        slideCBs.forEach(item => {
                if (item.disabled) { // can't check samples that are out
                    setCBAndParentSlide(item, false);
                } else {
                    setCBAndParentSlide(item, isChecked);
                }
            }
        );
        updateIsSomethingChecked();
    }

    function handleBlockCheckAll(){
        
        function setCBAndParentBlock(cbItem,isChecked) {
            cbItem.checked=isChecked;
            const parentBlock = blockInventory.find( sample => sample.id===Number(cbItem.dataset.parentId));
            parentBlock.checked=isChecked;  // set the parent too
        }
        
        const isChecked = document.getElementById("blockCB").checked;
        const blockCBs = document.getElementsByName("block-cb");
        blockCBs.forEach(item => {
                if (item.disabled) { // can't check samples that are out
                    setCBAndParentBlock(item,false);
                }
                else {
                    setCBAndParentBlock(item,isChecked);
                }
            }
        );
        updateIsSomethingChecked();
    }
    
    function handleSampleCheckChanged(item,isChecked) {
        item.checked=isChecked; // augment
        updateIsSomethingChecked();
    }
    
    function updateIsSomethingChecked() {
        const slideCBs = document.getElementsByName("slide-cb");
        let isSomethingChecked=false;
        slideCBs.forEach( item => { if(item.checked){isSomethingChecked=true;} });
        if(!isSomethingChecked) { // continue to check the blocks
            const blockCBs = document.getElementsByName("block-cb");
            blockCBs.forEach(item => {
                if (item.checked) {
                    isSomethingChecked = true;
                }
            });
        }
        setSampleIsSelected(isSomethingChecked);
    }
    
    function handleMarkForRetrieval() {
        if(!LIUtils.UserHasLevel(cookies,"RETRIEVER")) {
            Popup.show("", "<p>You are not a RETIEVER type user.</p>" +
                "Talk to your Archiver Administrator if you wish to change this.");
            return;
        }

        const checkedSlides = slideInventory?.filter(item => item.checked===true)??[];
        LIStorage.addSamplesToRetrievalList(checkedSlides);

        const checkedBlocks = blockInventory?.filter(item => item.checked===true)??[];
        LIStorage.addSamplesToRetrievalList(checkedBlocks);
        
        setRedirectToRetrievalOrders(true);
    }

    if(redirectToRetrievalOrders) {
        if(comingFromRetrievalOrder) { // redirect to the order we were working on
            return (<Redirect to={{
                    pathname: `/retrievals/details/${comingFromRetrievalOrderId}`,
                    state: {roInProgress: true, roOrderId: comingFromRetrievalOrderId}  // take from localstorage
                }}/>
            );
        }
        
        // new order!
        return (<Redirect to={{
                pathname: `/retrievals/new`,
                state: {roInProgress: true, roOrderId:-1}  // take from localstorage?
            }} push/>
        );
    }
    
    function handleNotifyWhenAvailable() {
        
        const checkSamples = slideInventory?.filter(item => item.checked===true)??[];
        checkSamples.push(...blockInventory?.filter(item => item.checked===true)??[]);
        const idsOnly = checkSamples.map( item => item.id);
        ArchiverClientApi.sendAddSamplesToWatchList(idsOnly)
        .then( (totalAdded) => {
            dispatch(clearWatchList());  // ensure it's loaded again when user wants to see it.
            Popup.show("",`Added ${totalAdded} samples to your watch list`);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError));
    }
    
    function handleBackClick() {
        if(comingFromRetrievalOrder) { // need to ensure we reload and not clear the previous form
            setRedirectToRetrievalOrders(true);
        }
        else {
            history.goBack();
        }
    }
    
    function showMarkForNotifyButton() {
        if(!comingFromRetrievalOrder) {
            return <button className="li-button li-primary li-font-medium h-100 p-2" disabled={!sampleIsSelected}
                           onClick={handleNotifyWhenAvailable}>Notify When Available</button>;
        }
    }

    function handleShowDeletedCBChanged(e) {
        e.stopPropagation();
        setIncludeDeleted(e.target.checked);
    }
    
    if(redirectWithPush) {
        return (
            <Redirect push to={redirectWithPush}/>
        );
    }
    
    return (
        <div className="container">
            <Header/>
            <div className="row li-header-row justify-content-between">
                <div>
                    <BackButton onClick={handleBackClick}/>
                    <span className="li-font-huge mt-2 mb-2">
                        Case: {caseNum}
                    </span>
                </div>
                <div className="li-font-small text-right my-auto ml-auto mr-3">[id: {caseId}]</div>
            </div>
            <div className="text-right mb-4">
                <input type="checkbox" className="m-2 medium align-self-center" name="include-deleted-cb" aria-label="Checkbox"
                       checked={includeDeleted} onChange={(e)=>handleShowDeletedCBChanged(e)}/>
                <label className="li-form-label" htmlFor="include-deleted-cb">show deleted</label>
            </div>
            <div className="mb-4 border-top border-bottom li-border-left-light">
                <div className="row m-2 p-0 justify-content-between">
                    <div>
                        <h3 className="d-inline-block">Slides</h3>
                        {slideInventory.length>1 && <span className="align-self-center">
                            <input type="checkbox" id="slideCB" className="ml-4 mr-2" aria-label="Checkbox" onChange={handleSlideCheckAll}/><i>select all</i>
                        </span>}
                    </div>
                    {reportNumFound(slideInventory.length)}
                </div>
                <div className="li-scrollable-at-250">
                    <SamplesList name="slide-cb" samples={slideInventory} sampleType={1} showCheckBox onSampleCheckChanged={handleSampleCheckChanged}
                                onSampleClicked={handleSampleClicked} onRepairClicked={handleSampleRepairClicked} onOrderClicked={handleSampleOrderClicked}
                                allowCheckIfOut={false} isLoading={isSearchingSlides} showImages/>
                </div>
            </div>
            <div className="mb-5 border-top border-bottom li-border-left-light">
                <div className="row m-2 p-0 justify-content-between">
                    <div>
                        <h3 className="d-inline-block">Blocks</h3>
                        
                        {blockInventory.length>1 && <span className="align-self-center">
                            <input type="checkbox" id="blockCB" className="ml-4 mr-2" aria-label="Checkbox" onChange={handleBlockCheckAll}/><i>select all</i>
                        </span>}
                    </div>
                    {reportNumFound(blockInventory.length)}
                </div>
                <div className="li-scrollable-at-250">
                    <SamplesList name="block-cb" samples={blockInventory} sampleType={2} showCheckBox onSampleCheckChanged={handleSampleCheckChanged} 
                                onSampleClicked={handleSampleClicked} onRepairClicked={handleSampleRepairClicked} onOrderClicked={handleSampleOrderClicked}
                                allowCheckIfOut={false} isLoading={isSearchingBlocks} showImages/>
                </div>
            </div>
            <div className="row justify-content-center">
                <button className="li-button li-primary li-font-medium h-100 p-2 mr-5" disabled={!sampleIsSelected} 
                        onClick={handleMarkForRetrieval}>Mark For Retrieval</button>
                {showMarkForNotifyButton()}
                {/*{showLocationButton()}*/}
            </div>
            <Footer/>
        </div>
    );
}

export default CaseInventoryPage;