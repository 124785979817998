import React, {useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import TextInput from '../GUI_COMMON/COMPONENTS/TextInput';
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import LocationSelector from "./LocationSelector";
import ContactFind from "./ContactFind";
import DatePicker from 'react-date-picker'
import SamplesList from "./SamplesList";
import CaseFind from "./CaseFind";
import TextArea from "../GUI_COMMON/COMPONENTS/TextArea";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import CalendarIcon from "./CalendarIcon";

const RetrievalOrderForm = ({retrievalInfo, onChange, onSubmit, onAddSamples, onDateChanged, onSampleClicked, onSampleOrderClicked,
                                onRemoveSample, onSampleRepairClicked, goodNess, formIsValid, isSaving, formIsReadOnly, isDirty}) => {
    
    const [dateControlInputClass,setDateControlInputClass] = useState("form-control p-0");
    const [locationIsValid, setLocationIsValid] = useState(false);      // set to true if the location component is satisfied with the current selection
    
    useEffect( () => {
        setDateControlInputClass(getDateValidationClassGivenValue(goodNess?.dueBackDate, !formIsReadOnly, formIsReadOnly));
    }, [goodNess?.dueBackDate,formIsReadOnly])
    
    function getDateValidationClassGivenValue(goodNess, isRequired, isReadOnly) {
        let inputClass = 'form-control li-form-field p-0';
        if(isReadOnly) {
            inputClass += ' muted';
            return inputClass;
        } // readonly is always muted.

        let markWith= "good"; // good by default 
        if(isRequired) {
            markWith=goodNess;
        }

        // update color
        if(markWith==="bad") inputClass += ' li-border-danger'; // mark bad
        else if(markWith==="good") inputClass += ' li-border-good' ; // mark good

        return inputClass;
    }
    
    function dumpPermanentRemoveCheckBox() {
        return (
            <div className="text-left">
                <input type="checkbox" className="m-2 mr-3 border-bottom" name="permanentlyRemove"
                       aria-label="Checkbox" checked={retrievalInfo?.permanentlyRemove ?? false}
                       onChange={onChange} readOnly={formIsReadOnly}/>
                <label className="li-form-label" htmlFor="permanentlyRemove">Mark these all for <span className="li-fg-danger font-weight-bold">Permanent Removal</span></label>
            </div>
        );
    }
    
    if(formIsReadOnly===true) {  // define some default empty functions to avoid browser errors when form is in readonly mode.
        onChange=() =>{};
        onAddSamples=()=>{};
    }
    
    function isNewOrder() {
        return retrievalInfo?.orderId==="" || retrievalInfo?.orderId === -1
    }
    
    function onLocationIsValidChanged(isValid) {
        console.log(`*location* isValid=${isValid}`);
        setLocationIsValid(isValid);
    }
    
    return (
        <form id="retrievalOrderForm">
            {formIsReadOnly && <h6 className="text-right li-fg-muted">
                <i className="fa fa-exclamation-circle"/> Form is read-only.</h6>}
            <div className="card border-0">
                <div>
                    <label className="li-form-label m-0 p-0">Created: <span className="li-fg-muted">
                        {(retrievalInfo?.createdOn)?LIUtils.dateToShortDateTimeString(retrievalInfo?.createdOn):" (now)"}</span>
                    </label>
                </div>
                <div className="row">
                    <div className="col-5">
                        <TextInput name="createdByName" label="Created By" readOnly onChange={onChange}
                                   placeholder=""
                                   value={retrievalInfo?.createdByName??""}
                                   required/>
                    </div>
                    <div className="col-7 offset-0">
                       <ContactFind widgetName="requestedBy" onContactSelected={onChange} 
                                    initialValue={
                                        {   
                                            name:retrievalInfo?.requestedByName??"",
                                            id:retrievalInfo?.requestedById??-1,
                                        }
                                    }
                                    required readOnly={formIsReadOnly}/>
                    </div>
                </div>
                            
               <LocationSelector onChange={onChange} 
                                 locationValue={retrievalInfo?.destLocation??""} 
                                 departmentValue={retrievalInfo?.destDepartment??""} 
                                 contactValue={retrievalInfo?.destContact??""}
                                 onIsValid={onLocationIsValidChanged} required readOnly={formIsReadOnly}/>
            </div>

            <TextArea name="notes" label="Notes" onChange={onChange} placeholder="" value={retrievalInfo?.notes??""} rows={3}/>


            <div className="row m-0 p-0">
                <div>
                    <label className="li-form-label" htmlFor="dueBackDate">Date Due Back</label>
                    <DatePicker className={dateControlInputClass} name="dueBackDate" clearIcon={null} calendarType="US"
                                onChange={onDateChanged} value={retrievalInfo?.dueBackDate??null} calendarIcon={<CalendarIcon/>}
                                required disabled={formIsReadOnly}/>
                    <small className="form-text text-muted">* month/day/year</small>
                </div>
                <div className="offset-1">
                    <label className="li-form-label" htmlFor="caseFind">Add Samples From Case...</label>
                    <CaseFind onItemSelected={onAddSamples} name="caseFind" goodNess={goodNess?.haveSamples} 
                              readOnly={formIsReadOnly}/>
                </div>
            </div>
            
            <div className="mt-4 li-slideblock-list">
                <div className="li-font-small font-weight-bold">Samples Currently On This Order</div>
                <div className="li-border-line"/>
                <h3 className=" p-2 border-bottom">Slides</h3>
                <SamplesList name="slide-cb" samples={retrievalInfo?.samples??[]} sampleType={1} onSampleClicked={onSampleClicked} 
                             onOrderClicked={onSampleOrderClicked} showDelete onDeleteClicked={onRemoveSample}
                             onRepairClicked={onSampleRepairClicked}
                             readOnly={formIsReadOnly} 
                             thisOrderId={retrievalInfo.orderId}/>
                <h3 className="mt-3 p-2 border-bottom">Blocks</h3>
                <SamplesList name="block-cb" samples={retrievalInfo?.samples??[]} sampleType={2} onSampleClicked={onSampleClicked}
                             onOrderClicked={onSampleOrderClicked} showDelete onDeleteClicked={onRemoveSample}
                             onRepairClicked={onSampleRepairClicked}
                             readOnly={formIsReadOnly} thisOrderId={retrievalInfo.orderId}/>
            </div>
            
            {dumpPermanentRemoveCheckBox()}
            
            <div className="row justify-content-center">
                {
                    !formIsReadOnly &&
                        <button type="button" className="li-button li-primary li-font-large px-5 mt-2" onClick={onSubmit}
                                disabled={!(formIsValid && locationIsValid && isDirty)}>{isSaving?<LoadingSpinner small/> : isNewOrder()?"Add Order":"Update Order"}</button>
                }
            </div>
        </form>
    );
};

RetrievalOrderForm.propTypes = {
    retrievalInfo: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func, 
    goodNess: PropTypes.object,
    onAddSamples: PropTypes.func,
    formIsValid: PropTypes.bool,
    isSaving: PropTypes.bool,
    onDateChanged: PropTypes.func,
    onSampleClicked: PropTypes.func,
    onSampleOrderClicked: PropTypes.func,
    onSampleRepairClicked: PropTypes.func,
    onRemoveSample: PropTypes.func,
    formIsReadOnly: PropTypes.bool,
    isDirty: PropTypes.bool,
};

export default RetrievalOrderForm;