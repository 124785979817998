import React, {useEffect, useState} from "react";
import Header from "./Header";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import ArchiverClientApi from "../API/archiverClientApi";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import {Redirect} from "react-router-dom";
import Footer from "./Footer";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import ConsumablesForm from "./ConsumablesForm";
import LIButton from "../GUI_COMMON/COMPONENTS/LIButton";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const ConsumablesPage = () => {
    
    const SLIDE_MAG_USAGE_REPORT_NAME = "slide mag usage rate";
    const BLOCK_MAG_USAGE_REPORT_NAME = "block mag usage rate";
    const BOX_USAGE_REPORT_NAME = "box usage rate";
    const SERVER_NO_USAGE_ESTIMATE_AVAILABLE_MAGIC = 9999;   // this needs to match the server's idea of this MAGIC number

    const [isLoading, setIsLoading] = useState(false);
    const [isSavingInventory, setIsSavingInventory] = useState(false);
    const [redirectTo, setRedirectTo] = useState();
    const [inventory, setInventory] = useState({
        slideMags:0,
        blockMags: 0,
        box8Mags: 0
    });
    const [dailyUsage, setDailyUsage] = useState({
        slideMags: 0,
        blockMags: 0,
        box8Mags: 0
    });
    const [weeklyUsage, setWeeklyUsage] = useState({
        slideMags: 0,
        blockMags: 0,
        box8Mags: 0
    });
    const [estDaysRemaining, setEstDaysRemaining] = useState({
        slideMags: 0,
        blockMags: 0,
        box8Mags: 0
    });
    const [alertAt, setAlertAt] = useState({
        slideMags: 0,
        blockMags: 0,
        box8Mags: 0,
    });
    const [adjustIsBusy, setAdjustIsBusy] = useState({
        slideMags: false,
        blockMags: false,
        box8Mags: false
    });
    const [alertAtIsBusy, setAlertAtIsBusy] = useState({
        slideMags: false,
        blockMags: false,
        box8Mags: false
    });

    useEffect(() => {
        console.debug("ConsumablesPage starting up...");
        setIsLoading(true);
        ArchiverClientApi.getConsumables()
        .then(({numConsumables,consumables}) => {
            console.debug(`processing ${numConsumables} consumables...`);
            setupAllValuesGivenConsumables(consumables);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsLoading(false));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    function setupAllValuesGivenConsumables(consumables:Array) {
        const newInventory = {...inventory};
        const newAlertAt = {...alertAt};
        const newEstDaysRemaining = {...estDaysRemaining};
        const newDailyUsage = {...dailyUsage};
        const newWeeklyUsage = {...weeklyUsage};
        consumables.forEach( item => {
            const itemType = item.name;
            newInventory[itemType]=item.numInStock;
            newAlertAt[itemType]=item.alertLevel;
            newEstDaysRemaining[itemType]=item.estDaysRemaining;
            newDailyUsage[itemType]=item.dailyUsage;
            newWeeklyUsage[itemType]=item.weeklyUsage;
        });
        setInventory(newInventory);
        setAlertAt(newAlertAt);
        setEstDaysRemaining(newEstDaysRemaining);
        setDailyUsage(newDailyUsage);
        setWeeklyUsage(newWeeklyUsage);
    }
    
    function setStockAndEstDaysForAll(slideMagsInfo, blockMagsInfo, box8MagInfo) {
        const newInventory = {...inventory};
        newInventory["slideMags"] = slideMagsInfo.numInStock;
        newInventory["blockMags"] = blockMagsInfo.numInStock;
        newInventory["box8Mags"] = box8MagInfo.numInStock;
        setInventory(newInventory);

        const newEstDaysRemaining = {...estDaysRemaining};
        newEstDaysRemaining["slideMags"] = slideMagsInfo.estDaysRemaining;
        newEstDaysRemaining["blockMags"] = blockMagsInfo.estDaysRemaining;
        newEstDaysRemaining["box8Mags"] = box8MagInfo.estDaysRemaining;
        setEstDaysRemaining(newEstDaysRemaining);

        
    }
    
    //#region ADJUST
    function handleAdjustStock(itemType:string) {
        Popup.showPrompt("Adjust Stock",inventory[itemType],"Update",()=>{},(newValue)=>{
            const newStockNum = Number(newValue);
            if(!Number.isInteger(newStockNum) || newStockNum<0) {
                ErrorPopup.showError("ERROR","Stock Level needs to be 0 or a positive number");
                return;
            }
            doAdjustInventory(itemType,newStockNum,null); // no change in alertAt level
        },1,"true stock value");
    }
    
    function handleAdjustAlertAt(itemType:string) {
        Popup.showPrompt("Alert when this many <u>days</u> of inventory remain",alertAt[itemType],"Update",()=>{},(newValue)=>{
            const newAdjustNum = Number(newValue);
            if(!Number.isInteger(newAdjustNum) || newAdjustNum<=0) {
                ErrorPopup.showError("ERROR","Alert Level needs to be a positive number");
                return;
            }
            doAdjustInventory(itemType,null,newAdjustNum); // no change in inventory level
        },1,"alert at level");
    }
    
    function doAdjustInventory(itemType:string, newStockValue:Number, newAlertAt:Number) {
        if(newStockValue!==null) {
            updateAdjustingIsBusy(itemType, true);
        }
        if(newAlertAt!==null) {
            updateAlertAtIsBusy(itemType, true);
        }
        ArchiverClientApi.adjustInventory(itemType,newStockValue,newAlertAt)
             .then(({itemType,numInStock,alertLevel,updatedEstDaysRemaining}) => {
                 if(numInStock!=null && numInStock>=0) {
                     const newInventory = {...inventory};
                     newInventory[itemType] = numInStock;
                     setInventory(newInventory);
                     const newEstDaysRemaining={...estDaysRemaining};
                     newEstDaysRemaining[itemType]=updatedEstDaysRemaining;
                     setEstDaysRemaining(newEstDaysRemaining);
                 }
                 if(alertLevel!=null && alertLevel>=0) {
                     const newAlertAt = {...alertAt};
                     newAlertAt[itemType] = alertLevel;
                     setAlertAt(newAlertAt);
                 }
             })
             .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
             .finally( () => {
                 updateAdjustingIsBusy(itemType,false);
                 updateAlertAtIsBusy(itemType,false);
             });
    }
    
    function updateAdjustingIsBusy(itemType:string, busyFlag:boolean) {
        let curAdjustIsBusy={...adjustIsBusy};
        curAdjustIsBusy[itemType]=busyFlag;
        setAdjustIsBusy(curAdjustIsBusy);
    }
    function updateAlertAtIsBusy(itemType:string, busyFlag:boolean) {
        let curAlertAtIsBusy={...adjustIsBusy};
        curAlertAtIsBusy[itemType]=busyFlag;
        setAlertAtIsBusy(curAlertAtIsBusy);
    }
    
    //#endregion ADJUST
    
    //#region ADD_INVENTORY
    
    function handleAddInventoryClicked(e,shipmentValues:Object) {
        
        setIsSavingInventory(true);
        ArchiverClientApi.addConsumableToInventory(Number(shipmentValues.slideMags),Number(shipmentValues.blockMags), Number(shipmentValues.boxes))
        .then( ({slideMags, blockMags, boxes8Mag}) => {
            setStockAndEstDaysForAll(slideMags,blockMags,boxes8Mag);
            const msg = `<p>Inventory has been updated</p>`;
            Popup.showInfo(msg);
        })
        .catch( errorResponse => ErrorPopup.showError("Save Failed",errorResponse.error))
        .finally( () => {
            setIsSavingInventory(false);
        });
    }
    
    //#endregion ADD_INVENTORY
    
    function sanitizeNumberWithMagic(inBoundValue:Number, numDecimals:Number=2) {
        if(inBoundValue===null || inBoundValue===undefined || inBoundValue===SERVER_NO_USAGE_ESTIMATE_AVAILABLE_MAGIC) {
            return "--";
        }
        return inBoundValue.toFixed(numDecimals);
    }
    
    function handleGetUsageStats(itemType:string) {
        
        let reportName;
        switch(itemType) {
            case "slideMags": reportName=SLIDE_MAG_USAGE_REPORT_NAME; break;
            case "blockMags": reportName=BLOCK_MAG_USAGE_REPORT_NAME; break;
            case "box8Mags": reportName=BOX_USAGE_REPORT_NAME; break;
            default: 
                console.error(`unknown item type provided '${itemType}' - ignoring request`);
                return;
        }
        
        ArchiverClientApi.getReportDetailsGivenName(reportName) // name is important here
        .then( ({basicInfo}) => {
            setRedirectTo("/run_report/"+Number(basicInfo.id));
        })
        .catch( responseError => {
            let error=responseError?.error??"";
            if(responseError.statusCode===404) {
                error = `A report with the name '${reportName}' was not found.<p class='mt-3'>Please create or rename a `+
                    "report that you wish to run whenever you press the 'Usage History' button.</p>";
            }
            ErrorPopup.showError("Problem",error)
        });
    }
    
    function showConsumablesTable() {
        const slideInventory = <tr>
            <td>Slide Mags</td>
            <td>{inventory.slideMags}<LIButton small faIcon="fa fa-pen" bgClass="ml-3" onClick={()=>handleAdjustStock("slideMags")} showBusy={adjustIsBusy.slideMags}/></td>
            <td>{sanitizeNumberWithMagic(weeklyUsage.slideMags,2)}<LIButton small label="" bgClass="fa fa-chart-line ml-3" onClick={()=>handleGetUsageStats("slideMags")}/></td>
            <td>{sanitizeNumberWithMagic(estDaysRemaining.slideMags,0)}{showAlertIfNeeded(estDaysRemaining.slideMags,alertAt.slideMags)}</td>
            <td>{alertAt.slideMags}<LIButton small faIcon="fa fa-cog" bgClass="ml-3" onClick={()=>handleAdjustAlertAt("slideMags")} showBusy={alertAtIsBusy.slideMags}/></td>
            </tr>;
        const blockInventory = <tr>
            <td>Block Mags</td>
            <td>{inventory.blockMags}<LIButton small faIcon="fa fa-pen" bgClass="ml-3" onClick={()=>handleAdjustStock("blockMags")} showBusy={adjustIsBusy.blockMags}/></td>
            <td>{sanitizeNumberWithMagic(weeklyUsage.blockMags,2)}<LIButton small label="" bgClass="fa fa-chart-line ml-3" onClick={()=>handleGetUsageStats("blockMags")}/></td>
            <td>{sanitizeNumberWithMagic(estDaysRemaining.blockMags,0)}{showAlertIfNeeded(estDaysRemaining.blockMags,alertAt.blockMags)}</td>
            <td>{alertAt.blockMags}<LIButton small faIcon="fa fa-cog" bgClass="ml-3" onClick={()=>handleAdjustAlertAt("blockMags")} showBusy={alertAtIsBusy.blockMags}/></td>
        </tr>;
        const boxInventory = <tr>
            <td>Boxes (8Mag)</td>
            <td>{inventory.box8Mags}<LIButton small faIcon="fa fa-pen" bgClass="ml-3" onClick={()=>handleAdjustStock("box8Mags")} showBusy={adjustIsBusy.box8Mags}/></td>
            <td>{sanitizeNumberWithMagic(weeklyUsage.box8Mags,2)}<LIButton small label="" bgClass="fa fa-chart-line ml-3" onClick={()=>handleGetUsageStats("box8Mags")}/></td>
            <td>{sanitizeNumberWithMagic(estDaysRemaining.box8Mags,0)}{showAlertIfNeeded(estDaysRemaining.box8Mags,alertAt.box8Mags)}</td>
            <td>{alertAt.box8Mags}<LIButton small faIcon="fa fa-cog" bgClass="ml-3" onClick={()=>handleAdjustAlertAt("box8Mags")} showBusy={alertAtIsBusy.box8Mags}/></td>
        </tr>;
        
        return <>
                <h1>Current Inventory</h1>
                <table id="consumablesTable" className="table li-bg-pale-blue text-center">
                    <thead className="li-bg-primary">
                        <tr>
                            <th className="align-middle">Item</th>
                            <th className="align-middle">In Stock</th>
                            <th className="align-middle">Avg Usage (weekly)</th>
                            <th className="align-middle">Est Days Remaining</th>
                            <th className="align-middle">Alert At (Days)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {slideInventory}
                        {blockInventory}
                        {boxInventory}
                    </tbody>
                </table>
            </>
    }
    
    function showAlertIfNeeded(daysRemaining:Number,alertLevel:Number) {
        /**
         * we show an alert if days remaining is below the alert level 
         * OR 
         * An unknown symbol if we match a magic value for estDaysReaminign coming from the server (9999)
         */
        if(daysRemaining===SERVER_NO_USAGE_ESTIMATE_AVAILABLE_MAGIC || daysRemaining===null) {
            return;
        }
        if(daysRemaining <= alertLevel) {
            return <i className="ml-2 fa fa-lg fa-exclamation-triangle li-fg-warn-light"/>;
        }
        return null;
    }
    
    if(redirectTo) {
        return (
            <Redirect push to={redirectTo}/>
        );
    }
    
    return(
        <div className="container">
            <Header/>
            <div className="row li-header-row m-0 p-0 justify-content-between">
                <div>
                    <BackButton onClick={() => setRedirectTo('/dashboard')}/>
                    <h1 className="d-inline-block align-middle">Consumables</h1>
                </div>
            </div>
            <div className="my-5 li-font-medium" >
                {isLoading && <LoadingSpinner medium center/>}
                {!isLoading && showConsumablesTable()}
            </div>
            <h4>
            * If you need to order consumables, please call <a href="http://labimprovements.com" target="_blank" rel="noreferrer">Lab Improvements</a> at +1-705-910-0518.
            </h4>
            <div className="my-5">
                <ConsumablesForm onSubmitClicked={handleAddInventoryClicked} isSaving={isSavingInventory}/>
            </div>
            <Footer/>
        </div>
    );
}

export default ConsumablesPage;