import Header from "./Header";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import Footer from "./Footer";
import React, {useContext, useEffect, useState} from "react";
import RetrievalOrderForm from "./RetrievalOrderForm";
import ArchiverClientApi from "../API/archiverClientApi";
import {StoreContext} from "../store";
import {Prompt, Redirect, useLocation, useParams} from "react-router-dom";
import LIStorage from "../SUPPORT/LIStorage";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import {useCookies} from "react-cookie";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const AddUpdateROPage = () => {
    
    const DEFAULT_DUE_DATE_INTERNAL_LOCATION = 14;  // default due date for internal destinations
    const DEFAULT_DUE_DATE_EXTERNAL_LOCATION = 30;  // default due date for external destinations
    
    const initialRetrievalsInfo = {
        saved: true,
        orderId: "",
        createdOn: new Date(),
        createdByName: "",
        createdById: -1,
        
        requestedByName: "",
        requestedById: -1,

        destLocation: -1,
        destDepartment: -1,
        destContact: -1,

        dueBackDate: new Date(),
        permanentlyRemove: false,

        notes: "",
        samples: []
    };
    
    const [globalState,] = useContext(StoreContext);
    const [infoGoodnessList, setInfoGoodnessList] = useState({});
    const [retrievalInfo, setRetrievalInfo] = useState(initialRetrievalsInfo);
    const [formIsValid, setFormIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [redirectToCaseInventory, setRedirectToCaseInventory] = useState(false);
    const [redirectToReplace, setRedirectToReplace] = useState();
    const [redirectToPush, setRedirectToPush] = useState();
    const [redirectToEditorWithSample, setRedirectToEditorWithSample] = useState();
    const [selectedCase, setSelectedCase] = useState();
    const [orderStatus, setOrderStatus] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [checkForCompleted, setCheckForCompleted] = useState(false);
    const [savedFlag, setSavedFlag]=useState(true);     //  true if the form hasn't been updated
    const [isInitializing, setIsInitializing] = useState(true);
    
    let {orderId} = useParams();
    if(!orderId) {
        orderId=-1;
    }
    const location = useLocation();
    const [cookies] = useCookies();
    const [comingFromCaseInventory] = useState(location.state?.roInProgress);
    
    useEffect( () => {
        if (orderId) {
            if (Number(orderId) === -1) { // new order - level needs to match.
                if (!LIUtils.UserHasLevel(cookies, "RETRIEVER")) {
                    Popup.show("", "<p>You are not a RETRIEVER type user.</p>" +
                        "Talk to your Archiver Administrator if you wish to change this.", () => {
                        setRedirectToPush("/dashboard");
                    });
                }
            }
            if (comingFromCaseInventory === true || orderId==="" || orderId===-1) {
                console.debug("addUpdateRO: [1] coming from case inventory...copying info over");
                readRetrievalInfoFromStorage();
                setIsInitializing(false);
            } else {
                setIsLoading(true);
                setIsInitializing(true);
                ArchiverClientApi.getRetrievalOrderGivenId(orderId)
                .then((response) => {
                    setupFormFieldData(response.order, response.samples);
                })
                .catch(responseError => {
                    ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError, () => {
                        setRedirectToPush("/retrievals");
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                    setIsInitializing(false);
                    setCheckForCompleted(true);
                });
            }
        }
    },[cookies,orderId]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        if (!comingFromCaseInventory===true) {
        //     console.debug("addUpdateRO: [2] coming from case inventory...copying info over");
        //     readRetrievalInfoFromStorage();
        //     setSavedFlag(retrievalInfo?.saved); // init from storage value
        // } else {
            handleClearForm();
        }
    },[comingFromCaseInventory,globalState.clientInfo]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        checkForValid();
        if(retrievalInfo && !comingFromCaseInventory) {
            LIStorage.setRetrievalInfo(retrievalInfo);
        }
    },[retrievalInfo]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        if(checkForCompleted) {
            console.debug("checking for RO is completed...");
            if (retrievalInfo.samples.length > 0 && !shouldFormBeReadOnly()) {
                checkForOnlyPickedSamples(retrievalInfo.samples);
            }
            setCheckForCompleted(false); // reset
        }
    },[checkForCompleted]);  // eslint-disable-line react-hooks/exhaustive-deps

    function shouldFormBeReadOnly() {
        if(!LIUtils.UserHasLevel(cookies,"RETRIEVER")) { // can't edit - ever
            return true;
        }
        return orderStatus.isPickedCompleted || orderStatus.isSent;
    }
    
    function showClearButtonIfRequired() {
        if(orderId!==-1) {
            return null;
        }
        return(
            <button className="btn btn-success align-self-center mr-4" onClick={handleClearForm}>
                Clear
            </button>
        );
    }

    /**
     * reorder the information coming from the server into something that
     * our form understands, so we can reuse the form to display the details.
     *
     * @param order
     * @param samples
     */
    function setupFormFieldData(order, samples) {

        const formFormattedInfo = initialRetrievalsInfo;
        formFormattedInfo.orderId = orderId ? Number(orderId) : null;
        formFormattedInfo.createdOn = LIUtils.dateFromJsonUtcDateStr(order.dateStarted);
        formFormattedInfo.createdById = order.creator.id;
        formFormattedInfo.createdByName = order.creator.fullName;
        formFormattedInfo.requestedByName = order.requester.fullName;
        formFormattedInfo.requestedById = order.requester.id;
        formFormattedInfo.destLocation = order.destinationLocation?.id;
        formFormattedInfo.destDepartment = order.destinationDepartment?.id;
        formFormattedInfo.destContact = order.destinationContact?.id;
        formFormattedInfo.dueBackDate = LIUtils.dateFromJsonUtcDateStr(order.dateDueBack);
        formFormattedInfo.permanentlyRemove = order.permanentlyRemove;
        formFormattedInfo.notes = order.notes;
        formFormattedInfo.samples = samples;
        
        // order is important here!
        setOrderStatus({
            isPickedCompleted: order.isPickCompleted,
            isSent: order.isSent
        })
        setRetrievalInfo(formFormattedInfo);
    }

    function readRetrievalInfoFromStorage() {
        let curInfo = LIStorage.getRetrievalInfo();
        
        // convert the date from a string back to a date -- ### hack
        curInfo.dueBackDate = curInfo.dueBackDate?new Date(curInfo.dueBackDate):new Date();
        // always refresh this
        curInfo.createdByName = globalState.clientInfo.fullName;
        curInfo.createdById = globalState.clientInfo.userId;
        curInfo.createdOn = curInfo.createdOn?new Date(curInfo.createdOn):new Date();
        setRetrievalInfo(curInfo);
        setSavedFlag(curInfo?.saved); // init from storage value now
    }
    
    function updateRetrievalValue(name,value) {
        const curInfo = {...retrievalInfo};
        curInfo[name]=value;
        
        // these 2 need to stay in sync
        curInfo["saved"]=false;
        setSavedFlag(false);

        setRetrievalInfo(curInfo);
        LIStorage.setRetrievalInfo(curInfo);        // update the backer
    }
    
    function markFormIsDirty(isDirty:boolean) {
        const curInfo = {...retrievalInfo};
        
        // keep these 2 in sync
        curInfo["saved"]=!isDirty;
        setSavedFlag(!isDirty);

        setRetrievalInfo(curInfo);
        LIStorage.setRetrievalInfo(curInfo);        // update the backer
    }

    function handleAddSamples(e) {
        const caseItem = e.target.item;
        if (!caseItem || !caseItem.id || !caseItem.number) {
            ErrorPopup.showError("ERROR", "unknown case");
            return;
        }
        setSelectedCase(caseItem);
        setRedirectToCaseInventory(true);
    }
    
    function handleDueDateChanged(value) {
        updateRetrievalValue("dueBackDate",value);
    }
    
    function handleClearForm() {
        console.debug("clearing form...");

        initialRetrievalsInfo["createdByName"]=globalState.clientInfo.fullName;
        initialRetrievalsInfo["createdById"]=globalState.clientInfo.userId;
        LIStorage.clearRetrievalInfo(initialRetrievalsInfo);
        
        // not saved and clean
        setRetrievalInfo({...initialRetrievalsInfo}); // copy so it changes
        setSavedFlag(true);
    }
    
    function handleInfoChanged(event) {
        console.debug(`handleInfoChanged for ${event.target.name}`);
        let updateInfo = {...retrievalInfo};
        const thing = event.target.name;

        switch (thing) {
            case "permanentlyRemove":
                // convert
                updateInfo[thing] = event.target.checked;
                break;
            case "destLocation":
                const locId = Number(event.target.value)
                updateInfo[thing] = locId;
                updateInfo["destDepartment"] = -1;
                updateInfo["destContact"] = -1;
                const preferredDueDate = getReturnDateBasedOnLocation(locId);
                updateInfo["dueBackDate"] = preferredDueDate;
                break;
            case "destDepartment":
                updateInfo[thing] = Number(event.target.value);
                updateInfo["destContact"]=-1;
                break;
            case "destContact":
                updateInfo[thing] = Number(event.target.value);
                break;
            case "requestedBy":
                const contactItem = event.target["contact"];  // augmented
                updateInfo["requestedById"] = contactItem.id;
                updateInfo["requestedByName"] = contactItem.fullName;
                break;
            default:
                updateInfo[thing] = event.target.value;
                break;
        }
        
        // these 2 need to stay in sync
        if (!isInitializing) {
            updateInfo["saved"] = false;
            setSavedFlag(false);
        }

        LIStorage.setRetrievalInfo(updateInfo);
        setRetrievalInfo(updateInfo);
    }
    
    function getReturnDateBasedOnLocation(locationId):Date {
        let nowDate = new Date();
        const selectedDestLocation = globalState.locations.find( item => item.id===locationId);
        if(selectedDestLocation) {
            const isInternal:boolean=selectedDestLocation.internal;
            let dueBackDays:Number = selectedDestLocation.dueBackDays;
            console.debug(`selected location id '${locationId}' isInternal: '${isInternal}' dueBackDays: '${dueBackDays}'`);
            
            if(!dueBackDays || dueBackDays<=0) { // go with default
                dueBackDays = isInternal ? DEFAULT_DUE_DATE_INTERNAL_LOCATION : DEFAULT_DUE_DATE_EXTERNAL_LOCATION;
            }
            nowDate.setDate(nowDate.getDate()+dueBackDays);
        }
        return nowDate;
    }
    
    function checkForValid() {
        const goodNessList = {...infoGoodnessList};
        let isValid=0;
        if(retrievalInfo.requestedById>0 && retrievalInfo.requestedByName!=="") { // mark green
            isValid|=1;
            goodNessList["requestedBy"]="good";
        }
        else {
            goodNessList["requestedBy"]="bad";
        }
        if(LIUtils.existsAndNotEmpty(retrievalInfo.createdByName)) { // mark green
            isValid|=2;
        }
        if(hasDestination()) { // mark green
            isValid|= 4;
            goodNessList["destLocation"]="good";
        }
        else {
            goodNessList["destLocation"]="bad";
        }
        if(dueBackDateIsGood()) { // mark green
            isValid|= 8;
            goodNessList["dueBackDate"]="good";
        }
        else {
            goodNessList["dueBackDate"]="bad";
        }
        // if(retrievalInfo.samples && retrievalInfo.samples.length>0) { // mark green
        //     isValid|= 16;
        //     goodNessList["haveSamples"]="good";
        // }
        // else {
        //     goodNessList["haveSamples"]="bad";
        // }

        setInfoGoodnessList(goodNessList);
        setFormIsValid(isValid >= 15); // all good if all flags=1
    }
    
    function hasDestination() {
        return retrievalInfo.destLocation>0;
    }
    
    function dueBackDateIsGood() {
        const date = retrievalInfo.dueBackDate;
        const ticks = date.getTime();
        return date && ticks>Date.now();
            
    }
    
    function handleFormSubmit(event) {
        event.stopPropagation(); // let the validation checks run
        event.preventDefault();

        const form = document.getElementById('retrievalOrderForm');
        if (form.checkValidity() === false) {
            return;
        }
        if(retrievalInfo.permanentlyRemove) { // show warning.
            Popup.showConfirm("Permanently Remove Reminder","<p>You have Permanent Removal enabled for all samples.</p>" +
                            "<p>This means any samples picked from this RO will be <strong>permanently removed from the system</strong>.</p>",
                            "Yes, This is OK",null,() => {
                doFormSave();
            });
        }
        else {
            doFormSave();
        }
    }
    
    function doFormSave() {
        setIsSaving(true);
        if(isNewOrder()) { // ADD NEW ORDER
            ArchiverClientApi.sendAddRetrievalOrder(retrievalInfo)
            .then(({order, samples}) => {
                Popup.show("Saved", `Retrieval Order ${LIUtils.prettyOrderNumber(order.id)} Saved`, () => {
                    markFormIsDirty(false);
                    LIStorage.clearRetrievalInfo(); // ensure our long term store is clear
                    setRedirectToReplace("/retrievals");  // force getting the order id setup
                });
            })
            .catch((error) => {
                Popup.showError("Problem", error);
            })
            .finally(() => {
                setIsSaving(false);
            });
        }
        else { // UPDATE EXISTING ORDER
            ArchiverClientApi.sendUpdateRetrievalOrder(orderId,retrievalInfo)
            .then(({order, samples}) => {
                Popup.show("Saved", `Retrieval Order ${LIUtils.prettyOrderNumber(order.id)} Updated`, () => {
                    markFormIsDirty(false);
                    // LIStorage.clearRetrievalInfo(); // ensure our long term store is clear
                    // setRedirectToReplace("/retrievals");
                    setCheckForCompleted(true);
                });
            })
            .catch((error) => {
                Popup.showError("Problem", error);
            })
            .finally(() => {
                setIsSaving(false);
            });
        }
    }
    
    function handleSampleClicked(sampleId) { //barcode,caseNumber,sampleNumber) {
        setRedirectToPush(`/sample_details/${sampleId}`); //${barcode}/${caseNumber}/${sampleNumber}`)
    }

    function handleSamplePendingOrderClicked(orderId) {
        window.location.replace(`/retrievals/details/${orderId}`); // # ### full refresh here, which seems extreme -pott 220201
        //isetRedirectToPush();
    }
    
    function sampleHasBeenPicked(sample) {
        return sample.parentOrderId===Number(orderId) && sample.datePickCompleted!=null;
    }
    
    function handleRemoveSample(sampleId) {
        const sample = retrievalInfo.samples?.find( item => item.id===sampleId);
        if(sampleHasBeenPicked(sample)) { // this sample has already been picked
            
            Popup.show("Sample Cannot Be Removed","This sample has already been picked and can no longer be removed from the order",null);
            return;
        }
        const samples = retrievalInfo.samples?.filter(item => item.id!==sampleId)??[];
        updateRetrievalValue("samples",samples);
    }

    function handleSampleRepairClicked(sampleId) {
        setRedirectToEditorWithSample(sampleId);
    }
    
    function generatePageTitle() {
        if (orderId === -1) {
            return (<h1 className="d-inline-block align-middle">New Retrieval Order</h1>);
        }
        return(
            <div>
                <h1 className="d-inline-block align-middle">Retrieval Order</h1>
                <div className="text-right li-font-medium"><i>Order #:</i> {LIUtils.prettyOrderNumber(retrievalInfo?.orderId)}</div>
            </div>
        );
    }
    
    function isNewOrder():boolean {
        return !orderId || orderId==="" || Number(orderId)===-1;
    }
    
    function showPickAndPrintIfRequired() {
        if (orderStatus.isPickedCompleted || isNewOrder()) { // don't show for completed or new orders
            return null;
        }
        
        return(
            <div className="text-right mt-3">
                <button className="li-button li-primary mr-5" onClick={handleGeneratePickSheet}>Generate Pick sheet</button>
                <button className="li-button li-primary" onClick={handlePrintPage}>Print</button>
            </div>
        );
    }

    function handleGeneratePickSheet() {
        
        if(LIUtils.UserHasLevel(cookies,"PICKER")) {
            if(!savedFlag) {
                Popup.showConfirm("","Changes have not been saved.  Really leave?","Yes",null,() => {
                    setRedirectToPush("/pick_sheet?" + orderId);
                });
                return;
            }
            setRedirectToPush("/pick_sheet?" + orderId);  
        }
        else {
            Popup.show("", "<p>You are not a SAMPLE PICKER type user.</p>" +
                "Talk to your Archiver Administrator if you wish to change this.");
        }
    }
    function handlePrintPage() {
        window.print();
    }
    
    function checkForOnlyPickedSamples(samples) {
        // get the samples that are either not picked yet, or brand new added
        const unpicked = samples.filter(s => (s.datePickCompleted===null || s.datePickCompleted===undefined));
        if(unpicked.length===0 && samples.length>0) { // only picked remain
            Popup.showConfirm("Complete the Order?","All samples on this order have been picked.<p>Do you want to move it to Unsent/Completed?",
            "Yes",null,() => {
                    ArchiverClientApi.markRetrievalAsPicked(orderId)
                    .then(() => {
                        LIStorage.clearRetrievalInfo(); // ensure our long term store is clear
                        setRedirectToReplace("/retrievals");
                    })
                    .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
                    .finally(() => {
                        setIsSaving(false);
                    });
                });
        }
    }
    
    function showPrompt() {
        return (
            <Prompt
                when={!savedFlag}
                message="Changes have not been saved.  Really leave?"
            />
        );
    }

    if(redirectToCaseInventory) {
        const stateObj = {roInProgress: true, roOrderId: orderId};
        return( <Redirect to={{
                pathname: `/case_inventory/${selectedCase.id}/${selectedCase.number}`,
                state: stateObj
            }}/>
        );
    }
    if(redirectToPush) {
        return <Redirect push to={redirectToPush}/>;
    }
    if(redirectToReplace) {
        return <Redirect to={redirectToReplace}/>;
    }
    if(redirectToEditorWithSample) {
        const stateObj = {roInProgress: true};
        return <Redirect push to={{
            pathname: `/attention_edit/${redirectToEditorWithSample}`,
            state: stateObj
        }}/>;
    }
    
    return(
        <div className="container">
            {showPrompt()}
            <Header/>
            <div className="row li-header-row m-0 p-0 justify-content-between">
                <div>
                    <div>
                        <BackButton/>
                        <div className="d-inline-block align-middle">{generatePageTitle()}</div>
                        <div className="d-inline-block ml-5">{isLoading && <LoadingSpinner medium/>}</div>
                    </div>
                </div>
                {showClearButtonIfRequired()}
            </div>

            {!isInitializing && <>
            {showPickAndPrintIfRequired()}

            <RetrievalOrderForm onSubmit={handleFormSubmit} retrievalInfo={retrievalInfo}
                                onAddSamples={handleAddSamples} onDateChanged={handleDueDateChanged}
                                onChange={handleInfoChanged} goodNess={infoGoodnessList}
                                onSampleClicked={handleSampleClicked}
                                onSampleOrderClicked={handleSamplePendingOrderClicked}
                                onSampleRepairClicked={handleSampleRepairClicked}
                                onRemoveSample={handleRemoveSample}
                                formIsValid={formIsValid}
                                formIsReadOnly={shouldFormBeReadOnly()} 
                                isSaving={isSaving} isDirty={!savedFlag}/>
            </>}
            <Footer/>
        </div>  
    );
    
}

export default AddUpdateROPage;