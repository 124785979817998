import React, {useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import $ from "jquery";
import ArchiverClientApi from "../API/archiverClientApi";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const ContactFind = ({initialValue, widgetName, onContactSelected, required, readOnly}) => {

    const MIN_NUM_CHARS_NEEDED_TO_SEARCH=2;     // how many chars needed to send search term and result dropdown
    const MAX_NUM_RESULTS_AT_A_TIME=5;          // show this many search results at a time only
    
    const [contactSearchResults, setContactSearchResults] = useState([]);
    const [inputClass, setInputClass] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [selectedId, setSelectedId] = useState(initialValue?.id??-1);
    const [searchTextField, setSearchTextField] = useState(initialValue?.name??"");
    
    useEffect( () => {
        setInputClass(LIUtils.getValidationClassGivenValue("good", required, readOnly, selectedId>0))
    },[selectedId, readOnly,required]);
    
    useEffect( () => {
        setSearchTextField(initialValue?.name??"");
        setSelectedId(initialValue?.id??-1);
    }, [initialValue]);
    
    function handleSearchContacts(e) {
        e.preventDefault();
        e.stopPropagation();
        
        setIsSearching(true);
        const searchTerm=getSearchTerm();
        ArchiverClientApi.searchContacts(searchTerm,MAX_NUM_RESULTS_AT_A_TIME)
        .then( results => {
            setContactSearchResults(results);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsSearching(false));
    }
    
    function handleSearchKeyUp(event) {
        event.preventDefault();
        event.stopPropagation();

        const searchTerm = getSearchTerm()
        if (event.code === 'Delete' || event.code === 'Backspace') { // backspace / del
            setSelectedId(false);
        }
        
        if(searchTerm.length >= MIN_NUM_CHARS_NEEDED_TO_SEARCH) {
            $("#contactSearchBTN").trigger("click");  // attempt a search
        }
    }

    function getSearchTerm() {
        const term = $("#contactSearchInput").val();
        return(term);
    }
    
    function handleContactItemClicked(e, item) {
        if(onContactSelected) {
            e.target.name=widgetName;  // fake for our parent handler
            e.target["contact"]=item;
            onContactSelected(e);
        }
        setSelectedId(item.id);
        setContactSearchResults([]); // make the list go away
        setSearchTextField(item.fullName);
    }
    
    function handleSearchTextChanged(e) {
        const value = e.target.value;
        setSearchTextField(value);
    }

    function showListDivIfRequired() {
        if(searchTextField.length<MIN_NUM_CHARS_NEEDED_TO_SEARCH || contactSearchResults.length===0) {
            return null;
        }
        return(
            <div className="card">
                <div style={{zIndex:"1000", overflow:"auto", height:"200px", width:"100%", position:"absolute"}}>
                    <ul className="list-group">
                        {contactSearchResults.map( (item) =>
                            <li key={item.id} className="list-group-item li-contact-search-item" 
                                onClick={(e)=>handleContactItemClicked(e,item)}>{item.fullName}</li>
                        )}
                    </ul>
                </div>
            </div>
        );
    }
    
    return (
        <div className="form-group">
            <label className="li-form-label" htmlFor={widgetName}>Requested By</label>
            <div className="input-group">
            <input type="text" className={inputClass} id="contactSearchInput"
                   placeholder="partial name"
                   onKeyUp={handleSearchKeyUp}
                   aria-describedby="inputGroupPrepend"
                   value={searchTextField}
                   onChange={handleSearchTextChanged}
                   autoComplete="off"
                   readOnly={readOnly}
                   required />
            <div className="input-group-append">
                <button type="button" id="contactSearchBTN" disabled={readOnly} className="li-button li-primary li-search-button" onClick={handleSearchContacts}>
                    {isSearching && <LoadingSpinner small/>}
                    {!isSearching && <i className="fa fa-lg fa-search"/>}
                </button>
            </div>
            </div>
            {showListDivIfRequired()}
        </div>
    );
};

ContactFind.propTypes = {
    onContactSelected: PropTypes.func.isRequired,
    required: PropTypes.bool,
    focus: PropTypes.bool,
    readOnly: PropTypes.bool,
    widgetName: PropTypes.string.isRequired,
    initialValue: PropTypes.object,
};

export default ContactFind;