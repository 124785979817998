import React, {useContext, useEffect, useState} from "react";
import Header from "./Header";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import {StoreContext} from "../store";
import ArchiverClientApi from "../API/archiverClientApi";
import Footer from "./Footer";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import {gotNotificationDelete, gotNotifications, gotNotificationStatusUpdate} from "../ACTIONS/clientActions";
import NotificationCard from "./NotificationCard";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import LIButton from "../GUI_COMMON/COMPONENTS/LIButton";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";

const NotificationsPage = () => {

    const [globalState, dispatch] = useContext(StoreContext);
    const [isLoading, setIsLoading] = useState(false);
    //const [redirectTo, setRedirectTo] = useState();
    const [notifsList,setNotifsList] = useState([]);

    const [forceRefresh, setForceRefresh] = useState(false);

    useEffect(() => {
        console.debug("NotificationsPage starting up...");
        if(globalState.notifications.initialized===false || globalState.notifications.notifs.length===0 || forceRefresh) { // get them
            doNotificationsUpdate();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        if(globalState.notifications) {
            setNotifsList(globalState.notifications.notifs);
        }
    }, [globalState.notifications.notifs]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        doNotificationsUpdate();
    }, [globalState.notifications.numNotifs, forceRefresh]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    function doNotificationsUpdate() {
        setIsLoading(true);
        ArchiverClientApi.getNotifications()
        .then(({numNotifs,notifs}) => {
            dispatch(gotNotifications(numNotifs,notifs));
        })
        .catch( responseError =>ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsLoading(false);
            setForceRefresh(false);
        });
    }

    function handleRefreshTab() {
        setForceRefresh(true);
    }
    
    function markNotifAsRead(e, notifItem) {
        e.preventDefault();
        e.stopPropagation();
        
        notifItem["isBusy"]=true;
        ArchiverClientApi.markNotificationAsRead(notifItem.id)
        .then(({notifId,status}) => {
            dispatch(gotNotificationStatusUpdate(notifId,status));
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => notifItem["isBusy"]=false);
    }

    function deleteNotif(e, notifItem) {
        e.preventDefault();
        e.stopPropagation();

        notifItem["isBusy"]=true;
        ArchiverClientApi.deleteNotification(notifItem.id)
        .then(({notifId,status}) => {
            dispatch(gotNotificationDelete(notifId));
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => notifItem["isBusy"]=false);
    }
    
    function showResultsList() {
        if (!notifsList || notifsList.length === 0) {
            return <div className="card text-center">none</div>;
        }
        
        const resultRows = notifsList.map((item, index) => {
            return <NotificationCard key={item.id} status={item.status} type={item.type} date={item.dateAdded} 
                                     from={item.fromContact?.fullName} details={item.details} id={item.id} 
                                     onMarkReadClick={(e) => markNotifAsRead(e,item)}
                                     onDeleteClick={(e) => deleteNotif(e,item)}
                                     isBusy={item.isSaving}/>
            }
        );

        return (
            <div id="notifsList" className="">
                {resultRows}
            </div>
        );
    }

    function markAllRead() {
        setIsLoading(true);
        ArchiverClientApi.markAllNotificationsAsRead()
        .then(({numUpdated}) => {
            setForceRefresh(true);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsLoading(false));
    }

    function markAllDeleted() {

        // make sure the user is sure
        Popup.showConfirm("Delete All Messages?", "<p>Are you sure you want to delete all your notifications?</p><p><b>They are removed forever.</b></p>", "Yes, Delete", undefined, () => doRealMarkAllDeleted());
    }
    
    function doRealMarkAllDeleted() {
        setIsLoading(true);
        ArchiverClientApi.deleteAllNotifications()
        .then(({numNotifsRemaining}) => {
            if(numNotifsRemaining>0) { // some weren't deleted, refresh
                setForceRefresh(true);
            }
            else { // fake none
                dispatch(gotNotifications(0, []));
            }
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsLoading(false));
    }

    function dumpAllReadOrDeleteButton() {
        if (globalState.notifications.numNotifs === 0) {
            return null;
        }
        if(allNotifsHaveBeenRead()) {
            return (<>
                <LIButton secondary name="deleteAllBtn" label="Delete All" faIcon="fa fa-trash ml-3"
                          onClick={markAllDeleted}/>
            </>
            );
        }
        return (<>
                <LIButton primary name="markAllReadBtn" label="Mark All as Read" faIcon="fa fa-envelope-open ml-3"
                          onClick={markAllRead}/>
            </>
        );
    }
    
    function allNotifsHaveBeenRead() {
        return globalState.notifications.notifs.every( (item) => !LIUtils.NotificationIsNew(item.status));
    }

    return(
        <div className="container">
            <Header/>
            <div className="row li-header-row m-0 p-0 justify-content-between">
                <div>
                    <BackButton/>
                    <h1 className="d-inline-block align-middle">Notifications</h1> 
                    <span className="d-inline-block ml-4">{isLoading && <LoadingSpinner medium/>}</span>
                </div>
                <button className="btn mr-4" onClick={handleRefreshTab} data-toggle="tooltip" title="refresh"><i className="fa fa-redo"/></button>
            </div>
            
            {/* button row */}
            <div className="row m-0 p-0 justify-content-end">
                {dumpAllReadOrDeleteButton()}
            </div>
            
            {/* results list */}
            <div className="my-3">
                {showResultsList()}
            </div>
            <Footer/>
        </div>
    );
}

export default NotificationsPage;