import React from 'react';
import * as PropTypes from "prop-types";

const WatchListCardRow = ({watchItem, isChecked,onCheckChanged,onSampleClick}) => {
    
    function dumpSampleInfo() {
        // ### we assume here that the case and sample won't be empty since this IS in a
        // case list after all...so it must have been part of a case.
        return `${watchItem.caseNumber??"-"} / ${watchItem.sampleNumber??"-"}`;
    }
    
    function dumpWatchType() {
        if(watchItem.type === 1) {
            return "WHEN SAMPLE RETURNED";
        }
        return "(unknown)";
    }
    
    function showCheckbox() {
        return <div className="d-inline-block">
            <input type="checkbox" className="m-2 mr-3" name="watchlist-cb" aria-label="Checkbox" checked={isChecked??false}
                   onChange={(e) => handleCBChanged(e)} onClick={(e) => handleCBClicked(e)}/>
        </div>
    }

    function handleCBChanged(e) {
        e.stopPropagation();
        if(onCheckChanged) {
            onCheckChanged(e.target.checked);
        }
    }

    function handleCBClicked(e) {
        e.stopPropagation(e);
    }

    function handleSampleOnClick(e) {
        e.stopPropagation();
        if (onSampleClick) {
            onSampleClick();
        }
    }
    
    return (
        <div>
            {showCheckbox()}
            <span className="btn-link li-pointer mr-5" onClick={(e) => handleSampleOnClick(e)}>{dumpSampleInfo()}</span>
            <span>{dumpWatchType()}</span>
        </div>
    );
}

WatchListCardRow.propTypes = {
    watchItem: PropTypes.object.isRequired,
    onSampleClick: PropTypes.func,
    dateDueBack: PropTypes.object,  // date
    isChecked: PropTypes.bool,
    onCheckChanged: PropTypes.func,
}

export default WatchListCardRow;