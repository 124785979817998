
import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

/* just check the action.type to see if it's a result of a successful action
 * recall that all reducers get ALL ACTIONS, so this will effectively close off
 * one of the previously noted ajax calls.
 */
function actionTypeEndsWithSuccess(aType) {
    return(aType.substring(aType.length - 8) === "_SUCCESS");
}

export default function ajaxStatusReducer( state = initialState.ajaxCallsInProgress, action) {
    if( action.type === types.BEGIN_AJAX_CALL) {
        return( state + 1 );
    }
    else if(action.type === types.ERRORED_AJAX_CALL || actionTypeEndsWithSuccess(action.type)) {
        return(state>0?(state-1):0);
    }
    return(state);
}