import React, {useContext, useEffect, useRef, useState} from "react";
import Header from "./Header";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import LIButton from "../GUI_COMMON/COMPONENTS/LIButton";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import ArchiverClientApi,{DOWNLOADABLE_FILE_PARENT_LIST} from "../API/archiverClientApi";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import {Redirect, useLocation} from "react-router-dom";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import {useCookies} from "react-cookie";
import Footer from "./Footer";
import HeaderBadge from "../GUI_COMMON/COMPONENTS/HeaderBadge";
import {StoreContext} from "../store";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import {useHistory} from "react-router";

const AdminPage = () => {

    const DEFAULT_PICK_DIR_VERBIAGE = "pick a directory";
    const DEFAULT_PICK_FILE_VERBIAGE = "pick a file";
    
    const [redirectToPush, setRedirectToPush] = useState();
    const [globalState,] = useContext(StoreContext);

    const [downloadableFilesList, setDownloadableFilesList] = useState([]);
    const [selectedFileParentDir, setSelectedFileParentDir] = useState();
    const [selectedDownloadFile, setSelectedDownloadFile] = useState();
    const [isBusyDownloadingFile, setIsBusyDownloadingFile] = useState(false);
    const [isBusyResettingDb, setIsBusyResettingDb] = useState(false);
    const [reportUploadIsBusy, setReportUploadIsBusy] = useState(false);
    const [dashboardCounts, setDashboardCounts] = useState({
        eventsCount: 0,
        notifsCount: 0,
        printJobsCount: 0,
    });
    const [isClearingEvents, setIsClearingEvents] = useState(false);
    const [isClearingNotifs, setIsClearingNotifs] = useState(false);
    const [isClearingPrintJobs, setIsClearingPrintJobs] = useState(false);
    
    const location = useLocation();
    const history = useHistory();
    
    const [cookies,] = useCookies();
    const generalRef = useRef();
    const serviceRef = useRef();
    const magRef = useRef();
    const userRef = useRef();
    const locationRef = useRef();
    const reportRef = useRef();
    const deviceRef = useRef();

    const [activePageRef, setActivePageRef] = useState();
    
    useEffect( () => {
        hideAllTabPages();
        const pageAnchor = location.hash.replace("#","");
        if(pageAnchor==="magazines") {
            showTabPage(magRef);
        } else if(pageAnchor==="users") {
            showTabPage(userRef);
        } else if(pageAnchor==="locations") {
            showTabPage(locationRef);
        } else if(pageAnchor==="reports") {
            showTabPage(reportRef);
        } else if(pageAnchor==="devices") {
            showTabPage(deviceRef);
        } else if(pageAnchor==="service") {
            showTabPage(serviceRef);
        }
        else {
            showTabPage(generalRef);
        }
        
    },[]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        if(activePageRef===generalRef) {
            doUpdateInterestingCounts();
        }
        
    }, [activePageRef]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    function doUpdateInterestingCounts() {
        ArchiverClientApi.getAdminDashboardCounts()
        .then( (results) => {
            setDashboardCounts({
                eventsCount: results.numEvents, notifsCount: results.numNotifs, printJobsCount: results.numPrintJobs,
            });
        })
    }
    
    function handleClearMagClicked() {
        Popup.showPrompt("Clear Magazine","","Remove Samples",() => {},(magBC) => {
            if(magBC && magBC!=="") {
                confirmAndRemoveClearMag(magBC);
            }
        },1, "magazine barcode (##.####.#)");
    }
    function confirmAndRemoveClearMag(magBC) {
        Popup.showConfirm("Remove Samples?",
            `<p>Are you sure you want to remove all samples from mag '${magBC}'?</p>` +
            "<p>*this may take some time if the mag is full.</p>", "Yes, Remove!",
            null, () => {
                ArchiverClientApi.requestReloadOfMag(magBC)
                .then(() => {
                    Popup.show("Mag Reloaded", `All child samples have been removed <MagBadge text='${magBC}'/>`);
                })
                .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError));
            });
    }
    
    function handleClearEventsClicked() {
        Popup.showConfirm("Clear Events?",
            "<p>Are you sure you want to remove all events from the system?</p>", "Yes, remove them!",
            null, () => {
                setIsClearingEvents(true);
                ArchiverClientApi.requestClearEvents()
                .then((numRemoved) => {
                    Popup.showInfo(`Successfully cleared ${numRemoved} events.`);
                    doUpdateInterestingCounts();
                })
                .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
                .finally(() => {
                    setIsClearingEvents(false);
                });
            });
    }
    function handleClearNotificationsClicked() {
        Popup.showConfirm("Clear Notifications?",
            "<p>Are you sure you want to remove all notifications from the system?</p><p>This removes notifications for <b>ALL USERS</b>.</p>", "Yes, remove them!",
            null, () => {
                setIsClearingNotifs(true);
                ArchiverClientApi.requestClearNotifs()
                .then((numRemoved) => {
                    Popup.showInfo(`Successfully cleared ${numRemoved} notifications.`);
                    doUpdateInterestingCounts();
                })
                .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
                .finally(() => {
                    setIsClearingNotifs(false);
                });
            });
    }
    function handleClearPrintJobsClicked() {
        Popup.showConfirm("Clear Print Jobs?",
            "<p>Are you sure you want to remove all print jobs from the system?</p>", "Yes, remove them!",
            null, () => {
                setIsClearingPrintJobs(true);
                ArchiverClientApi.requestClearPrintJobs()
                .then((numRemoved) => {
                    Popup.showInfo(`Successfully cleared ${numRemoved} print jobs.`);
                    doUpdateInterestingCounts();
                })
                .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
                .finally(() => {
                    setIsClearingPrintJobs(false);
                });
            });
    }
    
    function hideAllTabPages() {
        hideTabPage(generalRef);
        hideTabPage(serviceRef);
        hideTabPage(magRef);
        hideTabPage(userRef);
        hideTabPage(locationRef);
        hideTabPage(reportRef);
        hideTabPage(deviceRef);
    }
    function hideTabPage(pageRef) {
        if(pageRef && pageRef.current) {
            pageRef.current.classList.remove("show");
            pageRef.current.classList.remove("active");
            pageRef.current.classList.add("hide");
            pageRef.current.style.display = "none";
        }
    }
    function showTabPage(pageRef) {

        // remove active page info
        hideTabPage(activePageRef);
        
        if(pageRef && pageRef.current) {
            setActivePageRef(pageRef)
            // show the page we want
            pageRef.current.classList.remove("hide");
            pageRef.current.classList.add("show");
            pageRef.current.classList.add("active");
            pageRef.current.style.display="block";
            const tabId = pageRef.current.id;
            history.replace(`/adminPage#${tabId}`);
        }
    }

    //#region GETFILES

    function showGetFileParentList() {
        return DOWNLOADABLE_FILE_PARENT_LIST.map((p, index) => {
            return <option key={index} value={p}>{p}</option>
        });
    }

    function showDownloadableFilesList() {
        if(downloadableFilesList) {
            return downloadableFilesList.map((p, index) => {
                return <option key={index} value={p}>{p}</option>
            });
        }
        return null;
    }

    function handleGetFileParentSelect(e) {
        const parentDir = e.target.value;
        document.getElementById("getFilesSelect").value=DEFAULT_PICK_FILE_VERBIAGE;
        setSelectedDownloadFile(null);
        if(!parentDir || parentDir === DEFAULT_PICK_DIR_VERBIAGE) {
            return;
        }
        setSelectedFileParentDir(parentDir);


        ArchiverClientApi.getFiles(parentDir)
        .then( ({files}) => {
            if(!files || files.length===0) {
                ErrorPopup.showError("Error","No files found");
                setDownloadableFilesList([]);
                return;
            }
            files.sort();
            files.unshift(DEFAULT_PICK_FILE_VERBIAGE);         // prepend our default selector
            setDownloadableFilesList(files); // []
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError));
    }

    function handleGetFileSelect(e) {
        const fileToGet = e.target.value;
        if(!fileToGet || fileToGet === DEFAULT_PICK_FILE_VERBIAGE) {
            setSelectedDownloadFile(null);
            return;
        }
        setSelectedDownloadFile(fileToGet);
    }

    function handleDownloadFile() {

        setIsBusyDownloadingFile(true);
        ArchiverClientApi.downloadFileFromParentDir(selectedFileParentDir, selectedDownloadFile)
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsBusyDownloadingFile(false));
    }

    function fileIsNotSelected() {
        // noinspection RedundantIfStatementJS
        if(!selectedDownloadFile || selectedDownloadFile==="" || selectedDownloadFile===DEFAULT_PICK_FILE_VERBIAGE) { 
            return true;  // file is NOT selected
        }
        return false;  // file is selected
    }

    //#endregion GETFILES

    function dumpDownloadFilesSection() {
        return (
            <>
            <td className="w-50">
                <p>Download files from specific directories on the Archiver.</p>
                <p>*Note that files <strong>bigger than 1Meg</strong> will be compressed before sending ... this can take a while!</p>
            </td>
            <td colSpan="1">
                <div className="input-group w-100">
                    <select id="moveSelect" className="form-control" onSelect={handleGetFileParentSelect}
                            onChange={handleGetFileParentSelect}>{showGetFileParentList()}</select>
                </div>
                <div className="input-group w-100">
                    <select id="getFilesSelect" className="form-control my-3" onSelect={handleGetFileSelect}
                            onChange={handleGetFileSelect}>{showDownloadableFilesList()}</select>
                    <LIButton bgClass="ml-3" label="Download" small primary onClick={handleDownloadFile} showBusy={isBusyDownloadingFile} disabled={isBusyDownloadingFile || fileIsNotSelected()}/>
                </div>
            </td>
                </>
        );
    }
    
    function dumpResetDatabaseSection() {
        if(globalState.serverInfo?.demoMode!==true) {
            return null;
        }
        
        return(
            <>
                <h4 className="li-bg-light p-3">Reset Archiver Database</h4>
                <table id="resetArchiverTable" className="table">
                    <tbody>
                    <tr>
                        <td className="w-100 text-left li-font-medium font-weight-normal">
                            <p>Reset the Archiver Database, removing all magazines, samples, and retrieval orders.<br/>
                            All existing contacts, locations, departments, and profiles will remain untouched.</p>
                            <p className="li-fg-danger font-weight-bold text-center">*** NOTE: this CANNOT BE UNDONE ***</p>
                        </td>
                        <td><LIButton secondary fitHeight onClick={handleResetArchiverDatabase} label="Reset Database" showBusy={isBusyResettingDb}/></td>
                    </tr>
                    </tbody>
                </table>
            </>
        );
    }
    
    function handleResetArchiverDatabase() {
        Popup.showConfirm("Reset Archiver Database", "Do you really want to clear all samples and magazines from the Archiver database?",
            "Yes, Clear it!", () => {
            }, () => {
                setIsBusyResettingDb(true);
                ArchiverClientApi.sendWipeDbRequest()
                .then(() => {
                    Popup.showInfo("Database has been reset");
                })
                .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
                .finally(() => {
                    setIsBusyResettingDb(false);
                })
            });
    }

    function showClearEventsLabel():string {
        if(isClearingEvents) {
            return(
                `Clear Events &nbsp;<span class="fa fa-spinner fa-pulse fa-fw" role="status" aria-hidden="true"></span>`
            );
        }
        return(
            `Clear Events (${dashboardCounts["eventsCount"]})`
        );
    }

    function showClearNotifsLabel():string {
        if(isClearingNotifs) {
            return(
                `Clear Notifications &nbsp;<span class="fa fa-spinner fa-pulse fa-fw" role="status" aria-hidden="true"></span>`
            );
        }
        return(
            `Clear Notifications (${dashboardCounts["notifsCount"]})`
        );
    }

    function showClearPrintJobsLabel():string {
        if(isClearingPrintJobs) {
            return(
                `Clear Print Jobs &nbsp;<span class="fa fa-spinner fa-pulse fa-fw" role="status" aria-hidden="true"></span>`
            );
        }
        return(
            ` Print Jobs (${dashboardCounts["printJobsCount"]})`
        );
    }
    
    //#region IMPORT_REPORT
    function handleImportReport() {
        showFilePicker(false);
    }

    function showFilePicker(allowMultiple, fileTypes="*") {
        const fileElement = document.getElementById("fileElem");
        fileElement.value="";                                   // clear out old file selection
        //fileElement.setAttribute("multiple",allowMultiple);     // allow multiple selections?
        fileElement.click();
    }
    
    async function handleFileSelect(e) {
        if (!e.target.files || e.target.files.length === 0) return;
        let fileList = e.target.files; // FileList OBJECT! (not an array)

        setReportUploadIsBusy(true);
        
        let failedMsg="";
        let goodMsg="";
        let failedCount=0;
        for (let i=0;i<fileList.length;i++) {
            const fileInfo = fileList.item(i);
            const fileName = fileInfo.name;
            
            const resultObj = await readSingleFileAndSend(fileInfo);
            if(resultObj.errorResult) {
                //console.error(resultObj.errorResultMsg);
                failedCount++;
                failedMsg += resultObj.errorResultMsg;
            }
            if(resultObj.goodResult) {
                //console.debug();
                goodMsg += `Import succeeded for '${fileName}'<br/>`;
            }
        }
        let popupMsg = "";
        if(goodMsg!=="") {
            popupMsg+=`<p>${goodMsg}</p>`;
        }
        if(failedMsg!=="") {
            popupMsg += `<p class="li-fg-danger font-weight-bold">Import failed (${failedCount}):</p><p>${failedMsg}</p>`;
        }

        setReportUploadIsBusy(false);
        
        Popup.show("Import Results", popupMsg===""?"no reports imported":popupMsg, () => {});
    }
    
    const readSingleFileAndSend = async (fileInfo) => {

        const delay = (delayInms) => {
            return new Promise(resolve => setTimeout(resolve, delayInms));
        };
        
        let doneSendingGood=false;
        let doneSendingError=false;
        let doneSendingErrorMsg="";
        
        const fileName=fileInfo.name;
        const reader = new FileReader();
        reader.addEventListener("load", // done reading
            () => {
                let jsonObject;
                try {
                    jsonObject = JSON.parse(reader.result);
                } catch (e) {
                    doneSendingError=true;
                    doneSendingErrorMsg=`<p>Failed to read report file '${fileName}'<br/>=> <b>${e}</b></p>`;
                    return;
                }
                
                // make sure the ID is missing! (otherwise it'll edit the one that's there now with the same ID
                jsonObject.id = null;
                ArchiverClientApi.sendReportUpdate(jsonObject, (value) => {
                    console.debug("import progress " + value);
                })
                .then(() => {
                    doneSendingGood=true;
                })
                .catch((error) => {
                    doneSendingError=true;
                    doneSendingErrorMsg=`<b>${fileName}</b> => ${error.error}<br/>`;
                });
            }, false);

        reader.addEventListener("error", () => {
            doneSendingError=true;
            doneSendingErrorMsg=`<b>${fileName}</b> => failed to read file<br/>`;
        }, false);
        reader.readAsText(fileInfo);

        while(!doneSendingGood && !doneSendingError) {
            console.debug(`waiting for import result for '${fileName}'`);
            await delay(500);
        }
        
        return {goodResult: doneSendingGood, errorResult: doneSendingError, errorResultMsg: doneSendingErrorMsg};
    }
    
    //#endregion IMPORT_REPORT
    
    if (redirectToPush) {
        return <Redirect push to={redirectToPush}/>;
    }
    return(
        <div className="container">
            <Header hideAdmin/>
            <div className="li-header-row">
                    <BackButton/>
                    <h1 className="ml-4 d-inline-block align-middle">Archiver Administration</h1>
            </div>

            {/* TABS */}
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item mr-2" role="presentation">
                    <button className={"nav-link " + (activePageRef===generalRef?"active":"")} id="general-tab" onClick={() => showTabPage(generalRef)}>General</button>
                </li>
                <li className="nav-item mr-2" role="presentation">
                    <button className={"nav-link " + (activePageRef===magRef?"active":"")} id="users-tab" onClick={() => showTabPage(magRef)}>Magazines</button>
                </li>
                <li className="nav-item mr-2" role="presentation">
                    <button className={"nav-link " + (activePageRef===userRef?"active":"")} id="users-tab" onClick={() => showTabPage(userRef)}>Users</button>
                </li>
                <li className="nav-item mr-2" role="presentation">
                    <button className={"nav-link " + (activePageRef===deviceRef?"active":"")} id="devices-tab" onClick={() => showTabPage(deviceRef)}>Devices</button>
                </li>
                <li className="nav-item mr-2" role="presentation">
                    <button className={"nav-link " + (activePageRef===locationRef?"active":"")} id="locations-tab" onClick={() => showTabPage(locationRef)}>Locations/Departments</button>
                </li>
                <li className="nav-item mr-2" role="presentation">
                    <button className={"nav-link " + (activePageRef===reportRef?"active":"")} id="reports-tab" onClick={() => showTabPage(reportRef)}>Reports</button>
                </li>
                <li className="nav-item mr-2" role="presentation">
                    <button className={"nav-link " + (activePageRef===serviceRef?"active":"")} id="service-tab" onClick={() => showTabPage(serviceRef)}>Service</button>
                </li>
              
            </ul>

            {/* TAB CONTENT */}
            <div id="general" className="tab-pane fade" ref={generalRef}>
                <h4 className="p-3">General</h4>
                <table id="generalTable" className="table">
                    <tbody>
                    <tr>
                        <td className="w-75 text-left li-font-medium font-weight-normal">
                            <p>Clear out all events from the system.  Over time, there can be many events added
                                as devices report their various statuses and diagnostic issues.  It is important to
                                review these events regularly and clear them out afterwards.
                            </p>
                            <p className="li-font-small ml-3 m-0">*events are wiped completely from the system.  This cannot be undone.
                            </p>
                        </td>
                        <td className="text-right"><LIButton primary onClick={handleClearEventsClicked} fitHeight label={showClearEventsLabel()}/></td>
                    </tr>
                    <tr>
                        <td className="w-75 text-left li-font-medium font-weight-normal">
                            <p>Clear out all notifications from the system.  Notifications are sent to users via email and via the GUI.  These are ordinarily
                                removed from the system after they are around 10 days old (even if they are unread).  If the Archiver Scooter hasn't run in a while or there is
                                an excessive number of notifications being generated, you may want to remove these manually.  Do that here.
                            </p>
                            <p className="li-font-small ml-3 m-0">*notifications are wiped completely from the system.  This cannot be undone.
                            </p>
                        </td>
                        <td className="text-right"><LIButton primary onClick={handleClearNotificationsClicked} fitHeight label={showClearNotifsLabel()}/></td>
                    </tr>
                    <tr>
                        <td className="w-75 text-left li-font-medium font-weight-normal">
                            <p>Clear out all label print jobs from the system.  If a label printer has been off or there have been issues printing
                                magazine labels, there can be a long line of labels waiting to be printed.   It is sometimes useful to be able to
                                clear the internal print queue.  
                            </p>
                            <p className="li-font-small ml-3 m-0">*any previously labels that were to be printed can then be printed individually using
                                the "Reprint Label" button on 
                                the <span className="li-pointer btn-link" onClick={() => setRedirectToPush("/print")}>Print Labels</span> page.
                            </p>
                        </td>
                        <td className="text-right"><LIButton primary onClick={handleClearPrintJobsClicked} fitHeight label={showClearPrintJobsLabel()}/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div id="magazines" className="tab-pane fade" ref={magRef}>
                <h4 className="p-3">Magazines</h4>
                <table id="magTable" className="table">
                    <tbody>
                    <tr>
                        <td className="w-75 text-left li-font-medium font-weight-normal">
                            <p>Clear out a magazine, removing all of it's samples.
                                <br/>This is roughly the same as 'reloading' a mag at a device.
                            </p>
                            <p className="li-font-small ml-3 m-0">*Samples will remain in the system, but will no longer be in a mag.<br/>
                                *The mag may need to be re-assigned to a device before being loadable.
                            </p>
                        </td>
                        <td className="text-right"><LIButton primary onClick={handleClearMagClicked} label="Clear Magazine" fitHeight/></td>
                    </tr>
                    <tr>
                        <td className="w-75 text-left li-font-medium font-weight-normal">
                            <p>Add a new magazine to the system OR associated a mag to a different device.</p>
                        </td>
                        <td className="text-right"><LIButton primary onClick={()=>setRedirectToPush("/admin/mag_register")} label="Register Magazine" fitHeight/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div id="users" className="tab-pane fade" ref={userRef}>
                <h4 className="p-3">Users</h4>
                <table id="userTable" className="table">
                    <tbody>
                    <tr>
                        <td className="w-100 text-left li-font-medium font-weight-normal">
                            <p>Add, Edit, and Delete SlideSystem users.</p>
                        </td>
                        <td><LIButton primary onClick={()=>setRedirectToPush("/admin/users")} bgClass="fa fa-edit"/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div id="devices" className="tab-pane fade" ref={deviceRef}>
                <h4 className="p-3">Devices</h4>
                <table id="deviceTable" className="table">
                    <tbody>
                        <tr>
                            <td className="w-100 text-left li-font-medium font-weight-normal">
                                <p>Add and remove sample processing devices from the system, as well as assign
                                    magazine and sample profiles to devices.</p>
                                <p className="li-font-small ml-3 m-0">
                                    *NOTE: admin level users can update device profiles, but Service level is required to add/remove or edit device details.
                                </p>
                            </td>
                            <td><LIButton primary onClick={() => setRedirectToPush("/admin/devices")} bgClass="fa fa-edit"/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="locations" className="tab-pane fade" ref={locationRef}>
                <h4 className="p-3">Locations and Departments</h4>
                <table id="locationTable" className="table">
                    <tbody>
                    <tr>
                        <td className="w-100 text-left li-font-medium font-weight-normal">
                            <p>Add, Edit, and Delete Locations and Departments.</p>
                        </td>
                        <td><LIButton primary onClick={()=>setRedirectToPush("/admin/locations")} bgClass="fa fa-edit"/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div id="reports" className="tab-pane fade" ref={reportRef}>
                <h4 className="p-3">Reports</h4>
                <table id="reportsTable" className="table">
                    <tbody>
                    <tr>
                        <td className="w-75 text-left li-font-medium font-weight-normal">
                            <p>Add, edit, delete, and export system reports.</p>
                            <p className="li-font-small">NOTE: this is considered an Advanced Topic and requires an understanding
                                of SQL and the Archiver database structure.</p>
                        </td>
                        <td className="text-right"><LIButton primary onClick={()=>setRedirectToPush("/admin/reports")} bgClass="fa fa-edit"/></td>
                    </tr>
                    <tr>
                        <td className="w-75 text-left li-font-medium font-weight-normal">
                            <p>Import one or more reports into the system</p>
                        </td>
                        <td className="text-right"><LIButton primary onClick={handleImportReport} fitHeight label="Import Reports" showBusy={reportUploadIsBusy}/></td>
                    </tr>
                    </tbody>
                </table>
                <input type="file" id="fileElem" accept=".json" style={{display: "none"}} 
                       multiple onSelect={async(e) =>handleFileSelect(e)} 
                       onChange={async(e) =>handleFileSelect(e)}/>
            </div>
            <div id="service" className="tab-pane fade" ref={serviceRef}>

                <HeaderBadge title=" service level only " danger icon="fa-exclamation-circle" medium/>

                <div className="mb-4">
                    <h5 className="li-bg-dark p-3">General Archiver Info</h5>
                    <table id="archiverInfoTable" className="table table-sm table-bordered">
                        <tbody>
                            <tr>
                                <td className="text-right font-weight-bold">Name</td>
                                <td>{globalState.serverInfo.name}</td>
                            </tr>
                            <tr>
                                <td className="text-right font-weight-bold">WebService Version</td>
                                <td>{globalState.serverInfo.version}</td>
                            </tr>
                            <tr>
                                <td className=" text-right font-weight-bold">Database Version</td>
                                <td>{globalState.serverInfo.dbVersion}</td>
                            </tr>
                            <tr>
                                <td className=" text-right font-weight-bold">Min Required DB Version</td>
                                <td>{globalState.serverInfo.requiredDbVersion}</td>
                            </tr>
                            <tr>
                                <td className=" text-right font-weight-bold">Demo Mode?</td>
                                <td>{globalState.serverInfo.demoMode?"On":"Off"}</td>
                            </tr>
                            <tr>
                                <td className=" text-right font-weight-bold">Server Time (UTC)</td>
                                <td>{globalState.serverInfo.localTimeUtc}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                

                {/* SERVICE LEVEL SELECTIONS */}
                {LIUtils.UserHasLevel(cookies, "SERVICE") &&
                    <div className="mb-4">
                        <h4 className="li-bg-light p-3">Profiles</h4>
                        <table id="profilesTable" className="table">
                            <tbody>
                            <tr>
                                <td className="w-100 text-left li-font-medium font-weight-normal">
                                    <p>Add, Edit, and Delete Mag and Sample profiles.</p>
                                    <p className="li-font-small">NOTE: this is considered an Advanced Topic and requires an understanding
                                        of how barcodes are read and processed by the Slide System.</p>
                                </td>
                                <td><LIButton primary onClick={() => setRedirectToPush("/admin/profiles")} bgClass="fa fa-edit"/></td>
                            </tr>
                            </tbody>
                        </table>
                        <h4 className="li-bg-light p-3">File Download</h4>
                        <table id="fileDownloadTable" className="table">
                            <tbody>
                            <tr>{dumpDownloadFilesSection()}</tr>
                            </tbody>
                        </table>
                        {dumpResetDatabaseSection()}
                    </div>
                }
            </div>

            {/* FOOTER CONTENT */}
            <div className="m-5">&nbsp;</div>
            <Footer/>
        </div>
    );
}

export default AdminPage;