import React, {useContext, useEffect, useRef, useState} from "react";
import {StoreContext} from "../store";
import Header from "./Header";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import TextInput from "../GUI_COMMON/COMPONENTS/TextInput";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import Footer from "./Footer";
import {Redirect, useLocation, useParams} from "react-router-dom";
import ArchiverClientApi from "../API/archiverClientApi";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import ProfileList from "../GUI_COMMON/COMPONENTS/ProfileList";
import {useHistory} from "react-router";
import {useCookies} from "react-cookie";
import {clearAllSampleInfo, gotSampleBaseInfo, removeSampleFromNASearchResults} from "../ACTIONS/clientActions";
import GuiUtils from "../GUI_COMMON/SUPPORT/GuiUtils";
import LIButton from "../GUI_COMMON/COMPONENTS/LIButton";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import CheckInput from "./CheckInput";
import ReactDOMServer from "react-dom/server";

const SampleEditPage = () => {

    const [globalState, dispatch] = useContext(StoreContext);

    const [caseNumber, setCaseNumber] = useState('');
    const [sampleNumber, setSampleNumber] = useState('');
    const [formIsValid, setFormIsValid] = useState(false);
    const [failedBarcode, setFailedBarcode] = useState('');
    const [sampleType, setSampleType] = useState(-1);
    const [profileId: Number, setProfileId] = useState();
    const [naReason, setNAReason] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formatHints, setFormatHints] = useState({caseExample: "none", sampleExample: "none"});
    const [validations, setValidations] = useState({caseValidation: "", sampleValidation: ""});
    const [redirectTo, setRedirectTo] = useState();
    const [redirectToWithReplace, setRedirectToWithReplace] = useState();
    const [sampleGoodness, setSampleGoodness] = useState("bad");
    const [caseGoodness, setCaseGoodness] = useState("bad");

    const barcodeTB = useRef(null);
    const caseNumTB = useRef(null);
    const sampleNumTB = useRef(null);

    const {sampleId} = useParams();
    
    const history = useHistory();
    const [cookies,setCookies] = useCookies();
    
    const location = useLocation();
    const [shouldShowGotoNextCB,] = useState(location.state?.roInProgress !== true);
    const [showNextNASampleOnSave, setShowNextNASampleOnSave] = useState(cookies["showNextAttentionSampleFlag"]==="true");
    const [isRefreshingNA, setIsRefreshingNA] = useState(false);

    useEffect(() => {
        console.debug(">>>SlideEditPage initializing with sample...");

        if (globalState.sample) {
            setSampleType(globalState.sample.sampleType);
            setCaseNumber(globalState.sample.caseNumber ?? "");
            setSampleNumber(globalState.sample.sampleNumber ?? "");
            setFailedBarcode(globalState.sample.barcode);
            setProfileId(globalState.sample.profileId ?? -1);
            setNAReason(globalState.sample.attentionReason ?? "");
            setFocus();
        }
    }, [globalState.sample]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        console.debug(`>>>SlideEditPage initializing with profile id ${profileId}...`);
        let validationsList = validations;
        if (profileId) {
            validationsList = setFormatHintsAndValidationsGivenProfileId(profileId);
        }
        checkFormIsValid(validationsList);
        setFocus();

    }, [globalState?.profiles.items, profileId]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Number(sampleId) !== globalState.sample.id) { // we need to get the info from the archiver
            dispatch(clearAllSampleInfo());
            getSampleDetailsGivenSampleId(sampleId);
        }
    }, [sampleId]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        checkFormIsValid(validations);
    }, [sampleNumber, caseNumber, validations]); // eslint-disable-line react-hooks/exhaustive-deps

    function setFocus() {
        if (globalState?.sample?.caseNumber) { // have a case # -- focus sample #
            sampleNumTB?.current?.focus({preventScroll: false});
        } else if (globalState?.sample?.barcode) { // have a barcode -- focus case #
            caseNumTB?.current?.focus({preventScroll: false});
        } else { // have nothing -- focus case #
            caseNumTB?.current?.focus({preventScroll: false});
        }
    }
    
    function getSampleDetailsGivenSampleId(thisSampleId) {
        setIsLoading(true);
        dispatch(clearAllSampleInfo());
        ArchiverClientApi.getSampleNAInfoGivenId(thisSampleId)
        .then((sample) => {
            setIsLoading(false);
            dispatch(gotSampleBaseInfo(sample)); // ### note - reusing baseInfo call here with NA stuf in it -pott 220527
        })
        .catch(responseError => {
            let error = responseError?.error ?? "";
            const lowerError = error?.toLowerCase();
            if (responseError.statusCode === 404) { // may be ok - may have been fixed already?
                attemptGetBasicSampleInfo(thisSampleId);
                return;
            } else if (lowerError.indexOf("forbidden") !== -1) { // may be ok.
                error = "Forbidden";
            }
            ErrorPopup.showError("Error", error, () => {
                history.goBack();
            });
        })
        .finally( () => {
            setIsLoading(false);
        });
    }

    function attemptGetBasicSampleInfo(sampleId) {
        if (globalState.sample.id === Number(sampleId)) { // no need to refresh
            return;
        }
        setIsLoading(true);
        dispatch(clearAllSampleInfo());
        ArchiverClientApi.getSampleBaseInfoGivenId(sampleId)
        .then((sample) => {
            setIsLoading(false);
            dispatch(gotSampleBaseInfo(sample));
        })
        .catch(responseError => {
            let error = responseError?.error ?? "";
            const lowerError = error.toLowerCase();
            if (responseError.statusCode===404) { // may be ok -- sample is not around.
                error = GuiUtils.GetSampleNotFoundMessage();
            } else if (lowerError.indexOf("forbidden") !== -1) { // may be ok.
                error = "Forbidden";
            }
            ErrorPopup.showError("Error", error);
        })
        .finally( () => {
            setIsLoading(false);
        });
    }
    
    function handleCaseNumChanged(e) {
        if (e.keyCode === 13) { // return
            setFocus();
            return;
        }
        
        const re=new RegExp('[^0-9a-zA-Z-._]'); // added '_' to allowed list (220826 -pott)
        const value = e.target.value;
        if(re.test(value)) { // bad character -- ignore
            console.log("ignoring bad case # character");
            return;
        }
        const rawStr:string = e.target.value
        setCaseNumber(rawStr.toUpperCase());
    }

    function handleSampleNumChanged(e) {
        if (e.keyCode === 13) { // return
            setFocus();
            return;
        }
        const re=new RegExp('[^0-9a-zA-Z-._]');  // added '_' to allowed list (220826 -pott)
        const value = e.target.value;
        if(re.test(value)) { // bad character -- ignore
            console.log("ignoring bad sample # character");
            return;
        }
        const rawStr:string = e.target.value
        setSampleNumber(rawStr.toUpperCase());
    }
    
    function handleSaveClicked(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!checkValidation(validations.caseValidation, caseNumber)) {
            Popup.show("", "The CASE format is not correct", () => {
                document.getElementsByName("caseNum")[0].focus();
            });
            return;
        }
        if (!checkValidation(validations.sampleValidation, sampleNumber)) {
            Popup.show("", "The SAMPLE format is not correct", () => {
                document.getElementsByName("sampleNum")[0].focus();
            });
            return;
        }
        
        const fixedBarcode = LIUtils.removeUnicodeControlChars(failedBarcode);  // BUGFIX - 211028 pott
        doSave(sampleId, sampleType,fixedBarcode, caseNumber, sampleNumber,profileId,false);
    }
    
    function doSave(sampleId, sampleType, barcode, caseNumber,sampleNumber,profileId, permanentlyRemove) {
        
        setIsSaving(true);
        ArchiverClientApi.sendSampleUpdate(sampleId, sampleType, barcode, caseNumber, sampleNumber, profileId, permanentlyRemove)
        .then( (sample) => {
            dispatch(removeSampleFromNASearchResults(sampleId)); // kill this saved sample from the memory list, if found
            if(shouldShowGotoNextCB && showNextNASampleOnSave===true) {
                getNextNeedsAttentionSample();
            }
            else { // go back to RO page or attention page
                history.go(-1);
            }
        })
        .catch( responseError => {
            const lowerError = responseError?.error?.toLowerCase()??"error not provided";
            if(lowerError.indexOf("not found")!==-1) { // may be ok.
                ErrorPopup.showError("Error","Sample Not Found for updating");
            }
            else if(lowerError.indexOf("already exists")!==-1) { // duplicate!
                showDuplicateExistsError(responseError.existingSampleId);
            }
            else {
                ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError);
            }
        })
        .finally( () => {
            setIsSaving(false);
        });
    }
    
    function getNextNeedsAttentionSample() {
        setIsLoading(true);
        ArchiverClientApi.getNeedsAttentionList(0,1,"timedesc")
        .then( ({total,samples}) => {
            if(total>0) {
                history.push(`/attention_edit/${samples[0].id}`); // BUGFIX - 230608
            }
            else {
                setRedirectToWithReplace("/attention");
            }
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsLoading(false));
    }
    
    function showDuplicateExistsError(existingSampleId):string {
        // ### this is more primitive than it needs to be -pott 220119
        
        const msg="<p>A Sample with this case/sample information already exists in the system</p>" +
            "<p>Please find and remove the <a href=\"/sample_details/"+existingSampleId+"\">original sample</a>"+
            " OR change this sample's case# or sample# details.</p>";
        Popup.show("Duplicate Exists",msg);
    }
    
    function checkFormIsValid(validationsList) {
        let formIsValid:boolean;
        
        const sampleIsValid=checkValidation(validationsList.sampleValidation, sampleNumber);
        const caseIsValid=checkValidation(validationsList.caseValidation, caseNumber);
        formIsValid = caseIsValid && sampleIsValid && (profileId>0);
        setSampleGoodness(sampleIsValid ? "good" : "bad");
        setCaseGoodness(caseIsValid ? "good" : "bad");
        
        setFormIsValid(formIsValid);
    }
    
    function checkValidation(validationStr, value) {
        if(!LIUtils.existsAndNotEmpty(validationStr)) {
            return false;   //fail
        }
        let re = new RegExp(validationStr);
        return re.test(value);
    }
    
    function handleCopyToCaseClicked(e) {
        if(failedBarcode) {
            setCaseNumber(failedBarcode);
            document.getElementsByName("sampleNum")[0].focus();
        }
    }
    
    function handleProfileChanged(newProfileId) {
        setProfileId(newProfileId);
    }
    
    function handleShowNextAttentionSampleChanged(e) {
        const isChecked = e.currentTarget.checked;
        setShowNextNASampleOnSave(isChecked);

        setCookies('showNextAttentionSampleFlag', isChecked, {path: '/'});
    }
    
    function setFormatHintsAndValidationsGivenProfileId(thisProfileId) {
        const profile = LIUtils.findProfileGivenId(globalState.profiles.items, thisProfileId)
        const bcProcessingRule = LIUtils.findBarcodeProcessingRule(profile)
        
        const caseExample = bcProcessingRule?.caseExample ?? '-';
        const sampleExample = bcProcessingRule?.sampleExample ?? '-';
        console.debug(`setting hints to ${caseExample} and ${sampleExample}`);
        setFormatHints({caseExample: caseExample, sampleExample: sampleExample});
        
        const caseVal = bcProcessingRule?.caseValidation ?? "";
        const sampleVal = bcProcessingRule?.sampleValidation ?? "";
        const validationsList = {caseValidation: caseVal, sampleValidation: sampleVal};
        setValidations(validationsList);
        
        return validationsList;
    }
    
    function dumpSlideProfiles() {
        return <ProfileList profiles={globalState.profiles.items} sampleOnly sampleType={sampleType}
                            selectedId={profileId??-1}
                            onRadioChanged={(profileId) => handleProfileChanged(profileId)}/>
    }

    function showImageDataIfPresent(imageData) {
        if(!imageData) {
            return <div className="border p-3 li-sample-details-image text-center li-bg-light" style={{width:"500px",height:"333px"}}>no image available</div>;
        }
        
        return <div id="baseImageDiv" className="li-border-muted"
                    onMouseMove={(e)=>handleMouseMove(e,globalState.sample.imageData)}
                    onMouseOut={handleMouseOverImageOut}>
            {LIUtils.getImgFromImageData(globalState.sample.imageData,"li-sample-details-image",500,"sampleImage")}
        </div>;
    }
    
    //#region IMAGE_MAGNIFIER
    
    function handleMouseMove(e, imageData) {
        showMagnifier();
        
        const magnifier = document.getElementById("imageMagnifier");
        const baseImageDiv = document.getElementById("baseImageDiv");
        const baseImage = document.getElementById("sampleImage");
        //baseImage.style.cursor='none';

        const magnifierScale=5.0;
        
        const offsetX = e.pageX - baseImageDiv.offsetLeft - baseImageDiv.offsetParent.offsetLeft;
        const offsetY = e.pageY - baseImageDiv.offsetTop - baseImageDiv.offsetParent.offsetTop;
        
        const centerX = baseImage.width/2;
        const centerY = baseImage.height/2;
        
        const translateX = magnifierScale/2*(centerX-offsetX);
        const translateY = magnifierScale/2*(centerY-offsetY);
        
        // force magnifier to redraw and clear itself (avoids lines in background)
        magnifier.style.display='none';
        // eslint-disable-next-line no-unused-vars 
        const _=magnifier.offsetHeight;  
        
        magnifier.style.display='block';
        
        // place the magnifier at the roughly the cursor position
        magnifier.style.top= baseImage.offsetTop+baseImage.height+"px"; //offsetY-magnifierHeight/2+50+"px";
        magnifier.style.left=baseImage.offsetLeft+baseImage.width/2-magnifier.width/2+"px";// offsetX-magnifierWidth/2+50+"px";
        
        //const magnifierWidth = magnifier.clientWidth;
        //const magnifierHeight = magnifier.clientHeight;
        //magnifier.style.top=offsetY-magnifierHeight/2+50+"px";
        //magnifier.style.left=offsetX-magnifierWidth/2+50+"px";
        
        const magnifierImg = document.getElementById("magnifierImg");
        if(imageData) {
            magnifierImg.src="data:image/png;base64,"+imageData;
            magnifierImg.style.transform = `translate(${translateX}px,${translateY}px) scale(${magnifierScale})`;
        }
        else {
            magnifierImg.src="";  // bug fix - 231129 -pott
        }
    }

    function showMagnifier() {
        const magnifier = document.getElementById("imageMagnifier");
        magnifier?.classList.remove("hide");
        magnifier?.classList.add("show");
    }

    function handleMouseOverImageOut() {
        const magnifier = document.getElementById("imageMagnifier")
        magnifier?.classList.remove("show");
        magnifier?.classList.add("hide");
        magnifier.style.display='none';
        const magnifierImg = document.getElementById("magnifierImg");
        magnifierImg.src="";
    }

    function dumpImageMagnifier() {
        return(
            <div id="imageMagnifier" className="li-magnifier li-magnifier-sample-edit overflow-hidden" >
                <img id="magnifierImg" alt="missing sample pic"/>
            </div>
        );
    }
    
    //#endregion IMAGE_MAGNIFIER

    function sampleIsOut(item) {
        return !item.magBarcode || item.magBarcode === "";
    }
    
    function showSampleLocation() {
        
        if(!LIUtils.UserCanSeeSampleLocation(cookies)) {
            return null;
        }
        
        if(sampleIsOut(globalState.sample)) {
            return (
                <div className="text-center mt-2">
                    <span className="badge badge-warning li-font-medium">Sample is OUT</span>
                </div>
            );
        }

        const magLocationAsStr = LIUtils.buildMagLocationAsStr(globalState.sample.magBarcode, globalState.sample.magSection, globalState.sample.positionAbsolute);
        
        const magBarcode = globalState.sample.magBarcode ?? "-";
        const magSection = globalState.sample.magSection ?? "-";
        const position = globalState.sample.positionAbsolute <=0 ? "-" : globalState.sample.positionAbsolute;

        let locationMarkup =
            <div className="text-center li-font-medium mt-2">
                <span className="mr-3"><span className="li-fg-dark">Magazine:</span> <b>{magBarcode}</b></span>
                <span className="mr-3"><span className="li-fg-dark">Section:</span> <b>{LIUtils.convertMagSectionIndexToAlpha(magSection)}</b></span>
                <span className=""><span className="li-fg-dark">Position:</span> <b>{position}</b></span>
            </div>;

        return(
                <div id="sampleLocationArea">
                    <div className="text-center li-font-medium mt-2">
                        <span className="li-pointer btn-link" onClick={() => revealMagLocationAndNotify(locationMarkup, magLocationAsStr)}>
                            <i className="fa fa-hand-point-right mr-2"/>Mag Location
                        </span>
                    </div>
                </div>
        );
    }

    function revealMagLocationAndNotify(location:Element, magLocationAsStr:string) {
        ArchiverClientApi.sendNotifySampleLocationViewed(sampleId,"Sample Edit",magLocationAsStr); // fire and forget
        document.getElementById("sampleLocationArea").innerHTML = ReactDOMServer.renderToStaticMarkup(location);
    }
    
    function showProblemDescription() {
        const sample = globalState.sample;
        let reason = naReason;
        if(!LIUtils.existsAndNotEmpty(reason)) { // make up / guess a reason
            reason = LIUtils.GetMissingCriticalInfo(sample.caseNumber, sample.sampleNumber, sample.profileId, sample.isDuplicate);
        }
        if (reason === "") { // all good
            return null;
        }
        let colorClass = "li-bg-pale-warn";
        if (sample.isDuplicate) { // highlight these differently?
            colorClass = " li-bg-duplicate";
        }
        return (
            <div className={colorClass+" li-fg-black p-2 rounded m-2 li-border-dark w-75 ml-auto mr-auto"}>
                <h4>Reason for Attention?</h4>
                <div className="text-center li-font-medium">{reason}</div>
            </div>
        );
    }
    
    function handleDeleteClicked(e) {
        e.preventDefault();
        e.stopPropagation();
       
        Popup.showConfirm("Really Delete Sample?","<p>If permanently deleted, this sample will be:</p><ul><li>removed from it's current mag (if any)</li>" +
                                    "<li>marked as DELETED</li></ul>","Yes, Remove It",
            null,() => {
                // save with permanently remove
                doSave(sampleId, sampleType,failedBarcode, caseNumber, sampleNumber,profileId,true);
            });
    }
    
    function barcodeOrMessage() {
        if(LIUtils.existsAndNotEmpty(failedBarcode)) {
            return failedBarcode;
        }
        return "no barcode read";
    }

    function showDateIfRequired(displayDate:string) {
        if(LIUtils.existsAndNotEmpty(displayDate)) {
            const d:Date = LIUtils.dateFromJsonUtcDateStr(displayDate);
            return  <>
                <div className={"li-font-medium text-center mb-4"}>Added: {d.toLocaleString()}</div>
            </>
        }
    }
    
    function handleShowSampleHistoryClicked() {
        setRedirectTo(`/sample_details/${sampleId}`);
    }
    
    function handleRefreshNAClicked() {
        
        setIsRefreshingNA(true);
        ArchiverClientApi.refreshSingleNASample(sampleId)
        .then(({wasAdded,wasRemoved,wasSkipped}) => {
            if(wasRemoved) {
                Popup.show("Sample Updated",
                    "This sample has been <b>removed</b> from Needs Attention after re-applying its profile rules and checking for all required information.",
                    () => { 
                    dispatch(removeSampleFromNASearchResults(sampleId)); // remove from NA search results list 
                    history.go(-1);});
            }
            else { // added or skipped -- never added.
                Popup.show("No Changes","No changes were found to make to this sample after re-applying the profile rules");
            }
        })
        .catch(responseError => {
            let error = responseError?.error ?? "";
            const lowerError = error.toLowerCase();
            if (responseError.statusCode===404) { // sample wasn't in NA -- 
                error = "<p><strong>Your sample was not found in the Needs Attention list.</strong></p>No re-run was needed.";
            } else if (lowerError.indexOf("forbidden") !== -1) { // may be ok.
                error = "Forbidden";
            }
            ErrorPopup.showError("Error", error, () => {
                dispatch(removeSampleFromNASearchResults(sampleId)); // remove from NA search results list in case it was there by mistake
                //history.go(-1);  // removed - 231129 -pott
            });
        })
        .finally( () => {
            setIsRefreshingNA(false);
        });
    }

    if(redirectTo) {
        return (
            <Redirect push to={redirectTo}/>
        );
    }
    if(redirectToWithReplace) {
        return (
            <Redirect to={redirectToWithReplace}/>
        );
    }
    
    return(
        <div className="container">
            <Header/>
            <div className="row li-header-row justify-content-between">
                <div>
                    <BackButton/>
                    <span className="li-font-huge">Sample Edit</span>
                </div>
                <div className="align-self-center mr-3">
                    <LIButton label="Re-run NA Rules" secondary small showBusy={isRefreshingNA} onClick={handleRefreshNAClicked}/>
                    <span className="ml-3"/>
                    <LIButton label="Show Sample History" primary bgClass=""  small onClick={handleShowSampleHistoryClicked}/>
                    <div className="li-font-tiny my-auto mr-3 text-right pt-2">[id: {globalState.sample.id}]</div>
                </div>
            </div>

            {isLoading && <LoadingSpinner medium center/>}
            {!isLoading &&
                <form id="slideInputForm" autoComplete="off">
                    <div className="row p-0 m-0 mt-3">
                        <div className="col m-0 p-0 mt-5 mb-auto">
                            {showDateIfRequired(globalState.sample.addedToNeedsAttention)}
                            <div className="d-flex justify-content-center">
                                {showImageDataIfPresent(globalState.sample.imageData)}
                                {dumpImageMagnifier()}
                            </div>
    
                            <div className="">
                                {showSampleLocation()}
                            </div>
    
                            <div className="mt-4">
                                {showProblemDescription()}
                            </div>
                            <div className="text-center mt-5">
                                <LIButton medium secondary onClick={(e) =>handleDeleteClicked(e)} label=" Permanently Delete &nbsp;" 
                                          bgClass="mb-4" submitType={false} faIcon="fa fa-trash"/>
                            </div>
                        </div>
                        <div className="col m-0 p-0">
                            <div className="">
                                <table id="slideRepairTable" className="li-font-med-small ml-auto mr-auto">
                                    <tbody>
                                    <tr>
                                        <td className="text-right">
                                            <label htmlFor="bcRead" className="mr-3 mb-4 li-font-medium">Barcode:</label></td>
                                        <td>
                                            <div className="input-group mb-4">
                                                <input className="form-control text-center" ref={barcodeTB} name="bcRead" readOnly value={barcodeOrMessage()}/>
                                                <div className="input-group-append li-bg-primary">
                                                    <div className="input-group-text" id="btnGroupAddon" onClick={handleCopyToCaseClicked}>
                                                        <i className="fa fa-arrow-down"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr id="profilesTr" className="li-bg-pale-warn li-border-dark">
                                        <td className="text-right align-top"><label htmlFor="profiles" className="mr-3 li-font-medium">Profile:</label></td>
                                        <td className={profileId === -1 ? "li-border-left-danger" : null}>{dumpSlideProfiles()}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-right"><label htmlFor="caseNum" className="mr-3 li-font-medium">Case #:</label></td>
                                        <td>
                                            <div className="align-self-center mt-4">
                                                <TextInput label="" refName={caseNumTB} name="caseNum" onChange={handleCaseNumChanged} value={caseNumber}
                                                           helpText={"*hint: " + formatHints.caseExample} maxLength={20} required
                                                           goodNess={caseGoodness}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-right"><label htmlFor="sampleNum" className="mr-3 li-font-medium">Sample #:</label></td>
                                        <td>
                                            <div className="align-self-center">
                                                <TextInput label="" refName={sampleNumTB} name="sampleNum" onChange={handleSampleNumChanged} value={sampleNumber}
                                                           helpText={"*hint: " + formatHints.sampleExample} maxLength={20} required
                                                           goodNess={sampleGoodness}/>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
    
                                <div className="w-100">
                                    <div className="text-center">
                                        <button id="saveEditSampleBTN" className="text-center li-button li-primary li-font-large mt-3" 
                                                onClick={handleSaveClicked}
                                                disabled={!formIsValid}>{isSaving ? <LoadingSpinner small center/> : "Update"}</button>
                                   
                                    {shouldShowGotoNextCB &&
                                        
                                            <div className="mt-3">
                                                <CheckInput label="load next attention sample on save" checked={showNextNASampleOnSave} id="autoNextCB" 
                                                    showCheckLeft colorName="#777777" onChanged={handleShowNextAttentionSampleChanged}/>
                                            </div>
                                       
                                    }
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </form>
            }
            <Footer/>
        </div>
    );
}

export default SampleEditPage;