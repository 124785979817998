import * as PropType from "prop-types";
import React, {useEffect, useState} from "react";
import LoadingSpinner from "./LoadingSpinner";

const LIButton = ({label, bgClass, disabled, large, medium, small, primary, secondary, fitHeight, floatRight, showBusy, submitType, onClick, faIcon, tooltip}) => {

    const [isDoubleClickTimerRunning, setIsDoubleClickTimerRunning] = useState(false);
    const [dbTimerRef:number, setDbTimerRef] = useState();
    
    useEffect( () => {
        return( () => {
            if(dbTimerRef) { // clear the timeout so we to stop react browser errors regarding mem-leaks
                clearTimeout(dbTimerRef);
            }
        });
    },[]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    let sizeClass="li-font-medium"
    if(large) {
        sizeClass = "li-font-large";
    }
    else if(medium) {
        sizeClass = "li-font-medium";
    }
    else if(small) {
        sizeClass = "li-font-small";
    }

    let stdBtnClass="";
    if(primary) {
        stdBtnClass="li-primary";
    }
    else if(secondary) {
        stdBtnClass="li-secondary";
    }
    
    let heightClass="";
    if(fitHeight) {
        heightClass="li-button-height-auto";
    }

    let style=null
    if(floatRight) {
        style={position:'absolute',top:'10px',right:'-10px'};
    }

    function startDoubleClickTimer() {
        setDbTimerRef(setTimeout(() => {
            console.debug("double click timer reset");
            setIsDoubleClickTimerRunning(false);    // allow another click after xx time
        }, 250));
        setIsDoubleClickTimerRunning(true);
    }

    function handleOnClick(e) {
        if(onClick && !isDoubleClickTimerRunning) {
            startDoubleClickTimer();
            onClick(e);
        }
    }
    
    return(
        <button type={submitType?"submit":"button"}
                className={"li-button "+sizeClass+" "+bgClass+" "+stdBtnClass+" "+heightClass}
                data-toggle="tooltip" data-placement="auto" title={tooltip}
                style={style}
                disabled={disabled}
                onClick={(e)=>handleOnClick(e)}>
            {showBusy && <LoadingSpinner small/>}
            {!showBusy && label}
            {!showBusy && <i className={faIcon}/>}
        </button>
    );
}

LIButton.propTypes = {
    label: PropType.string,
    bgClass: PropType.string,
    disabled: PropType.bool,
    onClick: PropType.func,
    large: PropType.bool,
    medium: PropType.bool,
    small: PropType.bool,
    fitHeight: PropType.bool,
    submitType: PropType.bool,
    primary: PropType.bool,
    secondary: PropType.bool,
    showBusy: PropType.bool,
    faIcon: PropType.string,
    tooltip: PropType.string,
}

export default LIButton;