import React, {useState} from 'react';
import * as PropTypes from 'prop-types';
import $ from "jquery";
import ArchiverClientApi from "../API/archiverClientApi";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const CaseFind = ({name, onItemSelected, required, readOnly}) => {

    const MIN_NUM_CHARS_NEEDED_TO_SEARCH=2;         // min length to do the search send and results show
    const MAX_NUM_RESULTS_AT_A_TIME=5;          // show this many search results at a time only

    const [searchResults, setSearchResults] = useState([]);
    const [searchTextField, setSearchTextField] = useState("");
    const [inputClass] = useState("form-control");
    const [isSearching, setIsSearching] = useState(false);
    
    function handleSearchCases(e) {
        e.preventDefault();
        e.stopPropagation();
        const searchTerm=getSearchTerm();
        if(!searchTerm || searchTerm==="" || searchTerm===" ") {
            return;
        }
        setIsSearching(true);

        ArchiverClientApi.sendCaseSearchRequest(searchTerm,0,MAX_NUM_RESULTS_AT_A_TIME)
        .then( results => {
            setSearchResults(results);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsSearching(false));
    }
    
    function handleSearchKeyUp(event) {
        event.preventDefault();
        event.stopPropagation();
        const searchTerm = getSearchTerm()
        if(searchTerm.length >= MIN_NUM_CHARS_NEEDED_TO_SEARCH) {
            $("#caseSearchBTN").trigger("click");  // attempt a search
        }
    }

    function getSearchTerm() {
        const term = $("#caseSearchInput").val();
        return(term);
    }
    
    function handleItemClicked(e, item) {
        if(onItemSelected) {
            e.target.name=name;  // fake for our parent handler
            e.target["item"]=item;
            onItemSelected(e);
        }
        setSearchResults([]); // make the list go away
        setSearchTextField(item.number);
    }
    
    function handleSearchTextChanged(e) {
        const value = e.target.value;
        setSearchTextField(value);
    }
    
    function showListDivIfRequired() {
        if(searchTextField.length<MIN_NUM_CHARS_NEEDED_TO_SEARCH || searchResults.length===0) {
            return null;
        }
        return(
            <div className="card">
                <div style={{zIndex:"1000", overflow:"auto", height:"200px", width:"100%", position:"absolute"}}>
                    <ul className="list-group">
                        {searchResults.map( (item) =>
                            <li key={item.id} className="list-group-item li-contact-search-item" 
                                onClick={(e)=>handleItemClicked(e,item)}>{item.number}</li>
                        )}
                    </ul>
                </div>
            </div>
        );
    }
    
    return (
        <div className="form-group">
            <div className="input-group">
            <input type="text" className={inputClass} id="caseSearchInput"
                   placeholder="case #"
                   onKeyUp={handleSearchKeyUp}
                   aria-describedby="inputGroupPrepend"
                   value={searchTextField}
                   onChange={handleSearchTextChanged}
                   autoComplete="off"
                   required={required} readOnly={readOnly}/>
            <div className="input-group-append">
                <button type="button" id="caseSearchBTN" disabled={readOnly} className="li-button li-primary li-search-button" onClick={handleSearchCases}>
                    {isSearching && <LoadingSpinner small/>}
                    {!isSearching && <i className="fa fa-lg fa-search"/>}
                </button>
            </div>
            </div>
            {showListDivIfRequired()}
        </div>
    );
};

CaseFind.propTypes = {
    onItemSelected: PropTypes.func.isRequired,
    required: PropTypes.bool,
    goodNess: PropTypes.string,
    focus: PropTypes.bool,
    readOnly: PropTypes.bool,
    name: PropTypes.string.isRequired,
};

export default CaseFind;