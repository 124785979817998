import ArchiverClientApi from "../API/archiverClientApi";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import {VERSION} from "./version";

export function checkAndReportWwwAppUpdate(showPrompt:boolean=true) {
    console.debug("checking for www app update...");
    ArchiverClientApi.getServerSideWwwAppInfo()
    .then(({serverSideWwwAppVersion}) => {
        if (serverSideWwwAppVersion !== VERSION) {
            console.warn("server side version does not match client side version...forcing a reload.");
            if (showPrompt) {
                Popup.showConfirm("Application Update", `<p>A new version of the application is available (${serverSideWwwAppVersion})</p><p>Press 'Update Now' to restart with this new version.</p>
                <p>or Close to continue working, but you will see this messaga again shortly...`, "Update Now", () => {
                }, () => {
                    forceReloadOfApp();
                });
            }
            else {
                forceReloadOfApp();
            }
        }
    })
}

function forceReloadOfApp() {
    
    // this borrowed from 
    // https://www.wednesday.is/writing-tutorials/react-to-the-react-app-how-to-hard-reload-your-react-web-app-using-error-boundary
    
    // unregister any service workers
    if(window.navigator && window.navigator.serviceWorker) {
        window.navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.forEach((registration) => {
                registration.unregister();
            });
        });
    }
    
    // remove everything from browser cache
    if(window.caches) {
        window.caches.keys().then((keyList) => {
            return Promise.all(keyList.map((key) => {
                return window.caches.delete(key);
            }));
        });
    }
    
    // reload the page after a slight delay
    setTimeout(() => {
        window.location.reload();
    }, 1000);
    
}