
import PropTypes from "prop-types";
import LIUtils from "../SUPPORT/LIUtils";

const ProfileCard = ({profile, radioGroupName, checked, onRadioChanged}) => {
    
    const MAX_COMMENT_LEN=40;
    
        /*  barcodesAreUnique: true
            comment: "newer 20-S sample, dm topleft"
            destinationMagProfileId: 4
            holdsSampleType: null
            id: 2
            inspectionRegions: [{id: 1, name: "prhc-sample-topleft-dm", cropTopLeft: "400,400", cropBottomRight: "750,800",…}]
            isCaseMappedExternal: false
            isMagProfile: false
            name: "1.prhc-sample-20-s-dm"
            sampleImage: {,…}
            dataBytes: "/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAgGBgcGBQgHBwcJC
            isEmpty: false
            numBytes: 12093
            type: 1 
        */
    
    function dumpImage() {
        if(profile?.sampleImage) {
            return LIUtils.getImgFromImageData(profile.sampleImage.dataBytes,"profileImageInList", "100");
        }
        return <div className="image-missing li-font-small text-center pt-4">no image</div>
    }
    
    function processProfileComment(comment:string) {
        let trimmed = comment.substring(0,MAX_COMMENT_LEN);
        if(trimmed.length!==comment.length) {
            trimmed += "...";
        }
        return trimmed;
    }

    /**
     * enable clicking on the entire line to make it easier
     */
    function handleItemClicked() {
        if(onRadioChanged) {
            onRadioChanged(profile.id);
        }
    }

    const activeColor=checked?"li-border-good":"li-border-transparent";
    
    return (
      <div className={"li-pointer col-sm-12 "+activeColor}>
          <div className="li-profile-card d-flex m-0 p-0" onClick={handleItemClicked}>
              <input type="radio" className="my-auto m-2 p-0" name={radioGroupName} 
                     value={profile.id} checked={checked}
                  onChange={() => onRadioChanged(profile.id)}/>
              <div className="">
                    {dumpImage()}
              </div>
              <div className="ml-3">
                  <div className="li-fg font-weight-bold text-truncate">{profile.name}</div>
                  <div className="li-fg-light li-font-small">{processProfileComment(profile.comment)}</div>
              </div>
          </div>    
      </div>
    );
}

ProfileCard.propTypes = {
    profile: PropTypes.object.isRequired,
    radioGroupName: PropTypes.string.isRequired,
    checked: PropTypes.bool,
}

export default ProfileCard;