import $ from 'jquery';
import React, {useEffect} from 'react';
import * as PropTypes from 'prop-types';

const ErrorPopup = ({name}) => {

    useEffect(() => {
        $(document).off('shown.bs.modal', '#liModalErrorPopup');
        $(document).on('shown.bs.modal', '#liModalErrorPopup', function (e) {
            $("#liErrorCloseBTN").focus();
        });
        $(document).off('hidden.bs.modal','#liModalErrorPopup');
        return (() => { // unmount
            console.debug(">>> unmounting error popup");
            $('#liModalErrorPopup').modal('hide');
            $('.modal-backdrop').remove();
        });
    }, []);
    
    return (
        <div className="modal fade" tabIndex="-1" id="liModalErrorPopup" role="dialog" aria-labelledby="liErrorPopup"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content li-modal-content">
                    <div className="modal-header li-modal-header" id="liErrorPopupHeader">
                        <h5 className="modal-title" id="liErrorPopupTitle">The Title Is: {name}</h5>
                        <button id="liErrorPopupClose" type="button" className="close" data-dismiss="modal" aria-label="close" onClick={(e) => ErrorPopup.hide(e)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body" id="liErrorPopupBody">
                        This is the body of the popup.
                    </div>
                    <div className="modal-footer border-0">
                        <button className="li-button li-secondary" id="liErrorCloseBTN" onClick={(e) => ErrorPopup.hide(e)}>Close</button>
                    </div>
                </div>
            </div>
        </div>

    );
}

ErrorPopup.propTypes = {
    name: PropTypes.string.isRequired,
};

ErrorPopup.defaultProps = {
    name: "no name"
}

export default ErrorPopup;

ErrorPopup.gPopupOnClose=undefined;

ErrorPopup._showTitleIfNeeded = (title, color) => {
    $('#liModalErrorPopup .modal-header').css({'background':color});
    const titleBar = $('#liErrorPopupHeader');
    if(!title || title==="") {
        titleBar.hide();
    } else {
        titleBar.show();
        $('#liModalErrorPopup #liErrorPopupTitle').html(title);
    }
}

ErrorPopup.focus = () => {
    $("#liErrorCloseBTN").focus();
}

ErrorPopup._showBody = (message) => {
    const theBody = $('#liErrorPopupBody');
    theBody.html(message);
    const el = $('#liModalErrorPopup');
    el.modal();
    el.off("click");   // we don't want clicks handled by backdrop
}

ErrorPopup.showError = (title,message, onClose) => {
    ErrorPopup._showTitleIfNeeded(title,'indianred');
    ErrorPopup._showBody(message);
    ErrorPopup.gPopupOnClose=onClose;
}

ErrorPopup.showInfo = (title, message, onClose) => {
    ErrorPopup._showTitleIfNeeded(title, '#e0e0e0');
    ErrorPopup._showBody(message);
    ErrorPopup.gPopupOnClose = onClose;
}

ErrorPopup.hide = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    const $modal = $('#liModalErrorPopup');
    $modal.off('hidden.bs.modal');
    $modal.on('hidden.bs.modal', function (e) {
        if (ErrorPopup.gPopupOnClose) {
            ErrorPopup.gPopupOnClose(e);
        }
    })
    $modal.modal('hide');
}
