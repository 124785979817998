import React, {useContext, useEffect, useState} from "react";
import * as PropTypes from "prop-types";
import ArchiverClientApi from "../API/archiverClientApi";
import {gotContactsForDepartment, gotLocations} from "../ACTIONS/clientActions";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import {StoreContext} from "../store";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const LocationSelector = ({onChange, locationLabel, locationValue, departmentValue, contactValue, required, readOnly, showContact, withDepartmentsOnly, onIsValid}) => {
    
    const [globalState, dispatch] = useContext(StoreContext);
    const [locationInputClass, setLocationInputClass] = useState("");
    const [deptInputClass, setDeptInputClass] = useState("");
    const [selectedDeptValue,setSelectedDeptValue] = useState(departmentValue);
    const [selectedContactValue, setSelectedContactValue] = useState(contactValue);
    const [selectedLocation, setSelectedLocation] = useState(locationValue);
    
    useEffect( () => {
        if(!globalState.locations || globalState.locations.length===0) {
            // get the enabled and disabled locations (we filter based on readonly)
            ArchiverClientApi.getLocations(false).then( locations => {
                dispatch(gotLocations(locations));      // locations and their departments
            })
            .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError));
        }    
    },[globalState.locations]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        const inputClass = LIUtils.getValidationClassGivenValue(locationValue, required, readOnly);
        setLocationInputClass(inputClass)

        setSelectedContactValue(-1); // none
        const selectedLocation = globalState.locations.find( item =>item.id === locationValue);
        setSelectedDeptValue(-1);  // none
        setSelectedLocation(selectedLocation);
        if(!selectedLocation) {
            return;
        }
        if(locationValue===selectedLocation) {
            return; // no real change
        }
        if(selectedLocation) {
            if (selectedLocation.departments?.length === 0) { // no departments
                setSelectedDeptValue(-1);
            }
            else if (selectedLocation.departments?.length === 1) { // preselect the only department in the list
                const sel = document.getElementById("destDepartment");
                sel.value = selectedLocation.departments[0].id
                fireEvent(sel, 'change'); // manually fire the change event.
                setSelectedDeptValue(selectedLocation.departments[0].id);
            }
        }

    }, [globalState.locations,locationValue,required,readOnly]); // eslint-disable-line react-hooks/exhaustive-deps
    
    function updateDeptInputClass(selectedLocation,deptId) {
        let inputClass="";
        if(shouldDeptBeDisabled(selectedLocation)) {
            inputClass = LIUtils.getValidationClassGivenValue(null, required, readOnly=true);
            setDeptInputClass(inputClass); // - always bad
        }
        else { // either good or bad depending on selection
            inputClass = LIUtils.getValidationClassGivenValue(deptId, required, readOnly);
            setDeptInputClass(inputClass); // may be good or bad
        }
        if(onIsValid) { // update parent if they care
            onIsValid(LIUtils.isValidationClassValid(inputClass));
        }  
    }
    
    function shouldDeptBeDisabled(selectedLocation):boolean {
        if(!selectedLocation || selectedLocation===-1) {
            return true;
        }
        if(selectedLocation?.departments===null || selectedLocation?.departments?.length<2) {
            return true;
        }
        return false;
    }
    
    function fireEvent(element,event){
        if (document.createEventObject){
            // dispatch for IE
            const evt = document.createEventObject();
            return element.fireEvent('on'+event,evt)
        }
        else{
            // dispatch for firefox + others
            const evt = document.createEvent("HTMLEvents");
            evt.initEvent(event, true, true ); // event type,bubbling,cancelable
            return !element.dispatchEvent(evt);
        }
    }
    
    useEffect( () => {
        const selectedLocation = globalState.locations.find( item =>item.id === locationValue);
        updateDeptInputClass(selectedLocation,departmentValue); // always update the department class
        setSelectedLocation(selectedLocation);  // for use by disable dept button
        if(departmentValue===selectedDeptValue) { // no change
            return;
        }
        setSelectedDeptValue(departmentValue);
        
        if(departmentValue && departmentValue>-1 && showContact) {
            ArchiverClientApi.getContactsForDepartment(departmentValue).then(contacts => {
                dispatch(gotContactsForDepartment(contacts));
            })
            .catch(error => ErrorPopup.showError("ERROR", error));
        }
    },[locationValue,departmentValue,required,readOnly]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        setSelectedContactValue(contactValue);
    }, [contactValue]);
    
    function dumpTopLevelOptionsList(locations) {
        if(!locations || locations.length===0) {
            return <option disabled>(no locations available)</option>;
        }
        if(withDepartmentsOnly) {
            locations = locations.filter( l => l.departments!==null && l.departments.length>0);
        }
        return locations.map(item => {
            if(readOnly===true || item.isEnabled) { // only show if it's enabled and we're not readonly
                return <option key={item.id} value={item.id}>{item.name}</option>;
            }
            return null;
        });
    }

    function dumpDepartmentLevelOptionsList(locations, selectedLocationId) {
        const destDeptSection = document.getElementById("destDeptSection");
        
        if(!locations || locations.length===0) {
            return <option value={-1}>(select a location first)</option>
        } 
        if(!selectedLocationId || selectedLocationId===-1) {
            return <option value={-1}>(select a department)</option>
        }
        
        const selectedLocation = locations.find( item =>item.id === selectedLocationId);
        if(!selectedLocation || (selectedLocation.isEnabled===false && readOnly===false)) { // force a department re-selection
            return <option value={-1}>(select a department)</option>;
        }
        
        if (selectedLocation.departments===null || selectedLocation.departments.length===0) {
            if(destDeptSection!=null) destDeptSection.style.display = "none";
            const destContactSection = document.getElementById("destContactSection"); // hide the contacts
            if(destContactSection!=null) destContactSection.style.display = "none";
            return;
        }
        
        if(destDeptSection!=null) destDeptSection.style.display = "block";
        
        const optionList = selectedLocation.departments.map(item => {
            if(readOnly===true || item.isEnabled) { // only show if it's enabled and we're not readonly
                return <option key={item.id} value={item.id}>{item.name}</option>;
            }
            return null;
        });
        // add the default option
        optionList.unshift(<option key="-1" value={-1}>{selectedLocationId === -1 ? "(select a location first)" : "(select a department)"}</option>);
        
        return optionList;
    }

    function dumpContactLevelOptionsList(contacts, selectedDepartmentId) {
        const destContactSection = document.getElementById("destContactSection");
    
        if(!contacts || contacts.length===0 || selectedDepartmentId===-1) {             
            if(destContactSection!=null) destContactSection.style.display = "none";
            return ; //<option disabled>(no contacts available)</option>;
        }

        if(destContactSection!=null) destContactSection.style.display = "block";
        
        const optionList = contacts.map(item => <option key={item.id} value={item.id}>{item.fullName}</option>);
        if(optionList.length===1) { // preselect this single option
            if(document.getElementById("destContact")!=null) {
                document.getElementById("destContact").value = contacts[0].id;
            }
        }
        else {
            optionList.unshift(<option key="-1" value={-1}>{selectedDepartmentId === -1 ? "(select a department first)" : "(select a contact)"}</option>);
        }
        return optionList;
    }
    
    return (
          <div className="form-group m-0">
              
              <div className="row">
                  <div className="col">
                      {/* LOCATIONS */}
                      <label className="li-form-label" htmlFor="destLocation">{locationLabel??"Destination Location"}</label>
                      <select onChange={onChange} id="destLocation" name="destLocation" value={locationValue??-1} className={locationInputClass} required={required}
                            disabled={readOnly}>
                          <option value={-1}>(select a location)</option>
                          {dumpTopLevelOptionsList(globalState.locations)}
                      </select>
                  </div>
                  <div id="destDeptSection" className={(readOnly && (!departmentValue||departmentValue===-1)) ? "d-none":""}>
                      {/* DEPARTMENTS */}
                      <label className="li-form-label" htmlFor="destDepartment">Department</label>
                      <select onChange={onChange} id="destDepartment" name="destDepartment" value={selectedDeptValue??-1} className={deptInputClass}
                            disabled={readOnly || locationValue===-1 || shouldDeptBeDisabled(selectedLocation)}>
                          {dumpDepartmentLevelOptionsList(globalState.locations,locationValue)}
                      </select>
                  </div>
              </div>
              
              {/* CONTACT */}
              {showContact &&
                  <section id="destContactSection" className={(readOnly && (!contactValue || contactValue === -1)) ? "d-none" : ""}>
                      <label className="li-form-label" htmlFor="destContact">Person</label>
                      <select onChange={onChange} id="destContact" name="destContact" value={selectedContactValue} className="li-form-field"
                              disabled={readOnly || departmentValue === -1}>
                          {dumpContactLevelOptionsList(globalState.contacts, departmentValue)}
                      </select>
                      {/*<small className="form-text text-muted">* contact (if any)</small>*/}
                  </section>
              }
              
          </div>
    );
}

LocationSelector.defaultProps = {
    showContact: true,
    readOnly: false,
    withDepartmentsOnly: false,
}

LocationSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    locationValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    departmentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    contactValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    required: PropTypes.bool,
    focus: PropTypes.bool,
    readOnly: PropTypes.bool,
    showContact: PropTypes.bool,
    withDepartmentsOnly: PropTypes.bool,
    locationLabel: PropTypes.string,
    onIsValid: PropTypes.func,
};

export default LocationSelector;