
import React, {useContext, useEffect, useState} from "react";
import Header from "./Header";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import {StoreContext} from "../store";
import ArchiverClientApi from "../API/archiverClientApi";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import Footer from "./Footer";
import ChaseListCardRow from "./ChaseListCardRow";
import {gotChaseList, setChaseItemChecked} from "../ACTIONS/clientActions";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import GuiUtils from "../GUI_COMMON/SUPPORT/GuiUtils";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import TextArea from "../GUI_COMMON/COMPONENTS/TextArea";
import DatePicker from "react-date-picker";
import ReloadButton from "../GUI_COMMON/COMPONENTS/ReloadButton";
import LIButton from "../GUI_COMMON/COMPONENTS/LIButton";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import BackNextWidget from "../GUI_COMMON/COMPONENTS/BackNextWidget";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import {useCookies} from "react-cookie";
import CalendarIcon from "./CalendarIcon"; 

const CHASE_LIST_PAGE_SIZE=250;


const ChaseListPage = () => {

    const [globalState, dispatch] = useContext(StoreContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isSnoozing, setIsSnoozing] = useState(false);
    const [redirectTo, setRedirectTo] = useState();
    const [chaseList,setChaseList] = useState([]);
    const [chaseListByLocation, setChaseListByLocation] = useState([]);
    const [showSnoozeForm, setShowSnoozeForm] = useState(false);
    const [snoozeList, setSnoozeList] = useState([]);
    const [snoozeDetails, setSnoozeDetails] = useState( {
        dueBackDate: getTomorrow(),
        note: "",
    });
    const [caseGroupOpenList, setCaseGroupOpenList] = useState({});

    const history = useHistory();
    const location = useLocation();
    const [cookies,] = useCookies();
    
    const params = new URLSearchParams(location.search);
    const [pageSize,] = useState(CHASE_LIST_PAGE_SIZE);
    const [startIndex, setStartIndex]=useState(Number(params.get('si')??0));

    const [reRunChaseListLabel, setReRunChaseListLabel ] = useState("Re-run Chase Evaluation");
    const [canRefreshChaseSamples, setCanRefreshChaseSamples] = useState(true);


    useEffect(() => {
        console.debug("ChaseListPage starting up...");
        if(!globalState.chaseList.chaseListRaw || globalState.chaseList.chaseListRaw.length===0) { // get it
            handleBackNext(startIndex,pageSize);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        if(globalState.chaseList) {
            setChaseList(globalState.chaseList.chaseListRaw);
            setChaseListByLocation(globalState.chaseList.chaseListByLocation);
        }
    }, [globalState.chaseList]);

    function updateChaseListAndDispatch(updatedChaseList, totalNowInChaseList) {

        // rebuild both lists based on the updated list (no coms to server here)
        let chaseListByLocation = buildLocationGroupList(updatedChaseList);
        chaseListByLocation = augmentLocationsWithCaseGroups(chaseListByLocation); // order the child samples by case group
        dispatch(gotChaseList(updatedChaseList,chaseListByLocation,totalNowInChaseList));
    }

    function buildLocationGroupList(chaseList) {
        console.debug("grouping by location (expensive rebuild)...");

        let groupList = [];
        let curLocationHash = null;
        let groupName = "-";
        let curGroupItems = [];
        for (let i = 0; i < chaseList.length; i++) {
            let item = chaseList[i];
            const locationHash = item.order.destinationContact?.id ?? 0 + "." + item.order.destinationDepartment?.id ?? 0 + "." + item.order.destinationLocation?.id ?? 0;
            if (curLocationHash !== locationHash) { // changed -- start a new group
                if (curGroupItems.length > 0) { // add the last group to the list
                    groupList= addGroupToGroupList(groupList, groupName, curGroupItems,i);
                }
                groupName = GuiUtils.BuildPrettyLocation(item.order.destinationLocation, item.order.destinationDepartment, item.order.destinationContact);
                curLocationHash = locationHash;
                curGroupItems = []; // reset
            }
            curGroupItems.push(item);
        }
        if (curGroupItems.length > 0) { // add the last group to the list
            groupList = addGroupToGroupList(groupList, groupName, curGroupItems,chaseList.length);
        }
        
        return groupList;
    }
    
    function addGroupToGroupList(groupList:Array, groupName:string, sampleItems,index:Number):Array {
        let groupItem = groupList.find(item => item.groupName===groupName);
        if(groupItem) { // add these to existing location list
            groupItem.samples.push(...sampleItems);
        }
        else { // append this location since doesn't exist
            groupList.push({index:index, groupName, samples:sampleItems, expanded:false});
        }
        return groupList;
    }

    function buildCaseGroupList(sampleList) {
        console.debug("grouping by cases (expensive rebuild)...");

        const groupList = [];
        let curCaseNumber = "-";
        let curGroupItems = [];
        sampleList.sort( (a,b) => {
            if (a.sample?.caseNumber==null) return 1;
            if (b.sample?.caseNumber==null) return -1;
            return (a.sample.caseNumber.localeCompare(b.sample.caseNumber));

        });
        for (let i = 0; i < sampleList.length; i++) {
            let item = sampleList[i];
            if (curCaseNumber !== item.sample.caseNumber) { // changed -- start a new group
                if (curGroupItems.length > 0) { // add the last group to the list
                    groupList.push({name:curCaseNumber, samples:curGroupItems, isChecked:false, expanded:false});
                }
                curCaseNumber = item.sample.caseNumber;
                curGroupItems = []; // reset
            }
            curGroupItems.push(item);
        }
        if (curGroupItems.length > 0) { // add the last group to the list
            groupList.push({name:curCaseNumber??"-", samples:curGroupItems, isChecked:false, expanded:false});
        }

        return groupList;
    }
    
    function handleGotoSample(sampleId) {
        setRedirectTo(`/sample_details/${sampleId}`);
    }
    
    function handleGotoOrder(orderId) {
        setRedirectTo(`/retrievals/details/${orderId}`);
    }
    
    function handleExpandLocation(item, index) {
        const isShowingNow = document.getElementById("collapseGroup" + index).classList.contains("show");
        const curChaseListByLocation = [...chaseListByLocation];
        const expandedItem = curChaseListByLocation.find(i => i === item);
        if (expandedItem) {
            expandedItem.expanded = !isShowingNow; // do opposite
        }
        setChaseListByLocation(curChaseListByLocation);
    }

    function handleExpandCaseGroup(expanderGroupName) {
        const isShowingNow = document.getElementById("collapseCaseGroup-" +expanderGroupName).classList.contains("show");
        let curCaseGroupOpenList = {...caseGroupOpenList};
        if (isShowingNow) { // it's in the list now -- hide
            document.getElementById("collapseCaseGroup-" +expanderGroupName).classList.remove("show");
            curCaseGroupOpenList[expanderGroupName]=undefined; // out
        }
        else { // add to the showing list
            document.getElementById("collapseCaseGroup-" +expanderGroupName).classList.add("show");
            curCaseGroupOpenList[expanderGroupName]=true;
        }
        setCaseGroupOpenList(curCaseGroupOpenList);
    }
    
    function handleCaseGroupChecked(e,groupName, caseName) {
        const cListCopy = [...chaseListByLocation];
        const parentLocation = cListCopy.find( item => item.groupName === groupName);
        const cg = parentLocation.caseGroups.find(item => item.name === caseName);
        if(!cg) {
            console.error(`caseName ${caseName} not found for checking...ignoring.`);
            return;
        }
        cg.isChecked=e.target.checked;
        cg.samples.forEach( sample => sample.isChecked=cg.isChecked);
        setChaseListByLocation(cListCopy);
    }
    
    function augmentLocationsWithCaseGroups(cListByLocation:Array):Array {
        console.debug("augmenting chaseListByLocation with grouped cases...(expensive)");
        
        const cListCopy = [...cListByLocation];
        for(let i=0;i<cListCopy.length;i++) {
            const locationGroup = cListCopy[i];
            locationGroup.caseGroups = buildCaseGroupList(locationGroup.samples);  // augment.
        }
        return (cListCopy); // augmented is now the list we are using.
    }
    
    function showResultsList(cListByLocation:Array) {
        if (!cListByLocation || cListByLocation.length === 0) {
            return <div className="card text-center">none</div>;
        }

        const resultRows = cListByLocation.map((item, index) => {
                const locationGroupName = item.groupName;
                const locationGroupIndex = index;
                const samples = item.samples;
                const expanded = item.expanded;
                const caseGroups = item.caseGroups; // augmented from before

                const allCaseRows = [];
                let groupIndex = 0;
                for (const cg in caseGroups) {
                    const caseGroup = caseGroups[cg];
                    const groupChecked = caseGroup.isChecked;
                    let formattedSampleRows;
                    let formattedSection;

                    // build the rows of samples for this CASE GROUP
                    formattedSampleRows = caseGroup.samples.map((item) => {
                        return (
                            <ChaseListCardRow key={item.sample.id} sample={item.sample} order={item.order}
                                              dateDueBack={LIUtils.dateFromJsonUtcDateStr(item.dateDueBack)}
                                              isChecked={item.isChecked}
                                              onCheckChanged={(isChecked) => handleSampleCheckChanged(item, isChecked)}
                                              onSampleClick={() => handleGotoSample(item?.sample.id)}
                                              onOrderClick={() => handleGotoOrder(item?.order.id)}
                            />
                        );
                    });
                    // build the expandable section for this CASE GROUP
                    const expanderGroupName = `${locationGroupIndex}-${groupIndex}-${caseGroup.name}`;
                    const isExpanded = caseGroupOpenList[expanderGroupName]===true;
                    console.debug(`expander group ${expanderGroupName} expanded=${caseGroupOpenList[expanderGroupName]}`);
                    formattedSection = <div key={groupIndex} className="ml-2 p-1">
                        
                         <span className="btn-link li-pointer mr-3" onClick={() => handleExpandCaseGroup(expanderGroupName)}>
                            <i className={"li-fg-muted fa fa-lg "+(isExpanded?"fa-chevron-down":"fa-chevron-right")}/>
                        </span>
                        
                        <input type="checkbox" className="m-2 mr-3" name="caseGroup-cb" aria-label="Checkbox" checked={groupChecked}
                                onChange={(e) => handleCaseGroupChecked(e,locationGroupName, caseGroup.name)}/>
                        <span className="mr-4"><strong>Case {caseGroup.name}</strong></span>
                       
                        <span>{caseGroup.samples.length} {caseGroup.samples.length>1?"samples":"sample"}</span>

                        
                        <div className={isExpanded===true?"show ":"collapse "} id={"collapseCaseGroup-" +expanderGroupName}>
                            <div className="card card-body">
                                {dumpSampleChaseTable(formattedSampleRows, groupIndex)}
                            </div>
                        </div>
                    </div>
                    allCaseRows.push(formattedSection);
                    groupIndex++;
                }
                return (
                    // return the expandable section for THIS LOCATION GROUP
                    <div key={locationGroupName} className="p-3 border-bottom">
                        <span className="btn-nav mr-3" onClick={() => handleExpandLocation(item, index)}>
                            <i id={"collapseGrip" + index} className={"fa li-fg-primary fa-2x " + (expanded ? "fa-chevron-circle-down" : "fa-chevron-circle-right")}/>
                        </span>
                        <span className="li-font-medium">{locationGroupName} : {samples.length} samples</span>
                        <div className={"collapse " + (expanded ? "show" : "hide")} id={"collapseGroup" + index}>
                            <div className=" ml-5 mt-2 p-0">
                                {allCaseRows}
                            </div>
                        </div>
                    </div>
                );
            }
        );

        return (
            <div id="chaseList" className="">
                {resultRows}
            </div>
        );
    }
    
    function dumpSampleChaseTable(formattedSampleRows, keyIndex) {
        return(
            <table key={"table"+keyIndex} className="table-sm">
                <thead>
                <tr>
                    <th/>
                    <th>Case#/Sample#</th>
                    <th>Order#</th>
                    <th>Days Late</th>
                </tr>
                </thead>
                <tbody>
                {formattedSampleRows}
                </tbody>
            </table>
        );
    }

    function handleSampleCheckChanged(chaseItem,isChecked) {
        dispatch(setChaseItemChecked(chaseItem,isChecked));
    }

    function numSamplesChecked():boolean {
        let numChecked = 0;
        chaseList.forEach( item => {if(item.isChecked) numChecked++ });
        return numChecked;
    }

    //#region SNOOZE
    
    function handleSnoozeClicked() {
        const snoozed = chaseList?.filter(item => item.isChecked)??[];
        setSnoozeList(snoozed);
        setShowSnoozeForm(true);
    }
    
    function doSnoozeSamples(e) {
        e.preventDefault();
        e.stopPropagation();
        
        const sampleOrderList = snoozeList.map( item => {
            return {orderId: item.order.id, sampleId: item.sample.id};  // return a new object
        });
        
        setIsSnoozing(true);
        ArchiverClientApi.sendSnoozeRequestForSamples(sampleOrderList,snoozeDetails.dueBackDate, snoozeDetails.note)
        .then( ({totalInList, totalUpdated}) => {
            removeSnoozedSamples(totalInList);
            
            Popup.show("",`Snoozed ${totalUpdated} samples.`,() => {
                setShowSnoozeForm(false);
            });
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
           setIsSnoozing(false); 
        });
    }

    function handleSnoozeDetailsChanged(name,e) {
        let value;
        if(name==="dueBackDate") { // calendar is special (just sends the date up)
            value = e;  // Date
        }
        else { // inputs and such
            value = e.target.value; 
        }
        const updatedDetails = {...snoozeDetails};
        updatedDetails[name]=value;
        setSnoozeDetails(updatedDetails);
    }
    
    function removeSnoozedSamples(totalNowInChaseList) {
        const updatedChaseList = chaseList?.filter( item => !item.isChecked)??[]; // return all those that aren't checked
        updateChaseListAndDispatch(updatedChaseList,totalNowInChaseList);
    }
    
    //#endregion SNOOZE

    function haveResults() {
        return chaseList && chaseList.length>0;
    }
    
    function handleBackNext(si, numToReturn) {

        console.debug(`*** handling backnext ${si}->${numToReturn}***`);
        setStartIndex(si);
        replaceBrowserHistory(si,numToReturn);

        setIsLoading(true);
        ArchiverClientApi.getChaseList(si, numToReturn)
        .then(({totalItems,chaseItems}) => {
            updateChaseListAndDispatch(chaseItems,totalItems);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsLoading(false));
    }

    function replaceBrowserHistory(startIndex,numToReturn) {
        history.replace(`/chase?si=${startIndex}&nr=${numToReturn}`);
    }
    
    if(redirectTo) {
        return (
            <Redirect push to={redirectTo}/>
        );
    }
    
    function getTomorrow() {
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate()+1);
        return(tomorrow);
    }
    
    function dumpSnoozeForm() {
        
        const snoozeVerb = snoozeList.length>1?"samples":"sample";
        
        return(
            <div className="">
                <p className="li-fg-muted li-font-medium"><i>Snoozing {snoozeList.length} {snoozeVerb}</i></p>
                <hr/>
                <form name="snoozeForm">
                    <div className="row mt-5 mb-4">
                        <label className="li-form-label mt-0 mr-4" htmlFor="dueBackDate">Snooze Until Date:</label>
                        <div>
                            <DatePicker className="li-pointer border" name="dueBackDate" minDate={getTomorrow()} clearIcon={null} calendarType="US"
                                        onChange={(e) => handleSnoozeDetailsChanged("dueBackDate",e)} value={snoozeDetails?.dueBackDate??null} 
                                        calendarIcon={<CalendarIcon/>}
                                        required/>
                            <small className="form-text text-muted">* month/day/year</small>
                        </div>
                    </div>
                    <TextArea label="Reason for Snoozing" name="note" value={snoozeDetails.note} onChange={(e) => handleSnoozeDetailsChanged("note",e)}/>
                    <div className="row justify-content-center mt-3 mb-5">
                        <button className="li-button li-tertiary mr-5 li-font-medium h-100 p-3" onClick={() => setShowSnoozeForm(false)}>Cancel</button>
                        <LIButton primary bgClass="h-100 p-3" label="Snooze" showBusy={isSnoozing} disabled={numSamplesChecked()===0}
                                  onClick={doSnoozeSamples}/>
                    </div>
                </form>
            </div>
        );
    }

    function showReRunChaseSamplesIfRequired() {
        return LIUtils.UserHasLevel(cookies,"SERVICE");
    }

    function reRunSamples() {
        Popup.showConfirm("Rerun Chase List Evaluation",
            "<p>Are you sure you want to re-run the chase list evaluation?  This can take a while to complete.</p>" +
            "<p>NOTE: this will <b>NOT</b> notify sample watchers of samples being returned if any are found to now be IN magazines.</p>",
            "Yes, Run it",()=>{},() => {
            reRunSamplesActual();
        });
    }
    
    function reRunSamplesActual() {

        setCanRefreshChaseSamples(false);
        ArchiverClientApi.refreshChaseListSamples((progressValue:Number, isDone:boolean=false, isError:boolean=false, finalResults:Object=null)=> {

            let updatedLabel = "Rerun Evaluation";
            if(!isDone && !isError) {
                console.debug("chase list refresh progress: "+progressValue);
                updatedLabel = `Refreshing ... ${progressValue}%`;
            }
            setReRunChaseListLabel(updatedLabel);

            if(isDone===true) {
                setCanRefreshChaseSamples(true);
                handleBackNext(0,pageSize); // refresh the Chase list being displayed
                Popup.show("Chase List Refresh Completed",`<b>Samples Processed:</b> ${finalResults.numProcessed}<br/><b>Samples Removed from chase list:</b> ${finalResults.numRemoved}`);
            }
            if(isError===true) {
                setCanRefreshChaseSamples(true);
                ErrorPopup.showError("Rerun Error",`<p>Failed to run chase list refresh.</p><p>Reason: ***<b>${finalResults.errorReason}</b>***</p>`);
            }
        });
    }
    
    function dumpChaseList() {
        const numChecked = numSamplesChecked();
        return(
            <>
                <div className="row li-header-row m-0 p-0 justify-content-between">
                    <div>
                        <BackButton onClick={() => setRedirectTo('/dashboard')}/>
                        <h1 className="d-inline-block align-middle">Chase List</h1>
                    </div>
                    {showReRunChaseSamplesIfRequired() && <LIButton primary small label={reRunChaseListLabel} onClick={reRunSamples} disabled={!canRefreshChaseSamples}/>}
                    <ReloadButton/>
                </div>
                <div className="text-right my-1">
                    Samples Selected: {numChecked} / {(globalState.stats.numNeedsChasing<0?0:globalState.stats.numNeedsChasing)}</div>
                {canRefreshChaseSamples &&
                    <div className="">
                        {isLoading && <LoadingSpinner medium center/>}
                        {!isLoading && showResultsList(chaseListByLocation)}
                        {!isLoading && <BackNextWidget disableNext={chaseList < pageSize} show={haveResults()} pageSize={pageSize}
                                                       startIndex={startIndex} onClick={handleBackNext}/>}
                    </div>
                }
                {!canRefreshChaseSamples &&
                    <div className="text-center">
                        <LoadingSpinner medium center/>
                        <p className="li-font-medium mt-4">...Refreshing Chase List - please wait...</p>
                    </div>
                }
                <div className="mt-3 my-5">
                    <LIButton label={"Snooze Selected ("+numChecked+")"} showBusy={isSnoozing} disabled={numChecked===0}
                              onClick={handleSnoozeClicked}/>
                   
                </div>
                <p className="li-font-small ml-3">* this chase list shows most over-due samples first</p>
            </>
        );
    }
    
    return(
        <div className="container">
            <Header/>
            {showSnoozeForm && dumpSnoozeForm()}
            {!showSnoozeForm && dumpChaseList()}
            <Footer/>
        </div>
    );
}
     
export default ChaseListPage;