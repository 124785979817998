import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function pickSheetReducer(state = initialState.picksheet, action) {

    /**
     picksheet: {
        orders: [],
        samples: []
        }
     */
    
    switch (action.type) {
        case(types.GOT_SAMPLE_PICK_SHEET): {
            const orders = action.orders;
            const samples = action.samples;
  
            return {
                orders: orders,
                samples:samples
            };
        }
       
        default:
            return(state)
    }
}

