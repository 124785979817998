import React, {useContext, useEffect, useState} from "react";
import ArchiverClientApi from "../API/archiverClientApi";
import Header from "./Header";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import Footer from "./Footer";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import {StoreContext} from "../store";
import PrintLabelsForm from "./PrintLabelsForm";
import ReprintLabelsForm from "./ReprintLabelsForm";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const PrintLabelsPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [devices, setDevices] = useState([]);
    const [globalState] = useContext(StoreContext);

    useEffect(() => {
        console.debug("PrintLabelsPage starting up...");
        setIsLoading(true);
        ArchiverClientApi.getMagableDevices()
        .then((response) => {
            setDevices(response);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsLoading(false));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <div className="container">
            <Header/>
            <div className="li-header-row">
                <BackButton/>
                <h1 className="d-inline-block align-middle">Print Labels</h1>
            </div>

            {isLoading && <LoadingSpinner medium center/>}
            {!isLoading &&
            <>
                <PrintLabelsForm devices={devices} profiles={globalState.profiles.items}/>
                <div className="li-border-line my-4"/>
                <ReprintLabelsForm/>
            </>
            }
            <Footer/>
        </div>
    );
}

PrintLabelsPage.propTypes = {
    
}

export default PrintLabelsPage;