import ArchiverClientApi from "../API/archiverClientApi";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import React from "react";

class ArchiverGuiUtils {

    /**
     * get and dump out sample item information related to a sample that is marked as OUT.
     * This is used in several pages, so we share.
     */
    static GetSampleOutInfo(item) {
        ArchiverClientApi.getSampleLastRemovalDetails(item.id)
        .then(({lastPermRemoval, lastRoHistoryItem, lastStoredIn}) => {

            const pickedBy = lastRoHistoryItem?.pickedBy ?? "-";
            const sentToBrief = lastRoHistoryItem?.sentToBrief ?? "-";

            let permRemovalDetails = "";
            let roRemovalDetails = "";
            let lastStoredInDetails = "";
            
            if (lastPermRemoval) {
                permRemovalDetails =
                    `<p class="border-bottom">
                        <strong>Permanently Removed:</strong> 
                                ${LIUtils.shortDateTimeFromJsonUtcDateStr(lastPermRemoval?.date)} by ${lastPermRemoval.actor}
                    </p>`;
            }
            if(pickedBy !== "-" && sentToBrief !== "-") { // some useful retrieval info here
                roRemovalDetails = `<p class="mt-3"><strong>Picked By:</strong> ${pickedBy} on
                                    <u><i>${LIUtils.shortDateTimeFromJsonUtcDateStr(lastRoHistoryItem?.datePickCompleted)}</i></u></p>
                                    <p>
                                    <a href="/retrievals/details/${lastRoHistoryItem?.parentOrderId}">
                                        <strong>Order #:</strong> ${LIUtils.prettyOrderNumber(lastRoHistoryItem?.parentOrderId)}</a><br/>
                                    <strong>Destination: </strong> ${sentToBrief}<br/>
                                    <strong>Date Due Back:</strong> ${LIUtils.shortDateTimeFromJsonUtcDateStr(lastRoHistoryItem?.dateDueBack)}</p>`;
            }
            if(lastStoredIn) {
                const [magBC,magSection,magPosition] = lastStoredIn.split(":");
                const path=`<a href="/mag_details/${magBC}">Mag ${magBC}</a>, Section ${magSection}, Position: ${magPosition}`;
                lastStoredInDetails = `<p>Last stored in ${path}</p>`;
            }

            if (permRemovalDetails==="" && pickedBy === "-" && sentToBrief === "-") { // no useful info on any recent removal or retrieval
                Popup.show("Sample Removal Details", `<p>No recent permanent deletions or retrievals for this sample were found.</p>
                        <p>It is possible that this sample:<ul><li>has never been successfully added into a mag</li><li>or is currently in 'needs attention'</li>
                        <li>or it's mag has been 'Reloaded' and the sample is waiting for reload</li></p>`);
                return;
            }
            
            // show some useful info
            Popup.show("Sample Removal Details", `${permRemovalDetails} ${roRemovalDetails} ${lastStoredInDetails}`);
        })
        .catch( responseError => ErrorPopup.showError("ERROR", responseError.error));
    }

    static ShowSampleIsInOutOrPendingOrPicked(sampleItem, handlePendingOrderClicked:function=null, showFullDetails:boolean=false, thisOrderId:number=undefined) {
        if (sampleItem.isDeleted) {
            return (
                <span className="badge li-bg-muted ml-2 li-fg-white">DELETED</span>
            );
        }
        let dupBadge = null;
        if(sampleItem.isDuplicate) {
            dupBadge = <span className="badge li-bg-duplicate ml-2 li-fg-white">DUP</span>
        }
        if (!sampleItem.magBarcode || sampleItem.magBarcode === "") { // OUT
            let pickedBadge=null;
            if(thisOrderId && sampleItem.parentOrderId===Number(thisOrderId)) {
                if(sampleItem.datePickCompleted!=null) { // picked on this order
                    pickedBadge=<span className="badge li-bg-picked ml-2 li-fg-white">PICKED</span>;
                }
                else { // picked on some other order (or missing!)
                    pickedBadge=<span className="badge li-bg-danger ml-2 li-fg-white">DIFFERENT ORDER</span>;
                }
            }
           
            return (
                <>
                    <span className="badge badge-warning ml-2 btn-link li-pointer"
                          onClick={(e) => {e.stopPropagation(); ArchiverGuiUtils.GetSampleOutInfo(sampleItem);}}>* OUT</span>
                    {pickedBadge}
                    {dupBadge}
                </>
            );
        }

        // sample may be in
        if(sampleItem.isPendingRemovalOnOrder) {
            const pendingOnThisOrder = thisOrderId?sampleItem.isPendingRemovalOnOrder===thisOrderId:false;
            let fullDetails = "(this order)";
            let badgeColor = "badge-info";
            if(!pendingOnThisOrder) {
                fullDetails = "(Order #"+LIUtils.prettyOrderNumber(sampleItem.isPendingRemovalOnOrder)+")";
                badgeColor = "li-bg-warn-light";
            }
            return (
                <>
                    <span className={"badge ml-2 "+badgeColor+" "+(handlePendingOrderClicked?"btn-link li-pointer":"")}
                          onClick={() => handlePendingOrderClicked && handlePendingOrderClicked(sampleItem.isPendingRemovalOnOrder)}>
                        * PENDING {showFullDetails && fullDetails}
                    </span>
                    {dupBadge}
                </>
            );

            //
        }
        return (
            <>
                <span className="li-pointer-default badge badge-success ml-2 " title="is in a mag">IN</span>
                {dupBadge}
            </>
        );
    }

    static ShowSampleIsManuallyEdited(sampleItem) {
        
        if(sampleItem.manuallyAdded===true) {
            return(
                    <span className="badge badge-light li-bg-warn ml-2 px-3"
                          title="manually editted"><i className="fa fa-pen"/></span>
            );
        }
        
        return null;
    }

    /**
     * show and error popup unless the error is related to being unauthourized since the top level should handle that.
     * @param responseError
     * @param afterFunc
     * @constructor
     */
    static ShowErrorIfNotUnauthourized(responseError, afterFunc:function) {
        
        if (responseError?.statusCode === 401) {
            Popup.show("Unauthorized","<p><b>Your attempt to access the system has failed.</b><br/>This is either because:" +
                "<ul><li>your user code is incorrect</li><li>OR your session has expired</li></ul></p><p>Please dismiss this dialog to login again.</p>",
                () => {
                    window.location.href="/app/signout";
                });
            return;
        }
        let error = responseError?.error??"(none specified)";
        const lowerError = error.toLowerCase();
        if(lowerError.indexOf("forbidden")!==-1) { // not enough user level
            error="Forbidden";
        }
        else if (error.indexOf("trying to proxy") !== -1) {
            error = "<p>Failed to connect to Archiver WebService.</p><p>Please have your Archiver administrator ensure that the Archiver is accessible and that its WebService is running before trying again.</p>";
        }
        ErrorPopup.showError("ERROR", error, afterFunc);
    }
}

export default ArchiverGuiUtils;