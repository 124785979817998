import React from "react";
import PropTypes from "prop-types";
import {useHistory} from "react-router";

const BackButton = ({onClick, absolutePosition}) => {
    
    const history = useHistory();
    
    function handleGoBack() {
        if(onClick) {
            onClick();
        }
        else {
            history.goBack();
        }
    }
    
    return (
        <div className={"btn "+(absolutePosition?"position-absolute":null)} onClick={handleGoBack}>
            <i className="fa fa-3x fa-arrow-circle-left"/><div className="">back</div>
        </div>
    );
}

BackButton.propTypes = {
    onClick: PropTypes.func,
    absolutePosition: PropTypes.bool,
}

export default BackButton;