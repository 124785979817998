import React, {useContext, useEffect, useState} from "react"
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import {StoreContext} from "../store";
import Header from "./Header";
import Footer from "./Footer";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import ArchiverClientApi from "../API/archiverClientApi";
import {Redirect, useParams} from "react-router-dom";
import {clearAllSampleInfo, clearSampleHistory, clearWatchList, gotSampleBaseInfo, gotSampleHistory} from "../ACTIONS/clientActions";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import {useCookies} from "react-cookie";
import GuiUtils from "../GUI_COMMON/SUPPORT/GuiUtils";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import ReactDOMServer from "react-dom/server";

const SampleDetailsPage = () => {

    const [globalState,dispatch] = useContext(StoreContext);
    const [isSearchingBasic,setIsSearchingBasic] = useState(false);
    const [isSearchingHistory, setIsSearchingHistory] = useState(false);
    const [redirectTo, setRedirectTo] = useState();
    
    const {sampleId} = useParams();
    const [cookies,] = useCookies();
    
    useEffect(() => {
        // RECALL -- this runs AFTER all children mount!
        
        console.debug("ShowSampleDetailsPage starting up...");
        window.scrollTo(0,0);
        LIUtils.setBackgroundColor('--li-main-bg-color');

        if (!globalState.sample || globalState.sample.id !== Number(sampleId)) { // need to load
            dispatch(clearAllSampleInfo());
            getSampleBaseInfo(sampleId);
        }
        else { // just load the history
            getSampleHistory(sampleId); // get the history now that we have the basic info 
                                        // (this ensures history is included in case sample has beeen loaded elsewhere but no history was grabbed (ie. edittor)
            
        }
    }, [sampleId]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        if(LIUtils.existsAndNotEmpty(globalState?.sample?.barcode)) {
            document.title = `Sample Details - ${globalState.sample.barcode}`;
        }
        else {
            document.title = "Sample Details";
        }
    }, [globalState.sample.barcode]);
    
    function getSampleBaseInfo(sampleId) {
        
        setIsSearchingBasic(true);
        ArchiverClientApi.getSampleBaseInfoGivenId(sampleId)
        .then((sample) => {
            setIsSearchingBasic(false);
            dispatch(gotSampleBaseInfo(sample));
            getSampleHistory(sample.id); // get the history now that we have the basic info.
        })
        .catch( responseError => {
            setIsSearchingBasic(false);
            let error = responseError?.error??"";
            const lowerError = error.toLowerCase();
            if(lowerError.indexOf("not found")!==-1) { // may be ok.
                error=GuiUtils.GetSampleNotFoundMessage();
            }
            else if(lowerError.indexOf("forbidden")!==-1) { // may be ok.
                error="Forbidden";
            }
            ErrorPopup.showError("Error",error);
        });
    }

    function getSampleHistory(sampleId) {
        setIsSearchingHistory(true);
        dispatch(clearSampleHistory());
        ArchiverClientApi.getSampleHistory(sampleId)
        .then( (sampleHistory) => {
            dispatch(gotSampleHistory(sampleId,sampleHistory));
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsSearchingHistory(false));
    }

    function showImageDataIfPresent(imageData) {
        if(!imageData) {
            return <div className="border p-3">no image available</div>;
        }
        return( LIUtils.getImgFromImageData(globalState.sample.imageData,"li-sample-details-image","500px") );
    }

    function showManuallyAdded(manuallyAdded) {
        if(manuallyAdded) {
            return <div className="badge badge-warning li-font-medium align-self-center p-3">Manually<br/>Edited
                <span className="d-block li-font-tiny">&nbsp;</span> 
            </div>;
        }
        return <div className="badge li-bg-light li-font-medium align-self-center p-3">Device<br/>Added
            <span className="d-block li-font-tiny">&nbsp;</span> 
        </div>;
    }
    
    function showNeedFixerEditorLevels() {
        Popup.show("","<p>You are not a SAMPLE FIXER or EDITOR type user.</p>" +
            "Talk to your Archiver Administrator if you wish to change this.");
    }

    function showNeedsAttentionOrEdit(caseNum, sampleNum,profileId) {
        if(LIUtils.SampleHasCriticalInfo(caseNum, sampleNum, profileId)) { // all sample info is present (we need EDITOR level)
            if(LIUtils.UserHasLevel(cookies,"EDITOR")) { 
                // clickable EDIT button
                return <div 
                    className="badge badge-primary li-pointer li-font-medium p-3 mr-3"
                            onClick={() => setRedirectTo("/attention_edit/"+Number(globalState.sample?.id))}>
                    Edit<br/>Sample
                    <span className="d-block li-font-tiny">&nbsp;</span>
                </div>;
            }
            return null;
        }
        
        if(!LIUtils.UserHasLevel(cookies,"FIXER") && !LIUtils.UserHasLevel(cookies,"EDITOR")) { 
            // non-clickable NEEDS ATTENTION button
            return <div className="badge li-bg-danger li-pointer li-font-medium align-self-center p-3 mr-3"
                        onClick={showNeedFixerEditorLevels}>Needs<br/>Attention
                        <span className="d-block li-font-tiny">&nbsp;</span> 
                </div>;
        }
        
        // return a clickable FIXER button
        return(
            <div className="badge li-bg-danger li-pointer li-font-medium align-self-center p-3 mr-3" hidden={globalState.sample?.id === null}
                    onClick={() => setRedirectTo(`/attention_edit/${sampleId}`)}>Needs<br/>Attention
                <span className="d-block li-font-tiny">(click to fix)</span>
            </div>
        );
    }
    
    function sampleIsOut(item) {
        return !item.magBarcode || item.magBarcode === "";
    }
    
    function handleOnPendingOrderClicked(orderId) {
        setRedirectTo(`/retrievals/details/${orderId}`);
    }
    
    function showMagPositionClickableLink(item) {

        if (sampleIsOut(item) || !LIUtils.UserCanSeeSampleLocation(cookies)) { // pickers can see where things are
            return null;
        }
        
        const magLocationAsStr = LIUtils.buildMagLocationAsStr(item.magBarcode,item.magSection,item.positionOrder,item.positionAbsolute);
        
        let locationMarkup = <table className="table table-sm table-striped text-center border-left">
            <thead>
                <tr>
                    <th>Magazine</th>
                    <th>Section</th>
                    <th>Position</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{item.magBarcode ?? "-"}</td>
                    <td>{LIUtils.convertMagSectionIndexToAlpha(item.magSection)}</td>
                    <td>
                        {item.positionOrder === -1 ? "-" : item.positionAbsolute}</td>
                </tr>
            </tbody>
        </table>;
        
        return(
            <div className="card">
                <div className="card-body" style={{width:"15em"}}>
                    <h5>Sample Location</h5>
                    <div id="sampleLocationArea">
                        <span className="li-pointer btn-link" onClick={() => revealMagLocationAndNotify(locationMarkup, magLocationAsStr)}>
                            <i className="fa fa-hand-point-right mr-2"/>Mag Location
                        </span>
                    </div>
                </div>
            </div>
        );
    }
    
    function revealMagLocationAndNotify(location:Element, magLocationAsStr:string) {
        ArchiverClientApi.sendNotifySampleLocationViewed(sampleId,"Sample Details",magLocationAsStr); // fire and forget
        document.getElementById("sampleLocationArea").innerHTML = ReactDOMServer.renderToStaticMarkup(location);
    }

    function historyLinkClickHandler(note: Element) {
        if(!note) return;
        ArchiverClientApi.sendNotifySampleLocationViewed(sampleId,"Sample Details"); // fire and forget
        Popup.showInfo(ReactDOMServer.renderToString(note), true);
    }
    
    function dumpCaseNumberAsLink(caseNumber) {
        if(LIUtils.existsAndNotEmpty(caseNumber)) {
            return <span className="btn-link li-pointer" onClick={()=>setRedirectTo(`/case_details/${caseNumber}`)}>{caseNumber ?? "-"}</span>
        }
        else {
            return "-";
        }
    }

    function showBasicInfo() {
        if(isSearchingBasic) {
            return <LoadingSpinner medium center/>;
        }
        const sample = globalState.sample;
        return(
            <div className="row justify-content-between mt-3">
                <div className="mb-0 li-font-medium">
                    <div><span className="li-fg-muted">Barcode:</span> <strong>{sample.barcode??"-"}</strong></div>
                    <div><span className="li-fg-muted">Case Number:</span> <strong>{dumpCaseNumberAsLink(sample.caseNumber)}</strong></div>
                    <div><span className="li-fg-muted">Sample Number:</span> <strong>{sample.sampleNumber??"-"}</strong></div>
                    
                    <div className="li-font-small my-2">
                        <span className="li-fg-muted">Type:</span> <strong>{LIUtils.convertSampleTypeToStr(sample.sampleType)}</strong> 
                        <span className="ml-2 li-fg-muted">Profile:</span> <strong>{sample.profileId??"-"}</strong>
                    </div>
                    Status: {ArchiverGuiUtils.ShowSampleIsInOutOrPendingOrPicked(sample,handleOnPendingOrderClicked,true)}
                </div>
                <div>
                    {showMagPositionClickableLink(sample)}
                </div>
            </div>
        );
    }

    function showHistoryTable() {
        if(!globalState.sample) return;
        
        if (globalState.sample.id===-1 || globalState.sample.history.length === 0) {
            return <div className="card">{isSearchingHistory?<LoadingSpinner medium center/>:"No history found"}</div>;
        }

        const resultList = globalState.sample.history.map((item, index) =>
            <tr className="text-left" key={item.id}>
                <td>{LIUtils.shortDateTimeFromJsonUtcDateStr(item.date)}</td>
                <td width="250px">{item.actionVerb}</td>
                <td>{item.actor}</td>
                <td className="text-wrap">
                    {LIUtils.translateHistoryNote(item.note, LIUtils.UserCanSeeSampleLocation(cookies), historyLinkClickHandler)}
                </td>
            </tr>
        );

        return (
            <table className="table table-striped">
                <thead>
                <tr className="text-left"><th width="160px">Date</th><th className="50px text-wrap">Action</th><th width="200px">Who</th><th>Note</th></tr>
                </thead>
                <tbody>
                {resultList}
                </tbody>
            </table>
        );
    }
    
    function showButtonRow() {
        
        return (
        <div className="row m-0 p-0 my-3 justify-content-between">
            {showNotifyWhenAvailableButton()}
            <div>
                {!isSearchingBasic && showNeedsAttentionOrEdit(globalState.sample.caseNumber,globalState.sample.sampleNumber, globalState.sample.profileId)}
                {!isSearchingBasic && showManuallyAdded(globalState.sample.manuallyAdded)}
            </div>
        </div>
        );
    }
    
    function showNotifyWhenAvailableButton() {
        let disabled = true;
        if (sampleIsOut(globalState.sample)) {
            disabled = false;
        }
        return (
            <button className="li-button li-primary li-font-medium mr-2" disabled={disabled}
                    onClick={handleNotifyWhenAvailable}>
                Notify When Available
            </button>
        );
    }
    
    if(redirectTo) {
        return (
            <Redirect push to={redirectTo}/>
        );
    }

    function handleNotifyWhenAvailable() {
        
        if(globalState.sample?.id === null) return;
        
        const idsOnly = [Number(globalState.sample.id)];
        ArchiverClientApi.sendAddSamplesToWatchList(idsOnly)
        .then( (totalAdded) => {
            dispatch(clearWatchList());  // ensure it's loaded again when user wants to see it.
            Popup.show("",`Added ${totalAdded} samples to your watch list`);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError));
    }
 
    return (
        <div className="container">
            <Header/>
            <div className="row mt-3 li-header-row justify-content-between">
                <div>
                    <BackButton/>
                    <span className="li-font-huge">Sample: </span>
                    <span className="li-font-large li-fg-primary">
                        {GuiUtils.BuildCaseSampleDetailsStr(globalState.sample.caseNumber,globalState.sample.sampleNumber)}
                    </span>
                </div>
                <div className="li-font-small text-right my-auto ml-auto mr-3">[id: {globalState.sample.id}]</div>
            </div>
            
            {showBasicInfo()}
            {showButtonRow()}
            
            <div className="d-flex justify-content-center my-4">
                {showImageDataIfPresent(globalState.sample.imageData)}
            </div>
            <div className="mb-5">
                <div className="li-font-huge">History</div>
                <div className="li-search-results-table">
                    {showHistoryTable()}
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default SampleDetailsPage;