import React, {useContext, useEffect, useRef, useState} from "react";
import Header from "./Header";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import {Redirect, useLocation} from "react-router-dom";
import {StoreContext} from "../store";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import {gotCompletedRetrievalOrders, gotUnpickedRetrievalOrders, gotUnsentRetrievalOrders, markedROAsSent} from "../ACTIONS/clientActions";
import RetrievalOrderCardRow from "./RetrievalOrderCardRow";
import RetrievalOrderUnsentCardRow from "./RetrievalOrderUnsentCardRow";
import ArchiverClientApi from "../API/archiverClientApi";
import Footer from "./Footer";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import BackNextWidget from "../GUI_COMMON/COMPONENTS/BackNextWidget";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import {useCookies} from "react-cookie";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import SampleSearchInput from "./SampleSearchInput";
import {useHistory} from "react-router";

const RetrievalsPage = () => {

    const RO_PAGE_SIZE = 25;

    const [globalState, dispatch] = useContext(StoreContext);
    const [isLoadingUnpicked, setIsLoadingUnpicked] = useState(false);
    const [isLoadingCompleted, setIsLoadingCompleted] = useState(false);
    const [isLoadingUnsent, setIsLoadingUnsent] = useState(false);
    const [isMarkingSentId, setIsMarkingSentId] = useState(null);
    const [, setIsDeleting] = useState(null);       // ### should be signalling this -pott
    const [unpickedList, setUnpickedList] = useState([]);
    const [unsentList, setUnsentList] = useState([]);
    const [completedList, setCompletedList] = useState([]);
    const [redirectTo, setRedirectTo] = useState();

    const [startIndex, setStartIndex]=useState(0);

    const location = useLocation();
    const history = useHistory();

    const [cookies,] = useCookies();
    const unpickedRef = useRef();
    const unsentRef = useRef();
    const completedRef = useRef();
    
    const [activePageRef, setActivePageRef] = useState();
    const [forceRefresh, setForceRefresh] = useState(false);


    useEffect(() => {
        hideAllTabPages();
        const pageAnchor = location.hash.replace("#","");
        if(pageAnchor==="unpicked") {
            showTabPage(unpickedRef);
        } else if(pageAnchor==="unsent") {
            showTabPage(unsentRef);
        } else if(pageAnchor==="completed") {
            showTabPage(completedRef);
        }
        else {
            showTabPage(unpickedRef);
        }

        setForceRefresh(true);
        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( ()=> {
        if(globalState.retrievals.unpicked) {
            const listCopy=[...globalState.retrievals.unpicked];
            listCopy.forEach(item => item.isChecked=false);  // augment
            setUnpickedList(listCopy);
            resetSelectAllCB();
        }
    },[globalState.retrievals.unpicked]); 
    useEffect( ()=> {
        if(globalState.retrievals.completed) {
            setCompletedList(globalState.retrievals.completed);
        }
    },[globalState.retrievals.completed]);
    useEffect( ()=> {
        if(globalState.retrievals.unsent) {
            setUnsentList(globalState.retrievals.unsent);
        }
    },[globalState.retrievals.unsent]);
    
    useEffect( () => {
        if(activePageRef === unpickedRef) {
            //if(!globalState.retrievals.unpicked || forceRefresh) {
                loadUnpickedROs(forceRefresh);
                setForceRefresh(false);
            //}
        }
        else if(activePageRef === unsentRef) {
            //if(!globalState.retrievals.unsent || forceRefresh) {
                loadUnsentROs();
                setForceRefresh(false);
           // }
        }
        else if(activePageRef === completedRef) {
            //if(!globalState.retrievals.completed || forceRefresh) {
                loadCompletedROsAtIndex(0,RO_PAGE_SIZE);
                setForceRefresh(false);
            //}
        }
        
    },[activePageRef, forceRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

    //#region LOADERS
    
    function loadUnpickedROs(loadUnsentAfter=true) {
        setIsLoadingUnpicked(true);
        ArchiverClientApi.getRetrievalOrders("unpicked", 0, 1000)
        .then((orders) => {
            dispatch(gotUnpickedRetrievalOrders(orders));
            if(loadUnsentAfter===true) {
                loadUnsentROs();  // now load unsent
            }
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsLoadingUnpicked(false));
    }

    function loadUnsentROs() {
        setIsLoadingUnsent(true);
        ArchiverClientApi.getRetrievalOrders("unsent", 0, 1000)
        .then((orders) => {
            dispatch(gotUnsentRetrievalOrders(orders));
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsLoadingUnsent(false));
    }

    function loadCompletedROsAtIndex(startIndex=0, numToReturn=RO_PAGE_SIZE) {

        setStartIndex(startIndex);
        setIsLoadingCompleted(true);
        ArchiverClientApi.getRetrievalOrders("completed", startIndex, numToReturn)
        .then((orders) => {
            dispatch(gotCompletedRetrievalOrders(orders));
        })
        .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsLoadingCompleted(false));
    }
    
    //#endregion LOADERS
    
    function handleOrderCheckChanged(orderItem,isChecked) {
        let curList = [...unpickedList];
        let theOrder = curList.find( item => item.id===orderItem.id);
        theOrder.isChecked=isChecked;
        setUnpickedList(curList);
    }
    function handleGotoOrder(orderId) {
        setRedirectTo(`/retrievals/details/${orderId}`);
    }
    
    function resetSelectAllCB() {
        const cb= document.getElementById("unpicked-select-all-cb");
        if(cb) {
            cb.checked=false;
        }
    }
    function handleSelectAllCBChanged() {
        const isChecked = document.getElementById("unpicked-select-all-cb").checked;
        
        let curList = [...unpickedList];
        curList.forEach(item => item.isChecked=isChecked);
        setUnpickedList(curList);
    }
    
    function handleDeleteClicked(item) {
        const orderNum = LIUtils.prettyOrderNumber(item.id);
        Popup.showConfirm("",`Really delete order '${orderNum}'?`,"Yes, Delete!",() =>{}, 
            ()=> { // do it
                setIsDeleting(true);
                ArchiverClientApi.sendDeleteRORequest(item.id)
                .then( ({numPickedRemaining}) => {
                    if(numPickedRemaining>0) { // couldn't delete full RO
                        showRONotDeleted(numPickedRemaining);
                    }
                    else { // reload ### for now
                        loadUnpickedROs(false);
                    }
                })       // don't refresh the unsent
                .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
                .finally( () => {
                    setIsDeleting(false);
                });
        });
    }
    
    function showRONotDeleted(numPickedRemaining) {
        ErrorPopup.showError("Order Not Deleted",`<p>There were ${numPickedRemaining} samples <strong>already picked</strong> on this order.</p>`+
                            "<p>You will need revisit the order, <strong>mark this order complete</strong> and return "+
                            "the already picked samples to the system as normal.</p>"+
                            "<p>* Any unpicked samples have been removed from this order for you.</p>",
                            null);
    }
    
    function showUnpickedROsList(pList) {
        if (!pList || pList.length === 0) {
            return <>
                        <div className="card text-center">none</div>
                    </>
        }
        
        const resultRows = pList.map((item, index) => {
                return (
                    <RetrievalOrderCardRow  key={item.id} orderNumber={item.id}
                                            dateStarted={item.dateStarted}
                                            createdBy={item.creator}
                                            requestedBy={item.requester}
                                            destinedForContact={item.destinationContact}
                                            destinedForLocation={item.destinationLocation}
                                            notes={item.notes}
                                            showCheckBox isChecked={item.isChecked}
                                            onClick={() => handleGotoOrder(item.id)}
                                            onCheckChanged={(isChecked) => handleOrderCheckChanged(item, isChecked)}
                                            allowDelete onDeleteClicked={() => handleDeleteClicked(item)}/>
                );
            }
        );
        
        return (
            <>
                <table id="unpickedTable" className="table table-striped li-bg-white">
                    <thead>
                    <tr><th>
                        <input type="checkbox" className="m-2 mr-3 border-bottom" id="unpicked-select-all-cb" aria-label="Checkbox" onChange={handleSelectAllCBChanged}/>
                    </th><th>Order#</th><th>Date Started</th><th>Requested By</th><th>Created By</th><th>Destined For</th><th>Notes</th></tr>
                    </thead>
                    <tbody>
                    {resultRows}
                    </tbody>
                </table>
            </>
        );
    }
    
    function showUnSentROsList(pList) {
        
        if (!pList || pList.length === 0) {
            return <> <div className="card text-center">none</div></>;
        }
        
        const resultRows = pList.map((item, index) => {
                return (
                    <RetrievalOrderUnsentCardRow key={item.id} orderNumber={item.id}
                                                   dateStarted={item.datePickCompleted}
                                                   requestedBy={item.requester}
                                                   destinedForContact={item.destinationContact}
                                                   destinedForLocation={item.destinationLocation}
                                                   onOrderClick={() => handleGotoOrder(item.id)}
                                                   onMarkCompleteClick={() => handleMarkSent(item.id)} 
                                                   isBusyMarking={isMarkingSentId===item.id}
                                                   onShowSamplesClick={() => handleShowSamples(item.id)}
                                           />
                );
            }
        );
        
        return (
            <>
                <table id="unsentTable" className="table table-striped">
                    <thead>
                    <tr className="li-bg-pale-warn"><th>Order#</th><th>Date Picked</th><th>Requested By</th><th>Destined For</th><th/></tr>
                    </thead>
                    <tbody>
                    {resultRows}
                    </tbody>
                </table>
            </>
        );
    }
    function showCompletedROsList(pList) {
        if (!pList || pList.length === 0) {
            return <div className="card text-center">none</div>;
        }

        const resultRows = pList.map((item, index) => {
                return (
                    <RetrievalOrderCardRow key={item.id} orderNumber={item.id}
                                           dateStarted={item.dateSent}  // dateSent here!
                                           createdBy={item.creator}
                                           requestedBy={item.requester}
                                           destinedForContact={item.destinationContact}
                                           destinedForLocation={item.destinationLocation}
                                           notes={item.notes}
                                           onClick={() => handleGotoOrder(item.id)}
                    />
                );
            }
        );

        return (
            <table id="completedTable" className="table table-striped">
                <thead>
                <tr className="li-bg-pale-ok"><th>Order#</th><th>Date Completed</th><th>Requested By</th><th>Created By</th><th>Destined For</th><th>Notes</th></tr>
                </thead>
                <tbody>
                {resultRows}
                </tbody>
            </table>

        );
    }
    
    function handleMarkSent(orderId) {
        setIsMarkingSentId(orderId);
        ArchiverClientApi.markRetrievalAsSent(orderId)
        .then((order) => {
            dispatch(markedROAsSent(order));
            // Popup.show("",`Retrieval Order ${LIUtils.prettyOrderNumber(orderId)} has been marked as sent`, 
            //     () => {});
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsMarkingSentId(null));
    }
    
    function handleShowSamples(orderId) {
        setRedirectTo(`/retrievals/samples/${orderId}`);
    }
    
    function handleCompletedROsBackNext(startIndex,numToReturn) {
        loadCompletedROsAtIndex(startIndex,numToReturn);
    }
    
    function aUnpickedIsSelected():boolean {
        return !!unpickedList.find(item => item.isChecked);
    }

    function showGeneratePickSheet() {
        let isDisabled = true;
        if (aUnpickedIsSelected()) {
            isDisabled = false;
        }
        return (
            <button className="li-button li-primary mb-2 mt-0" disabled={isDisabled} onClick={handleGeneratePickSheet}>Generate Pick Sheet</button>
        );
    }
    
    function handleGeneratePickSheet() {

        if(LIUtils.UserHasLevel(cookies,"PICKER")) {
            const pickSheetItems = unpickedList?.filter(item => item.isChecked)??[];
            let pickList = "";
            pickSheetItems.forEach(item => {
                console.debug(`picking order: ${item.id}`);
                pickList+=item.id+",";
            });
            setRedirectTo("/pick_sheet?"+pickList);
        }
        else {
            Popup.show("", "<p>You are not a SAMPLE PICKER type user.</p>" +
                "Talk to your Archiver Administrator if you wish to change this.");
        }
    }
    
    function showAddOrderButton() {
        if(!LIUtils.UserHasLevel(cookies,"RETRIEVER")) {
            return null;
        }
        return(
            <button className="li-button li-bg-success li-fg-white align-self-center mr-4" onClick={()=>setRedirectTo("/retrievals/new")}>
                <i className="fa fa-plus mr-2 font-weight-bold"/>Order
            </button>
        );
    }

    function hideAllTabPages() {
        hideTabPage(unpickedRef);
        hideTabPage(unsentRef);
        hideTabPage(completedRef);
    }
    function hideTabPage(pageRef) {
        if(pageRef && pageRef.current) {
            pageRef.current.classList.remove("show");
            pageRef.current.classList.remove("active");
            pageRef.current.classList.add("hide");
            pageRef.current.style.display = "none";
        }
    }
    function showTabPage(pageRef) {

        // remove active page info
        hideTabPage(activePageRef);

        if(pageRef && pageRef.current) {
            setActivePageRef(pageRef)
            // show the page we want
            pageRef.current.classList.remove("hide");
            pageRef.current.classList.add("show");
            pageRef.current.classList.add("active");
            pageRef.current.style.display="block";
            const tabId = pageRef.current.id;
            history.replace(`/retrievals#${tabId}`);
        }
    }
    
    function handleRefreshTab() {
        setForceRefresh(true);
    }
    
    if(redirectTo) {
        return (
            <Redirect push to={redirectTo}/>
        );
    }

    function showUnpickedTabHeader() {
        if(isLoadingUnpicked) {
            return (
                <div className="d-inline-block">Unpicked 
                    <span className="d-inline-block ml-2">
                        <i className="d-block fa fa-spinner fa-pulse fa-fw"/>
                    </span>
                </div>);
        }
        return <>Unpicked ({unpickedList?.length??"-"})</>;
    }

    function showUnsentTabHeader() {
        if(isLoadingUnsent) {
            return (
                <div className="d-inline-block">Unsent
                    <span className="d-inline-block ml-2">
                        <i className="d-block fa fa-spinner fa-pulse fa-fw"/>
                    </span>
                </div>);
        }
        return <>Unsent ({unsentList?.length??"-"})</>;
    }
    
    function showCompletedTabHeader() {
        if(isLoadingCompleted) {
            return (
                <div className="d-inline-block">Completed
                    <span className="d-inline-block ml-2">
                        <i className="d-block fa fa-spinner fa-pulse fa-fw"/>
                    </span>
                </div>);
        }
        return <>Completed</>;
    }

    return(
        <div className="container">
            <Header/>
            <div className="row li-header-row m-0 p-0 justify-content-between">
                <div>
                    <BackButton onClick={() => setRedirectTo('/dashboard')}/>
                    <h1 className="d-inline-block align-middle">Retrievals</h1>
                </div>
                {showAddOrderButton()}
            </div>
            <SampleSearchInput onSearchClicked={handleGotoOrder} name="findOrderInput" placeholder="order #" right small/>

            {/* TABS */}
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item mr-2" role="presentation">
                    <button className={"nav-link li-font-medium " + (activePageRef===unpickedRef?"active":"")} id="unpicked-tab" onClick={() => showTabPage(unpickedRef)}>
                        <i className={"fa fa-clipboard-check mr-2 "+(activePageRef===unpickedRef?"":"li-fg-primary")}/>{showUnpickedTabHeader()}</button>
                </li>
                <li className="nav-item mr-2 li-font-medium " role="presentation">
                    <button className={"nav-link " + (activePageRef===unsentRef?"active":"")} id="unsent-tab" onClick={() => showTabPage(unsentRef)}>
                        <i className={"fa fa-truck-moving mr-2 "+(activePageRef===unsentRef?"":"li-fg-primary")}/>{showUnsentTabHeader()}</button>
                </li>
                <li className="nav-item mr-2 li-font-medium " role="presentation">
                    <button className={"nav-link " + (activePageRef===completedRef?"active":"")} id="completed-tab" onClick={() => showTabPage(completedRef)}>
                        <i className={"fa fa-check mr-2 "+(activePageRef===completedRef?"":"li-fg-primary")}/>{showCompletedTabHeader()}</button>
                </li>
            </ul>

            {/* TAB CONTENT */}
            <div id="unpicked" className="tab-pane fade" ref={unpickedRef}>
                <div className="text-right">
                    <div className="d-inline-block mt-4 mr-3">{showGeneratePickSheet()}</div>
                    <button className="btn " onClick={handleRefreshTab} data-toggle="tooltip" title="refresh"><i className="fa fa-redo"/></button>
                </div>
                <div className="mt-3 mb-2">
                    {showUnpickedROsList(unpickedList)}
                </div>
            </div>

            <div id="unsent" className="tab-pane fade" ref={unsentRef}>
                <div className="text-right">
                    <button className="btn " onClick={handleRefreshTab} data-toggle="tooltip" title="refresh"><i className="fa fa-redo"/></button>
                </div>
                <div className="mt-3 mb-2">
                    {showUnSentROsList(unsentList)}
                </div>
            </div>

            <div id="completed" className="tab-pane fade" ref={completedRef}>
                <div className="text-right">
                    <button className="btn " onClick={handleRefreshTab} data-toggle="tooltip" title="refresh"><i className="fa fa-redo"/></button>
                </div>

                <div className="mt-3 mb-2">
                {showCompletedROsList(completedList)}
                <BackNextWidget disableNext={completedList.length < RO_PAGE_SIZE} pageSize={RO_PAGE_SIZE} 
                                    startIndex={startIndex} onClick={handleCompletedROsBackNext}/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default RetrievalsPage;