import React from 'react';
import PropTypes from 'prop-types';
import HelpLink from "./HelpLink";

const TextArea = ({name, label, onChange, placeholder, value, helpText, rows, 
                      required, error, readonly, onClick, extraClass, spellCheck, maxLength, helpLink}) => {
    let wrapperClass = 'form-group';
    if(error && error.length>0) {
        wrapperClass += ' has-error';
    }

    let inputClass = 'li-form-field no-height border-dark';
    if(extraClass) {
        inputClass += ' '+extraClass;
    }
    
    return(
        <div className={wrapperClass}>
            {label && label!==''?<label className="li-form-label" htmlFor={name}>{label}</label>:null}
            {helpLink && helpLink!==''?<HelpLink link={helpLink} small/>:null}

            <div className="field">
                
                
                <textarea
                    name={name}
                    className={inputClass}
                    placeholder={placeholder}
                    rows={rows ? rows : 3}
                    value={value}
                    onChange={onChange}
                    onClick={onClick}
                    disabled={readonly}
                    required={required && 'required'}
                    spellCheck={spellCheck}
                    maxLength={maxLength}
                />
                
                {error && <div className="alert alert-danger">{error}</div>}
                {!error && <small className="form-text text-muted">{helpText}</small>}
            </div>
        </div>
    );
};

TextArea.defaultProps = {
    readonly: false,
    spellCheck: true,
}

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    value: PropTypes.string,
    helpText: PropTypes.string,
    helpLink: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.string,
    readonly: PropTypes.bool,
    extraClass: PropTypes.string,
    spellCheck: PropTypes.bool,
    maxLength: PropTypes.number
};

export default TextArea;