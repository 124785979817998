import React, {useEffect, useState} from 'react';
import {Prompt, Redirect, useHistory} from "react-router-dom";
import Header from "../Header";
import BackButton from "../../GUI_COMMON/COMPONENTS/BackButton";
import TextInput from "../../GUI_COMMON/COMPONENTS/TextInput";
import LIButton from "../../GUI_COMMON/COMPONENTS/LIButton";
import Popup from "../../GUI_COMMON/COMPONENTS/Popup";
import ArchiverClientApi from "../../API/archiverClientApi";
import ErrorPopup from "../../GUI_COMMON/COMPONENTS/ErrorPopup";
import CheckInput from "../CheckInput";
import DigitInput from "../../GUI_COMMON/COMPONENTS/DigitInput";
import LIUtils from "../../GUI_COMMON/SUPPORT/LIUtils";
import LocationSelector from "../LocationSelector";
import ArchiverGuiUtils from "../../SUPPORT/ArchiverGuiUtils";

const UserAdminPage = () => {

    const [curUserDetails, setCurUserDetails] = useState(getDefaultUserDetails());
    const [fullUserList, setFullUserList] = useState([]);

    const [redirectToPage, setRedirectToPage] = useState();
    
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [locationIsValid, setLocationIsValid] = useState(true);
    const [locationIsRequired, setLocationIsRequired] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isLoadingAuthCode, setIsLoadingAuthCode] = useState(false);

    const history = useHistory();

    useEffect(() => {
        console.debug("refreshing user list");
        doGetUserList();
    }, []);
    
    function getDefaultUserDetails():{} {
        return(
            {
                id: null,
                fullName: "",
                email: "",
                phone: "",
                enabled: true,
                accessCode: null,
                userLevels: "",
                notifyFlags: "",
                locationId: -1,
                departmentId: -1,
            }
        );
    }
    
    function doGetUserList() {
        setIsLoading(true);
        ArchiverClientApi.searchContacts("") 
        .then( (contacts) => {
            setFullUserList(contacts);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsLoading(false);
        });
    }
    
    function handleCancelClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        confirmClearDirty();
    }
    
    function confirmClearDirty() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetUserDetailsShown();
                });
        }
        else {
            resetUserDetailsShown(); // just clear what's there
        }
    }
    
    function handleDeleteUserClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        Popup.showConfirm("Delete User?",`<p>This will remove '${curUserDetails.fullName}' completely from the system</p>`+
                                "<p>Are you sure you want to do this?</p>","Yes, Delete them",null, 
            () => {
               doUserRemove(curUserDetails.fullName,curUserDetails.id);
            });
    }
   
    function doUserRemove(userName, userId) {
        setIsRemoving(true);
        ArchiverClientApi.sendContactRemove(userId)
        .then(() => {
            Popup.showInfo(`User '${userName}' removed`);
            removeFromFullUserList(userId);
            resetUserDetailsShown();
        })
        .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally(() => {
            setIsRemoving(false);
        });
    }
    
    function handleSaveClicked(e,action) {
        e.preventDefault();
        e.stopPropagation();
        
        if(!checkForValid()) {
            return;
        }
       
        setIsSaving(true);
        ArchiverClientApi.sendContactsUpdate(curUserDetails)
        .then( (savedUserInfo) => {
            Popup.showInfo(`User '${curUserDetails.fullName}' Saved`);
            if(action==="ADD") {
                addToFullUserList(savedUserInfo);
                resetUserDetailsShown();
            }
            else if(action==="UPDATE") {
                updateExistingUser(savedUserInfo);
                setIsDirty(false);
            }
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
           setIsSaving(false); 
        });
        
        
    }
    
    function checkForValid():boolean {
        let isValid=true;
        let notValidMsg="";
        if(!document.getElementsByName("fullName")[0].checkValidity()) {
            isValid=false;
            notValidMsg="Name needs to be non-blank";
        }
        if(curUserDetails.email && curUserDetails.email!=="") {
            const re=new RegExp('^.+@.+[.].+$');
            if(!re.test(curUserDetails.email)) { // no match
                isValid=false
                notValidMsg="Email needs to contain at least a '@' and contain at least one '.'";
            }
        }
        if(curUserDetails.locationId!==-1 && curUserDetails.departmentId===-1) {
            isValid=false;
            notValidMsg="A department needs to be selected";
        }
        
        if(!isValid) {
            ErrorPopup.showError("Validation Problem",notValidMsg);
            return false;
        }
        return true;

    }
    
    function addToFullUserList(userDetails) {
        const curList = [...fullUserList];
        curList.push(userDetails);
        curList.sort( (a,b) => {
            if(a.fullName < b.fullName) return -1;
            if(a.fullName > b.fullName) return 1;
            return 0;
        });
        setFullUserList(curList);
    }

    function removeFromFullUserList(userId) {
        const curList = fullUserList.filter( item => item.id !== userId);
        setFullUserList(curList);
    }
    
    function updateExistingUser(userDetails) {
        const curList = [...fullUserList];
        let thisUserIndex = curList.findIndex(item => item.id===userDetails.id);
        curList[thisUserIndex]=userDetails;
        setFullUserList(curList);
    }
    
    function onChange(e) {
        const thing = e.target.name;
        const editInfo = {...curUserDetails};
        if(e.target.type==="checkbox") { // this is a checkbox
            editInfo[thing] = e.target.checked;
        }
        else {
            const value = e.target.value;
            
            // ### NOTE: we only allow 1 location and department per user here using the frontend.
            //           the webservice can handle many. -pott 220324
            
            // the location selector has it's own names for fields - we remap
            if(thing==="destLocation") { // value here is the id selected
                if(Number(value)===editInfo.locationId) { // no change
                    return;
                }
                editInfo.locationId=Number(value);
                editInfo.departmentId=-1;   // reset
                setLocationIsRequired(Number(value)!==-1); // now need to ensure department is selected too if applicable.
            }
            else if(thing==="destDepartment") { // value here is the id selected
                if(Number(value)===editInfo.departmentId) { // no change
                    return;
                }
                editInfo.departmentId=Number(value);
            }
            else {
                editInfo[thing] = value;
            }
        }
        setCurUserDetails(editInfo);
        setIsDirty(true);
    }
    
    function showUserList(listContents) {

        if (isLoading) {
            return <div className="pr-4 w-30 li-border-right-light">...loading...</div>;
        }

        let resultList = listContents.map((item, index) => {
            const isDisabledFont = (item.enabled ? "" : "li-fg-muted");
            return <li key={index} className={"list-group-item li-pointer " + isDisabledFont}
                       onClick={() => handleListItemClicked(item)}>{item.fullName}</li>;
        });
        if(!resultList || resultList.length===0) {
            resultList = "no users found";
        }

        return (
            <div className="pr-4 w-30 li-border-right-light">
                <h4>Users</h4>
                <div className="li-scrollable-at-500 ">
                    <ul className="list-group-flush li-list-group-w-hover pl-0">
                        {resultList}
                    </ul>
                    <div className="li-border-line-light"/>
                </div>
                <LIButton small primary label="" bgClass={isRemoving ? "m-3" : "fa fa-trash m-3"} disabled={curUserDetails?.id === null}
                          onClick={handleDeleteUserClicked} showBusy={isRemoving}/>
            </div>
        );
    }
    
    function handleListItemClicked(item) {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetUserDetailsShown();
                    doRealListItemClicked(item);
                });
        }
        else {
            doRealListItemClicked(item);
        }
    }
    
    function doRealListItemClicked(item) {
        const user = fullUserList.filter(i => i===item);
        if(user.length===0) {
            resetUserDetailsShown();
        }
        else {
            setCurUserDetails(user[0]);
        }
    }
    
    function resetUserDetailsShown() {
        setCurUserDetails(getDefaultUserDetails());
        setIsDirty(false);
    }
    
    function handleGenerateNewAccessCode() {
        setIsLoadingAuthCode(true);
        ArchiverClientApi.generateNewAuthCode()
        .then( (newCode) => {
            const editInfo = {...curUserDetails};
            editInfo.accessCode=newCode;
            setCurUserDetails(editInfo);
            setIsDirty(true);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
           setIsLoadingAuthCode(false); 
        });
    }
    
    //#region USERLEVEL_AND_NOTIFYFLAGS
    
    function handleUserLevelCheckChanged(e) {
        e.stopPropagation();
        const editInfo = {...curUserDetails};
        if(e.target.checked) {
            editInfo["userLevels"]=_addToStringList(e.target.value,editInfo["userLevels"]);
        }
        else {
            editInfo["userLevels"]=_removeFromStringList(e.target.value, editInfo["userLevels"]);
        }
        setCurUserDetails(editInfo);
        setIsDirty(true);
    }
    function handleNotifyFlagCheckChanged(e) {
        e.stopPropagation();
        const editInfo = {...curUserDetails};
        if(e.target.checked) {
            editInfo["notifyFlags"]=_addToStringList(e.target.value,editInfo["notifyFlags"]);
        }
        else {
            editInfo["notifyFlags"]=_removeFromStringList(e.target.value, editInfo["notifyFlags"]);
        }
        setCurUserDetails(editInfo);
        setIsDirty(true);
    }
    function _addToStringList(thisString:string, stringList:string) {
        if(LIUtils.UserHasLevelGivenList(stringList,thisString)) {
            return stringList; // already there
        }
        if(stringList==="") {
            return thisString;  // only thing in the list
        }
        return stringList+","+thisString;
    }
    function _removeFromStringList(thisString:string, stringList:string) {
        if(!stringList || stringList==="") {
            return stringList; // already gone
        }
        
        if(!LIUtils.UserHasLevelGivenList(stringList,thisString)) {
            return stringList; // already gone
        }
        
        let splitBy=",";
        if(stringList.indexOf(", ")!==-1) {
            splitBy=", ";  // strange " " appears between these cookies values at times... 211115 -pott
        }
        const values = stringList.split(splitBy);
        let newList=[];
        values.forEach( v => {
            if(v!==thisString) {  // add this one back in
                newList.push(v);
            }
        });
       return newList.join(',');
    }
    
    //#endregion USERLEVEL_AND_NOTIFYFLAGS

    function handleBackClicked() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    setRedirectToPage("/adminPage");
                });
        }
        else {
            history.goBack();
        }

    }
    
    function onLocationIsValidChanged(isValid) {
        console.log(`*location* isValid=${isValid}`);
        setLocationIsValid(isValid);
    }

    if (redirectToPage) {
        return <Redirect to={redirectToPage}/>;
    }
    
    return (
        <div className="container">
            <Prompt
                when={isDirty}
                message={ () => "You have unsaved changes.  Really leave?"}
            />
            <Header hideAdmin/>
            <div className="li-header-row">
                <BackButton onClick={handleBackClicked}/>
                <h1 className="ml-4 d-inline-block align-middle">User Administration</h1>
            </div>
            
            <div className="row">
                {/* LEFT SIDE USER LIST */}
                {showUserList(fullUserList)}

                {/* RIGHT SIDE FORM */}
                <div className="pl-4 w-60">
                    <div className="li-fg-slightly-muted li-font-small text-right">[Id:{curUserDetails?.id??"-"}]</div>
                    <div className="row p-0 m-0 justify-content-between">
                        {/* right side, row #1 */}
                        <TextInput name="fullName" label="Name" onChange={(e)=>onChange(e)}
                                   placeholder="John Smith" textLeft
                                   value={curUserDetails?.fullName??""}
                                   required/>
                        <CheckInput label="Enabled" name="enabled" checked={curUserDetails.enabled} onChanged={(e)=>onChange(e)}/>
                    </div>
                    { /* right side, row #2 */}
                    <TextInput name="email" label="Email" onChange={(e)=>onChange(e)}
                               placeholder="" textLeft
                               value={curUserDetails?.email??""}
                               />
                    { /* right side, row #3 */}
                    <div className="row p-0 m-0 justify-content-between">
                        <TextInput name="phone" label="Phone" onChange={(e)=>onChange(e)}
                                   placeholder="" textLeft
                                   value={curUserDetails?.phone??""}
                        />
                        <div className="row p-0 m-0">
                            <DigitInput name="accessCode" label="Access Code" onChange={(e)=>onChange(e)}
                                       placeholder="" maxLength={6} minLength={6} widthPels={250}
                                       value={curUserDetails?.accessCode??""}
                                        helpText="* leave empty to disallow login, but keep this contact as a sample receiver"/>
                            <span className="mt-5 ml-4">
                                <LIButton label="Generate New" small bgClass="li-bg-success li-fg-white"
                                            onClick={handleGenerateNewAccessCode} showBusy={isLoadingAuthCode}/>
                            </span>
                        </div>
                    </div>
                    { /* right side, row #4 */}
                    <label className="li-form-label">User Levels</label>
                    <div className="row ml-4">
                        <div className="col text-right">
                            <CheckInput label="Admin" name="ul-admin" small value="ADMIN"
                                        checked={LIUtils.UserHasLevelGivenList(curUserDetails?.userLevels,"ADMIN")}
                                        onChanged={handleUserLevelCheckChanged}/>
                            <div className="col text-right p-0">
                                <CheckInput label="Consumables Admin" labelWidthPels={100} name="ul-consumables_admin" small value="CONSUMABLE_ADMIN"
                                            checked={LIUtils.UserHasLevelGivenList(curUserDetails?.userLevels,"CONSUMABLE_ADMIN")}
                                            onChanged={handleUserLevelCheckChanged}/>
                            </div>
                            <CheckInput label="Editor" name="ul-editor" small value="EDITOR"
                                        checked={LIUtils.UserHasLevelGivenList(curUserDetails?.userLevels,"EDITOR")}
                                        onChanged={handleUserLevelCheckChanged}/>
                           
                        </div>
                        <div className="col text-right">
                            <CheckInput label="Fixer" name="ul-fixer" small value="FIXER"
                                        checked={LIUtils.UserHasLevelGivenList(curUserDetails?.userLevels,"FIXER")}
                                        onChanged={handleUserLevelCheckChanged}/>
                            <CheckInput label="Picker" name="ul-picker" small value="PICKER"
                                        checked={LIUtils.UserHasLevelGivenList(curUserDetails?.userLevels,"PICKER")}
                                        onChanged={handleUserLevelCheckChanged}/>
                            <CheckInput label="Retriever" name="ul-retriever" small value="RETRIEVER"
                                        checked={LIUtils.UserHasLevelGivenList(curUserDetails?.userLevels,"RETRIEVER")}
                                        onChanged={handleUserLevelCheckChanged}/>
                            <CheckInput label="Searcher" name="ul-searcher" small value="SEARCHER"
                                        checked={LIUtils.UserHasLevelGivenList(curUserDetails?.userLevels,"SEARCHER")}
                                        onChanged={handleUserLevelCheckChanged}/>
                           
                        </div>
                        <div className="col text-right">
                            <CheckInput label="Sender" name="ul-sender" small value="SENDER"
                                        checked={LIUtils.UserHasLevelGivenList(curUserDetails?.userLevels,"SENDER")}
                                        onChanged={handleUserLevelCheckChanged}/>
                            <CheckInput label="Service" name="ul-service" small value="SERVICE"
                                        checked={LIUtils.UserHasLevelGivenList(curUserDetails?.userLevels,"SERVICE")}
                                        onChanged={handleUserLevelCheckChanged}/>
                           
                            <CheckInput label="Reporter" name="ul-reporter" small value="REPORTER"
                                        checked={LIUtils.UserHasLevelGivenList(curUserDetails?.userLevels,"REPORTER")}
                                        onChanged={handleUserLevelCheckChanged}/>
                        </div>
                       
                    </div>
                    
                    <div className="li-border-line-light mt-2"/>
                    
                    { /* right side, row #5 */}
                    <label className="li-form-label">Notifications?</label>
                    <div className="row offset-1">
                        <div className="col text-right">
                            <CheckInput label="Admin" name="nl-admin" small value="ADMIN"
                                        checked={LIUtils.UserHasNotifyFlagGivenList(curUserDetails?.notifyFlags,"ADMIN")}
                                        onChanged={handleNotifyFlagCheckChanged}/>

                            <CheckInput label="Consumables" name="nl-consumables" small value="CONSUMABLES"
                                        checked={LIUtils.UserHasNotifyFlagGivenList(curUserDetails?.notifyFlags,"CONSUMABLES")}
                                        onChanged={handleNotifyFlagCheckChanged}/>

                            
                        </div>
                        <div className="col text-right">
                            <CheckInput label="Sample Overdue" name="nl-overdue" small value="SAMPLE_OVER_DUE"
                                        checked={LIUtils.UserHasNotifyFlagGivenList(curUserDetails?.notifyFlags,"SAMPLE_OVER_DUE")}
                                        onChanged={handleNotifyFlagCheckChanged}/>
                            <CheckInput label="Sample Returned" name="nl-returned" small value="SAMPLE_WAS_RETURNED"
                                        checked={LIUtils.UserHasNotifyFlagGivenList(curUserDetails?.notifyFlags,"SAMPLE_WAS_RETURNED")}
                                        onChanged={handleNotifyFlagCheckChanged}/>


                        </div>
                        <div className="col text-right">

                            <CheckInput label="Service" name="nl-service" small value="SERVICE"
                                        checked={LIUtils.UserHasNotifyFlagGivenList(curUserDetails?.notifyFlags,"SERVICE")}
                                        onChanged={handleNotifyFlagCheckChanged}/>

                        </div>
                    </div>

                    <div className="li-border-line-light mt-2"/>

                    <LocationSelector onChange={onChange} locationLabel="Location"
                                      locationValue={curUserDetails?.locationId}
                                      departmentValue={curUserDetails?.departmentId}
                                      contactValue={curUserDetails?.id??""}
                                      showContact={false}
                                      onIsValid={onLocationIsValidChanged}
                                      required={locationIsRequired}
                                      withDepartmentsOnly/>

                    {/* BUTTON ROW */}
                    <div className="text-center my-5">
                        <LIButton name="cancel" label={isDirty?"Cancel":"Clear"} bgClass="mr-4" primary onClick={handleCancelClicked}/>
                        <LIButton name="save" label={curUserDetails?.id===null?"ADD":"UPDATE"} secondary disabled={!isDirty || !locationIsValid || isSaving} 
                                  onClick={(e)=>handleSaveClicked(e,curUserDetails?.id===null?"ADD":"UPDATE")} showBusy={isSaving}/>
                    </div>
                </div>
            </div>

            
        </div>
    );
}

UserAdminPage.propTypes = {
   
};

export default UserAdminPage;