import {SAMPLE_TYPE_BLOCK, SAMPLE_TYPE_SLIDE} from "../GUI_COMMON/SUPPORT/LIUtils";
import CommonClientApi from "../GUI_COMMON/API/commonClientApi";
import refreshNaWorkerScript from '../SUPPORT/WEBWORKERS/refreshNAWorker';
import refreshChaseWorkerScript from '../SUPPORT/WEBWORKERS/refreshChaseWorker';

export const DEFAULT_PAGE_SIZE=20       // the # of items to load into a list if not specified

/**
 * this downloadable parent list needs to match the one that's built into the ar-webservice-mac since that
 * one will only serve up from from THESE directories
 * @type {string[]}
 */
export const DOWNLOADABLE_FILE_PARENT_LIST=[
    "pick a directory",
    "webservice","webservice/logs",
    "scooter","scooter/logs",
    "slidemapper","slidemapper/logs"        // not at every client!
];

class ArchiverClientApi extends CommonClientApi {
    
    //#region INFO
    
    static getServerSideWwwAppInfo() {
        /**
         * send a request for the version of the web app that should be running right now
         *
         * Sent:  /version.html
         *
         * Returns:
         * {
         *    version: <the version string - no 'v'>
         * }
         */

        console.log(
            `getServerSideWwwAppInfo(): sending INFO request...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestForTextFile("/version.html")
            .then((versionStr) => {
                versionStr=versionStr.replace("\n","");
                resolve({serverSideWwwAppVersion:versionStr});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion INFO
    
    //#region STATS

    static sendStatInfoRequest(statName) {
        /**
         * send a request for the current info on the stat 'statName'
         *
         * Sent:  /api/stat/<statName>
         *
         * Returns:
         * {
         *    <statInfo>
         * }
         */

        console.log(
            `getStatInfo(): sending stat INFO request for stat '${statName}'...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/stat/${statName}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion STATS
    
    //#region CASES
    
    static sendCaseSearchRequest(searchTerm:string, startIndex:number=0, numToReturn:number=DEFAULT_PAGE_SIZE) {
        /**
         * send a request to search cases for the given search term,
         * returning any results that are found.
         *
         * Sent:  /api/cases/<searchTerm>
         *
         * Returns:
         * {
         *  samples: [
         *  
         *  ]
         *  ...
         * }
         */

        console.log(
            `sendCaseSearchRequest(): sending CASE request using search term '${searchTerm}' (# at a time '${numToReturn}')...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/cases/${searchTerm}?si=${startIndex}&nr=${numToReturn}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }

                resolve(response.cases);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getCaseHistory(caseId) {
        /**
         * send a request for the case history for the given caseId
         * returning any results that are found.
         *
         * Sent:  /api/case/history?caseId=<caseId>
         *
         * Returns:
         * {
         *  caseHistory: [
         *  
         *  ]
         *  ...
         * }
         */

        console.log(
            `getCaseHistory(): sending CASE HISTORY request for id '${caseId}'...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/case/history?caseId=${caseId}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }

                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getCaseBaseInfo(caseNum) {
        /**
         * send a request for the basic information for the given caseId
         * returning any results that are found.
         *
         * Sent:  /api/case?caseNum=<caseNum>
         *
         * Returns:
         * {
         *  
         * }
         */

        console.log(
            `getCaseBaseInfo(): sending CASE BASE INFO request for case# '${caseNum}'...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/case?caseNum=${caseNum}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }

                resolve(response["caseInfo"]);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static getCaseSampleInventory(caseId,includeDeleted:boolean, sampleType:number=null) {
        /**
         * send a request for the complete sample inventory for the given caseId
         * returning any results that are found.  If a type is specified, only that type of sample will be returned.
         *
         * Sent:  /api/case/inventory?caseId=<caseId>&id=<t|f>&t=<slide|block>
         *
         * Returns:
         * [
         *      {
         *          
         *      }
         *      ...
         * ]
         */

        console.log(
            `getCaseSampleInventory(): sending CASE SAMPLE INVENTORY request for case id '${caseId}'...`
        );

        return new Promise((resolve, reject) => {
            
            let sampleTypeStr="";
            if(sampleType===SAMPLE_TYPE_SLIDE) {
                sampleTypeStr="slide";
            }
            else if(sampleType===SAMPLE_TYPE_BLOCK) {
                sampleTypeStr="block";
            }
            this.sendRequestAsJson(`/api/case/inventory?caseId=${caseId}&id=${includeDeleted}&t=${sampleTypeStr}`, "GET")
            .then((response) => {
                if(response?.statusCode===404) {
                    resolve([]); // ignore not found
                    return;
                }
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.samples);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion CASES
    
    //#region SAMPLES

    static sendSampleSearchRequest(searchTerm:string, startIndex:number, numToReturn:number, 
                                   includeDeleted:boolean, sampleType:string, barcodesOnly:boolean) {
        /**
         * send a request to search samples for the given search term,
         * returning any results that are found.
         *
         * Sent:  /api/samples/<searchTerm>&si=#&nr=#&id=<bool>&bcOnly=<bool>
         *
         * Returns:
         * {
         *  samples: [
         *  
         *  ]
         *  ...
         * }
         */

        console.log(
            `sendSampleSearchRequest(): sending SAMPLE request using search term '${searchTerm}'...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/samples/${searchTerm}?si=${startIndex}&nr=${numToReturn}&id=${includeDeleted}&t=${sampleType}&bcOnly=${barcodesOnly}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }

                resolve(response.samples);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getSampleHistory(sampleId) {
        /**
         * send a request for the sample history for the given sampleId
         * returning any results that are found.
         *
         * Sent:  /api/sample/history?sampleId=<sampleId>
         *
         * Returns:
         * {
         *  sampleHistory: [
         *  
         *  ]
         *  ...
         * }
         */

        console.log(
            `getSampleHistory(): sending SAMPLE HISTORY request for id '${sampleId}'...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/sample/history?sampleId=${sampleId}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }

                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static getSampleBaseInfoGivenId(sampleId) {
        /**
         * send a request for the basic information for the given sample Id
         *
         * Sent:  /api/sample/<id>
         *
         *     Returns a single match
         *
         * Returns:

         *   {
         *     barcode:
         *     caseNumber:
         *     id:
         *     imageData:
         *     magBarcode:
         *     magSection:
         *     manuallyAdded:
         *     positionOrder:
         *     sampleNumber:
         *     sampleType: <#>,
         *     profileId:
         *   },
         *
         */

        console.log(
            `getSampleBaseInfoGivenId(): sending SAMPLE BASE INFO request for sample id '${sampleId}'...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/sample/id/${sampleId}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }

                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getSampleNAInfoGivenId(sampleId) {
        /**
         * send a request for the NeedsAttention information for the given sample Id
         *
         * Sent:  /api/na_sample/<id>
         *
         *     Returns a single match
         *
         * Returns:

         *   {
         *     attentionReason: <string | null>
         *     addedToNeedsAttention: <date>
         *     // all stuff below included from basic sample info
         *     barcode:
         *     caseNumber:
         *     id:
         *     imageData:
         *     magBarcode:
         *     magSection:
         *     manuallyAdded:
         *     positionOrder:
         *     sampleNumber:
         *     sampleType: <#>,
         *     profileId:
         *   },
         *
         */

        console.log(
            `getSampleNAInfoGivenId(): sending SAMPLE NA INFO request for sample id '${sampleId}'...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/na_sample/id/${sampleId}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }

                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getDashboardCounts() {
        /**
         * send a request for various counts associated with the different major 
         * buttons on the dashboard page.
         *
         * Sent:  /api/general/dash_stats
         *
         * Returns: {
         *  numNeedsAttention: <#>,
         *  numChaseList: <#>,
         *  numWatchList: <#>,
         *  numRetrievalsList: <#>,
         * }
         */

        console.log(
            `getDashboardCounts(): sending count request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/general/dash_stats`, "GET")
            .then((response) => {
                if(response?.statusCode===404) {
                    resolve([]); // ignore not found
                    return;
                }
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                
                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getAdminDashboardCounts() {
        /**
         * send a request for various counts associated with the different major
         * buttons on the admin dashboard page.
         *
         * Sent:  /api/general/dash_stats/admin
         *
         * Returns: {
         *  numEvents: <#>,
         *  numNotifs: <#>,
         *  numPrintJobs: <#>,
         * }
         */

        console.log(
            `getAdminDashboardCounts(): sending count request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/general/dash_stats/admin`, "GET")
            .then((response) => {
                if(response?.statusCode===404) {
                    resolve([]); // ignore not found
                    return;
                }
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }

                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static getNumNeedsAttention() {
        /**
         * send a request for the number of samples that need attention
         *
         * Sent:  /api/samples/attentionCount
         *
         * Returns: {
         *  numNeedsAttention: <#>
         * }
         */

        console.log(
            `getNumNeedsAttention(): sending NEEDS ATTENTION count ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/samples/attentionCount`, "GET")
            .then((response) => {
                if (!response || response.isError) {
                    if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                        return;
                    }
                    reject(response?.error??"Not Specific Error provided - is the Archiver Service running?");
                    return;
                }

                resolve(response.numNeedsAttention);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getNumNeedsChasing() {
        /**
         * send a request for the number of samples that need chasing.
         *
         * Sent:  /api/case/count
         *
         * Returns: {
         *  count: <#>
         * }
         */

        console.log(
            `getNumNeedsChasing(): sending NEEDS CHASING count ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/chase/count`, "GET")
            .then((response) => {
                if(response?.statusCode===404) {
                    resolve([]); // ignore not found
                    return;
                }
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }

                resolve(response.count);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getNeedsAttentionList(startIndex, numItemsPerPage, orderBy:string=null, sampleType:string=null) {
        /**
         * send a request for the next page of the needs attention list
         * returning any results that are found.
         *
         * Sent:  /api/samples/attention&si=<#>&nr=<#>&ob=<timedesc|timeasc>&t=<null|slide|block>
         *
         * Returns:
         * samples = [
         *      {
         *          <sample>
         *      }
         *      ...
         * ]
         */

        console.log(
            `getNeedsAttentionList(): sending NEEDS ATTENTION request for startingIndex '${startIndex}'...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/samples/attention?si=${startIndex}&nr=${numItemsPerPage}&ob=${orderBy}&t=${sampleType}`, "GET")
            .then((response) => {
                if(response?.statusCode===404) {
                    resolve([]); // ignore not found
                    return;
                }
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }

                resolve({total:response.total,samples:response.samples});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendSampleUpdate(sampleId, sampleType, barcode, caseNumber, sampleNumber, profileId, permanentlyRemove:boolean) {
        /**
         * send a request to update the given sampleId with the specified new info
         *
         * Sent:  /api/sample/<sampleId>  (AS PUT)
         *
         * PUT = {
         *      sampleType: <int>,
         *      barcode: <barcode>,
         *      caseNumber: <#>,
         *      sampleNumber: <#>,
         *      profileId: <#>,
         *      permanentlyRemove: <t|f>
         * }
         *
         * Returns:
         * {
         *  <updated Sample>
         * }
         */

        console.log(
            `sendSampleUpdate(): sending sample update request for sampleId='${sampleId}'...`
        );

        return new Promise((resolve, reject) => {

            const profileIdAsNum=Number(profileId)
            const body={
                sampleType: sampleType,
                barcode: barcode,
                caseNumber: caseNumber,
                sampleNumber: sampleNumber,
                profileId: profileIdAsNum,
                permanentlyRemove: permanentlyRemove
            }

            this.sendRequestAsJson(`/api/sample/${sampleId}`, "PUT",body)
            .then((response) => {
                if(response?.isError && response?.statusCode===300) { // sample exists! (duplicate creation - not allowed
                    const existingSampleId=response.existingSampleId; // supplied
                    const duplicateError = this.buildErrorReturn(300,response.error);
                    duplicateError["existingSampleId"]=existingSampleId; // augment
                    reject(duplicateError);
                    return;
                }
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }

                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static sendNotifySampleLocationViewed(sampleId:number, pageName:string="-", linkDetails:string=null) {
        /**
         * send a request to notify the server that the given sample has been viewed
         *
         * Sent:  /api/sample/notify_viewed/<sampleId>  (AS PUT)
         *
         * PUT = {
         *      pageName: <string>,
         *      linkDetails: <string>
         * }
         *
         * Returns:
         * {
         *  success: <true|false> 
         *      OR
         *      error: <string> (as per normal error object)
         * }
         */

        console.log(
            `sendNotifySampleLocationViewed(): sending sample viewed notification for sampleId='${sampleId}'...`
        );

        return new Promise((resolve, reject) => {

            const body={
                pageName: pageName,
                linkDetails: linkDetails
            }

            this.sendRequestAsJson(`/api/sample/location_viewed/${sampleId}`, "PUT",body)
            .then((response) => {
                // note -- ignoring all errors here 
                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion SAMPLES
    
    //#region RETRIEVAL_ORDERS
    
    static getRetrievalOrders(roType:string, startIndex:number, pageSize:number) {
        /* Description: request a list of retrieval orders from the system.
        *               if the unpicked flag is true, the old,completed orders will be 
        *               requested.  Otherwise, just get the unpicked ones.
        * 
        *
        *  GET /api/ro_<un>picked?si=<>&nr=<>
        * 
        *  Body: {}
        *
        * Returns: {
        *     orders: [...]
        * }
        * 
        */

        console.log(
            `getRetrievalOrders(): sending request for RO type ${roType}...`
        );
        
        let actionVerb = "ro_unpicked";
        if (roType==="unsent") {
            actionVerb = "ro_unsent";
        }
        else if(roType==="completed") {
            actionVerb = "ro_completed";
        }

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/${actionVerb}?si=${startIndex}&nr=${pageSize}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.orders);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getRetrievalOrderGivenId(orderId) {
        /* Description: request the details for a given order id
        * 
        *
        *  GET /api/ro/<orderId>
        * 
        *  Body: {}
        *
        * Returns: {
        *     order: [...]
        *     samples: [...]
        * }
        * 
        */

        console.log(`getRetrievalOrder(): sending request for retrieval order '${orderId}'...`);
        
        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/ro/${orderId}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({order:response.order,samples:response.samples});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static getSamplePickSheet(orderList) {
        /* Description: request a list of order samples (ordered by mag) for each of the listed orders
        * 
        *  NOTE: we are using POST here because we are adding a body
        *
        *  POST /api/ro_picksheet
        * 
        *  Body: {
        *   orders=[]
        * }
        *
        * Returns: {
        *       orders: [...],
        *       samples: [...]
        * }
        * 
        */

        console.log(
            `getSamplePickSheet(): sending request for order list having ${orderList.length} orders...`
        );
        
        return new Promise((resolve, reject) => {
            
            this.sendRequestAsJson(`/api/ro_picksheet`, "POST",orderList)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({orders:response.orders,samples:response.samples});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static sendAddRetrievalOrder(orderInfo) {
        /* Description: add the given order info as a new retrieval order
        *         
        *  POST /api/ro
        * 
        *  Body: {
        *   ...
        * }
        *
        * Returns: {
        *       order: [...],
        *       samples: [...]
        * }
        * 
        */

        console.log(`sendAddRetrievalOrder(): adding new order...`);
        return new Promise((resolve, reject) => {
            
            const sampleListOfIds = orderInfo.samples.map(item => item.id);
            
            const body = {
                createdById: orderInfo.createdById,
                destContactId: orderInfo.destContact,
                destDepartmentId: orderInfo.destDepartment,
                destLocationId: orderInfo.destLocation,
                dateDueBack: orderInfo.dueBackDate,
                requestedById: orderInfo.requestedById,
                permanentlyRemove: orderInfo.permanentlyRemove,
                notes: orderInfo.notes,
                samples:  sampleListOfIds
            }

            this.sendRequestAsJson(`/api/ro`, "POST",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({order:response.order,samples:response.samples});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendUpdateRetrievalOrder(orderId, orderInfo) {
        /* Description: update the given order info as a new retrieval order
        *         
        *  POST /api/ro
        * 
        *  Body: {
        *   ...
        * }
        *
        * Returns: {
        *       order: [...],
        *       samples: [...]
        * }
        * 
        */

        console.log(`sendUpdateRetrievalOrder(): updating order ${Number(orderId)}`);

        return new Promise((resolve, reject) => {

            const sampleListOfIds = orderInfo.samples.map(item => item.id);
            const body = {
                createdById: orderInfo.createdById,
                destContactId: orderInfo.destContact,
                destDepartmentId: orderInfo.destDepartment,
                destLocationId: orderInfo.destLocation,
                dateDueBack: orderInfo.dueBackDate,
                requestedById: orderInfo.requestedById,
                permanentlyRemove: orderInfo.permanentlyRemove,
                notes: orderInfo.notes,
                samples:  sampleListOfIds
            }

            this.sendRequestAsJson(`/api/ro/${orderId}`, "PUT",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({order:response.order,samples:response.samples});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static sendPickSamplesRequest(samplesList) {
        /* Description: send a list of samples and their order ids to be picked out of the system.
        *         
        *  POST /api/ro_remove
        * 
        *  Body: [...sample/order id list...]
        * 
        * Returns: {
        *       samplesRemoved: [...ids of samples actually removed...],
        *       samplesAlreadyOut: [...ids of samples already OUT of mags (not pickable)...]
        * }
        * 
        */

        console.log(
            `sendPickSamplesRequest(): sending request to pick ${samplesList.length} samples...`
        );

        return new Promise((resolve, reject) => {
            
            this.sendRequestAsJson(`/api/ro_remove`, "POST",samplesList)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({samplesRemoved:response["samplesRemoved"],samplesAlreadyOut:response["samplesAlreadyOut"]});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static getSampleLastRemovalDetails(sampleId) {
        
        // /api/sample/last_removal?sampleId={sampleId}"

        console.log(`getSampleLastRemovalDetails(): sending request for sampleId='${sampleId}'...`);

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/sample/last_removal?sampleId=${sampleId}`, "GET")
            .then( (response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({
                    lastPermRemoval: response.lastPermRemoval,
                    lastRoHistoryItem: response.lastRoHistoryItem,
                    lastStoredIn: response.lastStoredIn
                });
            })
            .catch((e) => {
                reject(e);
            });
        });
        
    }
    
    static markRetrievalAsSent(orderId) {
        /* Description: mark the given RO as sent (from unsent)
       *         
       *  PUT /api/ro_sent/<orderid>
       * 
       *  Body: {}
       *
       * Returns: {
       *       order: {<the updated ro>}
       * }
       * 
       */

        console.log(
            `markRetrievalAsSent(): for order id ${orderId}...`
        );

        return new Promise((resolve, reject) => {
            
            const body = {}

            this.sendRequestAsJson(`/api/ro_sent/${orderId}`, "PUT",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.order);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static markRetrievalAsPicked(orderId) {
        /* Description: mark the given RO as being picked (unsent)
       *         
       *  PUT /api/ro_unsent/<orderid>
       * 
       *  Body: {}
       *
       * Returns: {
       *       order: {<the updated ro>}
       * }
       * 
       */

        console.log(
            `markRetrievalAsUnSent(): for order id ${orderId}...`
        );

        return new Promise((resolve, reject) => {

            const body = {}

            this.sendRequestAsJson(`/api/ro_picked/${orderId}`, "PUT",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.order);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static markRetrievalListAsSent(orderIdList:[]) {
        /* Description: mark the given RO as sent (from unsent)
       *         
       *  PUT /api/ro_sent/list
       * 
       *  Body: {
       *    orderIds: [3,4,5,6,...]
       * }
       *
       * Returns: {
       *       order: {<the updated ro>}
       * }
       * 
       */

        console.log(
            `markRetrievalListAsSent(): for ${orderIdList.length} orders...`
        );

        return new Promise((resolve, reject) => {

            const body = {
                orderIds:orderIdList
            }

            this.sendRequestAsJson(`/api/ro_sent/list`, "PUT",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.order);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static sendDeleteRORequest(orderId) {
        /* Description: delete this RO from the system and remove all of it's samples from it.
          *         
          *  PUT /api/ro_delete/<orderid>
          * 
          *  Body: {}
          *
          * Returns: {
          *       numPickedRemaining: <#> 
          * }
          * 
          */

        console.log(
            `sendDeleteRORequest(): for order id ${orderId}...`
        );

        return new Promise((resolve, reject) => {

            const body = {}

            this.sendRequestAsJson(`/api/ro_delete/${orderId}`, "PUT",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({numPickedRemaining:response.numPickedRemaining});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendDeleteSampleFromRORequest(sampleId, orderId) {
        
        /* Description: delete a single sample from the given RO.
          *         
          *  PUT /api/ro_delete/<orderid>/<sampleId>
          * 
          *  Body: {}
          *
          * Returns: {
          *       result: <true|false>
          * }
          * 
          */

        console.log(
            `sendDeleteSampleFromRORequest(): for sample_id ${sampleId} from order_id ${orderId}...`
        );

        return new Promise((resolve, reject) => {

            const body = {}

            this.sendRequestAsJson(`/api/ro_delete/${orderId}/${sampleId}`, "PUT",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({result:response.result});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion RETRIEVAL_ORDERS
    
    //#region PROFILES

    static getProfiles() {
        /* Description: request the list of sample and mag profiles from the server
        *
        *  GET /api/profiles
        * 
        *  Body: {}
        *
        * Returns: {
        *     profiles: [...]
        * }
        * 
        */
        
        console.log(
            `getProfiles(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson("/api/profiles?all=true", "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getProfilesForDevice(deviceId) {
        /* Description: request the list of profiles for the given device (with device info)
        *
        *  GET /api/device/<deviceId>
        * 
        *  Body: {}
        *
        * Returns: {
        *     profiles: [...]
        * }
        * 
        */

        console.log(
            `getProfilesForDevice(): sending request for device id=${deviceId}...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/device/${deviceId}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.device);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendProfileUpdateForDevice(deviceId:Number, profileList:[]) {
        /* Description: send updated device details (or NEW details) to the server for updating
        *  
        * PUT /api/profiles/<id>
        * 
        *  Body: {
                ids=[1,2,3,4...]
        *   }
        *
        * Returns: {
        *       <profiles>
        * }
        * 
        */

        console.log(
            `sendProfileUpdateForDevice(): sending request to add/update profiles for device with id: ${deviceId}...`
        );

        return new Promise((resolve, reject) => {
            
            let method="PUT";
            let url = `/api/profile/device/${deviceId}`;
            
            this.sendRequestAsJson(url,method,profileList)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.profiles);  // user model returned
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendProfileUpdate(profileDetails, updatedImageId) {
        /* Description: send updated profile to the server.  This also covers ADDs
        *  
        * PUT /api/profiles/<id>
        * 
        *  Body: {
                ids=[1,2,3,4...]
        *   }
        *
        * Returns: {
        *       <profiles>
        * }
        * 
        */

        console.log(
            `sendProfileUpdate(): sending request to add/update profile with id: ${profileDetails.id}...`
        );

        return new Promise((resolve, reject) => {

            // assume update first
            let method="PUT";
            let url = `/api/profile/${profileDetails.id}`;
            if(profileDetails.id===null || profileDetails.id===-1) { // new profile here
                method="POST";
                url = `/api/profile`;
            }
            
            const body = {
                name: profileDetails.name,
                type: profileDetails.type,
                comment: profileDetails.comment,
                isCaseMappedExternal: profileDetails.isCaseMappedExternal,
                barcodesAreUnique: profileDetails.barcodesAreUnique,
                imageId: (updatedImageId>0?updatedImageId:null),
                holdsSampleType: profileDetails.holdsSampleType,
                destinationMagProfileId: profileDetails.destinationMagProfileId,
                enabled: profileDetails.enabled,
                isSampleNumMappedLater: profileDetails.isSampleNumMappedLater
            }

            this.sendRequestAsJson(url,method,body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.profile);  // model returned
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendProfileRemove(profileId) {
        /** send up a request to remove the given profile from the archiver
         *
         *  DELETE /api/profile/<id>
         *
         *  Response: {
         *     success: true
         *     }
         *
         */

        console.log(
            `sendProfileRemove(): sending remove profile request for ${profileId}...`
        );

        return new Promise((resolve, reject) => {
            let url = `/api/profile/${profileId}`;
            this.sendRequestAsJson(url, "DELETE")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.success);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendInspectionRegionUpdate(parentProfileId, irDetails) {
        /* Description: send updated inspection region to the server.  This also covers ADDs
        *  
        * PUT /api/profile/irs/<parentProfileId>
        * 
        *  Body: {
                <irModel>
        *   }
        *
        * Returns: {
        *       <profile>
        * }
        * 
        */

        console.log(
            `sendInspectionRegionUpdate(): sending request to add/update IR '
                            ${irDetails.Id}' into profile with id: ${parentProfileId}...`
        );

        return new Promise((resolve, reject) => {

            let method="PUT";
            let url = `/api/profile/irs/${parentProfileId}`;
            
            const body = {
                id: irDetails.id,
                name: irDetails.name,
                hint: irDetails.hint,
                decodeMethod: irDetails.decodeMethod,
                finalResize: irDetails.finalResize,
                postProcessing: irDetails.postProcessing,
                cropTopLeft: irDetails.cropTopLeft, // injected
                cropBottomRight: irDetails.cropBottomRight // injected
            }

            this.sendRequestAsJson(url,method,body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.profile);  // model returned
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendInspectionRegionRemove(profileId, irId) {
        /** send up a request to remove the given inspection region from the given profile from the archiver
         *
         *  DELETE /api/profile/<profileId>/<irId>
         *
         *  Response: {
         *     success: true
         *     }
         *
         */

        console.log(
            `sendInspectionRegionRemove(): sending remove inspection region request for ${irId} from profile ${profileId}...`
        );
       
        return new Promise((resolve, reject) => {

            if(!irId || irId===-1 || !profileId || profileId===-1) {
                reject(this.buildErrorReturn(400,"empty IR or Profile id sent"));
                return;
            }


            let url = `/api/profile/${profileId}/${irId}`;
            this.sendRequestAsJson(url, "DELETE")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.success);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    //#endregion PROFILES
    
    //#region LOCATIONS
    
    static getLocations(enabledOnly:bool) {
        /**
         * send a request for the list of loations for retrieval orders
         *
         * Sent:  /api/locations
         *
         * Returns:
         * {
         *      locations=[]
         * }
         */

        console.log(
            `getLocation(): sending Locations list request (enabledOnly=${enabledOnly})...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/location?eo=${enabledOnly}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.locations);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getLocationGivenId(locationId, includeDisabledDepts:bool=false) {
        /**
         * send a request for the details for the given location id
         *
         * Sent:  GET /api/location/{locationid}?idd=<t|f>
         *
         * Returns:
         * {
         *      location: {
         *          id:
         *          name:
         *          ...
         *          departments: {...}
         *      }
         * }
         */

        console.log(
            `getLocationGivenId(): sending Location details request for location ${locationId}...`
        );
        let extra="";
        if(includeDisabledDepts===true) { // include disabled departments
            extra="?idd=true";
        }

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/location/${locationId}${extra}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.location);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendLocationsUpdate(locationDetails:{}) {
        /* Description: send updated location details (or NEW details) to the server for updating
        *  
        * PUT /api/location/<id>
        * 
        *  Body: {
                name: "",
                name: "",
                address: "",
                internal: false,
                notes: "",
                dueBackDays: 14,
        *   }
        *
        * Returns: {
        *       <locationModel>
        * }
        * 
        */

        console.log(
            `sendLocationsUpdate(): sending request to add/update location with id: ${locationDetails.id}...`
        );

        return new Promise((resolve, reject) => {

            // assume update first
            let method="PUT";
            let url = `/api/location/${locationDetails.id}`;
            if(locationDetails.id===null || locationDetails.id===-1) { // new user
                method="POST";
                url = `/api/location`;
            }

            this.sendRequestAsJson(url,method,locationDetails)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.location);  // user model returned
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendLocationRemove(locationId) {
        /** send up a request to remove the given location from the archiver
         *
         *  DELETE /api/location/<id>
         *
         *  Response: {
         *     success: true
         *     }
         *
         */

        console.log(
            `sendLocationRemove(): sending remove location request for ${locationId}...`
        );

        return new Promise((resolve, reject) => {
            let url = `/api/location/${locationId}`;
            this.sendRequestAsJson(url, "DELETE")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.success);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendDepartmentUpdate(deptDetails:{}) {
        /* Description: send updated department details (or NEW details) to the server for updating
        *  
        * PUT /api/department/<id>
        * 
        *  Body: {
                name: "",
                notes: "",
        *   }
        *
        * Returns: {
        *       <departmentModel>
        * }
        * 
        */

        console.log(
            `sendDepartmentUpdate(): sending request to add/update department with id: ${deptDetails.id} for parent location ${deptDetails.parentLocationId}...`
        );

        return new Promise((resolve, reject) => {

            // assume update first
            let method="PUT";
            let url = `/api/department/${deptDetails.id}`;
            if(deptDetails.id===null || deptDetails.id===-1) { // new user
                method="POST";
                url = `/api/department`;
            }

            this.sendRequestAsJson(url,method,deptDetails)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.department);  // user model returned
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendDepartmentRemove(deptId) {
        /** send up a request to remove the given department from the archiver
         *
         *  DELETE /api/department/<id>
         *
         *  Response: {
         *     success: true
         *     }
         *
         */

        console.log(
            `sendDepartmentRemove(): sending remove dept request for ${deptId}...`
        );

        return new Promise((resolve, reject) => {
            let url = `/api/department/${deptId}`;
            this.sendRequestAsJson(url, "DELETE")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.success);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion LOCATIONS
    
    //#region CONTACTS

    static sendContactsUpdate(userDetails:{}) {
        /* Description: send updated user details (or NEW details) to the server for updating
        *  
        * PUT /api/contact/<id>
        * 
        *  Body: {
                name: "",
                email: "",
                phone: "",
                enabled: true,
                accessCode: null,
                userLevels: "",
                notifyFlags: "",
                locationId: -1,             // only used if no department id.
                departmentId: -1,           // department id is the main id used for mapping contacts to
        *   }
        *
        * Returns: {
        *       <contactModel>
        * }
        * 
        */

        console.log(
            `sendContactsUpdate(): sending request to add/update use with id: ${userDetails.id}...`
        );

        return new Promise((resolve, reject) => {

            // assume update first
            let method="PUT";
            let url = `/api/contact/${userDetails.id}`;
            if(userDetails.id===null || userDetails.id===-1) { // new user
                method="POST";
                url = `/api/contact`;
            }

            this.sendRequestAsJson(url,method,userDetails)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.contact);  // user model returned
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendContactRemove(userId) {
        /** send up a request to remove the given user from the archiver
         *
         *  GET /api/contact/<id>
         *
         *  Response: {
         *     success: true
         *     }
         *
         */

        console.log(
            `sendContactRemove(): sending remove contact request for ${userId}...`
        );

        return new Promise((resolve, reject) => {
            let url = `/api/contact/${userId}`;
            this.sendRequestAsJson(url, "DELETE")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.success);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getContactsForDepartment(departmentId) {
        /**
         * send a request for the list of contacts for a given department
         *
         * Sent:  /api/contacts/dept/<departmentId>
         *
         * Returns:
         * {
         *      contacts=[]
         * }
         */

        console.log(
            `getContactsGivenDepartment(): sending Contacts list request for department '${departmentId}'...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/contact/dept/${departmentId}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.contacts);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static searchContacts(searchTerm, numToReturn:Number=-1) {
        /** send up a search request for contacts, returning any that match.
         *  NOTE: if the searchTerm is empty here, we call a different api to return all contacts.
         *
         *  GET /api/contact/search?s=<searchTerm>&nr=<#>
         *
         *  Response: {
         *     contacts: [<contact list>]
         *     }
         *
         */

        console.log(
            `searchContacts(): sending search request for contacts matching '${searchTerm}' (returning '${numToReturn}')...`
        );
        
        return new Promise((resolve, reject) => {
            let url = `/api/contact/search?s=${searchTerm}&nr=${numToReturn}`;
            if(searchTerm==="") {
                url = "/api/contact";  // return all (different call -- why - this returns access codes)
            }
            this.sendRequestAsJson(url, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.contacts);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static generateNewAuthCode() {
        /** send up a request to generate a new , unique authcode
         *
         *  GET /api/auth/newcode
         *
         *  Response: {
         *     code: <authCode>
         *     }
         *
         */

        console.log(
            `generateNewAuthCode(): sending authcode generation request...`
        );

        return new Promise((resolve, reject) => {
            let url = `/api/auth/newcode`;
            this.sendRequestAsJson(url, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.code);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion CONTACTS
    
    //#region CHASE_LIST

    static getChaseList(startIndex:number, pageSize:number) {
        /* Description: request the chase list from the system.
        *  
        * GET /api/chase?si=<>&nr=<>
        * 
        *  Body: {}
        *
        * Returns: {
        *       total: <#>,
        *       chaseItems: [...]
        * }
        * 
        */

        console.log(
            `getChaseList(): sending request...`
        );
        
        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/chase?si=${startIndex}&nr=${pageSize}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({totalItems:response.total, chaseItems:response.chaseItems});  // returns an array
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static sendSnoozeRequestForSamples(sampleOrderList:Array,snoozeToDate:Date, note:string) {
        /* Description: request that the given sampleOrderList be snoozed until the given date and noted.
        *  
        * PUT /api/chase/snooze
        * 
        *  Body: {
        *       date: <date>,       
        *       notes: <str>,
        *       sampleOrderList: [{orderId: <#>, sampleId:<#>},{},...],
        * } 
        *
        * Returns: {
        *       total: <#>,   // total number of items now in chase list.
        * }
        * 
        */

        console.log(
            `sendSnoozeRequestForSamples(): sending request for '${sampleOrderList.length}' samples...`
        );

        return new Promise((resolve, reject) => {
            const body = {
                sampleOrderList: sampleOrderList,
                note: note,
                date: snoozeToDate
            };
            this.sendRequestAsJson(`/api/chase/snooze`, "PUT",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({totalInList:response.count, totalUpdated:response.updated});  
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion CHASE_LIST
    
    //#region WATCH_LIST

    static getWatchList(startIndex:number, pageSize:number) {
        /* Description: request the watch list from the system for the current user.
        *  
        * GET /api/watch?si=<>&nr=<>
        * 
        *  Body: {}
        *
        * Returns: {
        *       total: <#>,
        *       watchItems: [...]
        * }
        * 
        */

        console.log(
            `getWatchList(): sending request...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/watch?si=${startIndex}&nr=${pageSize}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({totalItems:response.total, watchItems:response.watchItems});  // returns an array
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendRemoveWatchesRequest(sampleIds:Array) {
        /* Description: request that the given sampleList be removed from the current watch list for this user
        *  
        * POST /api/watch/remove
        * 
        *  Body: []
        *
        * Returns: {
        *       total: <#>,   // total number of items now in watch list.
        * }
        * 
        */

        console.log(
            `sendRemoveWatchesRequest(): sending request for '${sampleIds.length}' samples...`
        );

        return new Promise((resolve, reject) => {
           
            this.sendRequestAsJson(`/api/watch/remove`, "POST",sampleIds)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({totalInList:response["count"], removedIdList:response["removedIds"]});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static sendAddSamplesToWatchList(sampleIds:Array) {
        /* Description: request that the given sampleList be added to the watch list for this user
        *  
        * POST /api/watch/add
        * 
        *  Body: [<array of sample ids>]
        *
        * Returns: {
        *       total: <#>,   // total number of items added.
        * }
        * 
        */

        console.log(
            `sendAddSamplesToWatchList(): sending request to add '${sampleIds.length}' samples...`
        );

        return new Promise((resolve, reject) => {

            this.sendRequestAsJson(`/api/watch/add`, "POST",sampleIds)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response["totalAdded"]);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    //#endregion WATCH_LIST

    //#region DEVICES

    static getDevices() {
        /* Description: request the list of devices from the server
        *
        *  GET /api/devices
        * 
        *  Body: {}
        *
        * Returns: {
        *     devices: [...]
        * }
        * 
        */

        console.log(
            `getDevices(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson("/api/devices", "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.devices);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getMagableDevices() {
        /* Description: request the list of devices from the server that are magable (ie they 
        *               aren't processors and we can print labels for.
        *
        *  GET /api/devices/magable
        * 
        *  Body: {}
        *
        * Returns: {
        *     devices: [...]
        * }
        * 
        */

        console.log(
            `getMagableDevices(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson("/api/devices/magable", "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.devices);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendDeviceUpdate(deviceDetails:{}) {
        /* Description: send updated device details (or NEW details) to the server for updating
        *  
        * PUT /api/device/<id>
        * 
        *  Body: {
                name: "",
                ipAddress: "",
                deviceType: -1,
                accessCode: "",
                enabled: true,
                profiles: null
        *   }
        *
        * Returns: {
        *       <deviceModel>
        * }
        * 
        */

        console.log(
            `sendDeviceUpdate(): sending request to add/update device with id: ${deviceDetails.id}...`
        );

        return new Promise((resolve, reject) => {
            
            const adjustedDeviceDetails = {...deviceDetails};
            adjustedDeviceDetails.profiles=null; // don't update

            // assume update first
            let method="PUT";
            let url = `/api/device/${deviceDetails.id}`;
            if(deviceDetails.id===null || deviceDetails.id===-1) { // new user
                method="POST";
                url = `/api/device`;
                adjustedDeviceDetails.id=-1;   // force -- ensure this isn't null for devices (limitation of loginAble)
            }

            this.sendRequestAsJson(url,method,adjustedDeviceDetails)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.device);  // user model returned
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendDeviceRemove(deviceId) {
        /** send up a request to remove the given device from the archiver
         *
         *  DELETE /api/device/<id>
         *
         *  Response: {
         *     success: true
         *     }
         *
         */

        console.log(
            `sendDeviceRemove(): sending remove device request for ${deviceId}...`
        );

        return new Promise((resolve, reject) => {
            let url = `/api/device/${deviceId}`;
            this.sendRequestAsJson(url, "DELETE")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.success);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    //#endregion DEVICES
    
    //#region PRINTING
    
    static getMostRecentRegisteredMagForProfile(profileId) {
        /* Description: request the most recent barcode printed for the given profile and device
        *
        *  GET /api/mags/last_for_profile
        * 
        *  Body: {}
        *
        * Returns: {
        *    <mag details>
        * }
        * 
        */

        console.log(
            `getMostRecentRegisteredMagForProfile(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/mags/last_for_profile?pid=${profileId}`, "GET")
            .then((response) => {
                if(response?.statusCode===404) {
                    resolve([]); // ignore not found
                    return;
                }
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendPrintNewLabelsRequest(numLabels:number, forDeviceId:number, forProfileId:number) {
        /* Description: request that the server register and print the given number of labels
        *               for the given deviceId, and the given profileId.
        *  
        * POST /api/mags/register?n=<#toPrint>
        * 
        *  Body: {
        *       barcode: <string>,
        *       parentProfileId: <#>,
        *       parentDeviceId: <#>,
        *       locationId: null or <#>
        *   }
        *
        * Returns: {
        *       total: <#>,   // total number of items added.
        * }
        * 
        */

        console.log(
            `sendPrintNewLabelsRequest(): sending request to print '${numLabels}' labels for profileId ${forProfileId}...`
        );

        return new Promise((resolve, reject) => {
            
            const body = {
               // barcode: null,
                //type: 0,
                parentProfileId: forProfileId,
                parentDeviceId: forDeviceId
            }

            this.sendRequestAsJson(`/api/mags/register?n=${numLabels}`, "POST",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response["mags"]);  // [] of mags that were registered to the given device.
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendReprintLabelsRequest(magBC:string) {
        /* Description: request that the server reprint the mag label for the given barcode
        *  
        * PUT /api/mag/reprint?bc=<magBC>
        * 
        *  Body: {}
        *
        * Returns: {
        *       <...magInfo>
        * }
        * 
        */

        console.log(
            `sendReprintLabelsRequest(): sending request to reprint mag barcode '${magBC}' label...`
        );

        return new Promise((resolve, reject) => {

            const body = { }

            this.sendRequestAsJson(`/api/mag/reprint?bc=${magBC}`, "PUT",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response);  // mag details returned
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion PRINTING

    //#region MAGAZINES

    static sendMagSearchRequest(searchTerm:string, sampleType:string, startIndex:number, numToReturn:number) {
        /**
         * send a request to search magazines for the given search term,
         * returning any results that are found.
         *
         * Sent:  /api/mag_search?s=<term>&t=<nothing | slide | block>
         *
         * Returns:
         * {
         *  mags: [
         *      < mag info model list>
         *  ]
         *  ...
         * }
         */

        console.log(
            `sendMagSearchRequest(): sending MAGAZINE request using search term '${searchTerm}'...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/mag_search?s=${searchTerm}&t=${sampleType}&si=${startIndex}&nr=${numToReturn}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response["mags"]);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getMagDetailsGivenBarcode(magBC,showSection) {
        /**
         * send a request for the mag details for the given mag barcode
         *
         * Sent:  /api/mag_details/<barcode>?section=A
         *
         *     Returns all the samples in the mag, with details
         *
         * Returns:
         *      {
         *          magazine = {
         *              barcode:,
         *              ...
         *          },
         *         samples = [
         *             <sample>,
         *             <sample>
         *         ]
         *      }
         *   
         *
         */

        console.log(
            `getMagDetailsGivenId(): sending MAG DETAILS for section ${showSection} request for mag barcode '${magBC}'...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/mag/samples?bc=${magBC}&section=${showSection}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({magazine:response.magazine,samples:response.samples});  // returns the array of samples
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendRegisterMagToDevice(magBarcode:string, forDeviceId:number) {
        /* Description: request that the server register and the given mag barcode against the spec'd device.
        *  
        * POST /api/mags/register?n=0
        * 
        *  Body: {
        *       barcode: <string>,
        *       parentProfileId: <#>,
        *       parentDeviceId: <#>,
        *       locationId: null or <#>
        *   }
        *
        * Returns: {
        *       total: <#>,   // total number of mags resgistered (should be one).
        * }
        * 
        */

        console.log(
            `sendRegisterMagToDevice(): sending request to register '${magBarcode}' against device '${forDeviceId}'...`
        );

        return new Promise((resolve, reject) => {

            // NewMagModel
            const body = {
                barcode: magBarcode,
                parentDeviceId: forDeviceId
            }

            this.sendRequestAsJson(`/api/mags/register/force`, "POST",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response["mag"]);  // [] of mags that were registered to the given device.
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion MAGAZINES
    
    //#region REPORTS

    static getReportsList() {
        // Description: request the list of available reports from the server
        //
        // GET /api/reports
        //
        // Body: {}
        //
        // Returns: {
        //     reports: [...]
        // }
        //

        console.log(
            `getReportsList(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson("/api/reports", "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({reports:response.reports});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getReportDetails(reportId) {
        // Description: request that the server return info on the given report
        //
        // GET /api/reports/<id>
        //
        // Body: {}
        //
        // Returns: {
        //     basicInfo: {...},    // basic report details.
        // }
        //

        console.log(
            `getReportDetails(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/reports/${reportId}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({basicInfo:response.basicInfo});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getReportDetailsGivenName(reportName) {
        // Description: request that the server return info on the given report given the name of the report
        //
        // GET /api/reports/<name>
        //
        // Body: {}
        //
        // Returns: {
        //     basicInfo: {...},    // basic report details.
        // }
        //

        console.log(
            `getReportDetailsGivenName(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/reports/named/${reportName}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({basicInfo:response.basicInfo});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static runReport(reportId,fromTicks=null, toTicks=null, ourTzOffset=0) {
        // Description: request that the server run the given report
        //
        // GET /api/reports/run/<id>
        //
        // Body: {}
        //
        // Returns: {
        //     basicInfo: {...},    // basic report details.
        //     results: [{}...{}]   // rows
        // }
        //

        console.log(
            `runReport(): sending request (tzOffset = ${ourTzOffset})...`
        );
        
        let suffix="";
        if(fromTicks!==null || toTicks!==null) {
            suffix = `?from=${fromTicks}&to=${toTicks}&tz=${ourTzOffset}`;
        }

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/reports/run/${reportId}${suffix}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({basicInfo:response.basicInfo, resultRows: response.results});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendReportUpdate(reportDetails:{}) {
        /* Description: send updated report details (or NEW details) to the server for updating
        *  
        * PUT /api/reports/<id>
        * 
        *  Body: {
                id: null,
                name: "",
                description:null,
                iconName: "fa-chart-bar",
                code: null,
                codeType: CODE_TYPE_SQL,        // only SQL types for now
                dateColumnName: null,
        *   }
        *
        * Returns: {
        *       <reportModel>
        * }
        * 
        */

        console.log(
            `sendReportUpdate(): sending request to add/update report with id: ${reportDetails.id}...`
        );

        return new Promise((resolve, reject) => {

            const adjustedReportDetails = {...reportDetails};
            //adjustedReportDetails.profiles=null; // don't update

            // assume update first
            let method="PUT";
            let url = `/api/reports/${reportDetails.id}`;
            if(reportDetails.id===null || reportDetails.id===-1) { // new report
                method="POST";
                url = `/api/reports`;
            }

            this.sendRequestAsJson(url,method,adjustedReportDetails)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.report); 
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static sendReportRemove(reportId) {
        /** send up a request to remove the given report from the archiver
         *
         *  DELETE /api/report/<id>
         *
         *  Response: {
         *     success: true
         *     }
         *
         */

        console.log(
            `sendReportRemove(): sending remove report request for ${reportId}...`
        );

        return new Promise((resolve, reject) => {
            let url = `/api/reports/${reportId}`;
            this.sendRequestAsJson(url, "DELETE")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.success);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion REPORTS
    
    //#region NOTIFICATIONS

    static getNotifications() {
        // Description: request the the notification for this user from the server
        //
        // GET /api/notifs
        //
        // Body: {}
        //
        // Returns: {
        //     numNotifs: <#>,
        //     notifs: [...]
        // }
        //

        console.log(
            `getNotifications(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson("/api/notifs", "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({numNotifs:response.numNotifs, notifs:response.notifs});  // []
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static getNumNotifications() {
        // Description: request the the number of read and unread notifications for this user from the server
        //
        // GET /api/notif/num_notifs
        //
        // Body: {}
        //
        // Returns: {
        //     numUnread: <#>,
        //      numNotifs: <#>
        // }
        //

        console.log(
            `getNumNotifications(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson("/api/notifs/num_notifs", "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({numNotifs:response.numNotifs, numUnread:response.numUnread});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static markNotificationAsRead(notifId:number) {
        /* Description: request that the given notification be marked as read at the server
        *  
        * PUT /api/notif/mark_read?id=<#>
        * 
        *  Body: {} 
        *
        * Returns: {
        *       notifId: <id>,
        *       status: <updated status int>
        * }
        * 
        */

        console.log(
            `markNotificationAsRead(): sending request for notification id '${notifId}'...`
        );

        return new Promise((resolve, reject) => {
            const body = {};
            this.sendRequestAsJson(`/api/notifs/mark_read?id=${notifId}`, "PUT",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({notifId:response.notifId, status:response.status});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static markAllNotificationsAsRead() {
        /* Description: request that all notifications get marked as read
        *  
        * PUT /api/notif/mark_read/all
        * 
        *  Body: {} 
        *
        * Returns: {
        *       numUpdated: <#>
        * }
        * 
        */

        console.log(
            `markAllNotificationsAsRead(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            const body = {};
            this.sendRequestAsJson(`/api/notifs/mark_read/all`, "PUT",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({numUpdated:response.numUpdated});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    static deleteNotification(notifId:number) {
        /* Description: request that the given notification be deleted at the server
        *  
        * DELETE /api/notif/<#>
        * 
        *  Body: {} 
        *
        * Returns: {
        *       notifId: <id>
        * }
        * 
        */

        console.log(
            `deleteNotification(): sending request do delete notification id '${notifId}'...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/notifs/${notifId}`, "DELETE")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({notifId:response.notifId});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static deleteAllNotifications() {
        /* Description: request that all notifications get deleted
        *  
        * DELETE /api/notif/all
        * 
        *  Body: {} 
        *
        * Returns: {
        *       numRemaining: <#>
        * }
        * 
        */

        console.log(
            `deleteAllNotifications(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            const body = {};
            this.sendRequestAsJson(`/api/notifs/all`, "DELETE",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({numRemaining:response.numRemaining});
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion NOTIFICATIONS
    
    //#region CONSUMABLES
    
    static getConsumables() {
        // Description: request the the consumables inventory from the server
        //
        // GET /api/consumables
        //
        // Body: {}
        //
        // Returns: {
        //      "numConsumables": <#>,
        //      "consumables": [
        //          {
        //              "name": "Slide Magazine",
        //              "numInStock": <#>,
        //              "alertLevel": <#>,
        //              "estDaysRemaining": <#>,
        //              "dailyUsage": <#>,
        //              "weeklyUsage": <#>
        //          },
        //          ...
        //      ]
        //  }
        //

        console.log(
            `getConsumables(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson("/api/consumables", "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({numConsumables:response.numConsumables, consumables:response.consumables});  // []
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static addConsumableToInventory(slideMags:Number, blockMags:Number, boxes8Mag:Number) {
        /* Description: add the given POSITIVE ONLY inventory to the server consumables inventory number
        *  
        * PUT /api/consumables/add
        * 
        *  Body: {
        *   slideMags: <#>,
        *   blockMags: <#>.
        *   boxes8Mag: <#>
        * } 
        *
        * Returns: {
        *       slideMags: { numInStock: <#>, estDaysRemaing: <#> },
        *       blockMags: { numInStock: <#>, estDaysRemaing: <#> },
        *       boxes8Mag: { numInStock: <#>, estDaysRemaing: <#> }
        * }
        * 
        */

        console.log(
            `addConsumableToInventory(): sending request to update consumables...`
        );

        return new Promise((resolve, reject) => {
            const body = {
                slideMags,
                blockMags,
                boxes8Mag,
            };
            this.sendRequestAsJson(`/api/consumables/add`, "PUT",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({
                    slideMags: response.slideMags, 
                    blockMags: response.blockMags, 
                    boxes8Mag: response.boxes8Mag
                });
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static adjustInventory(itemType:string, newValue:Number, newAlertAt:Number) {
        /* Description: adjust the given consumable "IN STOCK" value to the 'newValue' specified.
        *               this also adjusts the alertAtLevel too if needed.
        *  
        * PUT /api/consumables/adjust
        * 
        *  Body: {
        *   itemType: <slideMags,blockMags, or boxes>
        *   newValue: <#>.
        *   newAlertAt: <#>
        * } 
        *
        * Returns: {
        *       itemType:
        *       numInStock: <#>,
        *       alertLevel:<#>,
        *       estDaysRemaining: <#>
        * }
        * 
        */

        console.log(
            `adjustInventory(): sending request to update consumables...`
        );

        return new Promise((resolve, reject) => {
            const body = {
                itemType: itemType,
                newValue: newValue,
                newAlertAt: newAlertAt
            };
            this.sendRequestAsJson(`/api/consumables/adjust`, "PUT",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({
                    itemType:response.itemType,
                    numInStock: response.numInStock,
                    alertLevel: response.alertLevel,
                    updatedEstDaysRemaining: response.estDaysRemaining // note the name change here - BUGFIX: pott 230904
                });
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion CONSUMABLES
    
    //#region MAINTANENCE_ADMIN

    static requestReloadOfMag(magBC) {
        /**
         * send a request for the mag given mag to be reloaded (ie. all samples removed)
         *
         * DELETE /api/mag/allsamples/<mag bc>?forsure=y&uid=<userId>
         */

        console.log(
            `requestReloadOfMag(): for mag barcode '${magBC}'...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/mag/allsamples/${magBC}?forsure=y`, "DELETE")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.barcode);  // returns a magInfoModel
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static requestClearEvents() {
        // Description: request that the server clear all events from the system
        //
        // GET /api/admin/maintanence/clear_events
        //
        // Body: {}
        //
        // Returns: {
        //     numRemoved: <#>
        // }
        //

        console.log(
            `requestClearEvents(): sending request ...`
        );
        
        return new Promise((resolve, reject) => {
            this.sendRequestAsJson("/api/admin/maintanence/clear_events", "DELETE")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.numRemoved);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static requestClearNotifs() {
        // Description: request that the server clear all notifications from the system
        //
        // GET /api/admin/maintanence/clear_notifis
        //
        // Body: {}
        //
        // Returns: {
        //     numRemoved: <#>
        // }
        //

        console.log(
            `requestClearEvents(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson("/api/admin/maintanence/clear_notifs", "DELETE")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.numRemoved);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static requestClearPrintJobs() {
        // Description: request that the server clear all print jobs from the system
        //
        // GET /api/admin/maintanence/clear_printjobs
        //
        // Body: {}
        //
        // Returns: {
        //     numRemoved: <#>
        // }
        //

        console.log(
            `requestClearPrintJobs(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson("/api/admin/maintanence/clear_printjobs", "DELETE")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response.numRemoved);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    static sendWipeDbRequest() {
        // Description: request that the server clear the entire archiver DB.
        //              NOTE: this is a request only - the archiver won't do it if the 
        //              authentication and authorization requirements aren't met.
        //
        // GET /api/admin/wipe?forSure=true
        //
        // Body: {}
        //
        // Returns: {
        //     success: true  || http error response
        // }
        //

        console.log(
            `requestWipeDb(): sending request ...`
        );

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson("/api/admin/wipe?forSure=true", "POST")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve();
            })
            .catch((e) => {
                reject(e);
            });
        });
    }
    
    //#endregion MAINTANENCE_ADMIN
    
    //#region ATTENTION

    static refreshSingleNASample(sampleId:number): Promise {

        // Description: attempt an NA refresh on the given single sample
        //
        // PUT /api/sample/refresh_na/<sample_id>
        //
        // Body: {}
        //
        // Returns: {
        //     wasAdded: <t|f>,
        //     wasRemoved: <t|f>,
        //     wasSkipped: <t|f>
        // }
        //

        console.log(`refreshSingleNASample(): refreshing NA for single sample ${sampleId}...`);


        const body={};
        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/sample/refresh_na/${sampleId}`, "PUT",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve({wasAdded:response.wasAdded, wasRemoved:response.wasRemoved, wasSkipped:response.wasSkipped});  
            })
            .catch((e) => {
                reject(e);
            });
        });
        
    }
    
    //#endregion ATTENTION
    
    //#region FILE_DOWNLOAD

    static getFiles(parentDir:string) {

        // Description: request a list of the files in a given parent directory under prod_fs on a archiver
        //
        // GET /api/admin/prodfs_filelist?pd=<parentdir>
        //
        // Returns: {
        //     parentDir: <parentDir>,
        //     files: [
        //          <file1 as str>,
        //          <file2 as str>,
        //          ...
        //      ]
        // }
        //

        console.log(
            `getFiles(): sending request for parentDir '${parentDir}'...`
        );

        return new Promise((resolve, reject) => {
            CommonClientApi.sendRequestAsJson(`/api/admin/prodfs_filelist?pd=${parentDir}`, "GET")
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });
    }

    static downloadFileFromParentDir(parentDir, fileName) {
        // Description: request a file download from the webservice (the directories allowed
        // to download from are strictly enforced as to make this less of a security risk.
        // As well, you need to be logged in as an SERVICE user to the Archiver prior to calling this.
        //
        // GET /api/admin/prodfs_getfile?pd=<parentDir>&fn=<fileName> 
        ////
        // Response:
        // {
        //    <file data>
        // }
        //

        console.log(`downloadFileFromParentDir(): sending for ${fileName} in ${parentDir}...`);

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/admin/prodfs_getfile?pd=${parentDir}&fn=${fileName}`, "GET")
            .then( () => resolve())
            .catch((e) => reject("could not get file.  Details: " + e + "]"));
        });
    }
    
    //#endregion FILE_DOWNLOAD
    
    //#region WEBSOCKET_LONG_RUNNING
    
    static refreshNASamples(updateFunc:function): void {
        console.log(`refreshNASamples(): refreshing NA samples...`);

        if (window.Worker) {
            const myWorker = new Worker(refreshNaWorkerScript);
            //const myWorker = new Worker("/refreshNAWorker.js"); // this works well for development (placed in public web folder) -pott

            this._mainWorkerReceiver(myWorker,updateFunc);
        } else {
            console.log('Your browser doesn\'t support web workers.');
        }
    }

    static refreshChaseListSamples(updateFunc:function): void {
        console.log(`refreshChaseListSamples(): refreshing Chase list samples...`);

        if (window.Worker) {
            const myWorker = new Worker(refreshChaseWorkerScript);
            //const myWorker = new Worker("/refreshChaseListWorker.js"); // this works well for development (placed in public web folder) -pott

            this._mainWorkerReceiver(myWorker,updateFunc);
            
        } else {
            console.log('Your browser doesn\'t support web workers.');
        }
    }
    
    static _mainWorkerReceiver(workerScript:Worker, updateFunc:function) {
        
        // send out our initial header
        workerScript.postMessage({
            "token": CommonClientApi.liToken,
            "baseUrl": "ws://"+window.location.host // includes the port
        }); // set the current token
        workerScript.postMessage({"action": "start"});

        workerScript.onmessage = function (e) { // progress messages
            const result = e.data;

            // result is JSON obj => { isDone, isProgress, isError, finalResults (complex object) }

            if (result.isDone) { // refresh is complete
                console.log(`DONE -- #Processed: ${result.numProcessed}, #Removed: ${result.numRemoved}`);
                workerScript.terminate();  // we're done with this worker.
                if(updateFunc) {
                    updateFunc(result.percent, true, false, result);
                }
            } else if (result.isProgress) { // progress update
                if(updateFunc) {
                    updateFunc(result.percent);
                }
            }
            else if (result.isError) { // problem (likely with authentication
                console.log(`ERROR -- reason: ${result.errorReason}`);
                if(updateFunc) {
                    updateFunc(false,0,true,result); // result.errorReason is set here
                }
                workerScript.terminate();  // we're done with this worker.
            }
            else {
                console.log(`Unhandled WS Message received from '_mainWorkerReceiver' ${result} `);
            }
        }
    }
    
    //#endregion WEBSOCKET_LONG_RUNNING
    
    //#region FILE_UPLOAD

    static sendFileAsJson(fileInfo, onProgressUpdate) {

        // UNTESTED! 231030 -pott
        
        // Description: request a list of the files in a given parent directory under prod_fs on a archiver
        //
        // POST /api/admin/report_upload>
        // body = { <report body> }
        //
        // Returns: {
        //     success: true,
        //     id: <new report id>,
        //     name: <report name>
        // }
        //
        // or standard archiver error result
        //

        console.log(
            `postFileAsJson(): sending request for report upload...`
        );
        
        const body=fileInfo;

        return new Promise((resolve, reject) => {
            this.sendRequestAsJson(`/api/report/upload`, "POST",body)
            .then((response) => {
                if(this.checkForNoResponseOrErrorResponse(response,reject)) {
                    return;
                }
                resolve(response);
            })
            .catch((e) => {
                reject(e);
            });
        });

        // return new Promise((resolve, reject) => {
        //     let xhr = new XMLHttpRequest();
        //
        //     let formData = new FormData();
        //     formData.append("drawerName", drawerName); // # actually
        //     formData.append("Upload", "true");
        //     formData.append("encFileName", rawurlencode(fileName));
        //     formData.append("Filedata", fileBlob, rawurlencode(fileName));
        //
        //     xhr.open('POST', url);
        //     xhr.setRequestHeader(
        //
        //     xhr.onprogress = function (e) { // downloads
        //         if (e.lengthComputable && onProgressUpdate) {
        //             onProgressUpdate(e.loaded / e.total * 100);
        //         }
        //     };
        //     xhr.upload.onprogress = function (e) { //uploads
        //         if (e.lengthComputable && onProgressUpdate) {
        //             onProgressUpdate(e.loaded / e.total * 100);
        //         }
        //     };
        //     xhr.onloadend = function(e) {
        //         const target = e.currentTarget;
        //         console.debug("done load (2) => " + target.statusText);
        //         if(target.status>299) { // some error
        //             reject({isError: true, error: target.statusText, statusCode: target.status});
        //         }
        //
        //         // all good
        //         let result = JSON.parse(target.response);
        //         result["result"]="success";
        //         resolve(result);
        //     }
        //
        //     xhr.send(formData);
        
    }
    
    //#endregion FILE_UPLOAD
}

export default ArchiverClientApi;
