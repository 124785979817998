import React from "react";
import PropTypes from "prop-types";
import HelpLink from "../GUI_COMMON/COMPONENTS/HelpLink";

const CheckInput = ({label,name,checked,small, medium, value, onChanged, helpText, labelWidthPels, helpLink, showCheckLeft, colorName}) => {
    
    let fontClass="li-font-medium";
    if(small) {
        fontClass="li-font-small";
    }
    
    let labelStyle={};
    if(labelWidthPels) {
        //labelStyle = {width:labelWidthPels+"px"};
        labelStyle.width=labelWidthPels+"px";
    }
    if(colorName) {
        labelStyle.color=colorName;
    }
    
    function handleChanged(e) {
        if(onChanged) {
            onChanged(e);
        }
    }
    
    function dumpCheckBox() {
        return (
        <input type="checkbox" className="m-2 mr-3" name={name} aria-label="checkbox" checked={checked} value={value}
               onChange={(e) => handleChanged(e)}/>
        );
    }
    
    return(
        <div className="align-self-end mb-2">
            {showCheckLeft && dumpCheckBox()}
            <div className={"d-inline-block "+fontClass} style={labelStyle}>{label}</div>
            <span className="align-top mr-3">
                {helpLink && helpLink!==''?<HelpLink link={helpLink} small/>:null}
            </span>
            {!showCheckLeft && dumpCheckBox()}
            
            <small className="form-text text-muted">{helpText}</small>
        </div>
    );
}

CheckInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    checked: PropTypes.bool,
    onChanged: PropTypes.func,
    small: PropTypes.bool,
    medium: PropTypes.bool,
    value: PropTypes.string,
    helpText: PropTypes.string,
    labelWidthPels: PropTypes.number,
    helpLink: PropTypes.string,
    showCheckBox: PropTypes.bool,
    colorName: PropTypes.string,
}

export default CheckInput;