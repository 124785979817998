import React, {useContext, useEffect, useState} from "react"
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import Header from "./Header";
import Footer from "./Footer";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import SampleSearchInput from "./SampleSearchInput";
import ArchiverClientApi from "../API/archiverClientApi";
import CaseCard from "./CaseCard";
import {Redirect, useLocation, useParams} from "react-router-dom";
import {useHistory} from "react-router";
import BackNextWidget from "../GUI_COMMON/COMPONENTS/BackNextWidget";
import SearchButtonList from "./SearchButtonList";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import {StoreContext} from "../store";
import {saveSearchResults} from "../ACTIONS/clientActions";

const DEFAULT_CASE_PAGE_SIZE=30;

const SearchCasesPage = () => {

    const [globalState, dispatch] = useContext(StoreContext);

    const [isSearching,setIsSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [redirectTo, setRedirectTo] = useState("");
    
    const history = useHistory();
    const { searchTerm } = useParams();
    const [term,setTerm] = useState(searchTerm??"");
    const [isIniting, setIsIniting] = useState(true);
    
    const location = useLocation();
    const [pageSize,] = useState(DEFAULT_CASE_PAGE_SIZE);

    const params = new URLSearchParams(location.search);
    const [startIndex, setStartIndex]=useState(Number(params.get('si')??0));
    
    useEffect(() => {
        // RECALL -- this runs AFTER all children mount!
        console.debug("SearchCasesPage starting up...");
        window.scrollTo(0,0);
        LIUtils.setBackgroundColor('--li-main-bg-color');

        setIsIniting(false);
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        if(!isIniting) {
            if(searchResultsHaveNotChanged()) {
                setSearchResults(globalState.searchResults.searchResults);
            }
            else { // go to the API
                handleBackNext(startIndex, pageSize);
            }
        }
    }, [term,isIniting]); // eslint-disable-line react-hooks/exhaustive-deps

    function searchResultsHaveNotChanged() {
        const searchTypeKey = "case_search";
        const gSearchResults = globalState.searchResults;

        return gSearchResults.searchType === searchTypeKey &&
            gSearchResults.startIndex === startIndex &&
            gSearchResults.numToReturn === pageSize &&
            gSearchResults.searchResults !== undefined &&
            gSearchResults.searchTerm === term;
    }
    
    function handleCaseSearchClicked(newTerm) {
        if(newTerm===term) {
            console.debug("ignoring search request -- term didn't change");
            return;
        }
        setStartIndex(0);
        setTerm(newTerm);
    }
    
    function handleCaseClicked(caseItem) {
        handleGoToCase(caseItem.id, caseItem.number);
    }
    
    function handleGoToCase(caseId, caseNumber) {
        setRedirectTo(`/case_details/${caseNumber}`);
    }
    
    function showResultsTable() {
        if(!term || term==="") {
            return <div className="text-center mt-5"/>;
        }
        if(isSearching) {
            return <div className="text-center mt-5">...searching...</div>;
        }
        if(!searchResults || searchResults.length===0) {
            return <div className="badge-pill mt-5 p-3 li-bg-card">no matches found</div>;
        }

        const resultList = searchResults.map((item, index) =>
            <CaseCard key={item.id} caseId={item.id} caseNumber={item.number} dateStarted={item.dateStarted} dateModified={item.dateModified}
                      numSlides={item.numSlides} numBlocks={item.numBlocks}
                      numSlidesOut={item.numSlidesOut} numBlocksOut={item.numBlocksOut}
                        onClick={() => handleCaseClicked(item)}/>
        );

        return (
            <div className="row m-0 p-0 align-content-center">
                {resultList}
            </div>
        );
    }

    function haveResults() {
        return !isSearching && ((searchResults && searchResults.length>0) || startIndex>0);
    }

    function handleBackNext(si, numToReturn) {

        console.debug(`*** handling backnext (${si}:${numToReturn}) ***`);
        setStartIndex(si);
        replaceBrowserHistory(term,si,numToReturn);
        if (!term || term === "") {
            return;
        }
        setIsSearching(true);
        setSearchResults([]);
        ArchiverClientApi.sendCaseSearchRequest(term, si, numToReturn)
        .then( (results) => {
            if(results.length===1) { // just go there since there's only one
                history.replace("/case_search/");  // kill the search term so we don't loop coming back.
                handleGoToCase(results[0].id, results[0].number);
                return;
            }
            setSearchResults(results);
            dispatch(saveSearchResults("case_search",results,si, numToReturn, term)); // save for possible later use
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsSearching(false));
    }
    
    function replaceBrowserHistory(searchTerm,startIndex,numToReturn) {
        history.replace(`/case_search/${searchTerm}?si=${startIndex}&nr=${numToReturn}`);
    }
    
    if(redirectTo) {
        return (
          <Redirect push to={redirectTo}/>  
        );
    }
    
    return (
        <div className="container">
            <Header/>
                <div className="row mt-3 li-header-row justify-content-between">
                    <div>
                        <BackButton onClick={() => setRedirectTo("/dashboard")}/><span className="li-font-huge">Search</span>
                    </div>
                    <SearchButtonList activeChoice="cases"/>
                </div>

            <div className="position-relative mt-5 mb-4">
                <SampleSearchInput name="caseSearch" label="Case:" onSearchClicked={handleCaseSearchClicked} 
                                       busyFlag={isSearching} placeholder="" initialValue={term} hasFocus/>
            </div>
            
            <div className="mb-2">
                <BackNextWidget disableNext={searchResults.length < pageSize} pageSize={pageSize} startIndex={startIndex}
                                onClick={handleBackNext} show={haveResults()}/>

                <div className="li-search-results-table mt-2">
                    {showResultsTable()}
                </div>
            </div>

            <BackNextWidget disableNext={searchResults.length < pageSize} pageSize={pageSize} startIndex={startIndex} 
                                    onClick={handleBackNext} show={haveResults()}/>

            <Footer/>
        </div>
    );
}

export default SearchCasesPage;