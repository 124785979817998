import PropTypes from "prop-types";
import ProfileCard from "./ProfileCard";

const ProfileList = ({profiles, magOnly, sampleOnly, sampleType, selectedId, onRadioChanged, showDisabled}) => {

    if(!profiles || profiles.length===0) {
        return <option disabled>(no profiles available)</option>;
    }
    
    if(magOnly) {
        profiles = profiles?.filter(item => item.type===0 && (!showDisabled?item.enabled===true:true))??[];
    }
    if(sampleOnly) {
        if(sampleType===undefined) {
            profiles = profiles?.filter(item => item.type !== 0 && (!showDisabled ? item.enabled === true : true)) ?? [];
        }
        else { // filter specific type of sample
            
            // NOTE: this is left here as a placeholder for future implementation when we want to filter
            // on the TYPE of profile - slide or block.   For the moment, this does the same thing as 
            // the above case 'sampleType===undefined' -pott 230519
            profiles = profiles?.filter(item => item.type !== 0 && (!showDisabled ? item.enabled === true : true)) ?? [];
        }
    }
    
    return (
        <div className="">
            {profiles.map(item => 
                <ProfileCard key={item.id} profile={item} 
                             radioGroupName="profileRadios" checked={selectedId===item.id}
                             onRadioChanged={(profileId) => onRadioChanged(profileId)}/>)}
        </div>
    );

}

ProfileList.propTypes = {
    profiles: PropTypes.array.isRequired,
    magOnly: PropTypes.bool,
    sampleOnly: PropTypes.bool,
    sampleType: PropTypes.number,
    selectedId: PropTypes.number,
    showDisabled: PropTypes.bool,
}

export default ProfileList;