// holds the default state of our store.

const initialState = {
    ajaxCallsInProgress: 0,
    
    serverInfo: {
        initialized: false, // kicker
        version: "-",       // webservice version
        requiredDbVersion: "-", // required database version
        dbVersion: "-",     // current database version
        name: "-",          // our special name 
        demoMode: false,    // will be true if we need to support special functionality for demo mode.
    },
    
    clientInfo: {
        userId: -1,
        fullName: '',
        level: '',
    },
    
    notifications: {
        initialized: false,  // kicker
        lastUpdated: undefined,
        numUnread: 0,           // total number of unread
        numNotifs: 0,           // total number of notifs
        notifs: [],
    },
    
    case: {
        id: -1,
        number: "",
        numSlides: 0,
        numBlocks: 0,
        numSlidesOut: 0,
        numBlocksOut: 0,
        dateStarted: undefined,
        dateModified: undefined,
        history: [],
        //inventory: [],
    },

    sample: {
        id: -1,
        sampleNumber: "",
        caseNumber: "",
        sampleType: undefined,
        magBarcode: "",
        isPendingRemovalOnOrder: undefined,
        magSection: "",
        positionOrder: -1,
        profileId: -1,
        barcode: "",
        manuallyAdded: false,
        imageData: undefined,
        dateStarted: undefined,
        dateModified: undefined,
        history: [],
    },
    
    stats: {
        numNeedsAttention: -1,
        numNeedsChasing: -1,
        numWatching: -1,
        numRetrievals: -1,
        numEvents: -1,
    },
    
    profiles: {
        initialized: false,
        items: []
    },       // list of profiles
    
    retrievals: {
        unpicked: [],       // unpicked retrieval orders
        unsent: [],         // picked, but not sent
        completed: [],      // picked and sent retrieval orders
    },
    
    picksheet: {
        orders: [],     // all of the orders in the pick sheet
        samples: [],    // all of the samples for the pick sheet (can be sorted).
    },
    
    locations: [],
    
    contacts: [],
    
    chaseList: {  // the raw and processed chase list
        chaseListRaw: [],
        chaseListByLocation: [],
    },
    
    watchList: {
        watchListRaw: [],
        totalSamples: 0,
    },

    searchResults: {
        searchType: undefined,
        searchResults: undefined,
        startIndex: 0,
        numToReturn: 0,
        searchTerm: undefined,
        totalNumResults: 0,
    }
};

export default initialState;