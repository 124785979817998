import rootReducer from './REDUCERS/rootReducer'
import initialState from "./REDUCERS/initialState";
import {createContext, useReducer} from "react";

const StoreProvider = ({children}) => {
    const [state, dispatch] = useReducer(rootReducer, initialState);  // ### SAMPLE
    return (
        <StoreContext.Provider value={[state, dispatch]} displayName="StoreProvider">{children}</StoreContext.Provider>
    );
}

export const StoreContext = createContext(initialState);
export default StoreProvider;