import React, {useState} from 'react';
import PropTypes from 'prop-types';
import LIButton from "./LIButton";
import TextInput from "./TextInput";

const LoginFormWithKeyboard = ({onSubmit, isBusy}) => {

    const [authCode,setAuthCode] = useState("");
    
    function addDigit(e, newDigit:string) {
        e.preventDefault();

        let curCode = authCode;
        if(!newDigit) { // get the entire authcode from the event
            try {
                if(e.target.value!=="") {
                    let curNum = e.target.value;
                    curNum = curNum.replace(" ","");
                    if( Number.isInteger(Number(curNum))) {
                        curCode = curNum;
                    }
                    
                }
                else {
                    curCode = "";
                }
            }
            catch { // not a digit
                return;
            }
        }
        else {
            if (curCode.length < 6) {
                curCode += newDigit;
            }
        }
        if(curCode.length>=6) { // send it up
            if(onSubmit) {
                onSubmit(curCode);
            }
        }
        
        setAuthCode(curCode);
    }
    
    function clearAll(e) {
        e.preventDefault();
        setAuthCode("");
        document.getElementsByName("pass")[0].focus();
    }
    
    return (
        <>
            <form id="loginForm" style={{width:"300px",paddingTop:"10px"}} onSubmit={onSubmit}>
               
                    <TextInput   
                                name="pass" 
                                value={authCode}
                                onChange={(e) => addDigit(e)}
                                placeholder="user code" centered
                                autoComplete="off" required label=""
                                maxLength={6} 
                                focus
                                />
                    
                <div className="m-1">
                    <div className="row mt-4 justify-content-between">
                        <LIButton primary bgClass="col mx-2 px-0" label="1" onClick={(e) => addDigit(e,"1")} disabled={isBusy}/>
                        <LIButton primary bgClass="col mx-2 px-0" label="2" onClick={(e) => addDigit(e,"2")} disabled={isBusy}/>
                        <LIButton primary bgClass="col mx-2 px-0" label="3" onClick={(e) => addDigit(e,"3")} disabled={isBusy}/>
                    </div>
                    <div className="row mt-4 justify-content-between">
                        <LIButton primary bgClass="col mx-2 px-0" label="4" onClick={(e) => addDigit(e,"4")} disabled={isBusy}/>
                        <LIButton primary bgClass="col mx-2 px-0" label="5" onClick={(e) => addDigit(e,"5")} disabled={isBusy}/>
                        <LIButton primary bgClass="col mx-2 px-0" label="6" onClick={(e) => addDigit(e,"6")} disabled={isBusy}/>
                    </div>
                    <div className="row mt-4 justify-content-between">
                        <LIButton primary bgClass="col mx-2 px-0" label="7" onClick={(e) => addDigit(e,"7")} disabled={isBusy}/>
                        <LIButton primary bgClass="col mx-2 px-0" label="8" onClick={(e) => addDigit(e,"8")} disabled={isBusy}/>
                        <LIButton primary bgClass="col mx-2 px-0" label="9" onClick={(e) => addDigit(e,"9")} disabled={isBusy}/>
                    </div>
                    <div className="row mt-4">
                        <LIButton primary bgClass="col mx-2 px-0" label="0" onClick={(e) => addDigit(e,"0")} disabled={isBusy}/>
                        <LIButton secondary bgClass="col mx-2 px-0" label="Clear" onClick={(e) => clearAll(e)} disabled={isBusy}/>
                    </div>
                </div>
            </form>
        </>
    );
};

LoginFormWithKeyboard.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isBusy: PropTypes.bool,
};

export default LoginFormWithKeyboard;