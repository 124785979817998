import React, {useEffect, useRef, useState} from "react";
import Header from "./Header";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import ArchiverClientApi from "../API/archiverClientApi";
import {Redirect, useParams} from "react-router-dom";
import Footer from "./Footer";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import DatePicker from "react-date-picker";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import Chart from "chart.js/auto"; // eslint-disable-line
import {Bar, Line} from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {useCookies} from "react-cookie";
import TextInput from "../GUI_COMMON/COMPONENTS/TextInput";
import LIButton from "../GUI_COMMON/COMPONENTS/LIButton";
import CalendarIcon from "./CalendarIcon";

const RunReportPage = () => {

    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const [isLoadingResult, setIsLoadingResult] = useState(false);
    const [reportResults, setReportResults ] = useState([]);
    const [filteredResults, setFilteredResults] = useState(null);
    const [reportBasicInfo, setReportBasicInfo] = useState();
    const [showAs, setShowAs] = useState("table");
    const [showGraphOption, setShowGraphOption] = useState(false);
    const [redirectTo, setRedirectTo] = useState();
    
    const {reportId} = useParams();

    const [cookies,setCookies]= useCookies();
    
    //const fromTicksCookie = Number(cookies['reportFromTicks']);
    
    const [fromDate:Date, setFromDate] = useState(null); //Number.isNaN(fromTicksCookie)?null:new Date(Number(cookies['reportFromTicks']))); // msecs timestamp
    const [toDate:Date, setToDate] = useState(null); //cookies['reportToTicks']?new Date(Number(cookies['reportToTicks'])):null);   // msecs timestamp

    const textFilterRef = useRef(null);

    useEffect(() => {
        console.debug("RunReportPage starting up...");
        if(reportId) {
            setIsLoadingReport(true);
            ArchiverClientApi.getReportDetails(reportId)
            .then(({basicInfo}) => {
                setReportResults([]);
                setReportBasicInfo(basicInfo);
                if(hasGraphVerbs(basicInfo)) {
                    setShowGraphOption(true);
                    setShowAs("graph");
                }
                else {
                    setShowGraphOption(false);
                    setShowAs("table");
                }
                if(hasDateColumnName(basicInfo)) {
                    if(!fromDate) {
                        setFromDate(new Date(Date.now() - 2 * 7 * 3600 * 24 * 1000)); //  2 weeks ago.
                    }
                }
                else { // load report data now since we don't have to wait for dates
                    updateReportData(reportId);
                }
            })
            .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
            .finally(() => {
                setIsLoadingReport(false);
            });
        }
    }, [reportId]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        if(fromDate || toDate ) {
            let fromTicks = fromDate===null?"":computeTimeTFromDateGivenUTC(fromDate.getFullYear(),fromDate.getMonth(),fromDate.getDate()); //LIUtils.computeTimeTFromDateGivenLocal(fromDate.getFullYear(),fromDate.getMonth(),fromDate.getDate()):"";
            let toTicks = toDate===null?"":computeTimeTFromDateGivenUTC(toDate.getFullYear(),toDate.getMonth(),toDate.getDate(),0,0,0,true); //LIUtils.computeTimeTFromDateGivenLocal(toDate.getFullYear(),toDate.getMonth(),toDate.getDate(),0,0,0,true):"";
            
            if(Number.isNaN(fromTicks)) {
                fromTicks="";
            }
            if(Number.isNaN(toTicks)) {
                toTicks="";
            }
            updateReportData(reportId, fromTicks,toTicks)

            setCookies('reportFromTicks', fromTicks===""?null:fromTicks, {path: '/'});
            setCookies('reportToTicks', toTicks===""?null:toTicks, {path: '/'});
        }
    }, [fromDate,toDate]); // eslint-disable-line react-hooks/exhaustive-deps
    
    function computeTimeTFromDateGivenUTC(year, month, day, hour=0,minute=0,second=0, doEOD=false) {
        let ticks = Date.UTC(year,month,day,hour,minute,second)/1000;
        //localDT.setFullYear(year);
        //localDT.setMonth(month); // starts at 0
        //localDT.setDate(day); // starts at 1 with 0 being the last day of the previous month

        //let ticks = Math.floor(localDT.setHours(hour,minute,second)/1000);

        //let ticks = Math.floor(Date(year,month,day,hour,minute,second) / 1000);
        if(doEOD) {
            ticks += 86399; // include entire day of ticks
        }
        return ticks;
    }
    
    function hasDateColumnName(basicInfo) {
        return LIUtils.existsAndNotEmpty(basicInfo?.dateColumnName);
    }
    
    function hasGraphVerbs(basicInfo) {
        if(!LIUtils.existsAndNotEmpty(basicInfo?.code)) {
            return false;
        }
        return basicInfo.code.indexOf("GRAPH_")!==-1;
    }
    
    function updateReportData(reportId, fromTicks=null, toTicks=null) {
        setIsLoadingResult(true);
        const ourTzOffset = -(new Date().getTimezoneOffset())/60;  // in hours
        ArchiverClientApi.runReport(reportId,fromTicks, toTicks, ourTzOffset)
        .then(({basicInfo,resultRows}) => {
            setReportBasicInfo(basicInfo);
            setReportResults(resultRows);
            handleDataFilterChanged();
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsLoadingResult(false);
        });
    }
    
    function handleDateChanged(name,value:Date) {
        if(name==="fromDate") {
            setFromDate(value);
        }
        else if(name==="toDate") {
            setToDate(value);
        }
    }
    
    function handleDataFilterChanged() {
        if(textFilterRef.current) {
            const filterText = textFilterRef.current.value;
            if(filterText==="") {
                setFilteredResults(null);
            }
            else {
                const filtered = reportResults.filter( row => {
                    for(const [,value] of Object.entries(row)) {
                        if(value["value"].toString().toLowerCase().indexOf(filterText.toLowerCase())!==-1) {
                            return true;
                        }
                    }
                    return false;
                });
                setFilteredResults(filtered);
            }
        }
    }
    
    function handleClearFilterClicked() {
        if(textFilterRef.current) {
            textFilterRef.current.value = "";
        }
        setFilteredResults(null);
    }
    
    function dumpBasicInfo() {
        if(isLoadingReport) {
            return <LoadingSpinner medium center/>;
        }
        
        if(!LIUtils.existsAndNotEmpty(reportBasicInfo)) {
            return null;
        }
        
        // bit hacky, but we get newlines mapped this way.
        const descLines = reportBasicInfo?.description?.split('\n')??[];
        const desc = descLines.map( (item,index) => <div key={index}>{item}</div>);
        
        return(
            <div>
                <h4>
                {reportBasicInfo?.name??"no name"}
                </h4>
                <div className="text-wrap ml-5">{desc??"no description"}</div>
            </div>
        );
    }
    
    function dumpResults() {
        if(isLoadingResult) {
            return <LoadingSpinner medium center/>;
        }
        
        if(!LIUtils.existsAndNotEmpty(reportResults) || reportResults.length===0) {
            return <div className="text-left mb-5">No results found</div>;
        }
        if(showAs==="table") {
            return dumpResultsAsTable();
        }
        else if(showAs==="graph") {
            return dumpResultsAsGraph();
        }
        else {
            return dumpResultsAsCSV();
        }
    }
    
    function dateConvertIfNeeded(colName:string, colValue:string, dateFormat:string):string {
        if(looksLikeADate(colName, colValue)) { // convert?
            switch(dateFormat) {
                // ### TBD
                case "hour": return colValue;
                case "day":
                    const dt = new Date(LIUtils.dateFromJsonUtcDateStr(colValue));
                    if(dt instanceof Date && !isNaN(dt.valueOf())) {
                        return dt.toLocaleDateString("en-US", {
                            timeZone: 'UTC',
                            month: "short",
                            day: "numeric",
                            year: "numeric"
                        }).replace(/,/g,'');
                    }
                    return colValue;
                case "week": {
                    const dt = new Date(LIUtils.dateFromJsonUtcDateStr(colValue));
                    if(dt instanceof Date && !isNaN(dt.valueOf())) {
                        return dt.toLocaleDateString("en-US", {
                            timeZone: 'UTC',
                            month: "short",
                            day: "numeric",
                            year: "numeric"
                        }).replace(/,/g,'');
                    }
                    return colValue;
                }
                case "month": {
                    const dt = new Date(LIUtils.dateFromJsonUtcDateStr(colValue));
                    if(dt instanceof Date && !isNaN(dt.valueOf())) {
                        return dt.toLocaleDateString("en-US", {
                            timeZone: 'UTC', 
                            month: "short", 
                            year: "numeric"
                        }).replace(/,/g,'');
                    }
                    return colValue;
                }
                case "year": 
                    const [year] = colValue.split('-');
                    return year;
                default: return colValue;
            }
        }
        return colValue; // no change
    }
    
    //#region MAYBE_USEFUL_LATER

    // function setDateFromCookie(key:string) {
    //     const value = cookies[key];
    //     if(!value || value === "") return null;
    //     return new Date(Number(value));
    // }

    // function shouldConvertDatesToLocal():boolean {
    //     return !reportBasicInfo["noUtcConvert"]??false;  // do it by default
    // }
    //
    // function looksLikeAStatDate(thisThing:string, theValue:string):boolean {
    //     if (theValue.endsWith(" 00:00:00")) { // ### HACK - likely a stat - don't convert
    //         return true;
    //     }
    //     return false;
    // }
    //
    // function dateToLocalIfDate(colName:string, colValue:string):string {
    //     if(looksLikeADate(colName, colValue)) { // convert to local time?
    //         if(shouldConvertDatesToLocal()) {
    //             return LIUtils.dateToShortDateTimeString(LIUtils.dateFromJsonUtcDateStr(colValue));
    //         }
    //         if(looksLikeAStatDate(colName, colValue)) { // a stat time frame (time is meaningless)
    //             const parts =colValue.split(' ',2);
    //             if(parts.length===2) { // have a date and time hopefully
    //                 return parts[0]; // return the date only
    //             } 
    //         }
    //     }
    //     return colValue; // no change
    // }
    //#endregion MAYBE_USEFUL_LATER
    
    function looksLikeADate(thisThing:string, theValue:string):boolean {
        const lowCase = thisThing.toLowerCase();
        const dateNames = ["date","time_frame","time frame","timeframe","time","day","hour","week","month","year"];
        for(let index in dateNames) {
            if(lowCase.indexOf(dateNames[index])!==-1) { // possible date here
                return true;
            }
        }

        return false;
    }

    //#region RESULT_TYPES
    function dumpResultsAsTable() {

        const reportLines = reportBasicInfo.code.split("\n");
        const dateFormat = findGraphVarValue("DATETIME_DISPLAY:",reportLines)??"month";

        // rows have 1 or more subObjects which are the name/value pairs to report
        const resultsToShow = filteredResults??reportResults;
        if(resultsToShow.length===0) {
            return <div className="text-left my-4">No results found</div>;
        }
        const rowList = resultsToShow.map( (rowItem,index)=> {
            let retVal = [];
            for(const [key,value] of Object.entries(rowItem)) {
                //const columnData = dateToLocalIfDate(value["name"],value["value"]);
                const columnData = dateConvertIfNeeded(value["name"],value["value"], dateFormat);
                retVal.push(
                    // <td>{value["timeFrame"]}</td>
                    <td key={key}>
                        {/*{value["name"]}: */}
                        {columnData}
                    </td>
                );
            }
            return <tr key={index}>{retVal}</tr>;
        });
        const headerText = getHeaderText(reportResults[0]);
        return(
            <table className="table table-sm w-100">
                {headerText}
                <tbody>
                    {rowList}
                </tbody>
            </table>
        );
    }

    function dumpResultsAsCSV() {
        
        const reportLines = reportBasicInfo.code.split("\n");
        const dateFormat = findGraphVarValue("DATETIME_DISPLAY:",reportLines)??"month";

        // rows have 1 or more subObjects which are the name/value pairs to report
        const resultsToShow = filteredResults??reportResults;
        const rowList = resultsToShow.map( (rowItem,index)=> {
            let retVal = [];
            for(const [,value] of Object.entries(rowItem)) {
                const columnData = dateConvertIfNeeded(value["name"],value["value"], dateFormat);
                retVal.push(columnData);
            }
            return <div key={index}>{retVal.join(",")}</div>;
        });

        const headerText = getHeaderTextAsCSV(reportResults[0]);

        return(
            <div className="w-100">
                {headerText}
                {rowList}
            </div>
        );
    }

    function dumpResultsAsGraph() {

        const reportLines = reportBasicInfo.code.split("\n");
        // grab the values from the line-based report variable verbs
        const groupByVar = findGraphVarValue("GRAPH_GROUP_BY_OUTVAR:", reportLines);
        const graphYVar = findGraphVarValue("GRAPH_Y_VAR:", reportLines);
        const graphXVar = findGraphVarValue("GRAPH_X_VAR:", reportLines);
        const graphType = findGraphVarValue("GRAPH_TYPE:", reportLines)??"line";
        const dateFormat = findGraphVarValue("DATETIME_DISPLAY:",reportLines)??"month";

        const resultSets = {};
        let looksLikeTimeXAxis=false;

        const resultsToShow = filteredResults??reportResults;
        resultsToShow.map(
            rowItem => {
                let resultSetName=graphYVar??"Not Specified";  // general case (use the Y value)
                let yValue=null;
                let xValue=null;
                for(const [,dataSpecItem] of Object.entries(rowItem)) {
                    const name = dataSpecItem["name"];
                    const value = dataSpecItem["value"];
                    if(groupByVar && name===groupByVar) {
                        resultSetName=`${name}=${value}`;
                    }
                    else if(graphYVar && name===graphYVar) {
                        yValue=value;
                    }
                    else if(graphXVar && name===graphXVar) {
                        if(looksLikeADate(name,value)) {
                            looksLikeTimeXAxis=true;
                            xValue = LIUtils.fixAMPMInDateString(value);
                        }
                        else {
                            xValue = value; // value is just a value
                        }
                    }
                }
                if(!resultSets[resultSetName.toString()]) {  // create
                    resultSets[resultSetName.toString()]=[];
                }
                resultSets[resultSetName.toString()].push([yValue,xValue]); // pushign array onto array here onto the appropriate resultSet.
                return null; // not used
            }
        );

        const data = {
            labels: [],
            datasets: []
        };
        const colors = [
            "#ff0000",
            "#0000ff",
            "#00ff00",
            "#dc6db3",
            "#00f6f6",
            "#f88844"];

        let resultSetIndex=0;
        for(let [key,value] of Object.entries(resultSets)) {
            data.datasets.push({
                label: key,
                backgroundColor: colors[resultSetIndex],
                borderColor: colors[resultSetIndex],
                data: value.map(row => {
                    return {x: row[1], y: row[0]};
                }),
                tension: 0,
            });
            resultSetIndex++;
        }

        const xScaleTimeObj = {
            type: 'time',
            time: {
                displayFormats: {
                    // MM (1),MMM (Jan) ,MMMM (January)
                    // d (1), do (1st, 2nd), dd (01)
                    // E..EEE (Mon,), EEEE (Monday,), EEEEE (M,T,..), EEEEEE (Mo,Tu,We)
                    // H (0 - 23)), HH (00-23),
                    // mm, ss
                    // p (12:00 am)
                    // pp (12:00:00 am)
                    day: 'MMM dd',
                    hour: 'MMM dd HH:mm',
                },
                unit: dateFormat,
                tooltipFormat: getToolTipFormat(dateFormat),
            },
            stacked: (graphType==="stacked")
        }
        const xScaleLinearObj = {
            stacked: (graphType==="stacked")
        } // default

        const options =  {
            animation: true,
            parsing: {
                xAxisKey: 'x',
                yAxisKey: 'y'
            },
            scales: {
                x: looksLikeTimeXAxis?xScaleTimeObj:xScaleLinearObj,
                //offset: true,
                y: {
                    stacked: (graphType==="stacked")
                }
            }
        }

        return(
            <div>
                {graphType==='line' && <Line data={data} options={options}/>}
                {graphType==='bar' && <Bar data={data} options={options}/>}
                {graphType==='stacked' && <Bar data={data} options={options}/>}
            </div>
        );
    }
    //#endregion RESULT_TYPES

    function getHeaderTextAsCSV(sampleRow) {
        if(!sampleRow) {
            return null;
        }

        const headerRow = [];
        for(const [,value] of Object.entries(sampleRow)) {
            headerRow.push(value["name"]);
        }

        return <div className="font-weight-bold">{headerRow.join(",")}</div>

    }
    
    function findGraphVarValue(graphVerb:string, reportLines:[]) {
        const varLine = reportLines.filter( l => l.startsWith(graphVerb));
        let varValue;
        if(varLine[0]) {
            varValue = varLine[0].substring(graphVerb.length).trim(); // this is our dataset selector
        }
        return varValue;
    }
    
    function getToolTipFormat(dateFormat) {
        switch(dateFormat) {
            case 'day': return 'MMM dd';
            case 'week': return 'MMM dd';
            case 'month': return 'MMM y';
            case 'year': return 'y';
            default: return 'MMM dd HH:mm';
        }
    }
    
    function dumpDateRangePicker() {
        if(!hasDateColumnName(reportBasicInfo)) { // no settable date ranges
            return null;
        }
        
        return(
            <div className="row m-0 p-0">
                <div className="col">
                    <label className="li-form-label m-0" htmlFor="fromDate">From</label>
                    <DatePicker className="form-control p-0" name="fromDate" clearIcon={null} calendarType="US"
                                onChange={(e)=>handleDateChanged("fromDate",e)} value={fromDate??null} calendarIcon={<CalendarIcon/>}
                                required disabled={false}/>
                    <small className="form-text text-muted">* month/day/year</small>
                </div>
                <div className="col">
                    <label className="li-form-label m-0" htmlFor="toDate">To</label>
                    <DatePicker className="form-control p-0" name="toDate" clearIcon={null} calendarType="US"
                                onChange={(e)=>handleDateChanged("toDate",e)} value={toDate??null} calendarIcon={<CalendarIcon/>}
                                required disabled={false}/>
                    <small className="form-text text-muted">* month/day/year</small>
                </div>
            </div>
        );
    }
    
    function dumpDataFilter() {
        return (
            <div className="col">
                <div className="row m-0 p-0 align-self-center">
                    <TextInput refName={textFilterRef} name="textFilter" label="Text Filter" oneRow textLeft 
                               maxLength={20} onChange={handleDataFilterChanged}/>
                    <div className="align-self-center">    
                        <LIButton bgClass="ml-1 mt-3" faIcon="fa fa-times-circle" small onClick={handleClearFilterClicked}/>
                    </div>
                </div>
            </div>
        );
    }
    
    function getHeaderText(sampleRow) {
        if(!sampleRow) {
            return null;
        }

        var headerRow = [];
        for(const [key,value] of Object.entries(sampleRow)) {
            headerRow.push(<th key={key}>{value["name"]}</th>);
        }
        
        return <thead className="li-bg-primary li-fg-white"><tr>{headerRow}</tr></thead>
        
    }

    function dumpReportTypeFilter() {
        return(
            <div className="mt-4 mr-3">
                <span className="li-fg-muted">Show As: </span>&nbsp;
                <span className={showAs==="table"?"font-weight-bold":"btn-link li-pointer"} onClick={()=>setShowAs("table")}>table</span> |&nbsp;
                <span className={showAs==="csv"?"font-weight-bold":"btn-link li-pointer"} onClick={()=>setShowAs("csv")}>csv</span>
                {showGraphOption && 
                    <span> |&nbsp;
                        <span className={showAs==="graph"?"font-weight-bold":"btn-link li-pointer"} onClick={()=>setShowAs("graph")}>graph</span>
                    </span>}
            </div>
        );
    }
    
    function dumpEditReportIfRequired() {
        if(LIUtils.UserHasLevel(cookies,"ADMIN")) {
            return <span className="li-border-dark rounded p-2 align-self-center li-pointer" 
                         onClick={() => setRedirectTo("/admin/reports/"+Number(reportId))}><i className="fas fa-cog mr-1"/>edit report</span>
        }
        return null;
    }

    if(redirectTo) {
        return (
            <Redirect push to={redirectTo}/>
        );
    }
    
    return(
        <div className="container">
            <Header/>
            <div className="row justify-content-between li-header-row">
                <BackButton/>
                {dumpEditReportIfRequired()}
                {dumpReportTypeFilter()}
            </div>
            {dumpBasicInfo()}
            
            <div className="li-bg-blue-card p-2 my-2">
                {dumpDateRangePicker()}
                {dumpDataFilter()}
            </div>
            
            {dumpResults()}
            <Footer/>
        </div>
    );
}

export default RunReportPage;