import * as types from '../ACTIONS/actionTypes';
import initialState from './initialState';

export default function notificationReducer(state = initialState.notifications, action) {
    
    switch (action.type) {
        case types.GOT_NOTIFICATIONS: {
            const now = Date();
            const newState = {
                initialized: true, 
                numUnread: state.numUnread,  // from previous  ### fix.
                numNotifs: action.numNotifs,    // updated
                lastUpdated: now, 
                notifs: action.notifs.reverse()
            };
            return newState;
        }
        case types.GOT_NUM_NOTIFICATIONS: {
            const newState = {...state};
            newState.numUnread=action.numUnread;
            newState.numNotifs=action.numNotifs;
            return newState;
        }
        case types.GOT_NOTIFICATION_STATUS_UPDATE: {
            const notifId = action.notifId;
            const newStatus = action.status;
            const newState = {...state};
            const ourNotif = newState.notifs.find(item => item.id===notifId);
            ourNotif.status = newStatus;
            newState.numUnread--;  // ### dangerous here slighly -- 
            return newState;
        }
        case types.GOT_NOTIFICATION_DELETE: {
            const notifId = action.notifId;
            const newState = {...state};
            newState.notifs = newState.notifs.filter(item => item.id!==notifId);
            newState.numNotifs--;  // ### dangerous here slighly -- 
            return newState;
        }
        default:
            return(state)
    }
}

