import React, {useContext, useEffect, useState} from 'react';
import {Prompt, Redirect, useHistory, useParams} from "react-router-dom";
import Header from "../Header";
import BackButton from "../../GUI_COMMON/COMPONENTS/BackButton";
import TextInput from "../../GUI_COMMON/COMPONENTS/TextInput";
import LIButton from "../../GUI_COMMON/COMPONENTS/LIButton";
import Popup from "../../GUI_COMMON/COMPONENTS/Popup";
import ArchiverClientApi from "../../API/archiverClientApi";
import ErrorPopup from "../../GUI_COMMON/COMPONENTS/ErrorPopup";
import CheckInput from "../CheckInput";
import DigitInput from "../../GUI_COMMON/COMPONENTS/DigitInput";
import TextArea from "../../GUI_COMMON/COMPONENTS/TextArea";
import ArchiverGuiUtils from "../../SUPPORT/ArchiverGuiUtils";
import {StoreContext} from "../../store";
import {gotLocations} from "../../ACTIONS/clientActions";

const LocationsAdminPage = () => {

    const [,dispatch] = useContext(StoreContext);

    const [curLocationDetails, setCurLocationDetails] = useState(getDefaultLocationDetails());
    const [fullLocationsList, setFullLocationsList] = useState([]);
    
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const [redirectToPage, setRedirectToPage] = useState();
    
    const history = useHistory();
    const {locationId} = useParams();           // set in the url
    
    useEffect(() => {
        console.debug("refreshing locations list");
        getLocationsList();
    }, []); 
    
    useEffect( () => {
        if(fullLocationsList && fullLocationsList.length>0) {
            doRealListItemClicked(locationId?Number(locationId):null);  // preselect if the url has soemthign in it.
        }
    },[fullLocationsList]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    function getDefaultLocationDetails():{} {
        return(
            {
                id: null,
                name: "",
                address: "",
                internal: false,
                notes: "",
                dueBackDays: 14,
                isEnabled: true,
            }
        );
    }
    
    function getLocationsList() {
        setIsLoading(true);
        ArchiverClientApi.getLocations(false) // request ALL locations (even those that are disabled) 
        .then( (locations) => {
            setFullLocationsList(locations??[]);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsLoading(false);
        });
    }
    
    function handleCancelClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        confirmClearDirty();
    }
    
    function confirmClearDirty() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetLocationDetailsShown();
                });
        }
        else {
            resetLocationDetailsShown(); // just clear what's there
        }
    }
    
    function handleDeleteLocationClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        Popup.showConfirm("Delete Location?",`<p>This will remove '${curLocationDetails.name}' completely from the system,`+
                                " including all its departments.  Any users in those departments will remain in the system "+
                                " but will not be assignable in Retrieval Orders.</p>"+
                                "<p>Are you sure you want to do this?</p>","Yes, Delete it",null, 
            () => {
               doLocationRemove(curLocationDetails.name,curLocationDetails.id);
            });
    }
   
    function doLocationRemove(locationName, locationId) {
        setIsRemoving(true);
        ArchiverClientApi.sendLocationRemove(locationId)
        .then(() => {
            Popup.showInfo(`Location '${locationName}' removed`);
            removeFromFullLocationList(locationId);
            resetLocationDetailsShown();
        })
        .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally(() => {
            setIsRemoving(false);
        });
    }
    
    function handleSaveClicked(e,action) {
        e.preventDefault();
        e.stopPropagation();
        
        if(!checkForValid()) {
            return;
        }
       
        setIsSaving(true);
        ArchiverClientApi.sendLocationsUpdate(curLocationDetails)
        .then( (savedLocationInfo) => {
            Popup.showInfo(`Location '${curLocationDetails.name}' Saved`);
            setIsDirty(false);
            if(action==="ADD") {
                addToFullLocationList(savedLocationInfo);
                resetLocationDetailsShown();
            }
            else if(action==="UPDATE") {
                updateExistingLocation(savedLocationInfo);
            }
            // clear out the stored locations so ROs and stuff get the new list when they are re-visited
            dispatch(gotLocations([]));
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
           setIsSaving(false); 
        });
    }
    
    function checkForValid():boolean {
        let isValid=true;
        let notValidMsg="";
        if(!document.getElementsByName("name")[0].checkValidity()) {
            isValid=false;
            notValidMsg="Name needs to be non-blank";
        }
       
        if(!isValid) {
            ErrorPopup.showError("Validation Problem",notValidMsg);
            return false;
        }
        return true;

    }
    
    function addToFullLocationList(userDetails) {
        const curList = [...fullLocationsList];
        curList.push(userDetails);
        curList.sort( (a,b) => {
            if(a.fullName < b.fullName) return -1;
            if(a.fullName > b.fullName) return 1;
            return 0;
        });
        setFullLocationsList(curList);
    }

    function removeFromFullLocationList(userId) {
        const curList = fullLocationsList.filter( item => item.id !== userId);
        setFullLocationsList(curList);
    }
    
    function updateExistingLocation(locationDetails) {
        const curList = [...fullLocationsList];
        let thisUserIndex = curList.findIndex(item => item.id===locationDetails.id);
        curList[thisUserIndex]=locationDetails;
        setFullLocationsList(curList);
    }
    
    function onChange(e) {
        const thing = e.target.name;
        const editInfo = {...curLocationDetails};
        if(e.target.type==="checkbox") { // this is a checkbox
            editInfo[thing] = e.target.checked;
        }
        else {
            let value = e.target.value;
            if(thing==="dueBackDays") {  // number
                value = Number(value);
            }
            editInfo[thing] = value;
        }
        setCurLocationDetails(editInfo);
        setIsDirty(true);
    }
    
    function showLocationsList(listContents) {

        if(isLoading) {
            return <div className="pr-4 w-30 li-border-right-light">...loading...</div>;
        }
        
        let resultList = listContents.map((item,index) => {
            
                return <li key={index} className={"list-group-item li-pointer "+(item.isEnabled===false?"li-fg-muted":"")}
                    onClick={() => handleListItemClicked(item)}>{item.name}</li>;
            }
        );
        if(!resultList || resultList.length===0) {
            resultList = "no locations found";
        }

        return (
            <div className="pr-4 w-30 li-border-right-light">
                <h4>Locations</h4>
                <div className="li-scrollable-at-500 ">
                    <ul className="list-group-flush li-list-group-w-hover pl-0">
                        {resultList}
                    </ul>
                    <div className="li-border-line-light"/>
                </div>
                <LIButton small primary label="" bgClass={isRemoving?"m-3":"fa fa-trash m-3"} disabled={curLocationDetails?.id===null}
                          onClick={handleDeleteLocationClicked} showBusy={isRemoving}/>
            </div>
        );
    }
    
    function handleListItemClicked(item) {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetLocationDetailsShown();
                    doRealListItemClicked(item?.id);
                });
        }
        else {
            doRealListItemClicked(item?.id);
        }
    }
    
    function doRealListItemClicked(itemId) {
        const location = fullLocationsList.find(i => i.id===itemId);
        if(!location) {
            resetLocationDetailsShown();
        }
        else {
            setCurLocationDetails(location);
            updateUrlLink(`/admin/locations/${location.id}`);
        }
    }
    
    function updateUrlLink(toThis) {
        history.replace(toThis);
    }
    
    function resetLocationDetailsShown() {
        setCurLocationDetails(getDefaultLocationDetails());
        setIsDirty(false);
        updateUrlLink("/admin/locations"); // blank
    }
    
    function handleEditDepartmentsClicked() {
        const locationId = curLocationDetails.id;
        setRedirectToPage("/admin/depts/"+locationId);
    }
    
    function getDepartmentsLabel() {
        const numDepartments = curLocationDetails?.departments?.length??0;
        return(
            `Edit Departments (${numDepartments})`
        );
    }
    
    function handleBackClicked() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    history.goBack();
                });
        }
        else {
            history.goBack();
        }
        
    }
    
    if (redirectToPage) {
        return <Redirect to={redirectToPage}/>;
    }
    
    return (
        <div className="container">
            <Prompt
                when={isDirty}
                message={ () => "You have unsaved changes.  Really leave?"}
            />
            <Header hideAdmin/>
            <div className="li-header-row">
                <BackButton onClick={handleBackClicked}/>
                <h1 className="ml-4 d-inline-block align-middle">Location Administration</h1>
            </div>
            
            <div className="row">
                {/* LEFT SIDE USER LIST */}
                {showLocationsList(fullLocationsList)}

                {/* RIGHT SIDE FORM */}
                <div className="pl-4 w-60">
                    <div className="li-fg-slightly-muted li-font-small text-right">[Id:{curLocationDetails?.id??"-"}]</div>
                    <div className="text-right">
                        {/* right side, row #0 */}
                        <CheckInput label="Enabled" name="isEnabled" checked={curLocationDetails.isEnabled} onChanged={(e)=>onChange(e)}/>
                    </div>
                    <div className="row p-0 m-0 justify-content-between">
                        {/* right side, row #1 */}
                        <TextInput name="name" label="Name" onChange={(e)=>onChange(e)}
                                   placeholder="" textLeft
                                   value={curLocationDetails?.name??""}
                                   required/>
                        <CheckInput label="Internal" name="internal" checked={curLocationDetails.internal} onChanged={(e)=>onChange(e)}
                                                        helpLink="admin/locations_admin.html#internal"/>
                    </div>
                    { /* right side, row #2 */}
                    <TextInput name="address" label="Address" onChange={(e)=>onChange(e)}
                               placeholder="" textLeft
                               value={curLocationDetails?.address??""}
                               />
                    { /* right side, row #3 */}
                    <TextArea name="notes" label="Notes" value={curLocationDetails?.notes??""} readonly={false} 
                              onChange={(e)=>onChange(e)} rows={3}/>
                    
                    <DigitInput onChange={onChange} minLength={1} maxLength={4} oneRow
                                label="Sample Return Days" name="dueBackDays" value={curLocationDetails?.dueBackDays??14}
                                helpText="# of days samples are due back by when this location is used in a Retrieval Order"/>

                    {/* right side, row #4 */}
                    <div className="my-5 text-center">
                        <LIButton name="departments" label={getDepartmentsLabel()} bgClass="w-75" secondary 
                                  disabled={curLocationDetails?.id===null}
                                  onClick={handleEditDepartmentsClicked}/>
                    </div>
                    
                    {/* BUTTON ROW */}
                    <div className="text-center my-5">
                        <LIButton name="cancel" label={isDirty?"Cancel":"Clear"} bgClass="mr-4" primary onClick={handleCancelClicked}/>
                        <LIButton name="save" label={curLocationDetails?.id===null?"ADD":"UPDATE"} secondary disabled={!isDirty || isSaving} 
                                  onClick={(e)=>handleSaveClicked(e,curLocationDetails?.id===null?"ADD":"UPDATE")} showBusy={isSaving}/>
                    </div>
                </div>
            </div>

            
        </div>
    );
}

LocationsAdminPage.propTypes = {
   
};

export default LocationsAdminPage;