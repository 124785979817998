import React, {useEffect, useState} from "react"
import LIUtils, {SAMPLE_TYPE_BLOCK} from "../GUI_COMMON/SUPPORT/LIUtils";
import Header from "./Header";
import Footer from "./Footer";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import ArchiverClientApi from "../API/archiverClientApi";
import {Redirect, useLocation, useParams} from "react-router-dom";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import SampleCard from "./SampleCard";
import {useHistory} from "react-router-dom";
import ReloadButton from "../GUI_COMMON/COMPONENTS/ReloadButton";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const MagDetailsPage = () => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBSamples, setIsLoadingBSamples] = useState(false);
    const [redirectTo, setRedirectTo] = useState();
    const [sectionSamples, setSectionSamples] = useState([]);
    const [sectionBSamples, setSectionBSamples] = useState([]);       // used for block samples only
    const [showBlockFormation, setShowBlockFormation] = useState(false);
    
    const {magBC} = useParams();
    
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [showSection, setShowSection] = useState(params.get("section")??"A");
    
    const history = useHistory();
    
    useEffect(() => {
        // RECALL -- this runs AFTER all children mount!
        console.debug("ShowMagDetailsPage starting up...");
        window.scrollTo(0,0);
        LIUtils.setBackgroundColor('--li-main-bg-color');
        
        getMagDetails();
        
    }, [magBC,showSection]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    function handleSampleClicked(sampleItem) {
        const sampleId = sampleItem.id;
        setRedirectTo(`/sample_details/${sampleId}`);
    }
    
    function getMagDetails() {
        history.replace(`/mag_details/${magBC}?section=${showSection}`);
        setIsLoading(true);
        ArchiverClientApi.getMagDetailsGivenBarcode(magBC,showSection)
        .then(({magazine,samples}) => {
            setSectionSamples(samples);
            if(magazine?.holdsSampleType === SAMPLE_TYPE_BLOCK) {
                setShowBlockFormation(true);
                setIsLoadingBSamples(true);
                // get the B section too
                ArchiverClientApi.getMagDetailsGivenBarcode(magBC,"B")
                .then(({_,samples}) => {
                    setSectionBSamples(samples);
                })
                .finally( () => {
                   setIsLoadingBSamples(false);   // not loading b samples anymore
                });
            }
            else { // show single slide samples list
                setShowBlockFormation(false);
            }
            
        }) 
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsLoading(false);
        });
    }
    
    function showSamples(sectionASamples, optionalSectionBSamples) {
        if (isLoading) {
            return <LoadingSpinner medium center/>;
        }
        
        if (showBlockFormation) {
            return dumpBlockSection(sectionASamples, optionalSectionBSamples);
        }
        
        // just dump the slides out
        return dumpSlideSection(sectionASamples);
    }
    
    function dumpSlideSection(slideSamples) {
        if (!slideSamples || slideSamples.length === 0) {
            return <p className="mx-auto my-5 b-5 text-center">no samples found</p>;
        }

        const sampleList = slideSamples.map(item => <SampleCard key={item.id} sampleItem={item} showPosition
                                                            needsAttention={item.hasCaseAndSampleInfo===false} 
                                                            onClick={() => handleSampleClicked(item)}/>);
        return(
                    <div className="row mx-auto">
                        {sampleList}
                    </div>
        );
    }

    function dumpBlockSection(sectionASamples, optionalSectionBSamples) {
        
        let outList = [];
        for(let row=1; row<=25; row++) {
            const sLeftSide = sectionASamples?.find(item => item.positionOrder===row);
            if(sLeftSide) {
                outList.push(<div key={"A"+row} className="position-relative">
                    { generateRowNumberBadge(row) }
                                <SampleCard key={sLeftSide.id} sampleItem={sLeftSide} showPosition
                                         needsAttention={sLeftSide.hasCaseAndSampleInfo===false}
                                              onClick={() => handleSampleClicked(sLeftSide)}/>
                            </div>);
            }
            else { // empty
                outList.push(generateBlankForRow(row,0));
            }
            const sRightSide = optionalSectionBSamples?.find(item => item.positionOrder===row);
            if(sRightSide) {
                outList.push(<div key={"B"+row} className="position-relative">
                                <SampleCard key={2*sRightSide.id} sampleItem={sRightSide} showPosition
                                        needsAttention={sRightSide.hasCaseAndSampleInfo===false}
                                        onClick={() => handleSampleClicked(sRightSide)}/>
                            </div>);
            }
            else { // empty
                outList.push(generateBlankForRow(row,1));
            }
        }
        
        return(
            <>
                {isLoadingBSamples && <LoadingSpinner medium center/>}
                <div className="row justify-content-center">
                    {outList}
                </div>
            </>
        );
    }
    
    function generateRowNumberBadge(row:Number) {
        return <span className="position-absolute badge-pill li-bg-muted li-fg-white" style={{left:"-2.5em"}}>{row}</span> ;
    }
    function generateBlankForRow(row:Number,sectionIndex:Number) {
        return (
            <div key={"blank"+sectionIndex+row} className="position-relative">
                {sectionIndex===0 && generateRowNumberBadge(row)}
                <div className="card li-sample-card dark text-center">-</div>
            </div>
        );
    }
    
    function handleSectionClick(section) {
        setShowSection(section);
    }
    
    function dumpSectionRow() {
        let sections;
        if(showBlockFormation) {
            return null;
        }
        else {
            sections = [
                {name:"A",bg:"li-position-marker-section-a"},
                {name:"B",bg:"li-position-marker-section-b"},
                {name:"C",bg:"li-position-marker-section-c"},
                {name:"D",bg:"li-position-marker-section-d"}
                ];
        }
        
        const sectionList = sections.map( (i) => {
                    const bgClass=showSection===i.name?"li-border-dark-thick "+i.bg:"li-pointer "+i.bg;
                    return( 
                        <li key={i.name} className={"list-inline-item li-font-large badge "+bgClass} 
                                    onClick={()=>handleSectionClick(i.name)}>{i.name}</li>);
                        });
        return ( 
            <ul className="list-group-horizontal">
                {sectionList}
            </ul>
        );
    }
    
    if(redirectTo) {
        return (
            <Redirect push to={redirectTo}/>
        );
    }
    
    return (
        <div className="container">
            <Header/>
            <div className="row mt-3 li-header-row justify-content-between">
                <div>
                    <BackButton/>
                    <span className="li-font-huge">Magazine: </span>
                    <span className="li-font-large li-fg-primary">{magBC??"-"}</span>
                </div>
               <ReloadButton/>
            </div>
            <div className="row justify-content-around mt-5 mb-3">
                {dumpSectionRow()}
            </div>
            {showSamples(sectionSamples,sectionBSamples)}
            <Footer/>
        </div>
    );
}

export default MagDetailsPage;