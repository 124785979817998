import React, {useContext, useEffect, useState} from 'react';
import {Prompt, Redirect, useParams} from "react-router-dom";
import Header from "../Header";
import BackButton from "../../GUI_COMMON/COMPONENTS/BackButton";
import TextInput from "../../GUI_COMMON/COMPONENTS/TextInput";
import LIButton from "../../GUI_COMMON/COMPONENTS/LIButton";
import Popup from "../../GUI_COMMON/COMPONENTS/Popup";
import ArchiverClientApi from "../../API/archiverClientApi";
import ErrorPopup from "../../GUI_COMMON/COMPONENTS/ErrorPopup";
import DigitInput from "../../GUI_COMMON/COMPONENTS/DigitInput";
import {StoreContext} from "../../store";
import ValidationTester from "./ValidationTester";
import ArchiverGuiUtils from "../../SUPPORT/ArchiverGuiUtils";

const ProfilePRAdminPage = () => {

    /**
     * these are hardcoded into the archiver -- don't change
     */
    const PROCESSING_RULE_TYPES = [
        {type:0,name:"Text Match"},
        {type:1,name:"> Value"},
        {type:2,name:"< Value"},
        {type:3,name:"Equal to Value"},
    ];
    
    const [globalState, ] = useContext(StoreContext);
    
    const [curPRDetails, setCurPRDetails] = useState(getDefaultPRDetails());
    const [backupPRDetails, setBackupPRDetails] = useState(getDefaultPRDetails());
    const [allParentIRDetails, setAllParentIRDetails] = useState({});
    const [someParentProfileDetails, setSomeParentProfileDetails] = useState({});
    
    const [isDirty, setIsDirty] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [redirectToPage, setRedirectToPage] = useState();
    const [showValidationTester, setShowValidationTester] = useState(null);
    const [sampleValueList, setSampleValueList] = useState(
        {
            "main": "",
            "case": "",
            "sample": ""
        });
    
    const {profileId,irId} = useParams();           // set in the url
    
    useEffect(() => {
        console.debug("refreshing Profile, InspectionRegion Info");
        if (globalState.profiles && globalState.profiles.initialized) {
            setSomeOfOurParentProfileDetails(Number(profileId), globalState.profiles.items);
            const parentIRDetails = setOurParentIRDetails(Number(profileId), Number(irId), globalState.profiles.items);
            setCurPRDetails(parentIRDetails?.postProcessing);
            setBackupPRDetails(parentIRDetails?.postProcessing); // for the reset
            setIsDirty(false);
        }
    }, [globalState.profiles]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    function getDefaultPRDetails():{} {
        return(
            {
                id: null,
                name: "",
                validation: null,
                type: -1,
                caseExample: "",
                caseIndex: null,
                caseValidation: null,
                sampleExample: "",
                sampleIndex: null,
                sampleValidation: null,
            }
        );
    }
    
    function setOurParentIRDetails(profileId, irId, systemProfilesList:[]) {
        const ourProfile = systemProfilesList.find(item => item.id===profileId);
        if(!ourProfile) {
            return undefined;  // not found
        }
        const irsList = ourProfile.inspectionRegions;
        const parentIRDetails = irsList.find(item => item.id===irId); // undefined if not found
        setAllParentIRDetails(parentIRDetails);  
        return parentIRDetails;
    }
    
    function setSomeOfOurParentProfileDetails(profileId, systemProfilesList:[]) {
        const ourProfile = systemProfilesList.find(item => item.id===profileId);
        if(!ourProfile) {
            return undefined;  // not found
        }
        setSomeParentProfileDetails({id: ourProfile.id, name: ourProfile.name});
    }
    
    function handleCancelClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        confirmClearDirty();
    }
    
    function confirmClearDirty() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetPRDetailsShown();
                });
        }
        else {
            resetPRDetailsShown(); // just clear what's there
        }
    }
    
    function handleSaveClicked(e,action) {
        e.preventDefault();
        e.stopPropagation();
        
        if(!checkForValid()) {
            return;
        }
        
        //set our PR details into the IR
        allParentIRDetails.postProcessing=curPRDetails;
       
        setIsSaving(true);
        ArchiverClientApi.sendInspectionRegionUpdate(profileId,allParentIRDetails)
        .then( (savedProfileInfo) => {
            Popup.showInfo(`Rule '${curPRDetails.name}' Saved`);
                setIsDirty(false);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
           setIsSaving(false); 
        });
    }
    
    function checkForValid():boolean {
        let isValid=true;
        let notValidMsg="";
        if(!curPRDetails || curPRDetails.name==="") {
            isValid=false;
            notValidMsg="Name needs to be non-blank";
        }
       
        if(!isValid) {
            ErrorPopup.showError("Validation Problem",notValidMsg);
            return false;
        }
        return true;

    }
    
    function onChange(e) {
        const thing = e.target.name;
        const editInfo = {...curPRDetails};
        if(e.target.type==="checkbox") { // this is a checkbox
            editInfo[thing] = e.target.checked;
        }
        else {
            let value = e.target.value;
            
            // Digit only boxes
            if(thing==="caseIndex" || thing==="sampleIndex") {  // number
                value = Number(value);
            }
            
            // selection drop downs
            if(thing==="type") {
                value = Number(value);
            }
            editInfo[thing] = value;
        }
        setCurPRDetails(editInfo);
        setIsDirty(true);
    }
    
    function dumpProcessingMatchOptionsList() {
        return PROCESSING_RULE_TYPES.map(item => <option key={item.type} value={item.type}>{item.name}</option>);
    }
    
    function resetPRDetailsShown() {
        setCurPRDetails(backupPRDetails);
        setIsDirty(false);
    }
    
    function handleBackClicked() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    setRedirectToPage(`/admin/irs/${profileId}/${irId}`);
                });
        }
        else {
            setRedirectToPage(`/admin/irs/${profileId}/${irId}`);
        }
    }

    if (redirectToPage) {
        return <Redirect to={redirectToPage}/>;
    }

    function handleTestMainValidationClicked() {
        setShowValidationTester("main");
    }

    function handleTestCaseValidationClicked() {
        setShowValidationTester("case");
    }

    function handleTestSampleValidationClicked() {
        setShowValidationTester("sample");
    }
    
    function updateSampleValueFor(tester, newValue) {
        let curSampleValues = {...sampleValueList};
        curSampleValues[tester]=newValue;
        setSampleValueList(curSampleValues);
    }

    return (
        <div className="container">
            <ValidationTester showFor={showValidationTester} 
                              
                              regexList={{"main":curPRDetails?.validation??"","case":curPRDetails?.caseValidation??"","sample":curPRDetails.sampleValidation??""}} 
                              sampleValue={sampleValueList} caseIndexes={curPRDetails.caseIndex} sampleIndexes={curPRDetails.sampleIndex}
                              onSetSampleValue={(tester,newValue) => updateSampleValueFor(tester,newValue)} onClose={() => setShowValidationTester(null)} width="500px"/>

            <Prompt
                when={isDirty}
                message={ () => "You have unsaved changes.  Really leave?"}
            />
            <Header hideAdmin/>
            
            <div className="li-header-row">
                <div>
                    <BackButton onClick={handleBackClicked}/>
                    <h1 className="ml-4 d-inline-block align-middle">Processing Rules Administration</h1>
                </div>
            </div>
            <div className="li-font-medium row m-0 p-0">
                <div className="col-6 font-weight-bold">
                Profile: <span className="li-fg-primary">{someParentProfileDetails.name}</span>
                </div>
                <div className="col-6 font-weight-bold">
                Inspection Region: <span className="li-fg-primary">{allParentIRDetails.name}</span>
                </div>
            </div>
            <div className="li-border-line-light mt-4 mb-1"/>
            <div className="li-fg-slightly-muted li-font-small text-right">[Id:{curPRDetails?.id??"-"}]</div>

            {/* START OF FORM */} 
           
            {/*#region MAIN_VALIDATION*/}
            <div className="position-relative">

                <div className="row m-0 p-0">
                    <div className="col-6 align-self-center">
                    <TextInput name="name" label="Name" onChange={(e)=>onChange(e)}
                               placeholder="" textLeft minLength={30} value={curPRDetails?.name??""}
                               required helpText="* anything that helps you remember it (not used by the system)"/>
                    </div>
                    <div className="col-6 align-self-center">
                        <label htmlFor="type" className="li-form-label mr-3">Decode Method</label>
                        <select onChange={onChange} id="type" name="type" value={curPRDetails?.type??-1}
                                className="li-form-field no-width align-self-end li-border-dark" required={true}>
                            <option value={-1}>(select a method)</option>
                            {dumpProcessingMatchOptionsList()}
                        </select>
                        <small className="form-text text-muted">* type of text or value matching performed</small>
                    </div>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-8">
                        <TextInput name="validation" label="Main Barcode Validation" value={curPRDetails?.validation??""}
                                  onChange={(e)=>onChange(e)} textLeft helpLink="admin/ppr_admin.html#main_barcode_validation"
                                    helpText="* the regular expression or value that needs to match to have this profile selected"/>
                    </div>
                </div>
                <div className="row m-0 p-0">
                    <div className=" col-4">
                        <DigitInput onChange={onChange} label="Case Source Index" name="caseIndex" helpLink="admin/ppr_admin.html#case_source_index"
                                    value={curPRDetails?.caseIndex??0}
                                    helpText="* the index# of the () set that is used to auto fill the case#. Using several digits will append. Set to 0 to disable"/>
                    </div>
                    <div className=" col-4">
                        <DigitInput onChange={onChange} label="Sample Source Index" name="sampleIndex" helpLink="admin/ppr_admin.html#sample_source_index"
                                    value={curPRDetails?.sampleIndex??0}
                                    helpText="* the index# of the () set that can be used to auto fill the sample#. Using several digital will append. Set to 0 to disable"/>
                    </div>
                    <div className="col-4 align-self-center">
                        <LIButton label="test" bgClass="li-bg-warn" small onClick={handleTestMainValidationClicked}/>
                    </div>
                </div>
            </div>
            {/*#endregion MAIN_VALIDATION*/}
            
            <div className="li-border-line-light mt-4"/>

            {/*#region CASE_SECTION */}
            <div className="position-relative">
                <div className="row m-0 p-0">
                    <div className="col-5">
                        <TextInput name="caseValidation" label="Case Validation - applied during manual entry" value={curPRDetails?.caseValidation??""}
                                   onChange={(e)=>onChange(e)} textLeft
                                   helpText="* the regular expression that needs to match the case portion when editing in in Needs Attention"/>
                    </div>
                    <div className="col-1 align-self-center">
                        <LIButton label="test" bgClass="li-bg-warn" small onClick={handleTestCaseValidationClicked}/>
                    </div>
                    <div className="offset-1 col-5">
                        <TextInput name="caseExample" label="Example Hint Text" value={curPRDetails?.caseExample??""}
                                   onChange={(e)=>onChange(e)} textLeft
                                   helpText="* an couple of examples shown to the user in Needs Attention"/>
                    </div>
                </div>
            </div>
            {/*#endregion CASE_SECTION*/}

            {/*#region SAMPLE_SECTION */}
            <div className="position-relative">
                <div className="row m-0 p-0">
                    <div className="col-5">
                        <TextInput name="sampleValidation" label="Sample Validation - applied during manual entry" value={curPRDetails?.sampleValidation??""}
                                   onChange={(e)=>onChange(e)} textLeft
                                   helpText="* the regular expression that needs to match the sample portion when editing in in Needs Attention"/>
                    </div>
                    <div className="col-1 align-self-center">
                        <LIButton label="test" bgClass="li-bg-warn" small onClick={handleTestSampleValidationClicked}/>
                    </div>
                    <div className="offset-1 col-5">
                        <TextInput name="sampleExample" label="Example Hint Text" value={curPRDetails?.sampleExample??""}
                                   onChange={(e)=>onChange(e)} textLeft
                                   helpText="* an couple of examples shown to the user in Needs Attention"/>
                    </div>
                </div>
            </div>
            {/*#endregion SAMPLE_SECTION */}
            
            {/* BUTTON ROW */}
            <div className="text-center my-5">
                <LIButton name="cancel" label={isDirty?"Cancel":"Reset"} bgClass="mr-4" primary onClick={handleCancelClicked}/>
                <LIButton name="save" label="UPDATE" secondary disabled={!isDirty || isSaving} 
                          onClick={(e)=>handleSaveClicked(e,curPRDetails?.id===null?"ADD":"UPDATE")} showBusy={isSaving}/>
            </div>
        </div>
    );
}

ProfilePRAdminPage.propTypes = {
   
};

export default ProfilePRAdminPage;