import * as types from './actionTypes';

export function setClientInfo(userId, fullName, level) {
    return { type: types.SET_CLIENT_INFO, userId, fullName, level};
}

export function clearStoreOnLogout() {
    return({type: types.CLEAR_STATE_ON_LOGOUT});
}

//#region CASES

export function clearCaseHistory() {
    return({type: types.CLEAR_CASE_HISTORY});
}

export function gotCaseHistory(caseId, caseHistory) {
    return({type: types.GOT_CASE_HISTORY, caseId, caseHistory});
}

export function clearAllCaseInfo() {
    return({type: types.CLEAR_CASE_BASE_INFO});
}
export function gotCaseBasicInfo(caseId,caseItem) {
    return({type: types.SET_CASE_BASIC_INFO, caseId, caseItem});
}

//#endregion CASES

//#region SAMPLES

export function clearSampleHistory() {
    return({type: types.CLEAR_SAMPLE_HISTORY});
}

export function gotSampleHistory(sampleId, sampleHistory) {
    return({type: types.GOT_SAMPLE_HISTORY, sampleId, sampleHistory});
}

export function clearAllSampleInfo() {
    return({type: types.CLEAR_SAMPLE_BASE_INFO});
}
export function gotSampleBaseInfo(sampleItem) {
    return({type: types.SET_SAMPLE_BASIC_INFO, sampleItem});
}

export function gotNumNeedsAttention(numNeedsAttention) {
    return({type: types.GOT_NUM_NEEDS_ATTENTION, numNeedsAttention});
}

export function gotProfilesList(profiles) {
    return {type: types.GOT_PROFILES_LIST, profiles}
}

export function gotUnpickedRetrievalOrders(orders) {
    return {type: types.GOT_UNPICKED_RETRIEVAL_ORDERS, orders};
}

export function gotUnsentRetrievalOrders(orders) {
    return {type: types.GOT_UNSENT_RETRIEVAL_ORDERS, orders};
}

export function gotCompletedRetrievalOrders(orders) {
    return {type: types.GOT_COMPLETED_RETRIEVAL_ORDERS, orders};
}

export function gotSamplePickSheet(orders,samples) {
    return {type: types.GOT_SAMPLE_PICK_SHEET, orders, samples};
}

//#endregion SAMPLES

//#region CONTACT_AND_LOCATIONS

export function gotLocations(locations) {
    return {type: types.GOT_LOCATIONS_LIST, locations};
}

export function gotContactsForDepartment(contacts) {
    return {type:types.GOT_CONTACTS_FOR_DEPARTMENT,contacts};
}

//#endregion CONTACT_AND_LOCATIONS

//#region CHASE_LIST

export function gotChaseList(chaseItems,chaseListByLocation, totalItems) {
    return {type:types.GOT_CHASELIST, chaseItems,chaseListByLocation, totalItems};
}

export function setChaseItemChecked(chaseItem,isChecked) {
    return {type: types.SET_CHASE_ITEM_CHECKED, chaseItem, isChecked};
}

export function gotNumNeedsChasing(numNeedsChasing) {
    return({type: types.GOT_NUM_NEEDS_CHASING, numNeedsChasing});
}

//#endregion CHASE_LIST

//#region WATCH_LIST

export function gotWatchList(watchItems, totalItems) {
    return {type: types.GOT_WATCHLIST, watchItems, totalItems};
}
export function setWatchItemChecked(watchItem,isChecked) {
    return {type: types.SET_WATCH_ITEM_CHECKED, watchItem, isChecked};
}
export function clearWatchList() {
    return { type: types.CLEAR_WATCH_LIST };
}
export function gotNumWatches(numWatches) {
    return({type: types.GOT_NUM_WATCHES, numWatches});
}

//#endregion WATCH_LIST

//#region RETRIEVALS

export function gotNumRetrievals(numRetrievals) {
    return({type: types.GOT_NUM_RETRIEVALS, numRetrievals});
}
export function gotNumEvents(numEvents) {
    return({type: types.GOT_NUM_EVENTS, numEvents});
}

export function markedROAsSent(order) {
    return {type: types.MARKED_RO_AS_SENT, order};
}

//#endregion RETRIEVALS

//#region SERVER_INFO

export function gotServerInfo(name, version,demoMode, requiredDbVersion, dbVersion, localTimeUtc) {
    return { type: types.GOT_SERVER_INFO, name, version, demoMode, requiredDbVersion, dbVersion, localTimeUtc };
}

//#endregion SERVER_INFO

//#region PROFILES

export function gotAddNewProfile(profile) {
    return({type: types.ADD_NEW_PROFILE, profile});
}

export function gotRemoveProfile(profileId) {
    return({type: types.REMOVE_PROFILE, profileId});
}

export function gotUpdateProfile(profile) {
    return({type: types.UPDATE_PROFILE, profile});
}

export function gotRemoveIRFromProfile(profileId, irId) {
    return({type: types.REMOVE_IR_FROM_PROFILE, profileId, irId});
}


//#endregion PROFILES

//#region NOTIFICATIONS

export function gotNotifications(numNotifs,notifs) {
    return { type: types.GOT_NOTIFICATIONS, numNotifs, notifs};
}

export function gotNumNotifications(numNotifs, numUnread) {
    return { type: types.GOT_NUM_NOTIFICATIONS, numNotifs, numUnread};
}

export function gotNotificationStatusUpdate(notifId, status) {
    return { type: types.GOT_NOTIFICATION_STATUS_UPDATE, notifId, status };
}

export function gotNotificationDelete(notifId) {
    return { type: types.GOT_NOTIFICATION_DELETE, notifId };
}

//#endregion NOTIFICATIONS

//#region SEARCH_RESULTS

export function saveSearchResults(searchType:string, searchResults:Object, startIndex:Number, numToReturn:Number, searchTerm:string, totalNumResults:Number) {
    return { type: types.SAVE_SEARCH_RESULTS, searchType, searchResults, startIndex, numToReturn, searchTerm, totalNumResults };
}

export function removeSampleFromNASearchResults(sampleId) {
    return({type: types.REMOVE_NA_SAMPLE_FROM_SEARCH_RESULTS, sampleId});
}

//#endregion SEARCH_RESULTS

