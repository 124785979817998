import React, {useEffect, useState} from 'react';
import { renderToString } from 'react-dom/server';
import * as PropTypes from 'prop-types';
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import ArchiverClientApi from "../API/archiverClientApi";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import ProfileList from "../GUI_COMMON/COMPONENTS/ProfileList";
import LIButton from "../GUI_COMMON/COMPONENTS/LIButton";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const PrintLabelsForm = ({devices, profiles}) => {
    
    const ENTIRE_BOX_MAGIC:number=-1;  // special indicator to print an entire box of mag labels
    
    const [isPrinting, setIsPrinting] = useState(false);
    const [isLoadingRecentMag, setIsLoadingRecentMag] = useState(false);
    const [deviceInputClass, setDeviceInputClass] = useState("");
    const [deviceValue, setDeviceValue] = useState(-1);
    const [profileValue, setProfileValue] = useState(-1);
    const [showProfilesList, setShowProfilesList] = useState([]);
    const [numToPrintValue] = useState(ENTIRE_BOX_MAGIC);
    const [mostRecentMagBarcode, setMostRecentMagBarcode] = useState("-");

    useEffect( () => {
        setDeviceInputClass(LIUtils.getValidationClassGivenValue(deviceValue, true, false))
        updateMostRecentMagLabel();
    },[deviceValue]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        updateMostRecentMagLabel();
    },[profileValue]);  // eslint-disable-line react-hooks/exhaustive-deps
  
    function handleDeviceChanged(e) {
        const value = Number(e.target.value);
        setDeviceValue(value);
        setProfilesListGivenDevice(value);
    }
    
    function setProfilesListGivenDevice(deviceId) {
        const device = getDeviceGivenId(deviceId);
        if (!device) {
            setShowProfilesList([]); // none
            setProfileValue(0); // none
            return;
        }
        
        const matchingProfilesList = device["profiles"]?.filter(p => p.isMagProfile)??[];
        setShowProfilesList(matchingProfilesList);
        setProfileValue(0); // none
    }
    
    function getDeviceGivenId(deviceId):Object {
        return devices.find(d => d.id===deviceId);
    }
    
    function handleProfileChanged(profileId) {
        const value = Number(profileId);
        setProfileValue(value);
    }
    
    function updateMostRecentMagLabel() {
        if (!profileIsValid()) {
            setMostRecentMagBarcode("-");
            return;
        }
        setIsLoadingRecentMag(true);
        ArchiverClientApi.getMostRecentRegisteredMagForProfile(profileValue)
        .then(response => {
            setIsLoadingRecentMag(false);
            let mag = response?.barcode;
            let boxStr = "-";
            if (mag) {
                let [year, box, ] = mag.split(".");
                boxStr = year+"."+box;
            }
            setMostRecentMagBarcode(boxStr);
        })
        .catch((error:string) => {
            setIsLoadingRecentMag(false);
            setMostRecentMagBarcode("* " + error);
        }); // ignore
    }

    function dumpDeviceOptions(devices) {

        if(!devices || devices.length===0) {
            return <option disabled>(no devices available)</option>;
        }

        const optionList = devices.map(item => <option key={item.id} value={item.id}>{item.fullName}</option>);
        
        // this commented/removed -pott 230905 (BUGFIX: AR-76)
        // if(optionList.length===1) { // preselect this single option
        //     if(document.getElementById("deviceSelect")!=null) {
        //         document.getElementById("deviceSelect").value = devices[0].id;
        //     }
        // }
        // else {
            optionList.unshift(<option key="-1" value={-1}>(select a device)</option>);
        //}
        return optionList;
    }
    function deviceIsValid() {
        return deviceValue > 0;   
    }
    function profileIsValid() {
        return profileValue > 0;
    }
    function numToPrintIsValid() {
        return true;
        //return numToPrintValue > 0;
    }
    
    function dumpMostRecentMag() {
        return (
            <div className="mt-3">
                <span className="li-font-small mr-3">* Most recent box printed for this profile:</span>
                {isLoadingRecentMag?<LoadingSpinner small center/>:
                    <span className="badge li-bg-blue-card li-font-small">{mostRecentMagBarcode}</span>}
            </div>
        );
    }
    
    function formIsValid() {
        return deviceIsValid() && profileIsValid() && numToPrintIsValid();
    }
    
    function onSubmit() {
        setIsPrinting(true);

        ArchiverClientApi.sendPrintNewLabelsRequest(numToPrintValue,deviceValue,profileValue)
        .then( (mags:Array) => {
            const numRegistered = `${mags.length} barcode`+(mags.length>1?"s have":" has");
            const barcodesList = mags.map( item => <li>{item.barcode}</li>)
            const msg = `<p>A total of ${numRegistered} been registered.  Printing should be under way now.</p>`+
                `<ul>${renderToString(barcodesList)}</ul>`+
                "<p>*NOTE: it can take up to 30 seconds to print.</p>";
            
            Popup.showInfo(msg);
            updateMostRecentMagLabel(); // make sure the most recent mag barcode shows the new latest mag.
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsPrinting(false);
        });
        
    }
    
    return (
        <form id="printLabelsForm">
           
            <div className="card border-0 mt-4">
                <h2>New Box</h2>
                <p>Use this to print labels for new magazines that have not yet been assigned to any devices.<br/>
                    <strong>NOTE:</strong> a new <strong>box label</strong> will be automatically be printed for each new box.
                </p>
                
                <div className="row">
                    <div className="col-md-4">
                        <label className="li-form-label" htmlFor="deviceSelect">For Device</label>
                        <select onChange={handleDeviceChanged} id="deviceSelect" name="deviceSelect" 
                                value={deviceValue} className={deviceInputClass} required={true}
                                disabled={false}>
                            {dumpDeviceOptions(devices)}
                        </select>
                    </div>

                    <div className="offset-md-1 col-md-7 form-group">
                        <label className="li-form-label" htmlFor="profileSelect">For Profile</label>
                        <ProfileList profiles={showProfilesList} magOnly selectedId={profileValue} onRadioChanged={(profileId) => handleProfileChanged(profileId)}/>
                        {dumpMostRecentMag()}
                    </div>
                    
                </div>
                
                <div className="row justify-content-center">
                    <LIButton onClick={onSubmit} disabled={!formIsValid()} primary bgClass="my-4 px-5"
                              label="Print New Box Labels" large showBusy={isPrinting}/>
                </div>
            
            </div>
            
           
        </form>
    );
};

PrintLabelsForm.propTypes = {
    devices: PropTypes.array.isRequired,
    profiles: PropTypes.array.isRequired
};

export default PrintLabelsForm;