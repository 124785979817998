import React, {useEffect, useState} from 'react';
import {Prompt, Redirect, useHistory, useParams} from "react-router-dom";
import Header from "../Header";
import BackButton from "../../GUI_COMMON/COMPONENTS/BackButton";
import TextInput from "../../GUI_COMMON/COMPONENTS/TextInput";
import LIButton from "../../GUI_COMMON/COMPONENTS/LIButton";
import Popup from "../../GUI_COMMON/COMPONENTS/Popup";
import ArchiverClientApi from "../../API/archiverClientApi";
import ErrorPopup from "../../GUI_COMMON/COMPONENTS/ErrorPopup";
import TextArea from "../../GUI_COMMON/COMPONENTS/TextArea";
import LIUtils from "../../GUI_COMMON/SUPPORT/LIUtils";
import ArchiverGuiUtils from "../../SUPPORT/ArchiverGuiUtils";

const ReportAdminPage = () => {

    /**
     * these are hardcoded into the archiver -- don't change
     */
    //const CODE_TYPE_BUILTIN=0;
    const CODE_TYPE_SQL=1;
    // const CODE_TYPES = [
    //     {codeType:CODE_TYPE_BUILTIN,name:"Stats Code"},
    //     {codeType:CODE_TYPE_SQL,name:"SQL Code"}
    // ];
  
    const {reportId} = useParams();
    const history = useHistory();
    
    const [curReportDetails, setCurReportDetails] = useState(getDefaultReportDetails());
    const [fullReportList, setFullReportList] = useState([]);
    
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [redirectToPage, setRedirectToPage] = useState();
    
    useEffect(() => {
        console.debug("refreshing reports list");
        getReportList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        if(fullReportList && fullReportList.length>0 && !isDirty) {
            doRealListItemClicked(reportId?Number(reportId):null);  // preselect if the url has soemthign in it.
        }
    },[fullReportList]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    function getDefaultReportDetails():{} {
        return(
            {
                id: null,
                name: "",
                description:null,
                iconName: "fa-chart-bar",
                code: null,
                codeType: CODE_TYPE_SQL,        // only SQL types for now
                dateColumnName: null,
            }
        );
    }
    
    function getReportList() {
        setIsLoading(true);
        ArchiverClientApi.getReportsList() 
        .then( ({reports}) => {
            setFullReportList(reports);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsLoading(false);
        });
    }
    
    function handleCancelClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        confirmClearDirty();
    }
    
    function confirmClearDirty() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetReportDetailsShown();
                });
        }
        else {
            resetReportDetailsShown(); // just clear what's there
        }
    }
    
    function handleDeleteReportClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        Popup.showConfirm("Delete Report?",`<p>This will remove '${curReportDetails.name}' completely the system.</p>`+
                                "<p>Are you sure you want to do this?</p>","Yes, Delete it",null, 
            () => {
               doReportRemove(curReportDetails.name,curReportDetails.id);
            });
    }
   
    function doReportRemove(reportName, reportId) {
        setIsRemoving(true);
        ArchiverClientApi.sendReportRemove(reportId)
        .then(() => {
            Popup.showInfo(`Report '${reportName}' removed`);
            removeFromReportList(reportId);
            resetReportDetailsShown();
        })
        .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally(() => {
            setIsRemoving(false);
        });
    }
    
    function handleSaveClicked(e,action) {
        e.preventDefault();
        e.stopPropagation();
        
        if(!checkForValid()) {
            return;
        }
       
        setIsSaving(true);
        ArchiverClientApi.sendReportUpdate(curReportDetails)
        .then( (savedReportDetails) => {
            Popup.showInfo(`Report '${curReportDetails.name}' Saved`);
            if(action==="ADD") {
                addToFullReportList(savedReportDetails);
                resetReportDetailsShown();
            }
            else if(action==="UPDATE") {
                updateExistingReport(savedReportDetails);
                setIsDirty(false);
            }
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
           setIsSaving(false); 
        });
    }
    
    function checkForValid():boolean {
        let isValid=true;
        let notValidMsg="";
        //const typeSelect = document.getElementById("type");
        const reportMsg = isCodeValid(curReportDetails.code);
        if(!document.getElementsByName("name")[0].checkValidity()) {
            isValid=false;
            notValidMsg="Name needs to be non-blank";
        }
        else if(!LIUtils.existsAndNotEmpty(curReportDetails.description)) {
            isValid=false;
            notValidMsg="Description should not be blank";
        }
        else if(reportMsg) {
            isValid=false;
            notValidMsg = reportMsg;
        }
        
        // else if(typeSelect.value===-1 || typeSelect.value==="-1") {  // stupid here
        //     isValid=false;
        //     notValidMsg="Report type needs to be selected";
        // }
        
        if(!isValid) {
            ErrorPopup.showError("Validation Problem",notValidMsg);
            return false;
        }
        return true;

    }
    
    function isCodeValid(code:string) {
        if(!LIUtils.existsAndNotEmpty(code)) {
            return undefined; // fine.
        }
        // we assume SQL type reports only for now
        if(!code.startsWith("SQL:")) {
            return ("Report code needs to start with 'SQL:'");
        }
        if(code.indexOf("\nOUTVARS:")===-1) {
            return ("Report code second line needs to start with 'OUTVARS:'");
        }
        
        return undefined;  // ok
    }
    
    function addToFullReportList(reportDetails) {
        const curReportList = [...fullReportList];
        curReportList.push(reportDetails);
        curReportList.sort( (a,b) => {
            if(a.name < b.name) return -1;
            if(a.name > b.name) return 1;
            return 0;
        });
        setFullReportList(curReportList);
    }

    function removeFromReportList(reportId) {
        const curList = fullReportList.filter( item => item.id !== reportId);
        setFullReportList(curList);
    }
    
    function updateExistingReport(details) {
        const curList = [...fullReportList];
        let thisReportIndex = curList.findIndex(item => item.id===details.id);
        curList[thisReportIndex]=details;
        setFullReportList(curList);
    }
    
    function onChange(e) {
        const thing = e.target.name;
        const editInfo = {...curReportDetails};
        if(e.target.type==="checkbox") { // this is a checkbox
            editInfo[thing] = e.target.checked;
        }
        else {
            let value = e.target.value;
            // the device type selector has it's own names for fields - we remap
            if(thing==="codeType") { // value here is the id selected
                value = Number(value);
                if(value===editInfo.codeType) { // no change
                    return;
                }
                editInfo.codeType=value;
            }
            else {
                editInfo[thing] = value;
            }
        }
        setCurReportDetails(editInfo);
        setIsDirty(true);
    }
    
    function showReportList(listContents:[]) {

        if(isLoading) {
            return <div className="pr-4 w-30 li-border-right-light">...loading...</div>;
        }
        
        let resultList = listContents?.map((item,index) => {
                return <li key={index} className={"list-group-item li-pointer "}
                    onClick={() => handleListItemClicked(item)}>{item.name}</li>;
            }
        );
        if(!resultList || resultList.length===0) {
            resultList = "no reports found";
        }
        return (
            <div className="pr-4 w-30 li-border-right-light">
                <h4>Devices</h4>
                <div className="li-scrollable-at-500 ">
                    <ul className="list-group-flush li-list-group-w-hover pl-0">
                        {resultList}
                    </ul>
                    <div className="li-border-line-light"/>
                </div>
                <LIButton small primary label="" bgClass={isRemoving?"m-3":"fa fa-trash m-3"} disabled={curReportDetails?.id===null}
                          onClick={handleDeleteReportClicked} showBusy={isRemoving}/>
            </div>
        );
    }
    
    function handleListItemClicked(item) {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetReportDetailsShown();
                    doRealListItemClicked(item.id);
                });
        }
        else {
            doRealListItemClicked(item.id);
        }
    }
    
    function doRealListItemClicked(itemId) {
        const report = fullReportList.find(i => i.id===itemId);
        if(!report) {
            resetReportDetailsShown();
        }
        else {
            setCurReportDetails(report);
            updateUrlLink(`/admin/reports/${report.id}`);
        }
    }

    function updateUrlLink(toThis) {
        history.replace(toThis);
    }
    
    function resetReportDetailsShown() {
        setCurReportDetails(getDefaultReportDetails());
        setIsDirty(false);
        updateUrlLink("/admin/reports");
    }
    
    // function dumpReportTypeOptionsList() {
    //     return CODE_TYPES.map(item => <option key={item.codeType} value={item.codeType}>{item.name}</option>);
    // }
    
    function handleBackClicked() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    setRedirectToPage("/adminPage");
                });
        }
        else {
            history.goBack();
        }
    }
    
    function handleReportExport() {
        const reportCopy = {...curReportDetails};
        reportCopy.id=null;  // the id CANT go along for the ride when exporting.
        const reportJson = JSON.stringify(reportCopy,null,5);  // 5 spaces for readability
        
        /*
         * borrowed and modified from:
         *     https://stackoverflow.com/questions/32326973/file-write-operation-in-javascript
         */
        const blob = new Blob([reportJson], {type: 'application/json'});

        const dateParts = LIUtils.getParsedDate(new Date());
        const dateExtension = `${dateParts.year-2000}${dateParts.fullMonth}${dateParts.fullDate}`;
        const downloadFileName = curReportDetails.name.replace(/ /g, "_")+"-"+dateExtension;
        // hidden link
        const link = document.createElement("a");
        link.download = downloadFileName;
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        
        // activate
        link.click();
        
        // cleanup
        setTimeout(() => { // remove the link
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        }, 1000);
    }
    
    if (redirectToPage) {
        return <Redirect to={redirectToPage}/>;
    }
    
    function handleRunReport() {
        setRedirectToPage("/run_report/"+reportId);
    }
    
    return (
        <div className="container">
            <Prompt
                when={isDirty}
                message={ () => "You have unsaved changes.  Really leave?"}
            />
            <Header hideAdmin/>
            <div className="row justify-content-between li-header-row">
                <div>
                    <BackButton onClick={handleBackClicked}/>
                    <h1 className="ml-4 d-inline-block align-middle">Report Administration</h1>
                </div>
                <LIButton bgClass="mr-4" small name="exportBtn" faIcon="fas fa-download" primary disabled={isDirty||curReportDetails?.id===null}
                tooltip="Export Report" onClick={handleReportExport}/>
            </div>
            
            <div className="row">
                {/* LEFT SIDE LIST */}
                {showReportList(fullReportList)}

                {/* RIGHT SIDE FORM */}
                <div className="pl-4 w-60">
                    <div className="li-fg-slightly-muted li-font-small text-right">[Id:{curReportDetails?.id??"-"}]</div>
                    {/* right side, row #1 */}
                    <TextInput name="name" label="Name" onChange={(e)=>onChange(e)}
                               placeholder="" textLeft
                               value={curReportDetails?.name??""}
                               helpText="* the name shown on the report button"
                               required/>
                    
                    {/* right side, row #2 */}
                    {/*
                    <label htmlFor="type" className="li-form-label mr-3">Code Type</label>
                    <select onChange={onChange} id="type" name="type" value={curReportDetails.codeType??-1} 
                            className="li-form-field no-width align-self-end li-border-dark" required={true}>
                        <option value={-1}>(select a type)</option>
                        {dumpReportTypeOptionsList()}
                    </select>
                    <small className="form-text text-muted">* the type of code used in the Report Code edit widget below</small>
                    */}
                    
                    {/* right side, row #3 */}
                    <TextArea name="description" label="Description" onChange={(e)=>onChange(e)} placeholder=""  
                               value={curReportDetails?.description??""} textLeft rows={3}
                                helpText="* a short explaination shown to user at the top of the report"/>
                    { /* right side, row #4 */}
                    <div className="row m-0 p-0">
                        <div className="col p-0">
                            <TextInput name="iconName" label="Icon Name" value={curReportDetails?.iconName??""}
                                       onChange={(e)=>onChange(e)} oneRow textLeft
                                       helpText="* font-awesome icon name (optional)"/>
                        </div>
                        <div className="col">
                            <TextInput name="dateColumnName" label="Date Column Name" onChange={(e)=>onChange(e)} placeholder=""
                                       value={curReportDetails?.dateColumnName??""} oneRow textLeft
                                        helpText="* the database column name used to limit dates (not always possible)"/>
                        </div>
                    </div>
                    {/* right side, row #4 */}
                    <TextArea name="code" label="Report Code" onChange={(e)=>onChange(e)} 
                                placeholder="SQL:<select statement>;&#10;OUTVARS:<list of returned columns>&#10;[CONVERT:<colName>:<old>=<new>...]&#10;NO_UTC_CONVERT" rows={5}
                                value={curReportDetails?.code??""} spellCheck={false}
                                helpLink="admin/report_admin.html#sample_report"
                    helpText="* for an explanation of report different directives, use the help link above"/>
                    
                    {/* BUTTON ROW */}
                    <div className="text-center my-5">
                        <LIButton name="cancel" label={isDirty?"Cancel":"Clear"} bgClass="mr-4" primary onClick={handleCancelClicked}/>
                        <LIButton name="save" label={curReportDetails?.id===null?"ADD":"UPDATE"} secondary disabled={!isDirty || isSaving} 
                                  onClick={(e)=>handleSaveClicked(e,curReportDetails?.id===null?"ADD":"UPDATE")} showBusy={isSaving}/>
                        <span className={"btn-link ml-5 li-pointer"} hidden={isDirty||curReportDetails?.id===null} onClick={handleRunReport}>
                            <i className="fas fa-running mr-1"/>Run</span>

                    </div>
                </div>
            </div>

            
        </div>
    );
}

ReportAdminPage.propTypes = {
   
};

export default ReportAdminPage;