import React from "react";

const ReloadButton = () => {
    
    return (
        <span>
            <button className="btn ml-auto mr-3 mt-4" onClick={() => window.location.reload()}><i className="fa fa-redo"/></button>
        </span>
    );
}

export default ReloadButton;