import React, {useEffect, useRef, useState} from 'react';
import * as PropTypes from 'prop-types';
import $ from "jquery";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";

const SampleSearchInput = ({name, initialValue, label, busyFlag, onBlur, placeholder, onSearchClicked, hasFocus, helpText, centerText, small, left, right}) => {
    
    const [isSearchEmpty,setIsSearchEmpty] = useState(!initialValue);
    const [helpTextPosition, setHelpTextPosition] = useState(null);

    const searchBox = useRef(null); 
    
    const sizeClass = small?"li-font-small small":"li-font-large ";
    const leftClass = left?"left":"";
    const rightClass = right?"right":"";
    
    useEffect( () => {
        setupHelpTextPosition();
        window.addEventListener('resize', setupHelpTextPosition)
        return( () => {  // destructor
            window.removeEventListener('resize', setupHelpTextPosition);
        });
    },[]);       // eslint-disable-line react-hooks/exhaustive-deps

    function handleSearchKeyUp(event) {
        event.preventDefault();
        if (event.keyCode === 13) { // return
            $("#"+name+"_searchBTN").trigger("click");
        }
        if(getSearchTerm()!=="") {
            setIsSearchEmpty(false)
        }
        else {
            setIsSearchEmpty(true);
        }
    }

    function getSearchTerm() {
        const term = searchBox?.current.value;
        return(term);
    }

    function handlePreBlur() {
        if(onBlur) {
            onBlur(getSearchTerm()); // send up search term
        }
    }
    
    function showMagnifyOrBusy() {
        if(!busyFlag) {
            return <i className="fa fa-search"/>;
        }
        else {
            return <LoadingSpinner small/>;
        }
    }
    
    function showLabelIfRequired() {
        if(LIUtils.existsAndNotEmpty(label)) {
            return  <label htmlFor="searchInput" className="mr-2">{label}</label>;
        }
        else if (!leftClass) { // spacer - why?
            return <span className="w-25">&nbsp;</span>
        }
    }
    
    function setupHelpTextPosition() {
        if (!helpText) {
            return null;
        }

        // const el = searchBox.current
        // if (!el) {
        //     return null;
        // }
        // const left = el.getBoundingClientRect().left + "px";
        // const top = el.getBoundingClientRect().top + "px";

        setHelpTextPosition({top: "4.5em", left: "10em"});
    }

    function placeHelpText() {
        if(!helpTextPosition) {
            return null;
        }
        return (
            <div className="text-muted li-font-small" style={{position:"absolute",left:helpTextPosition.left,top:helpTextPosition.top}}>{helpText}</div>
        );
    }

    return (
        <div className={"d-flex li-search-field position-relative "+sizeClass+" "+leftClass+" "+rightClass}>
            {showLabelIfRequired()}
            <input ref={searchBox} type="text" className={centerText?"text-center":""} id="searchInput"
                   name={name}
                   placeholder={placeholder}
                   onKeyUp={handleSearchKeyUp}
                   aria-describedby="inputGroupPrepend"
                   defaultValue={initialValue}
                   autoComplete="off"
                   required onChange={handlePreBlur} 
                   onBlur={handlePreBlur} autoFocus={hasFocus}
                   />
            <div className="input-group-append">
                <button type="button" id={name+"_searchBTN"} disabled={isSearchEmpty} className="li-primary rounded px-3" onClick={() => onSearchClicked(getSearchTerm())}>
                    {showMagnifyOrBusy()}
                </button>
            </div>
            {placeHelpText()}
        </div>
    );
};

SampleSearchInput.propTypes = {
    name: PropTypes.string.isRequired,
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    onBlur: PropTypes.func,
    busyFlag: PropTypes.bool,
    onSearchClicked: PropTypes.func.isRequired,
    hasFocus: PropTypes.bool,
    label: PropTypes.string,
    helpText: PropTypes.string,
    centerText: PropTypes.bool,
    small: PropTypes.bool,
    left: PropTypes.bool
};

export default SampleSearchInput;