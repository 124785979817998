import React from 'react';
import * as PropTypes from "prop-types";

const LoadingSpinner = ({small, medium, center, left, right}) => {
    
    // I have no idea why this has to be repeated, but weird stuff goes on doing simple
    // substitution... -pott 220315
    
    if(left) {
        return(
        <div className="li-font-small d-inline-block ">
            {   small ? <i className={"my-2 d-block fa fa-spinner fa-pulse fa-lg fa-fw "+ (left?"mr-0":null)} aria-hidden/> :
                medium ? <i className={"my-2 d-block fa fa-spinner fa-pulse fa-2x fa-fw "+ (left?"mr-0":null)} aria-hidden/> :
                    <i className={"my-2 d-block fa fa-spinner fa-pulse fa-3x fa-fw "+ (left?"mr-0":null)} aria-hidden/>
            }
        </div>
        );
    }

    if(right) {
        return(
            <div className="li-font-small d-inline-block w-100">
                {   small ? <i className={"my-2 d-block fa fa-spinner fa-pulse fa-lg fa-fw "+ (right?"ml-auto":null)} aria-hidden/> :
                    medium ? <i className={"my-2 d-block fa fa-spinner fa-pulse fa-2x fa-fw "+ (right?"ml-auto":null)} aria-hidden/> :
                        <i className={"my-2 d-block fa fa-spinner fa-pulse fa-3x fa-fw "+ (right?"ml-auto":null)} aria-hidden/>
                }
            </div>
        );
    }
    
    return (
        <div className={"li-font-small d-inline-block "+center?"text-center":null}>
            {   small ? <i className={"my-2 d-block fa fa-spinner fa-pulse fa-lg fa-fw "+ (center?"mx-auto":null)} aria-hidden/> :
                medium ? <i className={"my-2 d-block fa fa-spinner fa-pulse fa-2x fa-fw "+ (center?"mx-auto":null)} aria-hidden/> :
                    <i className={"my-2 d-block fa fa-spinner fa-pulse fa-3x fa-fw "+ (center?"mx-auto":null)} aria-hidden/>
            }
        </div>
    );
};

LoadingSpinner.propTypes = {
    small: PropTypes.bool,
    medium: PropTypes.bool,
    large: PropTypes.bool,
    center: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
};

export default LoadingSpinner;