import {useContext, useEffect, useState} from "react";
import ArchiverClientApi from "../API/archiverClientApi";
import {gotNumNotifications} from "../ACTIONS/clientActions";
import {StoreContext} from "../store";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import {useCookies} from "react-cookie";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import {checkAndReportWwwAppUpdate} from "../SUPPORT/update";

const NotifyTimer = () => {

    const REFRESH_TIMEOUT_MSECS = 60000;        // get new notification numbers this often

    const [globalState, dispatch] = useContext(StoreContext);
    const [refreshTimer, setRefreshTimer] = useState();
    const [cookies,] = useCookies();
    
    useEffect(() => {
        if (globalState.clientInfo.userId!==-1) {
            if (!refreshTimer && LIUtils.isAuthenticatedHook(cookies) && globalState.notifications.initialized !== true) {
                getNumNotifications();
                startNotifRefreshTimer();
            }
        }
        else { // user logged out
            stopNotifRefreshTimer();
        }
    }, [globalState.clientInfo.userId,globalState.notifications.initialized]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    function getNumNotifications() {
        ArchiverClientApi.getNumNotifications().then(({numNotifs,numUnread}) => {
            dispatch(gotNumNotifications(numNotifs,numUnread)); // <#>
        })
        .catch(responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError));
        
        // also check for app update
        checkAndReportWwwAppUpdate();
    }
    
    function startNotifRefreshTimer() {
        if(!refreshTimer) {
            setRefreshTimer(setInterval(getNumNotifications, REFRESH_TIMEOUT_MSECS));
        }
    }
    
    function stopNotifRefreshTimer() {
        if(refreshTimer) {
            clearInterval(refreshTimer);
            setRefreshTimer(null);
        }
    }
    
    return null;
}

NotifyTimer.propTypes = {
    
}

export default NotifyTimer;