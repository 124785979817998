import React, {useContext, useEffect, useState} from "react";
import ArchiverClientApi from "../API/archiverClientApi";
import Header from "./Header";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import {Redirect, useParams} from "react-router-dom";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import Footer from "./Footer";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import LoadingSpinner from "../GUI_COMMON/COMPONENTS/LoadingSpinner";
import {StoreContext} from "../store";
import {markedROAsSent} from "../ACTIONS/clientActions";
import ButtonWithBusy from "../GUI_COMMON/COMPONENTS/ButtonWithBusy";
import GuiUtils from "../GUI_COMMON/SUPPORT/GuiUtils";
import {useCookies} from "react-cookie";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";

const RetrievalSamplesPage = () => {

    const [,dispatch] = useContext(StoreContext);
    const [isLoading, setIsLoading] = useState(false);
    const [redirectToPush, setRedirectToPush] = useState();
    const [orderSamples, setOrderSamples] = useState([]);
    const [orderDate,setOrderDate] = useState();
    const [orderDestination, setOrderDestination] = useState("");
    const [isMarkingSent, setIsMarkingSent] = useState(false);

    const {orderId} = useParams();
    const [cookies,] = useCookies();

    useEffect(() => {
        console.debug("RetrievalsSamplesPage starting up...");
        if(orderId && orderId!=="") {
            updateSamplesForOrder(orderId);
        }
    }, [orderId]); // eslint-disable-line react-hooks/exhaustive-deps
    
    function handlePrintPage() {
        window.print();
    }
    
    function updateSamplesForOrder(thisOrderId) {
        setIsLoading(true);
        ArchiverClientApi.getRetrievalOrderGivenId(thisOrderId)
        .then(({order,samples}) => {
            setOrderSamples(samples);
            const orderDate = LIUtils.dateFromJsonUtcDateStr(order.dateStarted);
            setOrderDate(LIUtils.dateToShortDateTimeString(orderDate));
            setOrderDestination(GuiUtils.BuildPrettyLocation(order?.destinationLocation,
                order?.destinationDepartment,
                order?.destinationContact));
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsLoading(false));
    }

    function handleMarkSent(orderId) {
        if(!LIUtils.UserHasLevel(cookies,"SENDER")) {
            Popup.show("","<p>You are not a SENDER type user.</p>" +
                "Talk to your Archiver Administrator if you wish to change this.");
            return;
        }
        setIsMarkingSent(true);
        ArchiverClientApi.markRetrievalAsSent(orderId)
        .then((order) => {
            dispatch(markedROAsSent(order));
            Popup.show("",`Retrieval Order ${LIUtils.prettyOrderNumber(orderId)} has been marked as sent`, () => {
                setRedirectToPush("/retrievals");
            });
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsMarkingSent(false));
    }
    
    function dumpSamples() {
        if(isLoading) {
            return <LoadingSpinner medium center/>;
        }
        
        if (!orderSamples || orderSamples.length === 0) {
            return <div className="card text-center">none</div>;
        }

        const resultRows = orderSamples.map((item, index) => {
                return (
                    <li className="list-group-item li-font-small p-1" key={item.id}>
                        <span className="li-fg-muted mr-2">
                            {index+1}. 
                            ({LIUtils.convertSampleTypeToStr(item.sampleType)})
                        </span>
                        {GuiUtils.BuildSampleDetailsStr(item.barcode,item.caseNumber,item.sampleNumber)}       
                    </li>
                );
            }
        );

        return (
            <ol className="list-group li-font-medium">
                {resultRows}
            </ol>
        );
    }
    
    if (redirectToPush) {
        return <Redirect push to={redirectToPush}/>;
    }
    return (
        <div className="container">
            <Header/>
            <div className="row li-header-row m-0 p-0">
                <BackButton/>
                <div>
                    <h1 className="d-inline-block align-middle">Retrieval Order Samples</h1>
                    <div className="text-right"><i>Order #:</i> {LIUtils.prettyOrderNumber(orderId)}</div>
                </div>
            </div>

            <div className="my-3">
                {/* INFO BOX */}
                <h4>Order Started: <span className="li-fg-muted">{orderDate}</span></h4>
                <h3>Destination: <span className="li-fg-muted">{orderDestination}</span></h3>
            </div>
            
            <div className="my-3">
                {dumpSamples()}
            </div>
            
            <div className="row justify-content-around">
                <ButtonWithBusy id="markSentBTN" addOnStyling="" 
                                isBusy={isMarkingSent} onClick={() => handleMarkSent(orderId)} label="Mark Sent"/>
                <button className="li-button li-primary" onClick={handlePrintPage}>Print</button>
            </div>
            
            <Footer/>
        </div>
    );
}

RetrievalSamplesPage.propTypes = {
    
}

export default RetrievalSamplesPage;