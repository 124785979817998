import React, {useContext, useEffect, useState} from "react"
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import Header from "./Header";
import Footer from "./Footer";
import BackButton from "../GUI_COMMON/COMPONENTS/BackButton";
import SampleSearchInput from "./SampleSearchInput";
import ArchiverClientApi from "../API/archiverClientApi";
import SampleCard from "./SampleCard";
import {Redirect, useHistory, useLocation, useParams} from "react-router-dom";
import BackNextWidget from "../GUI_COMMON/COMPONENTS/BackNextWidget";
import SearchButtonList from "./SearchButtonList";
import ArchiverGuiUtils from "../SUPPORT/ArchiverGuiUtils";
import {StoreContext} from "../store";
import {saveSearchResults} from "../ACTIONS/clientActions";

const BARCODES_PAGE_SIZE=50;

const SearchBarcodesPage = () => {

    const [globalState, dispatch] = useContext(StoreContext);

    const [isSearching,setIsSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [redirectTo, setRedirectTo] = useState();
    
    const history = useHistory();
    const {searchTerm} = useParams();
    const [term,setTerm] = useState(searchTerm??"");
    const [isIniting, setIsIniting] = useState(true);
    
    const location = useLocation();
    const [pageSize,] = useState(BARCODES_PAGE_SIZE);
    const params = new URLSearchParams(location.search);
    const includeDel = params.get('id')==="true";
    const [includeDeleted, setIncludeDeleted] = useState(includeDel??false);
    const [startIndex, setStartIndex]=useState(Number(params.get('si')??0));
    const [sampleType, ] = useState(params.get('t')??"");
    
    useEffect(() => {
        console.debug("SearchBarcodesPage starting up...");
        window.scrollTo(0,0);
        LIUtils.setBackgroundColor('--li-main-bg-color');

        setIsIniting(false);
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        if(!isIniting) {
            if(searchResultsHaveNotChanged()) {
                setSearchResults(globalState.searchResults.searchResults);
            }
            else { // go to the API
                handleBackNext(startIndex, pageSize);
            }
        }
    }, [term,includeDeleted,isIniting]); // eslint-disable-line react-hooks/exhaustive-deps
    
    function buildSearchTypeKey():string {
        return "barcode_search_" + sampleType + "_" + includeDeleted;
    }

    function searchResultsHaveNotChanged() {
        const searchTypeKey = buildSearchTypeKey();
        var gSearchResults = globalState.searchResults;

        return gSearchResults.searchType === searchTypeKey &&
            gSearchResults.startIndex === startIndex &&
            gSearchResults.numToReturn === pageSize &&
            gSearchResults.searchResults !== undefined &&
            gSearchResults.searchTerm === term;
    }
    
    function handleBarcodeSearchClicked(newTerm) {
        if(newTerm===term) {
            console.debug("ignoring search request -- term didn't change");
            return;
        }
        setStartIndex(0);
        setTerm(newTerm);
    }
    
    function handleSampleClicked(sampleItem) {

        replaceBrowserHistory(term,startIndex,BARCODES_PAGE_SIZE,includeDeleted,sampleType);
        setRedirectTo(`/sample_details/${sampleItem.id}`);
    }
    
    function showResultsTable() {
        if(!term || term==="") {
            return <div className="text-center mt-5"/>;
        }
        if(isSearching) {
            return <div className="text-center mt-5">...searching...</div>;
        }
        if(!searchResults || searchResults.length===0) {
            return <div className="badge-pill mt-5 p-3 li-bg-card">no matches found</div>;
        }
        if(searchResults.length%2!==0) { // need an extra one to keep the last left sample to the left (hack)
            searchResults.push(null); // final one
        }
        
        const resultList = searchResults.map( (item,index) => 
                    <SampleCard key={index} sampleItem={item}
                                showPosition needsAttention={item?.hasCaseAndSampleInfo===false}
                                onClick={() => handleSampleClicked(item)}/>
            );
        
        return(
                <div className="row justify-content-center">
                    {resultList}
                </div>
        );
    }
    
    function haveResults() {
        return !isSearching && ((searchResults && searchResults.length>0) || startIndex>0);
    }
    
    function handleBackNext(si, numToReturn) {

        console.debug(`*** handling backnext (${si}:${numToReturn}) ***`);
        setStartIndex(si);
        replaceBrowserHistory(term,si,numToReturn,includeDeleted,sampleType);
        if (!term || term === "") {
            return;
        }
        setIsSearching(true);
        setSearchResults([]);
        ArchiverClientApi.sendSampleSearchRequest(term,si,numToReturn, includeDeleted, sampleType, true)
        .then( (results) => {
            setSearchResults(results);
            dispatch(saveSearchResults(buildSearchTypeKey(),results,si, numToReturn, term)); // save for possible later use
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => setIsSearching(false));
    }
    
    function replaceBrowserHistory(searchTerm,startIndex,numToReturn,includeDeleted,sampleType) {
        history.replace(`/barcode_search/${searchTerm}?si=${startIndex}&nr=${numToReturn}&id=${includeDeleted}&t=${sampleType}`);
    }

    function handleCBChanged(e) {
        e.stopPropagation();
        setIncludeDeleted(e.target.checked);
        setStartIndex(0);
    }

    function handleCBClicked(e) {
        e.stopPropagation(e);
    }
    
    if(redirectTo) {
        return (
            <Redirect push to={redirectTo}/>
        );
    }
    
    return (
        <div className="container">
            <Header/>
            <div className="row mt-3 li-header-row justify-content-between">
                <div>
                    <BackButton onClick={() => setRedirectTo("/dashboard")}/><span className="li-font-huge">Search</span>
                </div>
                <SearchButtonList activeChoice="barcodes"/>

            </div>
           
            <div className="position-relative mt-5">
                <div className="position-absolute" style={{right:"0em",top:"-3em"}}>
                    <input type="checkbox" className=" medium align-self-center" name="include-deleted-cb" aria-label="Checkbox"
                           checked={includeDeleted} onChange={(e)=>handleCBChanged(e)} onClick={(e)=>handleCBClicked(e)}/>
                    <label className="li-form-label medium ml-2" htmlFor="include-deleted-cb">include deleted</label>
                </div>
            </div>

                <div className="pt-4 ml-auto mr-auto">
                    <SampleSearchInput name="barcodeSearch" label="Barcode:" onSearchClicked={handleBarcodeSearchClicked} busyFlag={isSearching} 
                                       initialValue={term} placeholder="" hasFocus 
                                       helpText="* be sure to include any special characters including spaces"/>
                </div>
            
            <div className="mt-3 mb-4">
                <BackNextWidget disableNext={searchResults.length < pageSize} show={haveResults()} pageSize={pageSize}
                                startIndex={startIndex} onClick={handleBackNext}/>
                <div className="li-search-results-table mt-2">
                    {showResultsTable()}
                </div>
            </div>

            <BackNextWidget disableNext={searchResults.length < pageSize} show={haveResults()} pageSize={pageSize} 
                            startIndex={startIndex} onClick={handleBackNext}/>
            <Footer/>
        </div>
    );
}

export default SearchBarcodesPage;