import React, {useContext, useEffect, useState} from 'react';
import {Prompt, Redirect, useParams} from "react-router-dom";
import Header from "../Header";
import BackButton from "../../GUI_COMMON/COMPONENTS/BackButton";
import LIButton from "../../GUI_COMMON/COMPONENTS/LIButton";
import Popup from "../../GUI_COMMON/COMPONENTS/Popup";
import ArchiverClientApi from "../../API/archiverClientApi";
import {StoreContext} from "../../store";
import LIUtils from "../../GUI_COMMON/SUPPORT/LIUtils";
import ArchiverGuiUtils from "../../SUPPORT/ArchiverGuiUtils";

const SetDeviceProfilesAdminPage = () => {

    const {deviceId} = useParams();
    const [globalState, ] = useContext(StoreContext);
    
    const [fullProfilesList, setFullProfilesList] = useState([]);
    const [deviceProfilesList, setDeviceProfilesList] = useState([]);
    const [someDeviceDetails, setSomeDeviceDetails] = useState({id:null,fullName:""});
    
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [redirectTo, setRedirectTo] = useState();
    
    useEffect(() => {
        console.debug("refreshing device's profiles list");
        getDeviceProfilesList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect( () => {
        if(globalState.profiles && globalState.profiles.initialized) {
            setFullProfilesList(checkOffWhatDeviceHas(getCleanCopyOfProfiles())); // copy 2
        }
    },[globalState.profiles,deviceProfilesList]); // eslint-disable-line react-hooks/exhaustive-deps
    
    function getCleanCopyOfProfiles() {
        return LIUtils.deepCopyUsingStringify(globalState.profiles.items)
    }
    
    function checkOffWhatDeviceHas(allProfiles:[]) {
        allProfiles?.forEach((item) => {
            item.isChecked = (deviceProfilesList.find(p => p.id === item.id)!==undefined);
        })
        return allProfiles;
    }
    
    function getDeviceProfilesList() {
        setIsLoading(true);
        ArchiverClientApi.getProfilesForDevice(deviceId) 
        .then( (deviceInfo) => {
            setSomeDeviceDetails({id:deviceInfo.id, fullName:deviceInfo.fullName});
            setDeviceProfilesList(deviceInfo.profiles??[]);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
            setIsLoading(false);
        });
    }
    
    function handleCancelClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        confirmClearDirty();
    }
    
    function confirmClearDirty() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    resetCheckedItems();
                });
        }
    }
    
    function handleSaveClicked(e,action) {
        e.preventDefault();
        e.stopPropagation();
        
        const selectedProfiles=getSelectedProfileIds();
        
        setIsSaving(true);
        ArchiverClientApi.sendProfileUpdateForDevice(deviceId,selectedProfiles)
        .then( (savedDeptDetails) => {
            Popup.showInfo(`Profiles Saved`);
            setIsDirty(false);
        })
        .catch( responseError => ArchiverGuiUtils.ShowErrorIfNotUnauthourized(responseError))
        .finally( () => {
           setIsSaving(false); 
        });
    }
    
    function getSelectedProfileIds():[] {
        const checkedList = fullProfilesList?.filter((item) => item.isChecked);
        return checkedList.map(item => item.id);
    }
    
    function handleCBChanged(e,profileId) {
        e.stopPropagation();
        //const target = e.target.checked;
        
        const all = [...fullProfilesList];
        const p = all.find(i => i.id===profileId);
        if(p) {
            p.isChecked=e.target.checked;
        }
        setFullProfilesList(all);
        setIsDirty(true);
    }

    function handleCBClicked(e) {
        e.stopPropagation(e);
    }
    
    function showMagProfilesList() {

        if(isLoading) {
            return <div className="text-center mt-5">...loading...</div>;
        }
        
        let magProfileList = fullProfilesList?.filter(item=>item.type!==1);
        let resultList = magProfileList?.map((item,index) => {
                let extraClass = item.enabled?"":" li-fg-muted";
                return <li key={index} className={"list-group-item li-pointer "+extraClass}>
                            <input type="checkbox" className="mr-3" checked={item.isChecked}
                                   onChange={(e)=>handleCBChanged(e,item.id)} 
                                   onClick={handleCBClicked} value={index}/>
                            {item.name}<br/>
                    <div className="text-right li-font-tiny">
                        <span>Holds: {LIUtils.convertSampleTypeToStr(item.holdsSampleType)}</span><br/>
                        <span className="ml-5">Comment: {item.comment}</span>
                    </div>
                </li>;
            }
        );
        if(!resultList || resultList.length===0) {
            resultList = "no mag profiles found";
        }
        return (
            <div className="">
                <h4>Magazine Profiles</h4>
                <div className="li-scrollable-at-500 ">
                    <ul className="list-group-flush li-list-group-w-hover px-5">
                        {resultList}
                    </ul>
                    <div className="li-border-line-light"/>
                </div>
            </div>
        );
    }

    function showSampleProfilesList() {
        if (isLoading) {
            return <div className="text-center mt-5">...loading...</div>;
        }

        let sampleProfileList = fullProfilesList?.filter(item => item.type !== 0); // samples only 
        let resultList = sampleProfileList?.map((item, index) => {
                let extraClass = item.enabled ? "" : " li-fg-muted";
                return <li key={index} className={"list-group-item li-pointer " + extraClass}>
                    <input type="checkbox" className="mr-3" checked={item.isChecked}
                           onChange={(e) => handleCBChanged(e, item.id)}
                           onClick={handleCBClicked} value={index}/>
                    {item.name}<br/>
                    <div className="text-right li-font-tiny">
                        <span>Unique Barcodes? {item.barcodesAreUnique.toString()}</span><br/>
                        <span>Case Mapped External? {item.isCaseMappedExternal.toString()}</span><br/>
                        <span className="ml-5">Comment: {item.comment}</span>
                    </div>
                </li>;
            }
        );
        if (!resultList || resultList.length === 0) {
            resultList = "no sample profiles found";
        }
        return (
            <div className="">
                <h4>Sample Profiles</h4>
                <div className="li-scrollable-at-500 ">
                    <ul className="list-group-flush li-list-group-w-hover px-5">
                        {resultList}
                    </ul>
                    <div className="li-border-line-light"/>
                </div>
            </div>
        );
    }
    
    function resetCheckedItems() {
        setFullProfilesList(getCleanCopyOfProfiles()); // back to defaults
        setIsDirty(false);
    }
    
    function handleBackButtonClicked() {
        if(isDirty) {
            Popup.showConfirm("Cancel?","You have change some information.","Forget changes",null,
                () => {
                    setRedirectTo(`/admin/devices/${deviceId}`);
                });
        }
        else {
            setRedirectTo(`/admin/devices/${deviceId}`);
        }
    }
    
    if (redirectTo) {
        return <Redirect to={redirectTo}/>;
    }
    
    return (
        <div className="container">
            <Prompt
                when={isDirty}
                message={ () => "You have unsaved changes.  Really leave?"}
            />
            <Header hideAdmin/>
            <div className="row m-0 p-0 li-header-row justify-content-between">
                <div>
                    <BackButton onClick={handleBackButtonClicked}/>
                    <h1 className="ml-4 d-inline-block align-middle">Set Device Profiles</h1>
                </div>
                <div className="li-font-large li-fg-slightly-muted mr-3 align-self-center">
                    {someDeviceDetails.fullName}
                </div>
            </div>
            
            {showMagProfilesList()}

            <div className="li-border-line-light mb-4"/>

            {showSampleProfilesList()}

            {/* BUTTON ROW */}
                <div className="text-center my-5">
                    <LIButton name="cancel" label={isDirty?"Cancel":"Clear"} bgClass="mr-4" primary onClick={handleCancelClicked}/>
                    <LIButton name="save" label="UPDATE" secondary disabled={!isDirty || isSaving} 
                              onClick={(e)=>handleSaveClicked(e)} showBusy={isSaving}/>
                </div>
        </div>
    );
}

SetDeviceProfilesAdminPage.propTypes = {
   
};

export default SetDeviceProfilesAdminPage;