import React from 'react';
import PropTypes from 'prop-types';
import HelpLink from "./HelpLink";

const TextInput = ({name, refName, label, onChange, placeholder, value, helpText, helpLink,
                       required, goodNess, autoComplete, 
                       minLength, maxLength, focus, readOnly, widthPels, textLeft, oneRow}) => {
    let inputClass = 'li-form-field border-dark';
    if(oneRow) {
        inputClass += ' remove-w-100';
    }
    if(textLeft) {
        inputClass += ' text-left';
    }
    else {
        inputClass += ' text-center';
    }
    if(goodNess && goodNess==="bad") {
        inputClass += ' li-border-danger';
    }
    else if(goodNess && goodNess==="good") {
        inputClass += ' li-border-good' ;
    }
    if(readOnly) {
        inputClass += ' li-bg-muted';
    }
    let styleInfo={};
    if(widthPels) {
        styleInfo={"width":widthPels.toString()+"px"};
    }
    
    return(
      <div className="form-group m-0" style={styleInfo} >
          {label && label!==''?<label className={"li-form-label "+(oneRow?"mr-3":"")} htmlFor={name}>{label}</label>:null}
          {helpLink && helpLink!==''?<HelpLink link={helpLink} small/>:null}
              <input
                  type="text"
                  name={name}
                  className={inputClass}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange} 
                  required={required && 'required'} 
                  autoComplete={autoComplete}
                  minLength={minLength} 
                  maxLength={maxLength}
                  autoFocus={focus}
                  readOnly={readOnly}
                  ref={refName}
              />
          {helpText && helpText!=="" && <small className="form-text text-muted">{helpText}</small>}
      </div>
    );
};

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    refName: PropTypes.object,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    helpText: PropTypes.string,
    helpLink: PropTypes.string,
    required: PropTypes.bool,
    goodNess: PropTypes.string,
    autoComplete: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    focus: PropTypes.bool,
    readOnly: PropTypes.bool,
    widthPels: PropTypes.number,
    textLeft: PropTypes.bool,
    oneRow: PropTypes.bool,
};

export default TextInput;