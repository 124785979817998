import React, {useState} from 'react';
import PropTypes from 'prop-types';
import TextInput from "./TextInput";

const DigitInput = ({name, label, onChange, placeholder, value, helpText, helpLink, required, goodNess, autoComplete, 
                      minLength, maxLength, focus, readOnly, widthPels, oneRow}) => {
    
    const [internalValue,setInternalValue] = useState("");
    
    function addDigit(e, newDigit:string) {
        e.preventDefault();

        let curCode = internalValue;
        if(!newDigit) { // get the entire authcode from the event
            try {
                if(e.target.value!=="") {
                    let curNum = e.target.value;
                    curNum = curNum.replace(" ","");
                    if( Number.isInteger(Number(curNum))) {
                        curCode = curNum;
                    }
                    else {
                        return; // not a digit
                    }
                }
                else {
                    curCode = "";
                }
            }
            catch { // not a digit
                return;
            }
        }
        else {
            if (curCode.length < maxLength) {
                curCode += newDigit;
            }
        }
        setInternalValue(curCode);
        // augment the event and send it forward (dangerous)
        e.target.name=name;
        e.target.valu=curCode;
        onChange(e);
    }
    
    return(
        <TextInput
            name={name}
            value={value}
            onChange={(e) => addDigit(e)}
            placeholder={placeholder} centered
            autoComplete={autoComplete} required={required} 
            label={label}
            maxLength={maxLength}
            minLength={minLength}
            focus={focus}
            goodNess={goodNess}
            helpText={helpText}
            helpLink={helpLink}
            readOnly={readOnly}
            widthPels={widthPels}
            oneRow={oneRow}
        />
    );
};

DigitInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    helpText: PropTypes.string,
    helpLink: PropTypes.string,
    required: PropTypes.bool,
    goodNess: PropTypes.string,
    autoComplete: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    stepValue: PropTypes.number,
    focus: PropTypes.bool,
    readOnly: PropTypes.bool,
    widthPels: PropTypes.number,
    oneRow: PropTypes.bool,
};

export default DigitInput;