import React, {useState} from 'react';
import TextInput from '../GUI_COMMON/COMPONENTS/TextInput';
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import ArchiverClientApi from "../API/archiverClientApi";
import Popup from "../GUI_COMMON/COMPONENTS/Popup";
import ErrorPopup from "../GUI_COMMON/COMPONENTS/ErrorPopup";
import LIButton from "../GUI_COMMON/COMPONENTS/LIButton";

const ReprintLabelsForm = () => {
    
    const [isPrinting, setIsPrinting] = useState(false);
    const [goodNess, setGoodness] = useState("bad");
    const [barcode, setBarcode] = useState("");
    
    function handleMagBarcodeChanged(e) {
        const rawStr:string = filterBadChars(e.target.value);

        setBarcode(rawStr.toUpperCase());
        setGoodness(LIUtils.magBarcodeHasGoodFormat(rawStr)?"good":"bad");
    }
    
    function filterBadChars(value:string) {
        // check for a bad characer
        const re=new RegExp('[^0-9a-zA-Z.]');
        if(re.test(value)) { // bad character -- ignore
            console.log("ignoring bad sample # character");
            return barcode;  // no change
        }
        return value;  // return new value
    }
    
    function formIsValid() {
       return LIUtils.magBarcodeHasGoodFormat(barcode);
    }
    
    function onSubmit() {
        setIsPrinting(true);

        ArchiverClientApi.sendReprintLabelsRequest(barcode)
        .then( (mag) => {
            const msg = `<p>Printing of the label for '${barcode}' should be under way now.</p>`;

            Popup.showInfo(msg);
        })
        .catch( errorResponse => ErrorPopup.showError("Print Failed",errorResponse.error))
        .finally( () => {
                setIsPrinting(false);
            });
    }
    
    return (
        <form id="reprintLabelsForm">
           
            <div className="card border-0">
                <h2>Reprint</h2>
                <p>Use this to reprint the barcode label for a single magazine that has already been used in a device.
                The barcode must match one that it already in the system and will still be assigned to the previous device.</p> 
                <p>To reprint a <strong>BOX LABEL</strong>, simply reprint the label first magazine in that box and the box label will automatically be reprinted too.</p>
                <div className="row">
                    <div className="col-5">
                        <TextInput name="barcode" label="Magazine Barcode" onChange={handleMagBarcodeChanged}
                                   goodNess={goodNess}
                                   placeholder="YY<nuemonic>.####.#"
                                   value={barcode}
                                   required/>
                    </div>
                </div>
                
            </div>
            
            <div className="row justify-content-center">
                <LIButton bgClass="mx-5 my-5" large primary onClick={onSubmit} disabled={!formIsValid()}
                          label="Reprint Label" showBusy={isPrinting}/>
            </div>
        </form>
    );
};

ReprintLabelsForm.propTypes = {
    
};

export default ReprintLabelsForm;