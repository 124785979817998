import React from 'react';
import * as PropTypes from "prop-types";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";

const ChaseListCardRow = ({sample,order,dateDueBack,isChecked,onCheckChanged,onSampleClick,onOrderClick}) => {
    
    function dumpSampleInfo() {
        // ### we assume here that the case and sample won't be empty since this IS in a
        // case list after all...so it must have been part of a case.
        return `${sample.caseNumber??"-"} / ${sample.sampleNumber??"-"}`;
    }
    
    function dumpDaysOverDue(dateDueBack) {
        //const dueDate = LIUtils.dateFromJsonUtcDateStr(dateDueBack);
        const now = new Date(); // ### utc?
        const numTicksDiff = (now.getTime() - dateDueBack.getTime())/1000; // fully UTC here
        const numDaysDiff = Math.floor(numTicksDiff / (3600*24));
        return <span>{numDaysDiff} days</span>;
    }

    function showCheckbox() {
        return <td>
            <input type="checkbox" className="m-2 mr-3" name="chaselist-cb" aria-label="Checkbox" checked={isChecked??false}
                   onChange={(e) => handleCBChanged(e)} onClick={(e) => handleCBClicked(e)}/>
        </td>
    }

    function handleCBChanged(e) {
        e.stopPropagation();
        if(onCheckChanged) {
            onCheckChanged(e.target.checked);
        }
    }

    function handleCBClicked(e) {
        e.stopPropagation(e);
    }
    
    function handleSampleOnClick(e) {
        e.stopPropagation();
        if (onSampleClick) {
            onSampleClick();
        }
    }

    function handleOrderOnClick(e) {
        e.stopPropagation();
        if (onOrderClick) {
            onOrderClick();
        }
    }

    return (
        <tr>
            {showCheckbox()}
            <td className="btn-link li-pointer" onClick={(e) => handleSampleOnClick(e)}>{dumpSampleInfo()}</td>
            <td className="btn-link li-pointer" onClick={(e) => handleOrderOnClick(e)}>{LIUtils.prettyOrderNumber(order.id)}</td>
            <td>{dumpDaysOverDue(dateDueBack)}</td>
            {/*<td>{LIUtils.dateToShortDateTimeString(dateDueBack)}</td>*/}
            {/*<td>{dumpDestination()}</td>*/}
        </tr>
    );
}

ChaseListCardRow.propTypes = {
    sample: PropTypes.object.isRequired,
    order: PropTypes.object,
    onSampleClick: PropTypes.func,
    onOrderClick: PropTypes.func,
    dateDueBack: PropTypes.object,  // date
    isChecked: PropTypes.bool,
    onCheckChanged: PropTypes.func,
}

export default ChaseListCardRow;