import React from "react";
import * as PropTypes from "prop-types";
import LIUtils from "../GUI_COMMON/SUPPORT/LIUtils";
import LIButton from "../GUI_COMMON/COMPONENTS/LIButton";

const NotificationCard = ({id, status, type, date, from, orderId, caseId, sampleId, details, isBusy, onMarkReadClick, onDeleteClick}) => {

    function handleMarkReadClicked(e) {
        if (onMarkReadClick) {
            onMarkReadClick(e);
        }
    }

    function handleDeleteClicked(e) {
        if (onDeleteClick) {
            onDeleteClick(e);
        }
    }
    
    function dumpNewDotIfRequired() {
        if(LIUtils.NotificationIsNew(status)) { // new
            // return <span>{LIUtils.ConvertNotificationStatusToStr(status)}</span>
            return <i className="position-absolute badge-pill li-bg-blue" style={{height:"18px",width:"18px"}}></i>;
        }
    }
    
    function dumpMarkReadButton() {
        if(LIUtils.NotificationIsNew(status)) {
            return <LIButton bgClass="li-primary" faIcon="fa fa-envelope-open" small tooltip="mark read"
                             onClick={(e) => handleMarkReadClicked(e)} showBusy={isBusy}></LIButton>
        }
    }
    
    function dumpDeleteButton() {
        if(!LIUtils.NotificationIsNew(status)) { // any "read" notification can be deleted
            return <LIButton bgClass="li-secondary" faIcon="fa fa-trash" tooltip="delete" small onClick={(e) => handleDeleteClicked(e)} showBusy={isBusy}></LIButton>;
        }
    }
    
    function dumpFromIfRequired() {
        if(from) {
            return <span className="">From: <span className="font-italic">{from}</span></span>;
        }
    }
    
    function dumpDate() {
        const dateObj = LIUtils.dateFromJsonUtcDateStr(date);
        return <div className="font-italic li-font-small">{LIUtils.dateToShortDateTimeString(dateObj)}</div>
    }
    
    function dumpDetailsWithNewLines() {
        if(!details) {
            return("no details provided");
        }
        return details.split('\n').flatMap((item,index) => [item, <br key={index}/>]);
    }

    return (<div className="card li-notification-card p-2 bg-light">
                {dumpNewDotIfRequired()}
                <div className="card-body mx-4">
                    <div className="row justify-content-start">
                        <div className="col-3">
                            {dumpDate()}
                            {dumpFromIfRequired()}
                            <div className="font-weight-bold li-font-small">{LIUtils.ConvertNotificationTypeToStr(type)}</div>
                        </div>
                        <div className="col-9 border-left">
                            <div className="">{dumpDetailsWithNewLines()}</div>
                        </div>
                    </div>
                    <div className="position-absolute" style={{right:"5px",top:"5px"}}>
                        {dumpMarkReadButton()}
                        {dumpDeleteButton()}
                    </div>
                </div>
            </div>);
}

NotificationCard.propTypes = {
    id: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
    date: PropTypes.string,
    from: PropTypes.string,
    orderId: PropTypes.number,
    caseId: PropTypes.number,
    sampleId: PropTypes.number,
    details: PropTypes.string,
    isBusy: PropTypes.bool,
    onMarkReadClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
}

export default NotificationCard;