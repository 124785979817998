import PropTypes from "prop-types";
import React from "react";

const HelpLink = ({link, small, medium}) => {
    
    let sizeClass = "fa-3x";
    if(small) {
        sizeClass="fa-1x";
    }
    else if(medium) {
        sizeClass="fa-2x";
    }
    
    return(
        <div className="d-inline-block ml-1">
            <a className="align-baseline" href={"/help/"+link} data-toggle="tooltip"
               title="archiver help" target="_blank" rel="noreferrer">
                <i className={"li-fg-primary fa fa-question-circle "+sizeClass}/></a>
        </div>
    );
}

HelpLink.propTypes = {
    link: PropTypes.string.isRequired,
    small: PropTypes.bool,
    medium: PropTypes.bool
}

export default HelpLink;